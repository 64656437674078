@use "vars" as *;
.page-fair_index {
	.content-left {
		.inner {
			padding: 0 14.644%;
			width: 100%;
			@include mq(xl){
				padding: 0 5%;
			}
			@include mq(){
				padding: 0 16px;
			}
		}
		&__mv {
			max-width: 100%;
		}
	}
}
.fair-list{
	//max-width: 1160px;
    //width: 100%;
    //margin-left: auto;
	//margin-right: auto;
    padding-top: 38px;
    margin-bottom: 119px;
    //transform: translateX(14px);
	@include mq(xl){
		padding-top: 0;
		margin-bottom: 80px;
		transform: translateX(0);
	}
	@include mq(){
		margin-bottom: 59px;
	}
	&__item{
		@include flex();
		margin-bottom: 64px;
		border-bottom: 1px solid #dbdad2;
		padding-bottom: 30px;
		@include mq(){
			margin-bottom: 40px;
			flex-wrap: wrap;
		}
	}
	&__left{
		width: 45.2%;
		@include mq(){
			width: 100%;
		}
	}
	&__img{
		border-radius: 16px;
		overflow: hidden;
		margin-bottom: 42px;
		@include mq(){
			margin-bottom: 37px;
		}
		img{
			width: 100%;
		}
	}
	&__right{
	    width: 54.8%;
		padding-left: 112px;
		@include mq(xl){
			padding-left: 8.99%;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
		}
	}
	&__option{
		&-box{
			&:not(:last-child){
				margin-bottom: 41px;
				@include mq(xl){
					margin-bottom: 34px;
				}
			}
		}
		&-item{
			padding-bottom: 26px;
			margin-bottom: 37px;
			border-bottom: 1px solid #DBDAD2;
			@include mq(xl){
				padding-bottom: 24px;
				margin-bottom: 29px;
			}
		}
		&-time{
			color: #000000;
			margin-bottom: 11px;
			font-family: $font-en;
			font-weight: 500;
			@include font-size(16);
			@include mq(){
				margin-bottom: 1px;
				@include font-size(14);
			}
		}
		&-heading{
			margin-bottom: 25px;
			@include mq(xl){
				margin-bottom: 17px;
			}
		}
		&-desc{
			margin-top: 17px;
			@include mq(xl){
				margin-top: 14px;
			}
		}
		&-ttl{
			font-weight: 600;
			color: #000;
			margin-bottom: 20px;
			@include font-size(16);
			@include mq(){
				margin-bottom: 11px;
				@include font-size(14);
			}
			&--accordion{
				span{
					display: inline-block;
					padding-right: 48px;
					cursor: pointer;
					position: relative;
					&:hover{
						opacity: 0.7;
						transition: all 0.3s;
					}
					@include p-after(32px,32px){
						background: url('../img/fair/icon-plus.svg') center no-repeat;
						background-size: contain;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}
				}
				&.on{
					span{
						&::after{
							background: url('../img/fair/icon-close.svg') center no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}
		&-ct{
			display: none;
		}
	}
}

.eoyster--02{
	max-width: 1160px;
	width: 100%;
	margin-left: auto;
	margin-right: 0;
	position: relative;
	.eoyster-box{
		padding: 25px 40px 31px;
		top: 56%;
		@include mq(){
			padding: 20px 17px;
			// top: 38.8%;
			top: 6%;
		}
	}
	.c-txt__01{
		max-width: 485px;
	}
	.c-btn__01{
		position: absolute;
		right: 39px;
		bottom: 59px;
		@include mq(){
			right: 15px;
			bottom: 35px;
		}
	}
	.eoyster-bg img{
		min-height: 394px;
		@include mq(xl){
			min-height: 300px;
		}
		@include mq(){
			min-height: 537px;
			height: auto;
		}
	}
}


.eoyster--03{
	margin-right: auto;
	transform: translateX(15px);
	@include mq(xl){
		transform: translateX(0);
	}
}

.eoyster--486{
	@include mq(){
		.eoyster-box{
			// top: 37.8%;
			top: 7%;
		}
		.eoyster-bg img{
			@include mq(){
				min-height: 486px;
			}
		}
	}
}
.eoyster--480{
	@include mq(){
		.eoyster-box{
			// top: 37.8%;
			top: 7%;
		}
		.eoyster-bg img{
			@include mq(){
				min-height: 480px;
			}
		}
	}
}