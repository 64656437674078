@charset "UTF-8";
/* ====================================================
Font
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap");
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px; /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

body {
  color: #000;
  font-family: "Noto Sans JP", serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}
a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover {
  opacity: 0.7;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  a {
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1245px) {
  .pc-xl {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-tb {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 385px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1246px) {
  .sp-xl {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-tb {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.u-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.u-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

.c-fc--white {
  color: white !important;
}
.c-fc--black {
  color: black !important;
}

.c-ft--en {
  font-family: "Lato", serif;
}
.c-ft--jp {
  font-family: "Noto Sans JP", serif;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-tx--v {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 835px) {
  .c-tx--v-pc {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  line-height: 2;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .c-txt__01 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 385px) {
  .c-txt__01 {
    font-size: 1.2rem;
  }
}
.c-txt__01--white {
  color: #fff;
}
@media screen and (max-width: 834px) {
  .c-txt__01--sp16 {
    font-size: 1.6rem;
  }
}

.c-ttl__01 {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    display: block;
  }
}
.c-ttl__01--white {
  color: #fff;
}
.c-ttl__01--block {
  display: block;
}
.c-ttl__01--block span {
  display: block;
}
@media screen and (min-width: 1246px) {
  .c-ttl__01--block .c-ft--jp {
    padding-top: 21px !important;
  }
}
.c-ttl__01 .c-ft--en {
  letter-spacing: -1px;
  font-weight: bold;
  margin-right: 41px;
  font-family: "Lato", serif;
  font-size: 6.4rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__01 .c-ft--en {
    letter-spacing: 0;
    margin-right: 20px;
    font-size: 5rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 .c-ft--en {
    margin-right: 0;
    width: 100%;
    display: block;
    font-size: 3.6rem;
  }
}
.c-ttl__01 .c-ft--jp {
  padding-top: 17px;
  font-weight: 600;
  font-size: 1.8rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__01 .c-ft--jp {
    padding-top: 10px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 .c-ft--jp {
    display: block;
    width: 100%;
  }
}
.c-ttl__02 {
  line-height: 1.65625;
  font-weight: bold;
  color: #fff;
  margin-bottom: 33px;
  font-size: 3.2rem;
}
@media screen and (max-width: 1440px) {
  .c-ttl__02 {
    margin-bottom: 12px;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 1245px) {
  .c-ttl__02 {
    margin-bottom: 12px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__02 {
    margin-bottom: 22px;
    line-height: 1.423;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__02 {
    font-size: 2.1rem;
  }
}
.c-ttl__02--black {
  color: #000;
}
.c-ttl__03 > span {
  display: block;
  color: #000;
}
.c-ttl__03 .c-ft--jp {
  font-weight: 500;
  line-height: 1;
  margin-bottom: 38px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: clamp(3.8rem, 2.933rem + 2.222vw, 7.2rem);
}
@media screen and (max-width: 1245px) {
  .c-ttl__03 .c-ft--jp {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03 .c-ft--jp {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__03 .c-ft--jp {
    font-size: 3rem;
  }
}
.c-ttl__03 .c-ft--jp span {
  display: inline-block;
  font-weight: 500;
  margin-left: 55px;
  font-size: 3.2rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__03 .c-ft--jp span {
    margin-left: 30px;
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__03 .c-ft--jp span {
    margin-top: 18px;
    margin-bottom: -5px;
    width: 100%;
    margin-left: 0;
    display: block;
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__03 .c-ft--jp span {
    font-size: 2rem;
  }
}
.c-ttl__03 .c-ft--en {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 1.8rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__03 .c-ft--en {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__03 .c-ft--en {
    font-size: 1.2rem;
  }
}
.c-ttl__04 {
  color: #000;
  font-weight: 600;
  margin-bottom: 13px;
  font-size: 2.9rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__04 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__04 {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__04 {
    font-size: 1.8rem;
  }
}
.c-ttl__04 span {
  font-weight: 500;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__04 span {
    font-size: 1.4rem;
  }
}
.c-ttl__05 {
  font-weight: bold;
  margin-bottom: 24px;
  font-size: 2.1rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__05 {
    margin-bottom: 15px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__05 {
    margin-bottom: 17px;
    line-height: 1.778;
  }
}
@media screen and (max-width: 385px) {
  .c-ttl__05 {
    font-size: 1.6rem;
  }
}
.c-ttl__06 {
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.6111;
  font-size: 1.8rem;
}
@media screen and (max-width: 1245px) {
  .c-ttl__06 {
    margin-bottom: 15px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__06 {
    margin-bottom: 15px;
    line-height: 1.778;
  }
}

.c-btn__01 {
  display: inline-block;
  padding-right: 65px;
  color: #fff;
  position: relative;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    padding-right: 47px;
  }
}
.c-btn__01 span {
  padding-bottom: 9px;
  display: inline-block;
  font-weight: 500;
  position: relative;
  font-size: 1.8rem;
}
.c-btn__01 span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
}
.c-btn__01 span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .c-btn__01 span {
    padding-bottom: 8px;
    line-height: 1.2;
    font-style: normal;
    font-size: 1.4rem;
  }
}
.c-btn__01--small {
  padding-right: 85px;
}
@media screen and (max-width: 834px) {
  .c-btn__01--small {
    padding-right: 45px;
    max-width: 189px;
    margin-left: auto;
    display: block;
  }
}
.c-btn__01--small span {
  font-style: normal;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .c-btn__01--small span {
    font-size: 1.4rem;
  }
}
.c-btn__01 .c-arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  right: 0;
  overflow: hidden;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .c-btn__01 .c-arrow {
    width: 36px;
    height: 36px;
  }
}
.c-btn__01 .c-arrow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .c-btn__01 .c-arrow:before {
    width: 12px;
    height: 10px;
  }
}
.c-btn__01 .c-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow.svg") center no-repeat;
  background-size: contain;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .c-btn__01 .c-arrow:after {
    width: 12px;
    height: 10px;
  }
}
@media screen and (min-width: 835px) {
  .c-btn__01:hover {
    opacity: 1;
  }
  .c-btn__01:hover span::before {
    width: 0;
    transition: all 0.3s;
  }
  .c-btn__01:hover span::after {
    width: 100%;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  .c-btn__01:hover .c-arrow {
    background: rgba(13, 38, 65, 0.9);
    transition: all 0.3s;
  }
  .c-btn__01:hover .c-arrow::before {
    opacity: 0;
    transition: all 0.3s;
  }
  .c-btn__01:hover .c-arrow::after {
    left: 13px;
    transition: all 0.3s;
  }
}
.c-btn__01--black {
  color: #000;
  padding-right: 45px;
}
@media screen and (max-width: 834px) {
  .c-btn__01--black {
    padding-right: 32px;
    max-width: max-content;
    display: inline-block;
    line-height: 1.3;
  }
}
.c-btn__01--black span::before, .c-btn__01--black span::after {
  background: #000;
}
.c-btn__01--black .c-arrow {
  top: 37%;
}
@media screen and (max-width: 834px) {
  .c-btn__01--black .c-arrow {
    background: none;
  }
}
.c-btn__01--black .c-arrow::after {
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
}
@media screen and (min-width: 835px) {
  .c-btn__01--black:hover .c-arrow {
    background: none;
  }
}
.c-btn__01--border {
  padding-right: 64px;
}
@media screen and (max-width: 834px) {
  .c-btn__01--border {
    padding-right: 48px;
  }
}
.c-btn__01--border .c-arrow {
  top: 52%;
  border: 1px solid #0A1F36;
}
@media screen and (max-width: 834px) {
  .c-btn__01--border .c-arrow {
    top: 48%;
  }
}
.c-btn__02 {
  color: #fff;
  background: #BA915B;
  border-radius: 4px;
  padding: 10px 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  max-width: 320px;
  width: 100%;
  height: 72px;
  position: relative;
  font-size: 1.8rem;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 1245px) {
  .c-btn__02 {
    max-width: 280px;
    height: 60px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 834px) {
  .c-btn__02 {
    max-width: 358px;
    margin: 0 auto;
    height: 68px;
    padding: 10px 15px;
    font-size: 1.4rem;
  }
}
.c-btn__02 .c-arrow {
  position: absolute;
  width: 14px;
  right: 24px;
  top: 50%;
  overflow: hidden;
  height: 12px;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .c-btn__02 .c-arrow {
    width: 12px;
    height: 10px;
    right: 16px;
  }
}
.c-btn__02 .c-arrow::after, .c-btn__02 .c-arrow::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .c-btn__02 .c-arrow::after, .c-btn__02 .c-arrow::before {
    width: 12px;
    height: 10px;
  }
}
.c-btn__02 .c-arrow::before {
  left: -20px;
  opacity: 0;
  transition: all 0;
}
.c-btn__02--center {
  margin: 0 auto;
}
.c-btn__02--left {
  margin-left: 0;
  margin-right: auto;
}
@media screen and (min-width: 835px) {
  .c-btn__02:hover {
    opacity: 1;
    background: #af854e;
  }
  .c-btn__02:hover .c-arrow::after {
    opacity: 0;
    transition: all 0.3s;
  }
  .c-btn__02:hover .c-arrow::before {
    opacity: 1;
    left: 0;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
}
.c-btn__02--link {
  position: relative;
}
.c-btn__02--link:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 10px;
  background: url("../img/common/icon-link-white.svg") center no-repeat;
  background-size: contain;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .c-btn__02--link:after {
    right: 17px;
  }
}
.c-btn__02--back {
  text-align: center;
  padding: 10px 30px;
  justify-content: center;
}
.c-btn__02--back .c-arrow {
  transform: scaleX(-1) translateY(-50%);
  right: auto;
  left: 24px;
}
@media screen and (max-width: 834px) {
  .c-btn__02--back .c-arrow {
    left: 16px;
  }
}
.c-btn__02--gray {
  background: #888888;
}
@media screen and (min-width: 835px) {
  .c-btn__02--gray:hover {
    opacity: 1;
    background: #888888;
  }
  .c-btn__02--gray:hover .c-arrow::after {
    opacity: 0;
    transition: all 0.3s;
  }
  .c-btn__02--gray:hover .c-arrow::before {
    opacity: 1;
    left: 0;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
}
.c-btn__02--lg {
  max-width: 400px;
}
.c-btn__03 {
  display: inline-block;
  color: #000;
  font-weight: 500;
  font-family: "Lato", serif;
  font-size: 1.4rem;
  line-height: 1.4;
  padding-right: 30px;
  position: relative;
}
.c-btn__03:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 20px;
  background: url("../img/index/arrow04.svg") center no-repeat;
  background-size: contain;
  right: 3px;
  top: 37%;
  transition: all 0.3s;
  transform: translateY(-50%);
}
.c-btn__03 > span {
  text-decoration: underline;
  display: inline-block;
}
@media screen and (max-width: 834px) {
  .c-btn__03 > span {
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid #000;
  }
}
.c-btn__03 > span span {
  font-family: "Noto Sans JP", serif;
}
@media screen and (max-width: 834px) {
  .c-btn__03 {
    padding-right: 21px;
  }
}
.c-btn__03--lg {
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .c-btn__03--lg {
    font-size: 1.4rem;
  }
}
.c-btn__03--shop {
  border-bottom: none !important;
}
.c-btn__03--shop > span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #000;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
}
.c-btn__03--shop > span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 1px;
  background: #000;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
.c-btn__03--shop:hover > span::before {
  width: 0;
  transition: all 0.3s;
}
.c-btn__03--shop:hover > span::after {
  width: 100%;
  transition: all 0.3s;
  transition-delay: 0.3s;
}
@media screen and (min-width: 835px) {
  .c-btn__03:hover::after {
    transition: all 0.3s;
    right: -4px;
  }
}
.c-btn__04 {
  position: relative;
  padding-bottom: 10px;
  padding-right: 30px;
  display: block;
  color: #000;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .c-btn__04 {
    padding-bottom: 6px;
    font-size: 1.4rem;
  }
}
.c-btn__04:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #000;
  bottom: 0;
  right: 0;
  transition: all 0.3s;
}
.c-btn__04:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0;
  height: 1px;
  border-bottom: 1px solid #000;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
.c-btn__04 .c-arrow {
  position: absolute;
  width: 14px;
  right: 0;
  top: 50%;
  overflow: hidden;
  height: 12px;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .c-btn__04 .c-arrow {
    width: 12px;
    height: 10px;
    right: 0;
  }
}
.c-btn__04 .c-arrow::after, .c-btn__04 .c-arrow::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .c-btn__04 .c-arrow::after, .c-btn__04 .c-arrow::before {
    width: 12px;
    height: 10px;
  }
}
.c-btn__04 .c-arrow::before {
  left: -20px;
  opacity: 0;
  transition: all 0;
}
@media screen and (min-width: 835px) {
  .c-btn__04:hover::before {
    width: 0;
    transition: all 0.3s;
  }
  .c-btn__04:hover::after {
    width: 100%;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  .c-btn__04:hover .c-arrow::after {
    opacity: 0;
    transition: all 0.3s;
  }
  .c-btn__04:hover .c-arrow::before {
    opacity: 1;
    left: 0;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
}
.c-btn__05 {
  color: #000;
  text-decoration: underline;
  font-size: 1.6rem;
  display: inline-block;
}
.c-btn__06 {
  position: relative;
  display: inline-block;
  padding-right: 30px;
  color: #000;
  font-size: 1.6rem;
}
.c-btn__06:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 15px;
  height: 12px;
  background: url("../img/8th_sea_oyster/for_restaurants/icon-down.svg") center no-repeat;
  background-size: contain;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.c-btn__06 span {
  display: inline-block;
  padding-bottom: 7px;
  border-bottom: 1px solid #000;
}
.c-btn__tel {
  display: inline-block;
  padding-left: 20px;
  color: #000;
  font-weight: 600;
  position: relative;
  font-size: 1.8rem;
}
@media screen and (max-width: 834px) {
  .c-btn__tel {
    font-size: 1.6rem;
  }
}
.c-btn__tel:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  background: url("../img/company/icon-tel.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.inner {
  display: block;
  max-width: 1712px;
  width: 90%;
  margin: 0 auto;
}
.inner--1440 {
  max-width: 1440px;
}
.inner--1325 {
  max-width: 1325px;
}
.inner--1160 {
  max-width: 1160px;
}
.inner--1120 {
  max-width: 1120px;
}
@media screen and (max-width: 834px) {
  .inner {
    width: 100%;
    padding: 0 16px;
  }
}

.wrapper {
  padding-top: 219px;
}
@media screen and (max-width: 834px) {
  .wrapper {
    padding-top: 164px;
  }
}

@media screen and (max-width: 834px) {
  .wrapper-sp {
    padding-top: 169px;
  }
}

.sec-breadcrumb .inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 0;
}
.sec-breadcrumb a,
.sec-breadcrumb span {
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .sec-breadcrumb a,
  .sec-breadcrumb span {
    font-size: 1.4rem;
  }
}
.sec-breadcrumb a {
  margin-right: 24px;
  padding-right: 24px;
  color: #000;
  position: relative;
  text-decoration: underline;
}
@media screen and (max-width: 834px) {
  .sec-breadcrumb a {
    margin-right: 21px;
    padding-right: 21px;
  }
}
.sec-breadcrumb a:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 18px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #000;
}
@media screen and (max-width: 834px) {
  .sec-breadcrumb a:after {
    height: 14px;
    top: 54%;
  }
}
.sec-breadcrumb span {
  color: #838383;
}

.list-tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .list-tags {
    margin-bottom: 11px;
  }
}
.list-tags__item span,
.list-tags__item a {
  border: 1px solid #000;
  display: inline-block;
  height: 28px;
  min-width: 120px;
  background: #fff;
  padding: 4px 24px;
  font-weight: 500;
  color: #000;
  font-size: 1.2rem;
  border-radius: 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .list-tags__item span,
  .list-tags__item a {
    padding: 4px 14px;
    min-width: 116px;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 835px) {
  .mbpc-120 {
    margin-bottom: 120px !important;
  }
  .mbpc-116 {
    margin-bottom: 116px !important;
  }
}
.font-noto {
  font-family: "Noto Sans JP", serif !important;
}

.font-lato {
  font-family: "Lato", serif !important;
}

.content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .content {
    flex-wrap: wrap-reverse;
  }
}
.content-left {
  padding: 129px 0 58px;
  width: calc(100% - 280px);
  border-right: 1px solid #dbdad2;
}
@media screen and (max-width: 1770px) {
  .content-left {
    width: calc(100% - 200px);
    padding: 110px 0 50px 0;
  }
}
@media screen and (max-width: 1245px) {
  .content-left {
    padding-right: 0;
    width: calc(100% - 150px);
  }
}
@media screen and (max-width: 834px) {
  .content-left {
    width: 100%;
    padding: 58px 0 26px;
    border-right: 0;
  }
}
.content-left__mw {
  max-width: 1296px;
  width: 100%;
}
.content-left--02 {
  padding-top: 0;
  padding-bottom: 0;
}
.content-right {
  width: 280px;
  padding: 79px 15px 0 39px;
  position: relative;
}
@media screen and (max-width: 1770px) {
  .content-right {
    width: 200px;
    padding: 79px 15px 0 15px;
  }
}
@media screen and (max-width: 1245px) {
  .content-right {
    width: 150px;
    padding: 60px 15px 0 15px;
  }
}
@media screen and (max-width: 834px) {
  .content-right {
    width: 100%;
    padding: 64px 0 0 15px;
    overflow-x: auto;
  }
}
.content-right__fixed {
  position: sticky;
  top: 150px;
  left: 0;
  padding-bottom: 30px;
}
@media screen and (max-width: 1024px) {
  .content-right__fixed {
    top: 50px;
  }
}
@media screen and (max-width: 834px) {
  .content-right__fixed {
    height: auto;
    padding-bottom: 0;
    position: static;
    width: 680px;
  }
}
.content-right .list-menu {
  display: block;
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .content-right .list-menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 0 20px;
    margin-bottom: 20px;
  }
}
.content-right .list-menu__item {
  margin-bottom: 16px;
}
@media screen and (max-width: 1245px) {
  .content-right .list-menu__item {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .content-right .list-menu__item {
    margin-bottom: 0;
  }
}
.content-right .list-menu__item a {
  text-align: left;
  padding: 8px 0;
  font-size: 1.2rem;
}
@media screen and (max-width: 1245px) {
  .content-right .list-menu__item a {
    padding: 8px 0;
  }
}
@media screen and (max-width: 834px) {
  .content-right .list-menu__item a {
    padding: 4px 0;
    font-size: 1.6rem;
  }
}
.content-right .list-menu__item a:hover {
  background: #0d2641;
  padding: 8px 24px;
}
@media screen and (max-width: 1245px) {
  .content-right .list-menu__item a:hover {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 834px) {
  .content-right .list-menu__item a:hover {
    padding: 11px 24px;
  }
}
.content-right .list-menu__item.active a {
  padding: 8px 24px;
}
@media screen and (max-width: 1245px) {
  .content-right .list-menu__item.active a {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 834px) {
  .content-right .list-menu__item.active a {
    padding: 6px 19px;
  }
}
@media screen and (max-width: 834px) {
  .content-right--02 {
    padding: 30px 0 0 0;
  }
}

@media screen and (max-width: 834px) {
  .menu-accordion {
    border-radius: 10px;
    background: #f3f3f3;
    margin: 0 16px 59px;
    padding: 0 15px;
  }
  .menu-accordion .list-menu {
    display: none;
    border-top: 1px solid #fff;
    max-width: 100%;
    padding: 22px 0 12px;
  }
  .menu-accordion .list-menu__item:not(:last-child) {
    margin-bottom: 11px;
  }
  .menu-accordion .list-menu__item a {
    font-size: 1.4rem;
  }
}
.menu-accordion__click {
  cursor: pointer;
  position: relative;
  font-family: "Lato", serif;
  font-size: 1.6rem;
  padding: 25px 0 25px 49px;
}
.menu-accordion__click:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 32px;
  background: url("../img/8th_sea_oyster/icon-plus.svg") center no-repeat;
  background-size: contain;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.menu-accordion__click.on::before {
  background: url("../img/8th_sea_oyster/icon-close.svg") center no-repeat;
  background-size: contain;
}

.content-block {
  margin-bottom: 94px;
}
@media screen and (max-width: 1245px) {
  .content-block {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 834px) {
  .content-block {
    margin-bottom: 54px;
  }
}
.content-block .c-ttl__01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1245px) {
  .content-block .c-ttl__01 {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .content-block .c-ttl__01 {
    margin-bottom: 15px;
  }
}
.content-block > .c-txt__01 {
  max-width: 650px;
}
.content-block__row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .content-block__row {
    flex-wrap: wrap;
  }
}
.content-block__row .c-ttl__02 {
  width: 53.25%;
  padding-right: 50px;
}
@media screen and (max-width: 1245px) {
  .content-block__row .c-ttl__02 {
    width: 45%;
    padding-right: 25px;
  }
}
@media screen and (max-width: 834px) {
  .content-block__row .c-ttl__02 {
    width: 100%;
    padding-right: 0;
    margin-bottom: 18px;
  }
}
.content-block__row .c-txt__01 {
  width: 46.75%;
  padding-right: 131px;
}
@media screen and (max-width: 1440px) {
  .content-block__row .c-txt__01 {
    padding-right: 0;
  }
}
@media screen and (max-width: 1245px) {
  .content-block__row .c-txt__01 {
    width: 55%;
  }
}
@media screen and (max-width: 834px) {
  .content-block__row .c-txt__01 {
    width: 100%;
  }
}
.content-block--full {
  margin-bottom: 66px;
}
@media screen and (max-width: 834px) {
  .content-block--full {
    margin-bottom: 54px;
  }
}
@media screen and (max-width: 834px) {
  .content-block--full .c-ttl__01 {
    margin-bottom: 28px;
  }
}
.content-block--full .content-block__row {
  display: block;
}
.content-block--full .content-block__row > * {
  width: 100%;
}

.voice-box .c-ttl__04 {
  margin-bottom: 67px;
}
@media screen and (max-width: 1245px) {
  .voice-box .c-ttl__04 {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .voice-box .c-ttl__04 {
    margin-bottom: 27px;
  }
}
.voice-box__list-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 41px;
  border-bottom: 1px solid #dbdad2;
}
@media screen and (max-width: 834px) {
  .voice-box__list-item {
    flex-wrap: wrap-reverse;
  }
}
.voice-box__list-item:not(:last-child) {
  margin-bottom: 47px;
}
@media screen and (max-width: 834px) {
  .voice-box__list-item:not(:last-child) {
    margin-bottom: 49px;
  }
}
@media screen and (max-width: 834px) {
  .voice-box__list-item {
    padding-bottom: 45px;
  }
  .voice-box__list-item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.voice-box__list-ct {
  width: 54.75%;
  padding-right: 112px;
}
@media screen and (max-width: 1440px) {
  .voice-box__list-ct {
    padding-right: 25px;
  }
}
@media screen and (max-width: 834px) {
  .voice-box__list-ct {
    padding-right: 0;
    width: 100%;
  }
}
.voice-box__list-ct .c-ttl__05 {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .voice-box__list-ct .c-ttl__05 {
    margin-bottom: 0;
  }
}
.voice-box__list-desc {
  margin-bottom: 31px;
}
@media screen and (max-width: 1245px) {
  .voice-box__list-desc {
    margin-bottom: 18px;
  }
}
.voice-box__list-desc span {
  display: block;
  font-weight: 500;
  margin-top: 4px;
  font-size: 1.2rem;
}
.voice-box__list-img {
  width: 45.25%;
  position: relative;
}
.voice-box__list-img::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.voice-box__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.voice-box__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.voice-box__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .voice-box__list-img {
    width: 100%;
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 834px) {
  .voice-box__list--franchise .voice-box__list-img::before {
    padding-top: 62.412214%;
  }
}
@media screen and (max-width: 834px) {
  .voice-box__list--franchise .voice-box__list-ct {
    padding: 0 16px;
  }
}

@media screen and (max-width: 834px) {
  .box-scroll {
    overflow-x: auto;
    margin-right: -15px;
    margin-bottom: 60px;
  }
}

.vol-tabs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 76px;
}
@media screen and (max-width: 834px) {
  .vol-tabs {
    margin-bottom: 0;
  }
}
.vol-tabs.tab-menu {
  max-width: max-content;
  flex-wrap: nowrap;
  gap: 8px;
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .vol-tabs.tab-menu {
    margin-bottom: 0;
  }
}
.vol-tabs li {
  display: inline-block;
  margin: 0 6px;
  padding: 13px 10px;
  border-radius: 25px;
  font-size: 1.8rem;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 834px) {
  .vol-tabs li {
    padding: 8px 5px;
  }
}
.vol-tabs li.active {
  background: #0d2641;
  color: #fff;
  padding: 13px 24px;
}
@media screen and (max-width: 834px) {
  .vol-tabs li.active {
    padding: 9px 21px;
  }
}
@media (any-hover: hover) {
  .vol-tabs li {
    transition: all 0.2s;
  }
  .vol-tabs li:hover {
    background: #0d2641;
    color: #fff;
    padding: 13px 24px;
  }
}
@media screen and (any-hover: hover) and (max-width: 834px) {
  .vol-tabs li:hover {
    padding: 9px 21px;
  }
}

.eoyster-box {
  width: 100%;
  padding: 75px 80px 81px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 1245px) {
  .eoyster-box {
    padding: 60px 30px;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-box {
    padding: 57px 16px 65px;
    border-radius: 15px;
  }
}
@media screen and (max-width: 640px) {
  .eoyster-box {
    top: 0;
    transform: none;
  }
}
.eoyster-box__heading {
  font-weight: 600;
  color: #fff;
  margin-bottom: 38px;
  font-size: 2.9rem;
}
@media screen and (max-width: 1245px) {
  .eoyster-box__heading {
    font-size: 2.2rem;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-box__heading {
    line-height: 1.6636;
  }
}
.eoyster-box__ttl {
  font-weight: 600;
  color: #fff;
  margin-bottom: 11px;
  font-size: 2.1rem;
}
@media screen and (max-width: 1245px) {
  .eoyster-box__ttl {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-box__ttl {
    margin-bottom: 13px;
  }
}
.eoyster-box .c-txt__01 {
  max-width: 585px;
  margin-bottom: 39px;
}
@media screen and (max-width: 1245px) {
  .eoyster-box .c-txt__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-box .c-txt__01 {
    margin-bottom: 30px;
  }
}
.eoyster-box__btn {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 18px;
}
@media screen and (max-width: 834px) {
  .eoyster-box__btn {
    gap: 20px 18px;
  }
}
.eoyster-box__btn-item {
  width: 320px;
}
.eoyster-box__btn-item img {
  margin-right: 10px;
}

.guide-list--number {
  counter-reset: item;
  margin-bottom: 80px;
}
@media screen and (max-width: 1245px) {
  .guide-list--number {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 834px) {
  .guide-list--number {
    margin-bottom: -9px;
  }
}
.guide-list--number .guide-list__item {
  counter-increment: item;
  margin-bottom: 40px;
}
@media screen and (min-width: 1246px) {
  .guide-list--number .guide-list__item {
    align-items: flex-end;
  }
}
@media screen and (max-width: 834px) {
  .guide-list--number .guide-list__item {
    padding-bottom: 40px;
    margin-bottom: 38px;
  }
}
.guide-list--number .guide-list__ct {
  padding-left: 96px;
  position: relative;
}
@media screen and (max-width: 1245px) {
  .guide-list--number .guide-list__ct {
    padding-left: 60px;
  }
}
@media screen and (max-width: 834px) {
  .guide-list--number .guide-list__ct {
    padding-left: 0;
  }
  .guide-list--number .guide-list__ct .c-ttl__05 {
    margin-bottom: 23px;
    padding-left: 63px;
  }
}
.guide-list--number .guide-list__ct:before {
  content: "0" counter(item);
  color: #ba915b;
  font-weight: 500;
  position: absolute;
  left: 0;
  top: -28px;
  line-height: 1;
  font-family: "Lato", serif;
  font-size: 4.8rem;
}
@media screen and (max-width: 1245px) {
  .guide-list--number .guide-list__ct:before {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 834px) {
  .guide-list--number .guide-list__ct:before {
    top: 0;
  }
}
.guide-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 48px;
  margin-bottom: 66px;
  border-bottom: 1px solid #dbdad2;
}
@media screen and (max-width: 1245px) {
  .guide-list__item {
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .guide-list__item {
    padding-bottom: 40px;
    margin-bottom: 23px;
  }
}
.guide-list__item .c-ttl__04 {
  width: 100%;
  margin-bottom: 66px;
}
@media screen and (max-width: 1245px) {
  .guide-list__item .c-ttl__04 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .guide-list__item .c-ttl__04 {
    margin-bottom: 30px;
  }
}
.guide-list__ct {
  width: calc(100% - 360px);
  padding-right: 70px;
  margin-bottom: -4px;
}
@media screen and (max-width: 1440px) {
  .guide-list__ct {
    padding-right: 30px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1245px) {
  .guide-list__ct {
    padding-right: 20px;
    width: calc(100% - 200px);
  }
}
@media screen and (max-width: 834px) {
  .guide-list__ct {
    padding-right: 0;
    width: 100%;
    margin-bottom: 25px;
  }
}
.guide-list__ct .c-ttl__05 {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .guide-list__ct .c-ttl__05 {
    margin-bottom: 7px;
  }
}
.guide-list__img {
  overflow: hidden;
  width: 360px;
  border-radius: 8px;
  position: relative;
}
.guide-list__img::before {
  content: "";
  float: left;
  padding-top: 55.5555555556%;
}
.guide-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.guide-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.guide-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .guide-list__img {
    width: 200px;
  }
}
@media screen and (max-width: 834px) {
  .guide-list__img {
    width: 100%;
  }
}
@media screen and (min-width: 1246px) {
  .guide-list--02 .guide-list__ct {
    padding-bottom: 33px;
  }
}

.bg-gray {
  background: #f3f3f3;
}

@media screen and (max-width: 834px) {
  .pd0-sp {
    padding: 0;
  }
}

@media screen and (min-width: 1246px) {
  .fz32-pc {
    font-size: 3.2rem !important;
  }
}
.wp-pagenavi {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 16px;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi {
    gap: 0 18px;
  }
}
.wp-pagenavi .page,
.wp-pagenavi .current,
.wp-pagenavi .extend {
  font-size: 1.8rem;
  font-weight: 500;
  border: 0 !important;
  font-family: "Lato", serif;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .page,
  .wp-pagenavi .current,
  .wp-pagenavi .extend {
    font-size: 1.6rem;
  }
}
.wp-pagenavi .current {
  color: #888888;
  text-decoration: none;
}
.wp-pagenavi .extend {
  text-decoration: none;
}

.previouspostslink,
.nextpostslink {
  width: 40px;
  height: 40px;
  position: relative;
  background: #fff;
  border: 1px solid #000;
  border-radius: 50%;
  right: 0;
  overflow: hidden;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .previouspostslink,
  .nextpostslink {
    width: 36px;
    height: 36px;
  }
}
.previouspostslink::before,
.nextpostslink::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url(../img/index/arrow02.svg) 50% no-repeat;
  background-size: contain;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .previouspostslink::before,
  .nextpostslink::before {
    right: 10px;
  }
}
.previouspostslink:after,
.nextpostslink:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url(../img/index/arrow.svg) 50% no-repeat;
  background-size: contain;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.previouspostslink {
  margin-right: 16px;
}
@media screen and (max-width: 834px) {
  .previouspostslink {
    margin-right: 50px;
  }
}
.previouspostslink::before, .previouspostslink:after {
  transform: translateY(-50%) rotate(-180deg);
}
.previouspostslink:after {
  left: auto;
  right: -20px;
}
@media (any-hover: hover) {
  .previouspostslink {
    transition: all 0.3s;
  }
  .previouspostslink:hover {
    background: #000;
    transition: all 0.3s;
  }
  .previouspostslink:hover::before {
    opacity: 0;
    transition: all 0.3s;
  }
  .previouspostslink:hover:after {
    right: 13px;
    transition: all 0.3s;
  }
}

.nextpostslink {
  margin-left: 16px;
}
@media screen and (max-width: 834px) {
  .nextpostslink {
    margin-left: 50px;
  }
}
@media (any-hover: hover) {
  .nextpostslink {
    transition: all 0.3s;
  }
  .nextpostslink:hover {
    background: #000;
  }
  .nextpostslink:hover::before {
    opacity: 0;
    transition: all 0.3s;
  }
  .nextpostslink:hover:after {
    left: 13px;
    transition: all 0.3s;
  }
}

.single-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.single-block__img {
  width: 49.74%;
}
@media screen and (max-width: 834px) {
  .single-block__img {
    width: 100%;
    margin-bottom: 40px;
  }
}
.single-block__ct {
  width: 50.26%;
  padding-left: 110px;
}
@media screen and (max-width: 1245px) {
  .single-block__ct {
    padding-right: 0;
    padding-left: 8.835vw;
  }
}
@media screen and (max-width: 834px) {
  .single-block__ct {
    width: 100%;
    padding: 0 16px;
  }
}
.single-block__ct-ttl {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .single-block__ct-ttl {
    margin-bottom: 35px;
  }
}

.btn-group {
  max-width: 816px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 16px;
}
@media screen and (max-width: 640px) {
  .btn-group {
    grid-template-columns: 1fr;
  }
}
.btn-group .c-btn__02 {
  max-width: 100%;
}
.btn-group--center {
  margin: 0 auto;
}

.custom-select {
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .custom-select {
    font-size: 1.2rem;
  }
}
.custom-select-trigger {
  width: 100%;
  height: 70px;
  background: #f8f8f8;
  border: 1px solid #dbdad2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 24px;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .custom-select-trigger {
    height: 65px;
    padding: 16px;
  }
}
@media (any-hover: hover) {
  .custom-select-trigger {
    transition: all 0.2s;
  }
  .custom-select-trigger:hover {
    opacity: 0.7;
  }
}
.custom-select-trigger:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 32px;
  background: url(../img/recruit/ico-dropdown.png) no-repeat center/100%;
  top: calc(50% - 16px);
  right: 32px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .custom-select-trigger:after {
    right: 16px;
  }
}
.custom-select.opened .custom-select-trigger:after {
  background: url(../img/recruit/ico-dropdown-02.png) no-repeat center/100%;
}
.custom-select.opened .custom-options {
  display: block;
}

.custom-options {
  background: #fff;
  border: 1px solid #dbdad2;
  border-top: none;
  display: none;
}
.custom-options .custom-option {
  padding: 20px 24px;
  display: block;
  position: relative;
}
@media screen and (max-width: 834px) {
  .custom-options .custom-option {
    padding: 20px 16px;
  }
}
.custom-options .custom-option:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 48px);
  height: 1px;
  background: #dbdad2;
  bottom: 0;
  left: 24px;
}
@media screen and (max-width: 834px) {
  .custom-options .custom-option:after {
    width: calc(100% - 32px);
    left: 16px;
  }
}
.custom-options .custom-option:last-child:after {
  display: none;
}
@media (any-hover: hover) {
  .custom-options .custom-option {
    transition: all 0.2s;
  }
  .custom-options .custom-option:hover {
    cursor: pointer;
    background: #f8f8f8;
  }
}

.form-control {
  width: 100%;
  height: 70px;
  padding: 0 24px;
  border: 1px solid #dbdad2;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .form-control {
    height: 65px;
    font-size: 1.2rem;
    padding: 0 15px;
  }
}
.form-control--short {
  width: 200px;
}
@media screen and (max-width: 834px) {
  .form-control--short {
    width: 114px;
  }
}
.form-control--textarea {
  height: 260px;
  padding: 24px;
  resize: none;
}
@media screen and (max-width: 834px) {
  .form-control--textarea {
    padding: 24px 16px;
    height: 230px;
  }
}
.form-control--select {
  cursor: pointer;
  position: relative;
  background: #fff url(../img/recruit/arr-down.png) no-repeat center right 32px/12px 7px;
}
@media screen and (max-width: 834px) {
  .form-control--select {
    background: #fff url(../img/recruit/arr-down.png) no-repeat center right 16px/12px 7px;
  }
}

input::placeholder {
  color: #d1d1d1;
}

textarea::placeholder {
  color: #d1d1d1;
}

.mwform-radio-field input,
.mwform-checkbox-field input,
.wpcf7-radio input,
.wpcf7-checkbox input,
.check-radio input {
  display: none;
}
.mwform-radio-field input:checked + .mwform-radio-field-text:after,
.mwform-checkbox-field input:checked + .mwform-radio-field-text:after,
.wpcf7-radio input:checked + .mwform-radio-field-text:after,
.wpcf7-checkbox input:checked + .mwform-radio-field-text:after,
.check-radio input:checked + .mwform-radio-field-text:after {
  transform: scale(1) translateY(-50%);
}
.mwform-radio-field input:checked + .mwform-checkbox-field-text:after,
.mwform-checkbox-field input:checked + .mwform-checkbox-field-text:after,
.wpcf7-radio input:checked + .mwform-checkbox-field-text:after,
.wpcf7-checkbox input:checked + .mwform-checkbox-field-text:after,
.check-radio input:checked + .mwform-checkbox-field-text:after {
  transform: scale(1) translateY(-50%);
}

.wpcf7-radio input:checked + .wpcf7-list-item-label:after,
.check-radio input:checked + .wpcf7-list-item-label:after {
  transform: scale(1) translateY(-50%);
}

.mwform-radio-field-text,
.mwform-checkbox-field-text,
.wpcf7-radio .wpcf7-list-item-label,
.check-radio .wpcf7-list-item-label {
  font-size: 1.4rem;
  display: block;
  position: relative;
  padding: 8px 0 8px 30px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .mwform-radio-field-text,
  .mwform-checkbox-field-text,
  .wpcf7-radio .wpcf7-list-item-label,
  .check-radio .wpcf7-list-item-label {
    padding: 4px 0 5px 38px;
    font-size: 1.2rem;
  }
}
.mwform-radio-field-text::before,
.mwform-checkbox-field-text::before,
.wpcf7-radio .wpcf7-list-item-label::before,
.check-radio .wpcf7-list-item-label::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #dbdad2;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 50%;
}
@media screen and (max-width: 834px) {
  .mwform-radio-field-text::before,
  .mwform-checkbox-field-text::before,
  .wpcf7-radio .wpcf7-list-item-label::before,
  .check-radio .wpcf7-list-item-label::before {
    width: 14px;
    height: 14px;
    left: 15px;
  }
}
.mwform-radio-field-text:after,
.mwform-checkbox-field-text:after,
.wpcf7-radio .wpcf7-list-item-label:after,
.check-radio .wpcf7-list-item-label:after {
  content: "";
  display: block;
  background-color: #ba915b;
  position: absolute;
  top: 50%;
  left: 4px;
  width: 12px;
  height: 12px;
  transform: translateY(-50%) scale(0);
  transform-origin: 50%;
  border-radius: 50%;
  transition: transform 200ms ease-out;
}
@media screen and (max-width: 834px) {
  .mwform-radio-field-text:after,
  .mwform-checkbox-field-text:after,
  .wpcf7-radio .wpcf7-list-item-label:after,
  .check-radio .wpcf7-list-item-label:after {
    width: 8px;
    height: 8px;
    left: 19px;
  }
}

.check-radio .wpcf7-list-item {
  display: block;
}

.wpcf7-list-item {
  margin: 0 !important;
}

/* ====================================================
Header
==================================================== */
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
  transition: 0.3s;
}
@media screen and (max-width: 834px) {
  .main-header {
    height: 62px;
  }
}
.main-header__inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
}
.main-header__logo {
  width: 285px;
  position: fixed;
  top: 33px;
  left: 51%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1440px) {
  .main-header__logo {
    top: 28px;
    width: 250px;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__logo {
    left: 53%;
    width: 220px;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__logo {
    transform: translateX(0);
    left: 16px;
    width: 196px;
    top: 20px;
  }
}
.main-header__logo img {
  width: 100%;
}
.main-header__logo .off {
  display: none;
}
.main-header__navGlobal {
  width: 100%;
  padding: 23px 71px;
}
@media screen and (min-width: 1025px) {
  .main-header__navGlobal {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: flex !important;
  }
}
@media screen and (max-width: 1440px) {
  .main-header__navGlobal {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__navGlobal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    padding: 94px 16px 80px;
    width: 100%;
    text-align: center;
    z-index: 99;
    overflow-y: auto;
  }
}
.main-header__navGlobal.is-show {
  background: #fff;
  height: 100vh;
}
.main-header__navGlobal-logo {
  position: fixed;
  top: 20px;
  left: 16px;
  width: 100%;
  max-width: 196px;
}
.main-header__navGlobal-logo img {
  width: 100%;
}
.main-header__navGlobal .app-flex {
  background: #e8e4df;
  padding: 18px 16px 30px;
  margin: 40px -16px 29px;
}
.main-header__menu {
  gap: 0 32px;
}
@media screen and (min-width: 1025px) {
  .main-header__menu {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__menu {
    gap: 0 25px;
  }
}
.main-header__menu-item {
  position: relative;
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item.on > a {
    color: #BA915B;
    border-color: #BA915B;
    transition: all 0.3s;
  }
}
@media screen and (min-width: 1025px) {
  .main-header__menu-item .accordion {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item {
    position: relative;
  }
  .main-header__menu-item .accordion {
    cursor: pointer;
    display: block;
    width: 56px;
    height: 72px;
    position: absolute;
    right: -16px;
    top: 0;
  }
  .main-header__menu-item .accordion:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 21px;
    height: 1px;
    background: #000;
    left: 50%;
    top: 50%;
    transition: all 0.3s;
    transform: translate(-50%, -50%);
  }
  .main-header__menu-item .accordion:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 1px;
    height: 21px;
    background: #000;
    left: 50%;
    top: 50%;
    transition: all 0.3s;
    transform: translate(-50%, -50%);
  }
  .main-header__menu-item .accordion.on::after {
    opacity: 0;
    transition: all 0.3s;
  }
  .main-header__menu-item .accordion.on::before {
    background: #BA915B;
    transition: all 0.3s;
  }
}
.main-header__menu-item > a {
  font-weight: 500;
  color: #fff;
  font-size: 1.6rem;
  display: block;
  padding: 16px 0;
}
@media screen and (max-width: 1245px) {
  .main-header__menu-item > a {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item > a {
    transition: all 0.3s;
    border-bottom: 1px solid #DBDAD2;
    font-weight: 500;
    color: #000;
    padding: 23px 0 22px;
    display: block;
    text-align: left;
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1025px) {
  .main-header__menu-item:hover .main-header__submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
.main-header__menu-item--contact {
  width: 139px;
}
@media screen and (max-width: 1245px) {
  .main-header__menu-item--contact {
    width: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item--contact {
    margin-top: 40px;
    width: 100%;
    margin-bottom: 23px;
  }
}
.main-header__menu-item--contact a {
  background: #0D2641;
  color: #fff;
  width: 100%;
  max-width: 139px;
  font-weight: 500;
  height: 48px;
  transition: all 0.3s;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 24px;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .main-header__menu-item--contact a {
    max-width: 100px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item--contact a {
    max-width: 100%;
    width: 100%;
    background: #BA915B;
    height: 68px;
    max-width: 358px;
    border-radius: 4px;
    padding: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;
    position: relative;
  }
  .main-header__menu-item--contact a:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 12px;
    height: 10px;
    background: url("../img/index/arrow.svg") center no-repeat;
    background-size: contain;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 835px) {
  .main-header__menu-item--contact a:hover {
    opacity: 1;
    background: #fff;
    transition: all 0.3s;
    color: #0D2641;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}
@media screen and (max-width: 1024px) {
  .main-header__menu-item--black {
    margin-top: 0;
  }
  .main-header__menu-item--black a {
    background: #202A34;
  }
}
@media screen and (min-width: 1025px) {
  .main-header__submenu {
    position: absolute;
    top: 50px;
    left: 0;
    background: #fff;
    padding: 15px;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    z-index: 10000;
    box-shadow: 5px 5px 15px 0px rgba(51, 51, 51, 0.3);
    transform: translateY(10px);
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media screen and (max-width: 1024px) {
  .main-header__submenu {
    display: none;
    padding: 15px 0 0;
  }
}
.main-header__submenu-item {
  margin-bottom: 19px;
  text-align: left;
}
.main-header__submenu-item:last-of-type {
  margin-bottom: 0;
}
.main-header__submenu-item > a {
  display: block;
  padding-left: 16px;
  color: #000;
  font-weight: 500;
  position: relative;
  font-size: 1.5rem;
}
.main-header__submenu-item > a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #BA915B;
  border-radius: 50%;
  left: 0;
  top: 7px;
}
.main-header__submenu02 {
  padding-left: 32px;
  margin-top: 14px;
}
.main-header__submenu02-item {
  border-bottom: 1px solid #DBDAD2;
}
.main-header__submenu02-item a {
  display: block;
  color: #000;
  font-weight: 500;
  padding-bottom: 13px;
  position: relative;
  font-size: 1.4rem;
}
.main-header__fixed {
  position: fixed;
  top: 122px;
  left: 50%;
  transition: all 0.3s;
  z-index: 98;
  max-width: 511px;
  width: 100%;
  padding: 24px 10px;
  gap: 0 10px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transform: translateX(-50%);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 1770px) {
  .main-header__fixed {
    top: 100px;
  }
}
@media screen and (max-width: 1440px) {
  .main-header__fixed {
    max-width: 450px;
    padding: 15px 10px;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__fixed {
    top: 73px;
    max-width: 400px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__fixed {
    top: 72px;
    max-width: 358px;
    width: 92%;
    padding: 10px 10px;
    gap: 0;
  }
}
@media screen and (max-width: 834px) {
  .main-header__fixed-item {
    padding: 0 5px;
    width: 33.33%;
  }
}
.main-header__fixed-item a {
  display: block;
  background: #BA915B;
  padding: 13px 15px;
  font-weight: 500;
  border-radius: 25px;
  color: #fff;
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .main-header__fixed-item a {
    padding: 10px 12px;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__fixed-item a {
    padding: 6px 8px;
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 834px) {
  .main-header__fixed-item a {
    border-radius: 35px;
    height: 57px;
    text-align: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4275;
  }
}
@media screen and (max-width: 385px) {
  .main-header__fixed-item a {
    font-size: 1.2rem;
  }
}
.main-header__fixed.hidden {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.main-header__mobile-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  width: 62px;
  height: 62px;
  transition: background 0.5s;
  z-index: 102;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .main-header__mobile-icon {
    display: block;
  }
}
.main-header__mobile-icon-border {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: block;
  width: 30px;
  height: 1px;
  user-select: none;
  transition: background-color 0.3s;
  background: #fff;
}
.main-header__mobile-icon-border:nth-child(1) {
  margin-top: 8px;
  margin-left: -15px;
}
.main-header__mobile-icon-border:nth-child(3) {
  margin-top: -8px;
  margin-left: -15px;
}
.main-header__mobile-icon.mobile-close .main-header__mobile-icon-border {
  background: #202A34;
}
.main-header.is-active {
  background: #fff;
  transition: all 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.main-header.is-active .main-header__menu-item a {
  color: #000;
  transition: all 0.3s;
}
.main-header.is-active .main-header__mobile-icon-border {
  background: #333;
}
.main-header.is-active .main-header__logo .on {
  display: none;
}
.main-header.is-active .main-header__logo .off {
  display: block;
}
.main-header.header-page {
  background: #fff;
  transition: all 0.3s;
}
.main-header.header-page .main-header__menu-item a {
  color: #000;
  transition: all 0.3s;
}
.main-header.header-page .main-header__logo .on {
  display: none;
}
.main-header.header-page .main-header__logo .off {
  display: block;
}
.main-header.header-page .main-header__mobile-icon .main-header__mobile-icon-border {
  background: #000;
}
@media screen and (max-width: 834px) {
  .main-header.header-page02 {
    transition: all 0.3s;
  }
  .main-header.header-page02 .main-header__menu-item a {
    color: #000;
    transition: all 0.3s;
  }
  .main-header.header-page02 .main-header__logo .on {
    display: none;
  }
  .main-header.header-page02 .main-header__logo .off {
    display: block;
  }
  .main-header.header-page02 .main-header__mobile-icon .main-header__mobile-icon-border {
    background: #000;
  }
}

@media screen and (max-width: 1024px) {
  .main-header__mobile-icon-border:nth-child(1) {
    -webkit-animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-1-close {
    0% {
      transform: translateY(5px) rotate(45deg);
    }
    50% {
      transform: translateY(5px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  @keyframes click-header-sp-bar-1-close {
    0% {
      transform: translateY(5px) rotate(45deg);
    }
    50% {
      transform: translateY(5px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  .main-header__mobile-icon-border:nth-child(2) {
    -webkit-animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-2-close {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes click-header-sp-bar-2-close {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .main-header__mobile-icon-border:nth-child(3) {
    -webkit-animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-3-close {
    0% {
      transform: translateY(-5px) rotate(-45deg);
    }
    50% {
      transform: translateY(-5px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  @keyframes click-header-sp-bar-3-close {
    0% {
      transform: translateY(-5px) rotate(-45deg);
    }
    50% {
      transform: translateY(-5px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }
  .mobile-close .main-header__mobile-icon-border:nth-child(1) {
    margin-top: -5px;
    -webkit-animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-1-open {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(5px) rotate(0);
    }
    100% {
      transform: translateY(5px) rotate(45deg);
    }
  }
  @keyframes click-header-sp-bar-1-open {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(5px) rotate(0);
    }
    100% {
      transform: translateY(5px) rotate(45deg);
    }
  }
  .mobile-close .main-header__mobile-icon-border:nth-child(2) {
    -webkit-animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-2-open {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes click-header-sp-bar-2-open {
    0% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .mobile-close .main-header__mobile-icon-border:nth-child(3) {
    margin-top: 5px;
    -webkit-animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
    animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  }
  @-webkit-keyframes click-header-sp-bar-3-open {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-5px) rotate(0);
    }
    100% {
      transform: translateY(-5px) rotate(-45deg);
    }
  }
  @keyframes click-header-sp-bar-3-open {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-5px) rotate(0);
    }
    100% {
      transform: translateY(-5px) rotate(-45deg);
    }
  }
}
/* ====================================================
Footer
==================================================== */
.main-footer {
  position: relative;
  padding: 64px 0 97px;
  color: #fff;
  background: #202A34;
}
@media screen and (max-width: 1245px) {
  .main-footer {
    padding: 50px 0;
  }
}
@media screen and (max-width: 834px) {
  .main-footer {
    padding: 40px 0 60px;
  }
}
.main-footer__logo {
  margin-bottom: 63px;
}
@media screen and (max-width: 1245px) {
  .main-footer__logo {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__logo {
    margin-bottom: 40px;
  }
}
.main-footer__logo img {
  width: 100%;
  max-width: 588px;
}
@media screen and (max-width: 1245px) {
  .main-footer__logo img {
    max-width: 450px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__logo img {
    max-width: 259px;
  }
}
.main-footer__content {
  border-top: 1px solid #DBDAD2;
  border-bottom: 1px solid #DBDAD2;
  padding: 62px 0 35px;
  margin-bottom: 28px;
}
@media screen and (max-width: 834px) {
  .main-footer__content {
    padding: 36px 0 40px;
    margin-bottom: 37px;
  }
}
.main-footer__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 1245px) {
  .main-footer__flex {
    flex-wrap: wrap;
  }
}
.main-footer__nav {
  width: 800px;
  gap: 0 40px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 1440px) {
  .main-footer__nav {
    gap: 0 35px;
  }
}
@media screen and (max-width: 1245px) {
  .main-footer__nav {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .main-footer__menu {
    width: 100%;
  }
}
.main-footer__menu-item {
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .main-footer__menu-item {
    margin-bottom: 34px;
  }
}
.main-footer__menu-item > a {
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .main-footer__menu-item > a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menu-item > a {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 835px) {
  .main-footer__menu-item > a:hover {
    text-decoration: underline;
    transition: all 0.3s;
  }
}
.main-footer__menusub {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .main-footer__menusub {
    margin-top: 18px;
  }
  .main-footer__menusub--02 {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 0 29px;
  }
}
.main-footer__menusub-item:not(:last-child) {
  margin-bottom: 14.5px;
}
@media screen and (max-width: 1440px) {
  .main-footer__menusub-item:not(:last-child) {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menusub-item:not(:last-child) {
    margin-bottom: 17px;
  }
}
.main-footer__menusub-item > a {
  color: #707070;
  font-weight: 500;
  font-size: 1.4rem;
}
@media screen and (max-width: 1440px) {
  .main-footer__menusub-item > a {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menusub-item > a {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 835px) {
  .main-footer__menusub-item > a:hover {
    text-decoration: underline;
    transition: all 0.3s;
  }
}
.main-footer__btn {
  width: 320px;
  margin-top: -19px;
}
@media screen and (max-width: 1245px) {
  .main-footer__btn {
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__btn {
    margin-top: 24px;
  }
}
.main-footer__btn-item:not(:last-child) {
  margin-bottom: 32px;
}
@media screen and (max-width: 1245px) {
  .main-footer__btn-item:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__btn-item:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 1245px) {
  .main-footer__btn-item .c-btn__02 {
    margin: 0 auto;
  }
}
.main-footer__privacy {
  gap: 0 30px;
  margin-bottom: 59px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1245px) {
  .main-footer__privacy {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__privacy {
    margin-bottom: 36px;
    gap: 0 22px;
  }
}
@media screen and (max-width: 385px) {
  .main-footer__privacy {
    gap: 15px 0;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 385px) {
  .main-footer__privacy-item {
    width: 100%;
  }
}
.main-footer__privacy-item a {
  color: #707070;
  font-weight: 600;
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .main-footer__privacy-item a {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__privacy-item a {
    font-size: 1.1rem;
  }
}
.main-footer__copyright {
  font-family: "Lato", serif;
  font-weight: bold;
  font-size: 1.4rem;
  color: #707070;
}
@media screen and (max-width: 834px) {
  .main-footer__copyright {
    text-align: center;
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__copyright {
    font-size: 1.1rem;
  }
}

.sec-mv {
  position: relative;
}

.page-index {
  overflow-x: hidden;
}

.mv-slider__item img {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .mv-slider__item {
    position: relative;
  }
  .mv-slider__item::before {
    content: "";
    float: left;
    padding-top: 202.5641025641%;
  }
  .mv-slider__item::after {
    content: "";
    display: table;
    clear: both;
  }
  .mv-slider__item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .mv-slider__item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.mv-slider__ct {
  padding: 0 104px;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 38.5%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 1245px) {
  .mv-slider__ct {
    padding: 0 20px;
    top: 33.5%;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__ct {
    top: 37.8%;
    padding: 0 16px;
  }
}
.mv-slider__ct-ttl {
  color: #fff;
  font-weight: bold;
  margin-bottom: 32px;
  font-family: "Lato", serif;
  font-size: 5rem;
}
@media screen and (max-width: 1245px) {
  .mv-slider__ct-ttl {
    margin-bottom: 20px;
    font-size: 4rem;
  }
}
@media screen and (max-width: 1245px) {
  .mv-slider__ct-ttl {
    margin-bottom: 12px;
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__ct-ttl {
    margin-bottom: 16px;
    font-size: 3rem;
  }
}
.mv-slider__ct-desc {
  color: #fff;
  font-weight: 500;
  font-size: 1.4rem;
}
@media screen and (max-width: 834px) {
  .mv-slider__ct-desc {
    line-height: 1.667;
    font-size: 1.2rem;
  }
}
.mv-slider__news {
  bottom: 361px;
  position: absolute;
  max-width: 454px;
  width: 100%;
  right: 104px;
  border-radius: 8px;
  background: rgba(13, 38, 65, 0.9);
  padding: 23px 50px 22px 30px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1440px) {
  .mv-slider__news {
    bottom: 280px;
  }
}
@media screen and (max-width: 1245px) {
  .mv-slider__news {
    padding: 15px 45px 15px 15px;
    bottom: 150px;
    max-width: 400px;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__news {
    padding: 15px 35px 21px 12px;
    background: rgba(10, 31, 54, 0.9);
    left: 50%;
    bottom: 241px;
    right: auto;
    width: 92%;
    max-width: 400px;
    transform: translateX(-50%);
  }
}
.mv-slider__news-img {
  width: 53px;
}
@media screen and (max-width: 834px) {
  .mv-slider__news-img {
    width: 52px;
  }
}
.mv-slider__news-img img {
  width: 100%;
}
.mv-slider__news-ct {
  width: calc(100% - 53px);
  padding-left: 18px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}
@media screen and (max-width: 834px) {
  .mv-slider__news-ct {
    top: 39.1%;
    width: calc(100% - 52px);
    padding-left: 22px;
  }
}
.mv-slider__news-ttl {
  line-height: 1.2;
  width: 91px;
  padding-right: 5px;
  letter-spacing: -1px;
  font-weight: bold;
  font-family: "Lato", serif;
  font-size: 2.4rem;
}
@media screen and (max-width: 1245px) {
  .mv-slider__news-ttl {
    letter-spacing: 0;
    font-size: 2rem;
    width: 70px;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__news-ttl {
    width: 63px;
  }
}
.mv-slider__news-desc {
  width: calc(100% - 91px);
  font-weight: 500;
  line-height: 1.7;
  font-size: 1.1rem;
}
@media screen and (max-width: 1245px) {
  .mv-slider__news-desc {
    width: calc(100% - 70px);
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__news-desc {
    width: calc(100% - 63px);
    padding-top: 6px;
    padding-right: 5px;
  }
}
.mv-slider__news .c-arrow {
  width: 36px;
  height: 36px;
  right: 14px;
  top: 50%;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transform: translateY(-50%);
}
.mv-slider__news .c-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1245px) {
  .mv-slider__news .c-arrow {
    right: 5px;
  }
}
.mv-slider__news .c-arrow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 0%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 50%;
  transition: all 0.5s;
}
.mv-slider__news .c-arrow img {
  width: 14px;
  transition: all 0.3s;
}
@media screen and (min-width: 835px) {
  .mv-slider__news:hover {
    opacity: 1;
  }
  .mv-slider__news:hover .c-arrow img {
    opacity: 0;
    transition: all 0.3s;
  }
  .mv-slider__news:hover .c-arrow::after {
    left: 10px;
    transition: all 0.5s;
    transition-delay: 0.2s;
  }
  .mv-slider__news:hover .c-arrow::before {
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }
}
.mv-slider__oysterbar {
  background: rgba(10, 31, 54, 0.5);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 115px 0;
}
@media screen and (max-width: 1770px) {
  .mv-slider__oysterbar {
    padding: 80px 0;
  }
}
@media screen and (max-width: 1245px) {
  .mv-slider__oysterbar {
    padding: 30px 0;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__oysterbar {
    padding: 37px 0 44px;
    background: #0A1F36;
    position: static;
  }
}
.mv-slider__oysterbar .inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .mv-slider__oysterbar .inner {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__oysterbar .c-ttl__01 {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .mv-slider__oysterbar-link {
    width: 100%;
    text-align: right;
    margin-top: 20px;
  }
}

.loop {
  position: relative;
  height: 320px;
}
@media screen and (max-width: 1440px) {
  .loop {
    height: 266px;
  }
}
@media screen and (max-width: 1245px) {
  .loop {
    height: 233px;
  }
}
@media screen and (max-width: 834px) {
  .loop {
    height: 192px;
  }
}

.list-loop {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  -webkit-animation: slider-move 50s linear infinite;
  animation: slider-move 50s linear infinite;
}
.list-loop:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.list-loop__item {
  width: 480px;
}
@media screen and (max-width: 1440px) {
  .list-loop__item {
    width: 400px;
  }
}
@media screen and (max-width: 1245px) {
  .list-loop__item {
    width: 350px;
  }
}
@media screen and (max-width: 834px) {
  .list-loop__item {
    width: 288px;
  }
}
.list-loop__item img {
  width: 100%;
}
.list-loop__img {
  position: relative;
}
.list-loop__img::before {
  content: "";
  float: left;
  padding-top: 66.6666666667%;
}
.list-loop__img::after {
  content: "";
  display: table;
  clear: both;
}
.list-loop__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.list-loop__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@-webkit-keyframes slider-move {
  0% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes slider-move {
  0% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
.sec-club {
  background: #fff;
}
.sec-club__link {
  padding: 42px 0;
  color: #000;
  position: relative;
  padding-right: 136px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 1770px) {
  .sec-club__link {
    padding-right: 90px;
  }
}
@media screen and (max-width: 1245px) {
  .sec-club__link {
    padding-right: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 834px) {
  .sec-club__link {
    padding-right: 0;
    padding: 34px 0 36px;
  }
}
.sec-club__link .c-arrow {
  border: 1px solid #000;
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  right: 0;
  overflow: hidden;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .sec-club__link .c-arrow {
    display: none;
    width: 36px;
    height: 36px;
  }
}
.sec-club__link .c-arrow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .sec-club__link .c-arrow:before {
    width: 12px;
    height: 10px;
  }
}
.sec-club__link .c-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow.svg") center no-repeat;
  background-size: contain;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .sec-club__link .c-arrow:after {
    width: 12px;
    height: 10px;
  }
}
@media screen and (min-width: 835px) {
  .sec-club__link:hover {
    opacity: 1;
  }
  .sec-club__link:hover .c-arrow {
    background: rgba(13, 38, 65, 0.9);
    transition: all 0.3s;
  }
  .sec-club__link:hover .c-arrow::before {
    opacity: 0;
    transition: all 0.3s;
  }
  .sec-club__link:hover .c-arrow::after {
    left: 13px;
    transition: all 0.3s;
  }
}

.club-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 834px) {
  .club-flex {
    flex-wrap: wrap;
    padding: 0 32px;
  }
}
@media screen and (max-width: 385px) {
  .club-flex {
    padding: 0 12px;
  }
}
.club-flex__logo {
  width: 164.5px;
}
@media screen and (max-width: 834px) {
  .club-flex__logo {
    width: 100%;
    text-align: left;
    margin-bottom: 51px;
  }
}
.club-flex__logo img {
  max-width: 164px;
  width: 100%;
}
@media screen and (max-width: 1245px) {
  .club-flex__logo img {
    margin: 0 auto;
  }
}
@media screen and (max-width: 834px) {
  .club-flex__logo img {
    margin: 0;
    max-width: 302px;
  }
}
.club-flex__opc {
  width: 590px;
  width: calc(100% - 164.5px);
  padding-left: 64px;
}
@media screen and (max-width: 1245px) {
  .club-flex__opc {
    padding-left: 25px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 834px) {
  .club-flex__opc {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .club-flex__opc p {
    padding-right: 50px;
    position: relative;
  }
  .club-flex__opc p:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 34px;
    height: 34px;
    background: #fff;
    border-radius: 50%;
    right: 0;
    border: 1px solid black;
    transform: translateY(-50%);
    top: 61%;
  }
  .club-flex__opc p:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 12px;
    height: 10px;
    background: url("../img/index/arrow02.svg") center no-repeat;
    background-size: contain;
    right: 11px;
    top: 50%;
    top: 61%;
    transform: translateY(-50%);
    z-index: 2;
  }
}
.club-flex__opc p {
  font-weight: 500;
}
.club-flex__opc-ttl {
  line-height: 1.2;
  margin-bottom: 25px;
  padding-top: 24px;
  font-weight: 500;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 1770px) {
  .club-flex__opc-ttl {
    padding-top: 0;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .club-flex__opc-ttl {
    margin-bottom: 25px;
  }
}
.club-flex__opc-ttl span {
  margin-right: 23px;
  font-weight: 600;
  font-size: 2.9rem;
}
@media screen and (max-width: 834px) {
  .club-flex__opc-ttl span {
    width: 100%;
    display: block;
    margin-bottom: 14px;
    margin-right: 0;
    font-size: 2.2rem;
  }
}

.club-img {
  width: 320px;
}
@media screen and (max-width: 834px) {
  .club-img {
    width: calc(100% + 32px);
    margin: 0 -16px;
  }
}
.club-img img {
  width: 100%;
}

.sec-sea {
  margin-bottom: -2px;
  position: relative;
}
.sec-sea .inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 834px) {
  .sec-sea .inner {
    top: 52%;
  }
}
@media screen and (max-width: 834px) {
  .sec-sea--02 .inner {
    top: 50%;
  }
}

.sea-img__item img {
  width: 100%;
}
@media screen and (max-width: 1245px) {
  .sea-img__item img {
    min-height: 400px;
    object-fit: cover;
  }
}
@media screen and (max-width: 1024px) {
  .sea-img__item img {
    min-height: 0;
  }
}
@media screen and (max-width: 834px) {
  .sea-img--02 .sea-img__item {
    position: relative;
  }
  .sea-img--02 .sea-img__item::before {
    content: "";
    float: left;
    padding-top: 153.8461538462%;
  }
  .sea-img--02 .sea-img__item::after {
    content: "";
    display: table;
    clear: both;
  }
  .sea-img--02 .sea-img__item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .sea-img--02 .sea-img__item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.sea-img--02 .sea-img__item img {
  min-height: 701px;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .sea-img--02 .sea-img__item img {
    min-height: 500px;
  }
}
@media screen and (max-width: 834px) {
  .sea-img--02 .sea-img__item img {
    min-height: 600px;
  }
}

.sea-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .sea-flex {
    flex-wrap: wrap;
  }
}
.sea-flex__logo {
  width: 50%;
  padding-top: 10px;
}
@media screen and (max-width: 1245px) {
  .sea-flex__logo {
    padding-top: 0;
    width: 35%;
    padding-right: 20px;
  }
}
@media screen and (max-width: 834px) {
  .sea-flex__logo {
    width: 100%;
    padding-right: 0;
    margin-bottom: 24px;
  }
}
.sea-flex__logo img {
  max-width: 410px;
  width: 100%;
}
@media screen and (max-width: 1245px) {
  .sea-flex__logo img {
    max-width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .sea-flex__logo img {
    max-width: 206px;
  }
}
.sea-flex__ct {
  width: 50%;
  padding-left: 56px;
}
@media screen and (max-width: 1770px) {
  .sea-flex__ct {
    padding-left: 0;
  }
}
@media screen and (max-width: 1245px) {
  .sea-flex__ct {
    width: 65%;
  }
}
@media screen and (max-width: 834px) {
  .sea-flex__ct {
    width: 100%;
  }
}
.sea-flex__ct .c-txt__01 {
  margin-bottom: 51px;
}
@media screen and (max-width: 1440px) {
  .sea-flex__ct .c-txt__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .sea-flex__ct .c-txt__01 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 834px) {
  .sea-flex__ttl {
    letter-spacing: -0.5px;
  }
}
.sea-flex--02 {
  display: block;
}
.sea-flex--02 .sea-flex__ct {
  padding-left: 0;
  width: 100%;
}
.sea-flex--02 .sea-flex__ct .c-txt__01 {
  max-width: 800px;
}
@media screen and (max-width: 834px) {
  .sea-flex--03 .sea-flex__logo {
    margin-bottom: 35px;
  }
  .sea-flex--03 .sea-flex__logo img {
    max-width: 228px;
  }
  .sea-flex--03 .sea-flex__ct .c-txt__01 {
    margin-bottom: 0;
  }
}

.sec-th {
  padding: 72px 0;
  background: #0A1F36;
}
@media screen and (max-width: 834px) {
  .sec-th {
    padding: 51px 0;
  }
}

.th-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .th-flex {
    flex-wrap: wrap;
  }
}
.th-flex__ct {
  width: 50%;
  padding-right: 50px;
}
@media screen and (max-width: 1245px) {
  .th-flex__ct {
    padding-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .th-flex__ct {
    width: 100%;
    margin-bottom: 31px;
  }
}
.th-flex__ttl {
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
  font-size: 2.9rem;
}
@media screen and (max-width: 1245px) {
  .th-flex__ttl {
    margin-bottom: 15px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .th-flex__ttl {
    margin-bottom: 22px;
    line-height: 1.6;
  }
}
.th-flex__list {
  margin: 0 -20px;
  width: calc(50% + 40px);
  padding-left: 56px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1245px) {
  .th-flex__list {
    margin: 0 -10px;
    width: calc(50% + 20px);
    padding-left: 20px;
  }
}
@media screen and (max-width: 834px) {
  .th-flex__list {
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    padding-left: 0;
    gap: 40px 0;
  }
}
.th-flex__list-item {
  width: 50%;
  padding: 0 20px;
}
@media screen and (max-width: 1245px) {
  .th-flex__list-item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 640px) {
  .th-flex__list-item {
    padding: 0;
    width: 100%;
  }
}
.th-flex__list-item a {
  display: block;
}
@media screen and (min-width: 835px) {
  .th-flex__list-item a:hover {
    opacity: 1;
  }
  .th-flex__list-item a:hover .th-flex__list-img img {
    transition: all 0.7s;
    transform: scale(1.08);
  }
  .th-flex__list-item a:hover .th-flex__list-link span::after {
    width: 0;
    transition: all 0.3s;
  }
  .th-flex__list-item a:hover .th-flex__list-link span::before {
    width: 100%;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  .th-flex__list-item a:hover .c-arrow::after {
    opacity: 0;
    transition: all 0.3s;
  }
  .th-flex__list-item a:hover .c-arrow::before {
    opacity: 1;
    left: 0;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
}
.th-flex__list-img {
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}
.th-flex__list-img::before {
  content: "";
  float: left;
  padding-top: 57.0512820513%;
}
.th-flex__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.th-flex__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.th-flex__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.th-flex__list-img img {
  transition: all 0.7s;
}
@media screen and (max-width: 834px) {
  .th-flex__list-img img {
    height: 99% !important;
  }
}
@media screen and (max-width: 1245px) {
  .th-flex__list-img {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 834px) {
  .th-flex__list-img {
    border-radius: 3vw;
    margin-bottom: 17px;
  }
}
.th-flex__list-link {
  color: #fff;
  padding-right: 20px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .th-flex__list-link {
    padding-right: 35px;
  }
}
.th-flex__list-link span {
  display: inline-block;
  padding-bottom: 9px;
  font-size: 1.6rem;
  position: relative;
}
@media screen and (max-width: 1245px) {
  .th-flex__list-link span {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 834px) {
  .th-flex__list-link span {
    padding-bottom: 7px;
    font-size: 1.4rem;
  }
}
.th-flex__list-link span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  right: 0;
}
.th-flex__list-link span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  left: 0;
}
.th-flex__list-link .c-arrow {
  position: absolute;
  width: 14px;
  right: 0;
  top: 50%;
  overflow: hidden;
  height: 12px;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .th-flex__list-link .c-arrow {
    width: 12px;
    height: 10px;
    right: 15px;
    top: 41%;
  }
}
.th-flex__list-link .c-arrow::after, .th-flex__list-link .c-arrow::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow.svg") center no-repeat;
  background-size: contain;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .th-flex__list-link .c-arrow::after, .th-flex__list-link .c-arrow::before {
    width: 12px;
    height: 10px;
  }
}
.th-flex__list-link .c-arrow::before {
  left: -20px;
  opacity: 0;
  transition: all 0;
}

.sec-eoyster {
  padding: 130px 0 39px;
}
@media screen and (max-width: 1245px) {
  .sec-eoyster {
    padding: 60px 0 50px;
  }
}
@media screen and (max-width: 834px) {
  .sec-eoyster {
    padding: 56px 0 0;
  }
}
.sec-eoyster .c-ttl__01 {
  margin-bottom: 72px;
}
@media screen and (max-width: 1245px) {
  .sec-eoyster .c-ttl__01 {
    margin-bottom: 31px;
  }
}

.eoyster {
  position: relative;
  max-width: 1440px;
  width: 100%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 10px;
}
@media screen and (max-width: 834px) {
  .eoyster {
    border-radius: 16px;
  }
}
@media screen and (min-width: 835px) {
  .eoyster:hover {
    opacity: 1;
  }
  .eoyster:hover .c-btn__01 span::before {
    width: 0;
    transition: all 0.3s;
  }
  .eoyster:hover .c-btn__01 span::after {
    width: 100%;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
  .eoyster:hover .c-btn__01 .c-arrow {
    background: rgba(13, 38, 65, 0.9);
    transition: all 0.3s;
  }
  .eoyster:hover .c-btn__01 .c-arrow::before {
    opacity: 0;
    transition: all 0.3s;
  }
  .eoyster:hover .c-btn__01 .c-arrow::after {
    left: 13px;
    transition: all 0.3s;
  }
  .eoyster:hover .eoyster-bg img {
    transition: all 0.5s;
    transform: scale(1.1);
  }
}
.eoyster-bg img {
  width: 100%;
  min-height: 441px;
  transition: all 0.5s;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .eoyster-bg img {
    min-height: 350px;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-bg img {
    height: 650px;
    width: 100%;
  }
}

.sec-tiktok {
  padding: 80px 0 119px;
}
@media screen and (max-width: 1245px) {
  .sec-tiktok {
    padding: 50px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-tiktok {
    padding: 19px 0 60px;
  }
}

.tiktok {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: block;
}
@media screen and (max-width: 834px) {
  .tiktok {
    border-radius: 16px;
  }
}
.tiktok-bg {
  border-radius: 10px;
}
.tiktok-bg img {
  width: 100%;
  min-height: 650px;
  object-fit: cover;
  transition: all 0.5s;
}
@media screen and (max-width: 1245px) {
  .tiktok-bg img {
    min-height: 440px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-bg img {
    height: 650px;
  }
}
@media screen and (min-width: 835px) {
  .tiktok:hover {
    opacity: 1;
  }
  .tiktok:hover .tiktok-bg img {
    transition: all 0.5s;
    transform: scale(1.1);
  }
  .tiktok:hover .tiktok-box__btn span::after {
    width: 0;
    transition: all 0.3s;
  }
  .tiktok:hover .tiktok-box__btn span::before {
    width: 100%;
    transition: all 0.3s;
    transition-delay: 0.3s;
  }
}
@media screen and (max-width: 834px) {
  .tiktok--02 .tiktok-box {
    top: 31%;
  }
}
.tiktok--02 .tiktok-bg img {
  min-height: 387px;
}
@media screen and (max-width: 834px) {
  .tiktok--02 .tiktok-bg img {
    min-height: 515px;
    max-height: 515px;
  }
}
.tiktok--02 .tiktok-box__btn::before {
  background: url("../img/index/arrow05.svg") center no-repeat;
  background-size: contain;
}

.tiktok-box {
  padding: 130px 121px 88px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .tiktok-box {
    padding: 100px 50px;
  }
}
@media screen and (max-width: 1245px) {
  .tiktok-box {
    padding: 60px 30px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box {
    padding: 57px 16px 64px;
    border-radius: 15px;
  }
}
@media screen and (max-width: 640px) {
  .tiktok-box {
    top: 0;
    transform: none;
  }
}
.tiktok-box .c-ttl__01 {
  margin-bottom: 71px;
}
@media screen and (max-width: 1440px) {
  .tiktok-box .c-ttl__01 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1245px) {
  .tiktok-box .c-ttl__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box .c-ttl__01 {
    margin-bottom: 33px;
  }
}
.tiktok-box .c-ttl__02 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1440px) {
  .tiktok-box .c-ttl__02 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box .c-ttl__02 {
    margin-bottom: 24px;
  }
}
.tiktok-box .c-txt__01 {
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .tiktok-box .c-txt__01 {
    margin-bottom: 41px;
  }
}
.tiktok-box__btn {
  font-family: "Lato", serif;
  color: #fff;
  display: inline-block;
  padding-left: 185px;
  position: absolute;
  top: 50%;
  right: 121px;
  transform: translateY(-50%);
  font-size: 1.6rem;
}
@media screen and (max-width: 1440px) {
  .tiktok-box__btn {
    right: 50px;
  }
}
@media screen and (max-width: 1245px) {
  .tiktok-box__btn {
    right: 30px;
    padding-left: 100px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box__btn {
    padding-left: 67px;
    transform: translateY(0);
    position: static;
    margin-left: auto;
    max-width: 132px;
    display: block;
    font-size: 1.4rem;
  }
}
.tiktok-box__btn span {
  display: inline-block;
  padding-bottom: 6px;
  position: relative;
}
.tiktok-box__btn span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  right: 0;
}
.tiktok-box__btn span:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 0%;
  height: 1px;
  border-bottom: 1px solid #fff;
  bottom: 0;
  left: 0;
}
.tiktok-box__btn:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 160px;
  height: 160px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 1245px) {
  .tiktok-box__btn:after {
    width: 80px;
    height: 80px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box__btn:after {
    width: 56px;
    height: 56px;
  }
}
.tiktok-box__btn:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 27px;
  height: 24px;
  background: url("../img/index/arrow03.svg") center no-repeat;
  background-size: contain;
  left: 70px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 1245px) {
  .tiktok-box__btn:before {
    left: 32px;
    width: 20px;
    height: 18px;
  }
}
@media screen and (max-width: 834px) {
  .tiktok-box__btn:before {
    left: 21px;
    width: 12px;
    height: 11px;
  }
}

.sec-contact {
  background: #202A34;
  padding: 63px 0 67px;
}
@media screen and (max-width: 834px) {
  .sec-contact {
    padding: 42px 0 40px;
  }
}

.contact-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 136px;
}
@media screen and (max-width: 1440px) {
  .contact-flex {
    padding-left: 0;
  }
}
@media screen and (max-width: 834px) {
  .contact-flex {
    flex-wrap: wrap;
  }
}
.contact-flex__ct {
  width: 650px;
}
@media screen and (max-width: 1245px) {
  .contact-flex__ct {
    padding-right: 20px;
  }
}
@media screen and (max-width: 834px) {
  .contact-flex__ct {
    padding-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}
.contact-flex__ct .c-ttl__02 {
  margin-bottom: 29px;
}
@media screen and (max-width: 1440px) {
  .contact-flex__ct .c-ttl__02 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1245px) {
  .contact-flex__ct .c-ttl__02 {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 834px) {
  .contact-flex__ct .c-ttl__02 {
    margin-bottom: 25px;
  }
}
.contact-flex__btn {
  width: 320px;
  padding-top: 32px;
}
@media screen and (max-width: 834px) {
  .contact-flex__btn {
    padding-top: 0;
    width: 100%;
  }
}

.sec-app {
  padding: 65px 0 66px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-app {
    padding: 47px 0 60px;
  }
}

.app-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1245px) {
  .app-flex {
    flex-wrap: wrap;
  }
}
.app-flex__left {
  padding-right: 72px;
  margin-right: 70px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid #DBDAD2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1440px) {
  .app-flex__left {
    padding-right: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 1245px) {
  .app-flex__left {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
    border-bottom: 1px solid #DBDAD2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .app-flex__left {
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin-bottom: 27px;
  }
}
.app-flex__list {
  margin-left: 72px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 24px;
}
@media screen and (max-width: 1440px) {
  .app-flex__list {
    gap: 0 15px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 834px) {
  .app-flex__list {
    gap: 0 10px;
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    justify-content: flex-start;
  }
}
.app-flex__list-item a {
  display: block;
}
.app-flex__list-item a img {
  width: auto;
  height: 40px;
}
@media screen and (max-width: 834px) {
  .app-flex__list-item a img {
    height: 57px;
  }
}
.app-flex__right {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .app-flex__right {
    flex-wrap: wrap;
  }
}
.app-flex__share {
  margin-left: 73px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 24px;
}
@media screen and (max-width: 1440px) {
  .app-flex__share {
    gap: 0 15px;
    margin-left: 30px;
  }
}
@media screen and (max-width: 834px) {
  .app-flex__share {
    gap: 0 10px;
    margin-top: 15px;
    width: 100%;
    margin-left: 0;
    justify-content: flex-start;
  }
}
.app-flex__share-item a {
  display: block;
}
.app-flex__share-item a img {
  width: 40px;
}
@media screen and (max-width: 834px) {
  .app-flex__share-item a img {
    width: 60px;
  }
}

.mv-page {
  padding: 155px 0 78px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 1245px) {
  .mv-page {
    padding: 100px 0 60px;
  }
}
@media screen and (max-width: 834px) {
  .mv-page {
    padding: 68px 0 60px;
  }
}

.sec-company {
  padding: 124px 0 120px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .sec-company {
    padding: 52px 0 58px;
  }
}

.company-option {
  border-top: 1px solid #DBDAD2;
}
.company-option__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 32px 0 36px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .company-option__dl {
    flex-wrap: wrap;
    padding: 24px 0 26px;
  }
}
.company-option__dt {
  width: 195px;
  padding-right: 10px;
  font-weight: 600;
}
@media screen and (max-width: 834px) {
  .company-option__dt {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2px;
  }
}
.company-option__dd {
  width: calc(100% - 195px);
}
.company-option__dd p {
  margin-bottom: 24px;
}
.company-option__dd p:last-of-type {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .company-option__dd {
    width: 100%;
  }
}
.company-option__dd-time-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.company-option__dd-time-item span:nth-child(1) {
  width: 66px;
}
@media screen and (max-width: 834px) {
  .company-option__dd-time-item span:nth-child(1) {
    width: 57px;
  }
}
.company-option__dd-time-item span:nth-child(2) {
  width: calc(100% - 66px);
}
@media screen and (max-width: 834px) {
  .company-option__dd-time-item span:nth-child(2) {
    width: calc(100% - 57px);
  }
}
.company-option__dd-label {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .company-option__dd-label {
    margin-bottom: 30px;
    margin-top: 10px;
  }
}
.company-option__dd-label li {
  display: inline-block;
  border: 1px solid;
  min-width: 120px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 6px 14px;
  border-radius: 15px;
}
@media screen and (max-width: 834px) {
  .company-option__dd-label li {
    min-width: 114px;
  }
}
.company-option__dd .c-btn__03 {
  border-bottom: 1px solid #000;
  padding-bottom: 8px;
  text-decoration: none;
}
@media screen and (max-width: 834px) {
  .company-option__dd .c-btn__03 {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.company-option__dd .c-btn__03 > span {
  text-decoration: none;
}

.sec-business {
  padding: 119px 0;
}
@media screen and (max-width: 834px) {
  .sec-business {
    padding: 52px 0 60px;
  }
}

.business-block {
  margin-bottom: 71px;
}
@media screen and (max-width: 834px) {
  .business-block {
    margin-bottom: 49px;
  }
}

.business-list {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .business-list {
    margin-bottom: 33px;
  }
}
.business-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 61px;
  padding-bottom: 62px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .business-list__item {
    flex-wrap: wrap;
    margin-bottom: 32px;
    padding-bottom: 42px;
  }
}
.business-list__ct {
  padding-right: 63px;
  width: calc(100% - 360px);
  margin-top: -4px;
}
@media screen and (max-width: 1245px) {
  .business-list__ct {
    margin-top: 0;
    padding-right: 25px;
    width: calc(100% - 300px);
  }
}
@media screen and (max-width: 834px) {
  .business-list__ct {
    width: 100%;
    padding-right: 0;
    margin-bottom: 28px;
  }
}
.business-list__ttl {
  margin-bottom: 19px;
}
@media screen and (max-width: 834px) {
  .business-list__ttl {
    margin-bottom: 17px;
  }
}
.business-list__btn {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 33px;
  margin-top: 34px;
}
@media screen and (max-width: 834px) {
  .business-list__btn {
    margin-top: 22px;
    gap: 21px 30px;
  }
}
.business-list__img {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.business-list__img::before {
  content: "";
  float: left;
  padding-top: 66.6666666667%;
}
.business-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.business-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.business-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.business-list__r {
  width: 360px;
}
@media screen and (max-width: 1245px) {
  .business-list__r {
    width: 300px;
  }
}
@media screen and (max-width: 834px) {
  .business-list__r {
    width: 100%;
  }
}

.sec-patents {
  padding: 123px 0;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-patents {
    padding: 52px 0 61px;
  }
}

.patents-block {
  margin-bottom: 107px;
}
@media screen and (max-width: 834px) {
  .patents-block {
    margin-bottom: 48px;
  }
}

.patents-list__item:not(:last-child) {
  margin-bottom: 111px;
}
@media screen and (max-width: 834px) {
  .patents-list__item:not(:last-child) {
    margin-bottom: 56px;
  }
}
.patents-list__pro {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -56px;
}
@media screen and (max-width: 1245px) {
  .patents-list__pro {
    margin: 0 -20px;
  }
}
@media screen and (max-width: 834px) {
  .patents-list__pro {
    margin: 0;
    gap: 20px 0;
    flex-wrap: wrap;
  }
}
.patents-list__pro-item {
  width: 50%;
  padding: 0 56px;
}
@media screen and (max-width: 1245px) {
  .patents-list__pro-item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 834px) {
  .patents-list__pro-item {
    width: 100%;
    padding: 0;
  }
}
.patents-list__pro-box {
  background: #fff;
  border-radius: 16px;
  padding: 71px 79px 75px;
}
@media screen and (max-width: 1245px) {
  .patents-list__pro-box {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 834px) {
  .patents-list__pro-box {
    padding: 39px 16px;
  }
}
.patents-list__pro-box .c-txt__01 {
  margin-bottom: 15px;
}
@media screen and (max-width: 834px) {
  .patents-list__pro-box .c-txt__01 {
    margin-bottom: 22px;
  }
}
.patents-list__pro-img {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: 0 auto 28px;
  display: block;
}
.patents-list__pro-img::before {
  content: "";
  float: left;
  padding-top: 136.25%;
}
.patents-list__pro-img::after {
  content: "";
  display: table;
  clear: both;
}
.patents-list__pro-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.patents-list__pro-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .patents-list__pro-img {
    width: 54.1%;
    margin: 0 auto 27px;
  }
}
.patents-list__pro-ttl {
  color: #000;
  font-weight: 600;
  margin-bottom: 14px;
  font-size: 2.1rem;
}
@media screen and (max-width: 834px) {
  .patents-list__pro-ttl {
    margin-bottom: 12px;
    line-height: 1.555;
    font-size: 1.8rem;
  }
}
.patents-list__table {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -56px;
}
@media screen and (max-width: 1245px) {
  .patents-list__table {
    margin: 0 -20px;
  }
}
@media screen and (max-width: 834px) {
  .patents-list__table {
    margin: 0;
    flex-wrap: wrap;
  }
}
.patents-list__table-item {
  width: 50%;
  padding: 0 56px;
}
@media screen and (max-width: 1245px) {
  .patents-list__table-item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 834px) {
  .patents-list__table-item {
    padding: 0;
    width: 100%;
  }
}
.patents-list__table-dl {
  padding: 34px 0;
  border-bottom: 1px solid #DBDAD2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .patents-list__table-dl {
    padding: 23.5px 0;
  }
}
.patents-list__table-dl:nth-child(1) dt, .patents-list__table-dl:nth-child(1) dd {
  font-weight: 600;
}
.patents-list__table-dt {
  width: 255px;
}
@media screen and (max-width: 834px) {
  .patents-list__table-dt {
    width: 233px;
  }
}
.patents-list__table-dd {
  width: calc(100% - 255px);
}
@media screen and (max-width: 834px) {
  .patents-list__table-dd {
    width: calc(100% - 233px);
  }
}

@media screen and (max-width: 834px) {
  .scroll-sp {
    overflow-x: auto;
    margin-bottom: 74px;
    width: 100%;
  }
}

.list-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 40px;
  margin-bottom: 112px;
}
@media screen and (max-width: 834px) {
  .list-menu {
    max-width: 875px;
    width: 100%;
    margin-bottom: 0;
    justify-content: flex-start;
    gap: 0 20px;
  }
}
.list-menu__item a {
  display: inline-block;
  color: #000;
  border-radius: 30px;
  font-weight: 500;
  padding: 12.5px 0;
  font-size: 1.8rem;
}
@media screen and (max-width: 834px) {
  .list-menu__item a {
    padding: 11px 24px;
    font-size: 1.6rem;
  }
}
.list-menu__item a:hover {
  padding-left: 24px;
  padding-right: 24px;
  opacity: 1;
  color: #fff;
  background: #0D2641;
}
.list-menu__item.active a {
  color: #fff;
  background: #0D2641;
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .sec-shop .list-menu {
    flex-wrap: nowrap;
    width: max-content;
  }
}
@media screen and (max-width: 834px) {
  .sec-shop .list-menu__item a {
    padding: 13px 0;
  }
}
.sec-shop .list-menu__item a:hover {
  padding: 12.5px 24px;
}
@media screen and (max-width: 834px) {
  .sec-shop .list-menu__item.active a {
    padding: 12.5px 24px;
  }
}

.shop-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.shop-list__item:not(:last-child) {
  margin-bottom: 63px;
}
@media screen and (max-width: 834px) {
  .shop-list__item:not(:last-child) {
    margin-bottom: 10px;
  }
}
.shop-list__item .c-ttl__04 {
  width: 136px;
  padding-right: 10px;
}
@media screen and (max-width: 1245px) {
  .shop-list__item .c-ttl__04 {
    width: 100px;
  }
}
@media screen and (max-width: 834px) {
  .shop-list__item .c-ttl__04 {
    width: 100%;
    margin-bottom: 32px;
    padding-right: 0;
  }
}
.shop-list__main {
  padding-top: 71px;
  width: calc(100% - 136px);
}
@media screen and (max-width: 1245px) {
  .shop-list__main {
    padding-top: 5px;
    width: 100%;
    width: calc(100% - 100px);
  }
}
@media screen and (max-width: 834px) {
  .shop-list__main {
    width: 100%;
    padding-top: 0;
  }
}
.shop-list__main-img {
  margin-bottom: 40px;
  width: 100%;
}
@media screen and (min-width: 641px) {
  .shop-list__main-img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}
@media screen and (max-width: 640px) {
  .shop-list__main-img {
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
  .shop-list__main-imgItem {
    position: relative;
  }
  .shop-list__main-imgItem::before {
    content: "";
    float: left;
    padding-top: 61.5384615385%;
  }
  .shop-list__main-imgItem::after {
    content: "";
    display: table;
    clear: both;
  }
  .shop-list__main-imgItem > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .shop-list__main-imgItem > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.shop-list__main-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 57px;
  margin-bottom: 63px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 1245px) {
  .shop-list__main-item {
    padding-bottom: 40px;
    margin-bottom: 33px;
    flex-wrap: wrap;
  }
}
.shop-list__main-ct {
  width: calc(100% - 320px);
  padding-right: 20px;
}
@media screen and (max-width: 1440px) {
  .shop-list__main-ct {
    width: calc(100% - 250px);
  }
}
@media screen and (max-width: 1245px) {
  .shop-list__main-ct {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .shop-list__main-ct {
    width: calc(100% - 250px);
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 640px) {
  .shop-list__main-ct {
    width: 100%;
  }
}
.shop-list__main-ct .c-ttl__05 a {
  color: inherit;
  background: linear-gradient(#333, #333) 100% 100%/0 1px no-repeat;
  transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
  will-change: background-size;
}
.shop-list__main-ct .c-ttl__05 a:hover {
  background-position: 0 100%;
  background-size: 100% 1px;
}
.shop-list__main-ct .list-tags {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .shop-list__main-ct .c-btn__03 span {
    border-bottom: 0;
    padding-bottom: 0;
    text-decoration: underline;
    font-size: 1.2rem;
  }
}
.shop-list__main-ct .c-txt__01 {
  margin-bottom: 4px;
}
.shop-list__main-desc dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.shop-list__main-desc dl dt {
  width: 5.2em;
}
.shop-list__main-desc dl dd {
  width: calc(100% - 5.2em);
}
.shop-list__main-desc dl.salary dt {
  width: 7.1em;
}
.shop-list__main-desc dl.salary dd {
  width: calc(100% - 7.1em);
}
.shop-list__main-btn {
  width: 320px;
  padding-top: 45px;
}
@media screen and (max-width: 1440px) {
  .shop-list__main-btn {
    width: 250px;
  }
}
@media screen and (max-width: 1245px) {
  .shop-list__main-btn {
    width: 100%;
    padding-top: 0;
  }
}
@media screen and (max-width: 834px) {
  .shop-list__main-btn {
    width: 250px;
  }
}
@media screen and (max-width: 640px) {
  .shop-list__main-btn {
    width: 100%;
  }
}
.shop-list__main-btn .c-btn__02:not(:last-child) {
  margin-bottom: 25px;
}
@media screen and (max-width: 1245px) {
  .shop-list__main-btn .c-btn__02:not(:last-child) {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 834px) {
  .shop-list__main-btn .c-btn__02:not(:last-child) {
    margin-bottom: 24px;
  }
}

.mv-shop {
  position: relative;
}
@media screen and (min-width: 835px) {
  .mv-shop .sec-breadcrumb {
    position: absolute;
    top: 220px;
    max-width: 1920px;
    left: 50%;
    z-index: 2;
    width: 100%;
    transform: translateX(-50%);
  }
  .mv-shop .sec-breadcrumb a,
  .mv-shop .sec-breadcrumb span {
    color: #fff;
  }
  .mv-shop .sec-breadcrumb a::after,
  .mv-shop .sec-breadcrumb span::after {
    background: #fff;
  }
}
.mv-shop__img .slick-track {
  display: flex !important;
}
.mv-shop__img .slick-slide {
  height: inherit !important;
}
@media screen and (max-width: 834px) {
  .mv-shop__img {
    margin-top: 59px;
  }
}
.mv-shop__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 835px) {
  .mv-shop__img img {
    max-height: 100vh;
  }
}
@media screen and (max-width: 834px) {
  .mv-shop__img img {
    object-fit: cover;
    height: 128.205vw;
  }
}

.sec-detail {
  padding: 113px 0 119px;
}
@media screen and (max-width: 1245px) {
  .sec-detail {
    padding: 90px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-detail {
    padding: 58px 0 61px;
  }
}
.sec-detail a[href^=tel] {
  pointer-events: auto;
}

.detail-block {
  margin-bottom: 103px;
}
@media screen and (max-width: 834px) {
  .detail-block {
    margin-bottom: 51px;
  }
}
.detail-block__btn {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px 16px;
  margin-bottom: 41px;
}
@media screen and (max-width: 834px) {
  .detail-block__btn {
    gap: 24px 16px;
    margin-bottom: 34px;
  }
}
.detail-block__btn-item {
  width: 320px;
}
@media screen and (max-width: 1440px) {
  .detail-block__btn-item {
    width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .detail-block__btn-item {
    width: 100%;
  }
}
.detail-block__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
@media screen and (max-width: 834px) {
  .detail-block__flex {
    display: block;
  }
}
.detail-block__flex .c-btn__03 {
  margin-right: 43px;
  padding-right: 41px;
}
@media screen and (max-width: 834px) {
  .detail-block__flex .c-btn__03 {
    padding-right: 26px;
  }
}
.detail-block__flex .c-btn__03::after {
  top: 29%;
}
@media screen and (max-width: 834px) {
  .detail-block__flex .c-btn__03 {
    margin-right: 0;
    margin-bottom: 21px;
  }
}
.detail-block__flex .c-btn__03 > span {
  font-weight: normal;
  font-size: 1.6rem;
}
@media screen and (min-width: 835px) {
  .detail-block__flex .c-btn__03 > span {
    border-bottom: 1px solid black;
    padding-bottom: 8px;
    text-decoration: none;
  }
}
@media screen and (max-width: 834px) {
  .detail-block__flex .c-btn__03 > span {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 835px) {
  .company-option--02 .company-option__dl .company-option__dt {
    width: 260px;
  }
  .company-option--02 .company-option__dl .company-option__dd {
    width: calc(100% - 260px);
  }
}
.menu-food {
  margin: 118px -24px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 1245px) {
  .menu-food {
    margin: 80px -10px 0;
  }
}
@media screen and (max-width: 834px) {
  .menu-food {
    margin: 60px 0 0;
    flex-wrap: wrap;
    gap: 24px 0;
  }
}
.menu-food__item {
  padding: 0 24px;
  width: 33.33%;
}
@media screen and (max-width: 1245px) {
  .menu-food__item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 834px) {
  .menu-food__item {
    padding: 0;
    width: 100%;
  }
}
.menu-food__item a {
  border-radius: 16px;
  overflow: hidden;
  display: block;
  position: relative;
}
.menu-food__item a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(10, 31, 54, 0.7);
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s;
}
@media screen and (min-width: 835px) {
  .menu-food__item a:hover {
    opacity: 1;
  }
  .menu-food__item a:hover .menu-food__img img {
    transform: scale(1.1);
    transition: all 0.5s;
  }
}
.menu-food__item--02 a::before {
  background: rgba(186, 145, 91, 0.3);
  transition: all 0.3s;
}
.menu-food__item--02 .menu-food__link-ttl span::after {
  background: url("../img/shop/icon-01.svg") center no-repeat;
  background-size: contain;
  transition: all 0.3s;
}
.menu-food__img {
  position: relative;
}
.menu-food__img::before {
  content: "";
  float: left;
  padding-top: 71.4285714286%;
}
.menu-food__img::after {
  content: "";
  display: table;
  clear: both;
}
.menu-food__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.menu-food__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .menu-food__img::before {
    padding-top: 48.2%;
  }
}
.menu-food__img img {
  transition: all 0.5s;
}
.menu-food__link {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 34px 70px 34px 40px;
  color: #fff;
  z-index: 2;
}
@media screen and (max-width: 1440px) {
  .menu-food__link {
    padding: 30px 50px 20px 20px;
  }
}
@media screen and (max-width: 1245px) {
  .menu-food__link {
    padding: 20px 50px 16px 15px;
  }
}
.menu-food__link:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  right: 31px;
  top: 64%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1245px) {
  .menu-food__link:after {
    right: 11px;
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 834px) {
  .menu-food__link:after {
    width: 34px;
    height: 34px;
    right: 16px;
  }
}
.menu-food__link:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 13px;
  height: 10px;
  background: url("../img/common/icon-link.svg") center no-repeat;
  background-size: contain;
  right: 46px;
  top: 64%;
  transform: translateY(-50%);
  z-index: 2;
}
@media screen and (max-width: 1245px) {
  .menu-food__link:before {
    right: 21px;
    width: 10px;
  }
}
@media screen and (max-width: 834px) {
  .menu-food__link:before {
    right: 28px;
  }
}
.menu-food__link-ttl {
  font-weight: 600;
  margin-bottom: 15px;
  letter-spacing: 0.5px;
  font-size: 2.9rem;
}
@media screen and (max-width: 1440px) {
  .menu-food__link-ttl {
    margin-bottom: 10px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1245px) {
  .menu-food__link-ttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 834px) {
  .menu-food__link-ttl {
    margin-bottom: 5px;
    font-size: 2.2rem;
  }
}
.menu-food__link-ttl span {
  transition: all 0.3s;
  padding-right: 40px;
  position: relative;
}
@media screen and (max-width: 1245px) {
  .menu-food__link-ttl span {
    padding-right: 30px;
  }
}
.menu-food__link-ttl span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  background: url("../img/shop/icon-02.svg") center no-repeat;
  background-size: contain;
  top: 54%;
  transform: translateY(-50%);
  right: 0;
}
@media screen and (max-width: 1245px) {
  .menu-food__link-ttl span:after {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 834px) {
  .menu-food__link-ttl span:after {
    width: 22px;
    height: 22px;
  }
}
.menu-food__link p {
  font-weight: 500;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .menu-food__link p {
    font-size: 1.4rem;
  }
}

.sec-fair {
  padding: 132px 0 78px;
  background: #F3F3F3;
}
@media screen and (max-width: 1245px) {
  .sec-fair {
    padding: 70px 0 50px;
  }
}
@media screen and (max-width: 834px) {
  .sec-fair {
    padding: 57px 0 60px;
  }
}
.sec-fair .c-ttl__01 {
  margin-bottom: 72px;
}
@media screen and (max-width: 1245px) {
  .sec-fair .c-ttl__01 {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .sec-fair .c-ttl__01 {
    margin-bottom: 27px;
  }
}
.sec-fair .c-ttl__01 .c-ft--en {
  margin-right: 27px;
}
@media screen and (max-width: 834px) {
  .sec-fair .c-ttl__01 .c-ft--en {
    margin-right: 0;
    margin-bottom: -4px;
  }
}

.fair-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0 112px;
}
@media screen and (max-width: 1245px) {
  .fair-flex {
    flex-wrap: wrap;
  }
}
.fair-flex__left {
  width: 320px;
}
@media screen and (max-width: 1245px) {
  .fair-flex__left {
    width: 100%;
    margin-bottom: 24px;
    padding-right: 20px;
  }
}
.fair-flex__left .c-txt__01 {
  margin-bottom: 50px;
}
.fair-flex__right {
  width: calc(100% - 432px);
}
@media screen and (max-width: 1245px) {
  .fair-flex__right {
    width: 100%;
  }
}

.tab-menu {
  margin-bottom: 71px;
}
@media screen and (max-width: 1245px) {
  .tab-menu {
    margin-bottom: 35px;
    gap: 10px 35px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media screen and (max-width: 834px) {
  .tab-menu {
    gap: 10px 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: 500px;
    margin: 0 auto;
  }
}
.tab-menu__item:not(:last-child) {
  margin-bottom: 24px;
}
@media screen and (max-width: 1245px) {
  .tab-menu__item:not(:last-child) {
    margin-bottom: 0;
  }
}
.tab-menu__item span {
  display: inline-block;
  padding: 13px 0;
  color: #000;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.6rem;
  border-radius: 30px;
  transition: 0.3s;
}
@media screen and (max-width: 834px) {
  .tab-menu__item span {
    padding: 11px 0;
    font-size: 1.4rem;
  }
}
.tab-menu__item:hover span {
  padding-left: 24px;
  padding-right: 24px;
  color: #fff;
  background: #0D2641;
}
@media screen and (max-width: 834px) {
  .tab-menu__item:hover span {
    margin-right: -15px;
  }
}
.tab-menu__item.active {
  margin-bottom: 27px;
}
@media screen and (max-width: 1245px) {
  .tab-menu__item.active {
    margin-bottom: 0;
  }
}
.tab-menu__item.active span {
  color: #fff;
  background: #0D2641;
  padding: 13px 24px;
}
@media screen and (max-width: 834px) {
  .tab-menu__item.active span {
    margin-right: -15px;
    padding: 11px 24px;
  }
}

.tab-content__item {
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: all 0.2s ease-in;
}
.tab-content__item.active {
  opacity: 1;
  display: block;
  visibility: visible;
}

@media screen and (max-width: 834px) {
  .fair-flex__posts {
    margin-bottom: 33px;
  }
}
.fair-flex__posts-item {
  padding-bottom: 39px;
  margin-bottom: 41px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-item {
    padding-bottom: 16px;
    margin-bottom: 24px;
  }
}
.fair-flex__posts-item a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 120px;
  position: relative;
}
@media screen and (max-width: 1440px) {
  .fair-flex__posts-item a {
    padding-right: 40px;
  }
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-item a {
    padding-right: 0;
    flex-wrap: wrap;
  }
}
.fair-flex__posts-item a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url("../img/index/arrow02.svg") center no-repeat;
  background-size: contain;
  right: 40px;
  top: 50%;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media screen and (max-width: 1440px) {
  .fair-flex__posts-item a:before {
    right: 10px;
  }
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-item a:before {
    display: none;
  }
}
@media screen and (min-width: 835px) {
  .fair-flex__posts-item a:hover::before {
    transition: all 0.3s;
    right: 30px;
  }
}
@media screen and (min-width: 835px) and (max-width: 1440px) {
  .fair-flex__posts-item a:hover::before {
    right: 0;
  }
}
.fair-flex__posts-item a:hover h3.c-txt__01 {
  background-position: 0 100%;
  background-size: 100% 1px;
}
.fair-flex__posts-img {
  width: 192px;
  position: relative;
}
.fair-flex__posts-img::before {
  content: "";
  float: left;
  padding-top: 75%;
}
.fair-flex__posts-img::after {
  content: "";
  display: table;
  clear: both;
}
.fair-flex__posts-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.fair-flex__posts-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-img {
    width: 36%;
  }
}
.fair-flex__posts-meta {
  width: 215px;
  padding-right: 20px;
  padding-left: 35px;
  padding-top: 5px;
}
@media screen and (max-width: 1440px) {
  .fair-flex__posts-meta {
    padding-right: 15px;
    padding-left: 15px;
    width: 148px;
  }
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-meta {
    width: 64%;
    padding-left: 15px;
    padding-right: 20px;
    position: relative;
  }
  .fair-flex__posts-meta:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 14px;
    height: 11px;
    background: url("../img/index/arrow02.svg") center no-repeat;
    background-size: contain;
    right: 0;
    top: 57%;
    transition: all 0.3s;
    transform: translateY(-50%);
  }
  .fair-flex__posts-meta .list-tags {
    gap: 7px;
  }
}
.fair-flex__posts-date {
  display: block;
  color: #000000;
  font-family: "Lato", serif;
  margin-bottom: 10px;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-date {
    margin-bottom: 6px;
    font-size: 1.4rem;
  }
}
.fair-flex__posts-ct {
  width: calc(100% - 407px);
  color: #000;
}
@media screen and (max-width: 1440px) {
  .fair-flex__posts-ct {
    width: calc(100% - 340px);
  }
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-ct {
    width: 100%;
    margin-top: 8px;
  }
}
.fair-flex__posts-ct .c-txt__01 {
  margin-bottom: 7px;
  color: #0A1F36;
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-ct .c-txt__01 {
    margin-bottom: 10px;
  }
}
.fair-flex__posts-ct .c-txt__01 span {
  font-weight: 500;
}
@media screen and (max-width: 834px) {
  .fair-flex__posts-ct .c-txt__01 span {
    width: 100%;
    display: block;
    margin-bottom: 5px;
  }
}
.fair-flex__posts-ct h3 {
  color: #000 !important;
  display: inline-block;
}
.fair-flex__posts-ct h3.c-txt__01 {
  position: relative;
  background: -webkit-gradient(linear, left top, left bottom, from(#333), to(#333)) 100% 100%/0 1px no-repeat;
  background: linear-gradient(#333, #333) 100% 100%/0 1px no-repeat;
  -webkit-transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
  transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
  will-change: background-size;
}

.banner-mw {
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .banner-mw {
    margin-top: 80px;
    height: 192px;
    position: relative;
  }
}

.banner-loop {
  padding: 120px 0 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .banner-loop {
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    -webkit-animation: slider-move 50s linear infinite;
    animation: slider-move 50s linear infinite;
  }
  .banner-loop:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }
}
.banner-loop__item {
  width: 33.33%;
  position: relative;
}
.banner-loop__item::before {
  content: "";
  float: left;
  padding-top: 62.5%;
}
.banner-loop__item::after {
  content: "";
  display: table;
  clear: both;
}
.banner-loop__item > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.banner-loop__item > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .banner-loop__item {
    width: 288px;
    position: relative;
  }
  .banner-loop__item::before {
    content: "";
    float: left;
    padding-top: 66.6666666667%;
  }
  .banner-loop__item::after {
    content: "";
    display: table;
    clear: both;
  }
  .banner-loop__item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .banner-loop__item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sec-instagram {
  padding: 120px 0;
  overflow: hidden;
}
@media screen and (max-width: 834px) {
  .sec-instagram {
    padding: 80px 0 59px;
  }
}

.instagram-img {
  margin-bottom: 55px;
}
@media screen and (max-width: 834px) {
  .instagram-img {
    margin-bottom: 35px;
  }
}
.instagram-img img {
  width: 100%;
}

.menu-link {
  margin: 0 -20px;
  gap: 51px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .menu-link {
    gap: 24.5px 0;
  }
}
.menu-link__item {
  padding: 0 20px;
  width: 16.6666666667%;
}
@media screen and (max-width: 1245px) {
  .menu-link__item {
    width: 25%;
  }
}
@media screen and (max-width: 834px) {
  .menu-link__item {
    width: 33.33%;
  }
}
@media screen and (max-width: 640px) {
  .menu-link__item {
    width: 50%;
  }
}

.page-fair_index .content-left .inner {
  padding: 0 14.644%;
  width: 100%;
}
@media screen and (max-width: 1245px) {
  .page-fair_index .content-left .inner {
    padding: 0 5%;
  }
}
@media screen and (max-width: 834px) {
  .page-fair_index .content-left .inner {
    padding: 0 16px;
  }
}
.page-fair_index .content-left__mv {
  max-width: 100%;
}

.fair-list {
  padding-top: 38px;
  margin-bottom: 119px;
}
@media screen and (max-width: 1245px) {
  .fair-list {
    padding-top: 0;
    margin-bottom: 80px;
    transform: translateX(0);
  }
}
@media screen and (max-width: 834px) {
  .fair-list {
    margin-bottom: 59px;
  }
}
.fair-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 64px;
  border-bottom: 1px solid #dbdad2;
  padding-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .fair-list__item {
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
}
.fair-list__left {
  width: 45.2%;
}
@media screen and (max-width: 834px) {
  .fair-list__left {
    width: 100%;
  }
}
.fair-list__img {
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 42px;
}
@media screen and (max-width: 834px) {
  .fair-list__img {
    margin-bottom: 37px;
  }
}
.fair-list__img img {
  width: 100%;
}
.fair-list__right {
  width: 54.8%;
  padding-left: 112px;
}
@media screen and (max-width: 1245px) {
  .fair-list__right {
    padding-left: 8.99%;
  }
}
@media screen and (max-width: 834px) {
  .fair-list__right {
    width: 100%;
    padding-left: 0;
  }
}
.fair-list__option-box:not(:last-child) {
  margin-bottom: 41px;
}
@media screen and (max-width: 1245px) {
  .fair-list__option-box:not(:last-child) {
    margin-bottom: 34px;
  }
}
.fair-list__option-item {
  padding-bottom: 26px;
  margin-bottom: 37px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 1245px) {
  .fair-list__option-item {
    padding-bottom: 24px;
    margin-bottom: 29px;
  }
}
.fair-list__option-time {
  color: #000000;
  margin-bottom: 11px;
  font-family: "Lato", serif;
  font-weight: 500;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .fair-list__option-time {
    margin-bottom: 1px;
    font-size: 1.4rem;
  }
}
.fair-list__option-heading {
  margin-bottom: 25px;
}
@media screen and (max-width: 1245px) {
  .fair-list__option-heading {
    margin-bottom: 17px;
  }
}
.fair-list__option-desc {
  margin-top: 17px;
}
@media screen and (max-width: 1245px) {
  .fair-list__option-desc {
    margin-top: 14px;
  }
}
.fair-list__option-ttl {
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .fair-list__option-ttl {
    margin-bottom: 11px;
    font-size: 1.4rem;
  }
}
.fair-list__option-ttl--accordion span {
  display: inline-block;
  padding-right: 48px;
  cursor: pointer;
  position: relative;
}
.fair-list__option-ttl--accordion span:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.fair-list__option-ttl--accordion span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 32px;
  height: 32px;
  background: url("../img/fair/icon-plus.svg") center no-repeat;
  background-size: contain;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.fair-list__option-ttl--accordion.on span::after {
  background: url("../img/fair/icon-close.svg") center no-repeat;
  background-size: contain;
}
.fair-list__option-ct {
  display: none;
}

.eoyster--02 {
  max-width: 1160px;
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  position: relative;
}
.eoyster--02 .eoyster-box {
  padding: 25px 40px 31px;
  top: 56%;
}
@media screen and (max-width: 834px) {
  .eoyster--02 .eoyster-box {
    padding: 20px 17px;
    top: 6%;
  }
}
.eoyster--02 .c-txt__01 {
  max-width: 485px;
}
.eoyster--02 .c-btn__01 {
  position: absolute;
  right: 39px;
  bottom: 59px;
}
@media screen and (max-width: 834px) {
  .eoyster--02 .c-btn__01 {
    right: 15px;
    bottom: 35px;
  }
}
.eoyster--02 .eoyster-bg img {
  min-height: 394px;
}
@media screen and (max-width: 1245px) {
  .eoyster--02 .eoyster-bg img {
    min-height: 300px;
  }
}
@media screen and (max-width: 834px) {
  .eoyster--02 .eoyster-bg img {
    min-height: 537px;
    height: auto;
  }
}

.eoyster--03 {
  margin-right: auto;
  transform: translateX(15px);
}
@media screen and (max-width: 1245px) {
  .eoyster--03 {
    transform: translateX(0);
  }
}

@media screen and (max-width: 834px) {
  .eoyster--486 .eoyster-box {
    top: 7%;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .eoyster--486 .eoyster-bg img {
    min-height: 486px;
  }
}

@media screen and (max-width: 834px) {
  .eoyster--480 .eoyster-box {
    top: 7%;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .eoyster--480 .eoyster-bg img {
    min-height: 480px;
  }
}

.sec-sea02 {
  background: #0A1F36;
  padding: 125px 0 57px;
}
@media screen and (max-width: 1245px) {
  .sec-sea02 {
    padding: 70px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-sea02 {
    padding: 40px 0 35px;
  }
}
.sec-sea02 .sea-flex--02 {
  position: relative;
}
@media screen and (max-width: 834px) {
  .sec-sea02 .sea-flex--02 .c-ttl__02 {
    margin-bottom: 17px;
  }
}
@media screen and (max-width: 834px) {
  .sec-sea02 .sea-flex--02 .sea-flex__logo {
    margin-bottom: 37px;
  }
}
@media screen and (max-width: 834px) {
  .sec-sea02 .sea-flex--02 .sea-flex__logo img {
    max-width: 230px;
  }
}
@media screen and (max-width: 834px) {
  .sec-sea02 .sea-flex--02 .c-txt__01 {
    margin-bottom: 0;
  }
}

.oyster-ct {
  position: relative;
}
@media screen and (max-width: 834px) {
  .oyster-ct {
    border-bottom: 1px solid #DBDAD2;
    padding-bottom: 55px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-ct__img {
    margin-bottom: 59px;
  }
}
.oyster-ct__img-item img {
  width: 100%;
  min-height: 1000px;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .oyster-ct__img-item img {
    min-height: 0;
  }
}
.oyster-ct__list {
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
@media screen and (max-width: 834px) {
  .oyster-ct__list {
    position: static;
    top: 0;
    left: 0;
    color: #000;
    transform: translate(0, 0);
  }
}
.oyster-ct__list-item:not(:last-child) {
  margin-bottom: 63px;
}
@media screen and (max-width: 834px) {
  .oyster-ct__list-item:not(:last-child) {
    margin-bottom: 45px;
  }
}
.oyster-ct__list-item .c-ttl__04 {
  color: #fff;
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .oyster-ct__list-item .c-ttl__04 {
    color: #000;
    margin-bottom: 15px;
  }
}

.sec-methods {
  padding: 131px 0 115px;
}
@media screen and (max-width: 1245px) {
  .sec-methods {
    padding: 80px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-methods {
    padding: 55px 0;
  }
}
.sec-methods .c-ttl__01 {
  margin-bottom: 68px;
}
@media screen and (max-width: 1245px) {
  .sec-methods .c-ttl__01 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .sec-methods .c-ttl__01 {
    margin-bottom: 29px;
  }
}
.sec-methods .c-ttl__02 {
  margin-bottom: 68px;
}
@media screen and (max-width: 1245px) {
  .sec-methods .c-ttl__02 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .sec-methods .c-ttl__02 {
    margin-bottom: 26px;
  }
}
.sec-methods .c-ttl__04 {
  margin-bottom: 30px;
}
@media screen and (max-width: 1245px) {
  .sec-methods .c-ttl__04 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .sec-methods .c-ttl__04 {
    margin-bottom: 9px;
  }
}

.methods-img {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -56px 48px;
}
@media screen and (max-width: 1245px) {
  .methods-img {
    margin: 0 -20px 40px;
  }
}
@media screen and (max-width: 834px) {
  .methods-img {
    margin: 0 0 28px;
    flex-wrap: wrap;
    gap: 29px 0;
  }
}
.methods-img__item {
  padding: 0 56px;
  width: 50%;
}
@media screen and (max-width: 1245px) {
  .methods-img__item {
    padding: 0 20px;
  }
}
@media screen and (max-width: 834px) {
  .methods-img__item {
    width: 100%;
    padding: 0;
  }
}
.methods-img__thumb {
  position: relative;
}
.methods-img__thumb::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.methods-img__thumb::after {
  content: "";
  display: table;
  clear: both;
}
.methods-img__thumb > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.methods-img__thumb > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .methods-img__thumb::before {
    padding-top: 68.412%;
  }
  .methods-img__thumb--02::before {
    padding-top: 55.412%;
  }
}

.sec-system02 {
  padding: 129px 0 120px;
  background: #F3F3F3;
}
@media screen and (max-width: 1245px) {
  .sec-system02 {
    padding: 80px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-system02 {
    padding: 56px 0 61px;
  }
}

.system02-block {
  margin-bottom: 64px;
}
@media screen and (max-width: 834px) {
  .system02-block {
    margin-bottom: 54px;
  }
}
.system02-block .c-ttl__01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1245px) {
  .system02-block .c-ttl__01 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .system02-block .c-ttl__01 {
    margin-bottom: 29px;
  }
}
.system02-block .c-ttl__02 {
  margin-bottom: 31px;
}
@media screen and (max-width: 1245px) {
  .system02-block .c-ttl__02 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .system02-block .c-ttl__02 {
    margin-bottom: 17px;
  }
}

.system02-list {
  margin-bottom: 117px;
}
@media screen and (max-width: 1245px) {
  .system02-list {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 834px) {
  .system02-list {
    margin-bottom: 58px;
  }
}
.system02-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (min-width: 835px) {
  .system02-list__item:nth-child(2n) .system02-list__ct {
    order: 2;
    padding-left: 112px;
    padding-right: 0;
  }
}
@media screen and (min-width: 835px) and (max-width: 1245px) {
  .system02-list__item:nth-child(2n) .system02-list__ct {
    padding-left: 25px;
  }
}
@media screen and (min-width: 835px) {
  .system02-list__item:nth-child(2n) .system02-list__img {
    order: 1;
  }
}
.system02-list__item:not(:last-child) {
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .system02-list__item:not(:last-child) {
    margin-bottom: 57px;
  }
}
.system02-list__ct {
  width: 54.85%;
  padding-right: 112px;
}
@media screen and (max-width: 1245px) {
  .system02-list__ct {
    width: 62%;
    padding-right: 25px;
  }
}
@media screen and (max-width: 834px) {
  .system02-list__ct {
    padding-right: 0;
    width: 100%;
    margin-bottom: 24px;
  }
}
.system02-list__ct .c-ttl__04 {
  margin-bottom: 32px;
}
@media screen and (max-width: 1245px) {
  .system02-list__ct .c-ttl__04 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .system02-list__ct .c-ttl__04 {
    margin-bottom: 11px;
  }
}
.system02-list__img {
  width: 45.15%;
  position: relative;
}
.system02-list__img::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.system02-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.system02-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.system02-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .system02-list__img {
    width: 38%;
  }
}
@media screen and (max-width: 834px) {
  .system02-list__img {
    width: 100%;
  }
  .system02-list__img::before {
    padding-top: 63.412%;
  }
}

.system02-box {
  background: #fff;
  padding: 62px 20px 70px;
  border-radius: 16px;
}
@media screen and (max-width: 1245px) {
  .system02-box {
    padding: 50px 10px;
  }
}
@media screen and (max-width: 640px) {
  .system02-box {
    padding: 34px 16px;
  }
}
.system02-box .c-ttl__04 {
  margin-bottom: 68px;
}
@media screen and (max-width: 1245px) {
  .system02-box .c-ttl__04 {
    margin-bottom: 40px;
  }
}
.system02-box__list {
  gap: 48px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .system02-box__list {
    gap: 36px 0;
  }
}
.system02-box__list-item {
  padding: 0 20px;
  width: 33.33%;
}
@media screen and (max-width: 1245px) {
  .system02-box__list-item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 834px) {
  .system02-box__list-item {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .system02-box__list-item {
    width: 100%;
    padding: 0;
  }
}
.system02-box__list-item .c-ttl__05 {
  text-align: center;
  margin-bottom: 12px;
}
@media screen and (max-width: 834px) {
  .system02-box__list-item .c-ttl__05 {
    margin-bottom: 0;
  }
}
.system02-box__list-img {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 27px;
}
.system02-box__list-img::before {
  content: "";
  float: left;
  padding-top: 55.5555555556%;
}
.system02-box__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.system02-box__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.system02-box__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .system02-box__list-img {
    margin-bottom: 6px;
  }
  .system02-box__list-img::before {
    padding-top: 52.556%;
  }
}

.sec-technology {
  padding: 129px 0 112px;
}
@media screen and (max-width: 1245px) {
  .sec-technology {
    padding: 80px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-technology {
    padding: 56px 0 60px;
  }
}
.sec-technology .c-ttl__01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 1245px) {
  .sec-technology .c-ttl__01 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .sec-technology .c-ttl__01 {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 834px) {
  .sec-technology .c-ttl__02 {
    margin-bottom: 18px;
  }
}

.technology-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.technology-flex__ct {
  width: 54.85%;
  padding-right: 112px;
}
@media screen and (max-width: 1245px) {
  .technology-flex__ct {
    padding-right: 25px;
    width: 62%;
  }
}
@media screen and (max-width: 834px) {
  .technology-flex__ct {
    padding-right: 0;
    width: 100%;
    margin-bottom: 24px;
  }
}
.technology-flex__img {
  width: 45.15%;
  position: relative;
}
.technology-flex__img::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.technology-flex__img::after {
  content: "";
  display: table;
  clear: both;
}
.technology-flex__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.technology-flex__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .technology-flex__img {
    width: 38%;
  }
}
@media screen and (max-width: 834px) {
  .technology-flex__img {
    width: 100%;
  }
}

.sec-offer {
  padding: 126px 0 120px;
  background: #F3F3F3;
}
@media screen and (max-width: 1245px) {
  .sec-offer {
    padding: 80px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-offer {
    padding: 56px 0 61px;
  }
}
.sec-offer .c-ttl__01 {
  margin-bottom: 68px;
}
@media screen and (max-width: 1245px) {
  .sec-offer .c-ttl__01 {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .sec-offer .c-ttl__01 {
    margin-bottom: 30px;
  }
}
.sec-offer .c-ttl__02 {
  margin-bottom: 36px;
}
@media screen and (max-width: 1245px) {
  .sec-offer .c-ttl__02 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .sec-offer .c-ttl__02 {
    margin-bottom: 20px;
  }
}
.sec-offer .eoyster--02:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .sec-offer .eoyster--02:not(:last-child) {
    margin-bottom: 20px;
  }
}
.sec-offer--02 {
  padding: 83px 0 118px;
}
@media screen and (max-width: 834px) {
  .sec-offer--02 {
    padding: 39px 0 60px;
  }
  .sec-offer--02 .eoyster-box {
    top: 0;
  }
  .sec-offer--02 .eoyster-bg img {
    max-height: max-content;
  }
}

.offer-block {
  margin-bottom: 69px;
}
@media screen and (max-width: 1245px) {
  .offer-block {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .offer-block {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 834px) {
  .page-8th_sea_oyster_e-oyster_index .mv-page .c-ttl__03 .c-ft--jp {
    line-height: 1.447;
  }
}

.eoyster-flex {
  margin-left: calc((100vw - 100%) / 2 * -1);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 1245px) {
  .eoyster-flex {
    padding: 50px 0 0;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-flex {
    padding: 0;
    flex-wrap: wrap;
    margin-left: 0;
  }
}
.eoyster-flex__img {
  width: 49.75%;
}
@media screen and (max-width: 1245px) {
  .eoyster-flex__img {
    width: 40%;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-flex__img {
    width: calc(100% + 32px);
    margin: 0 -16px 38px;
  }
}
.eoyster-flex__ct {
  width: 50.25%;
  padding-left: 112px;
  padding-top: 10px;
}
@media screen and (max-width: 1440px) {
  .eoyster-flex__ct {
    padding-left: 25px;
    padding-top: 0;
  }
}
@media screen and (max-width: 1245px) {
  .eoyster-flex__ct {
    width: 60%;
  }
}
@media screen and (max-width: 834px) {
  .eoyster-flex__ct {
    width: 100%;
    padding-left: 0;
  }
}
.eoyster-flex__ct .c-txt__01 {
  margin-bottom: 48px;
  max-width: 665px;
}
@media screen and (max-width: 1245px) {
  .eoyster-flex__ct .c-txt__01 {
    margin-bottom: 30px;
  }
}
.eoyster-flex__ct .c-btn__02 {
  max-width: 400px;
}

.sec-recommend {
  padding: 132px 0 70px;
}
@media screen and (max-width: 1245px) {
  .sec-recommend {
    padding: 90px 0 50px;
  }
}
@media screen and (max-width: 834px) {
  .sec-recommend {
    padding: 57px 0 31px;
  }
}

.recommend-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -56px 48px;
  gap: 80px 0;
}
@media screen and (max-width: 1245px) {
  .recommend-list {
    margin: 0 -15px 40px;
  }
}
@media screen and (max-width: 834px) {
  .recommend-list {
    margin: 0 0 28px;
    flex-wrap: wrap;
    gap: 60px 0;
  }
}
.recommend-list__item {
  padding: 0 56px;
  width: 50%;
}
@media screen and (max-width: 1245px) {
  .recommend-list__item {
    padding: 0 15px;
  }
}
@media screen and (max-width: 834px) {
  .recommend-list__item {
    width: 100%;
    padding: 0;
  }
}
.recommend-list__item .c-ttl__04 {
  margin-bottom: 33px;
}
@media screen and (max-width: 1245px) {
  .recommend-list__item .c-ttl__04 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .recommend-list__item .c-ttl__04 {
    margin-bottom: 15px;
  }
}
.recommend-list__item .c-ttl__05 {
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .recommend-list__item .c-ttl__05 {
    margin-bottom: 12px;
  }
}
.recommend-list__item .c-txt__01 {
  margin-bottom: 49px;
}
@media screen and (max-width: 1245px) {
  .recommend-list__item .c-txt__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .recommend-list__item .c-txt__01 {
    margin-bottom: 29px;
  }
}
.recommend-list__item .c-btn__02 {
  max-width: 400px;
}
.recommend-list__img {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 39px;
  position: relative;
}
.recommend-list__img::before {
  content: "";
  float: left;
  padding-top: 68.6746987952%;
}
.recommend-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.recommend-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.recommend-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .recommend-list__img {
    margin-bottom: 20px;
  }
}

.sec-guide {
  padding: 132px 0 118px;
  background: #F3F3F3;
}
@media screen and (max-width: 1245px) {
  .sec-guide {
    padding: 90px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-guide {
    padding: 57px 0 61px;
  }
}

.sec-how {
  padding: 131px 0 54px;
}
@media screen and (max-width: 1245px) {
  .sec-how {
    padding: 90px 0 60px;
  }
}
@media screen and (max-width: 834px) {
  .sec-how {
    padding: 56px 0 33px;
  }
}

.guide-box {
  background: #fff;
  border-radius: 8px;
  padding: 68px 96px;
}
@media screen and (max-width: 1245px) {
  .guide-box {
    padding: 50px 30px;
  }
}
@media screen and (max-width: 834px) {
  .guide-box {
    padding: 33px 16px;
  }
}
.guide-box .c-ttl__05 {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .guide-box .c-ttl__05 {
    margin-bottom: 16px;
  }
}
.guide-box__dots-item {
  position: relative;
  padding-left: 16px;
}
@media screen and (max-width: 834px) {
  .guide-box__dots-item {
    padding-left: 14px;
  }
}
.guide-box__dots-item:not(:last-child) {
  margin-bottom: 8px;
}
@media screen and (max-width: 834px) {
  .guide-box__dots-item:not(:last-child) {
    margin-bottom: 6px;
  }
}
.guide-box__dots-item:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #000;
  top: 13px;
  left: 0;
  border-radius: 50%;
}
@media screen and (max-width: 834px) {
  .guide-box__dots-item:before {
    width: 6px;
    height: 6px;
    top: 12px;
  }
}

.sec-restaurants {
  position: relative;
}

.restaurants-img {
  position: relative;
  height: 324px;
  overflow: hidden;
}
@media screen and (max-width: 1245px) {
  .restaurants-img {
    height: 237px;
  }
}
@media screen and (max-width: 834px) {
  .restaurants-img {
    height: 192px;
  }
}
.restaurants-img:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(51, 55, 59, 0.7);
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
}
.restaurants-img__loop {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  flex-direction: row;
  white-space: nowrap;
  -webkit-animation: slider-move 50s linear infinite;
  animation: slider-move 50s linear infinite;
}
.restaurants-img__loop--02 {
  -webkit-animation: slider-move 45s linear infinite;
  animation: slider-move 45s linear infinite;
}
.restaurants-img__loop-item {
  width: 546px;
  position: relative;
  position: relative;
}
.restaurants-img__loop-item::before {
  content: "";
  float: left;
  padding-top: 59.3406593407%;
}
.restaurants-img__loop-item::after {
  content: "";
  display: table;
  clear: both;
}
.restaurants-img__loop-item > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.restaurants-img__loop-item > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .restaurants-img__loop-item {
    width: 400px;
  }
}
@media screen and (max-width: 834px) {
  .restaurants-img__loop-item {
    width: 390px;
    position: relative;
  }
  .restaurants-img__loop-item::before {
    content: "";
    float: left;
    padding-top: 49.2307692308%;
  }
  .restaurants-img__loop-item::after {
    content: "";
    display: table;
    clear: both;
  }
  .restaurants-img__loop-item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .restaurants-img__loop-item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.restaurants-box {
  position: absolute;
  max-width: 688px;
  width: 100%;
  background: #fff;
  border-radius: 16px;
  padding: 80px;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
@media screen and (max-width: 1245px) {
  .restaurants-box {
    padding: 6.4252%;
    width: 60.26%;
  }
}
@media screen and (max-width: 834px) {
  .restaurants-box {
    padding: 35px 17px 41px;
    max-width: 100%;
    width: 100%;
    position: static;
    transform: translate(0, 0);
  }
}
.restaurants-box .c-ttl__02 {
  margin-bottom: 38px;
}
@media screen and (max-width: 834px) {
  .restaurants-box .c-ttl__02 {
    margin-bottom: 21px;
    line-height: 1.6818;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 385px) {
  .restaurants-box .c-ttl__02 {
    font-size: 1.8rem;
  }
}
.restaurants-box .c-txt__01 {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .restaurants-box .c-txt__01 {
    margin-bottom: 26px;
    line-height: 2.285;
  }
}

.sec-feature {
  padding: 0 0 119px;
}
@media screen and (max-width: 1245px) {
  .sec-feature {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 834px) {
  .sec-feature {
    padding: 57px 0 54px;
  }
}

.feature-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 73px;
  padding-left: 104px;
}
@media screen and (max-width: 1245px) {
  .feature-flex {
    padding-left: 30px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 834px) {
  .feature-flex {
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 61px;
  }
}
.feature-flex__ct {
  width: calc(43% + 120px);
  margin-right: -120px;
  position: relative;
  z-index: 2;
  padding-top: 50px;
}
@media screen and (max-width: 1440px) {
  .feature-flex__ct {
    padding-top: 0;
  }
}
@media screen and (max-width: 1245px) {
  .feature-flex__ct {
    width: 50%;
    margin-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .feature-flex__ct {
    padding: 0 16px;
    width: 100%;
    margin-bottom: 26px;
  }
}
.feature-flex__ct .c-ttl__01 {
  margin-bottom: 61px;
}
@media screen and (max-width: 1440px) {
  .feature-flex__ct .c-ttl__01 {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1245px) {
  .feature-flex__ct .c-ttl__01 {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 834px) {
  .feature-flex__ct .c-ttl__01 {
    margin-bottom: 28px;
  }
}
@media screen and (max-width: 1245px) {
  .feature-flex__ct .c-ttl__02 {
    margin-bottom: 0;
  }
}
.feature-flex__img {
  width: 57%;
}
@media screen and (max-width: 1245px) {
  .feature-flex__img {
    width: 50%;
  }
}
@media screen and (max-width: 834px) {
  .feature-flex__img {
    width: 100%;
  }
}
.feature-flex__img img {
  width: 100%;
}

.feature-list__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .feature-list__item {
    flex-wrap: wrap;
  }
}
.feature-list__item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .feature-list__item:not(:last-child) {
    margin-bottom: 56px;
  }
}
.feature-list__img {
  width: 45.25%;
  position: relative;
}
.feature-list__img::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.feature-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.feature-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.feature-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .feature-list__img {
    width: 100%;
    margin-bottom: 28px;
  }
}
.feature-list__ct {
  width: 54.75%;
  padding-left: 112px;
}
@media screen and (max-width: 1440px) {
  .feature-list__ct {
    padding-left: 25px;
  }
}
@media screen and (max-width: 834px) {
  .feature-list__ct {
    width: 100%;
    padding-left: 0;
  }
}
.feature-list__ct .c-ttl__04 {
  margin-bottom: 26px;
}
@media screen and (max-width: 1245px) {
  .feature-list__ct .c-ttl__04 {
    margin-bottom: 9px;
  }
}

.sec-safe {
  padding: 129px 0 120px;
  background: #F3F3F3;
}
@media screen and (max-width: 1440px) {
  .sec-safe {
    padding: 100px 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-safe {
    padding: 56px 0 60px;
  }
}

.safe-box {
  background: #fff;
  border-radius: 16px;
  padding: 71px 15px 66px;
  margin-bottom: 56px;
}
@media screen and (max-width: 1245px) {
  .safe-box {
    padding: 50px 5px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .safe-box {
    padding: 50px 16px;
    margin-bottom: 35px;
  }
}
.safe-box__list {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.safe-box__list-item {
  width: 33.33%;
  padding: 0 20px;
}
@media screen and (max-width: 1245px) {
  .safe-box__list-item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 834px) {
  .safe-box__list-item {
    padding: 0;
    width: 100%;
  }
  .safe-box__list-item:not(:last-child) {
    margin-bottom: 53px;
  }
}
.safe-box__list-item .c-ttl__05 {
  text-align: center;
  margin-bottom: 16px;
  line-height: 1.7;
}
@media screen and (max-width: 834px) {
  .safe-box__list-item .c-ttl__05 {
    margin-bottom: 6px;
  }
}
.safe-box__list-img {
  margin-bottom: 28px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.safe-box__list-img::before {
  content: "";
  float: left;
  padding-top: 66.6666666667%;
}
.safe-box__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.safe-box__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.safe-box__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .safe-box__list-img {
    margin-bottom: 15px;
  }
}

.sec-voice {
  padding: 129px 0 70px;
}
@media screen and (max-width: 1245px) {
  .sec-voice {
    padding: 100px 0 60px;
  }
}
@media screen and (max-width: 834px) {
  .sec-voice {
    padding: 57px 0 56px;
  }
}

.voice-step {
  margin: 0 -20px 112px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 1245px) {
  .voice-step {
    margin: 0 -10px 70px;
  }
}
@media screen and (max-width: 834px) {
  .voice-step {
    margin: 0 0 54px;
    gap: 21px 0;
  }
}
.voice-step__item {
  width: 33.33%;
  padding: 0 20px;
}
@media screen and (max-width: 1245px) {
  .voice-step__item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 834px) {
  .voice-step__item {
    padding: 0;
    width: 100%;
  }
}
.voice-step__box {
  padding: 36px 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
}
@media screen and (max-width: 834px) {
  .voice-step__box {
    padding: 30px 15px;
  }
}
.voice-step__box .c-ttl__05 {
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .voice-step__box .c-ttl__05 {
    font-size: 2.1rem;
  }
}
.voice-step__box-number {
  text-align: center;
  line-height: 1;
  color: #000;
  margin-bottom: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .voice-step__box-number {
    margin-bottom: 26px;
  }
}
.voice-step__box-number span {
  margin: 0 10px;
  font-family: "Bodoni Moda", serif;
  font-weight: bold;
  color: #0A1F36;
  font-size: 8rem;
}
@media screen and (max-width: 1245px) {
  .voice-step__box-number span {
    font-size: 5rem;
  }
}
@media screen and (max-width: 834px) {
  .voice-step__box-number span {
    font-size: 8rem;
  }
}
.voice-step__box-icon {
  text-align: center;
}
.voice-step__box-icon img {
  height: 48px;
  width: auto;
}
@media screen and (max-width: 834px) {
  .voice-step__box-icon img {
    height: 56px;
  }
}

.sec-deliver {
  padding: 131px 0 30px;
  background: #F0F0EF;
}
@media screen and (max-width: 1245px) {
  .sec-deliver {
    padding: 90px 0 20px;
  }
}
@media screen and (max-width: 834px) {
  .sec-deliver {
    padding: 56px 0 39px;
  }
}
.sec-deliver .c-ttl__01 {
  margin-bottom: 64px;
}
@media screen and (max-width: 1245px) {
  .sec-deliver .c-ttl__01 {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 834px) {
  .sec-deliver .c-ttl__01 {
    margin-bottom: 31px;
  }
}

.deliver-banner {
  margin-bottom: 54px;
}
@media screen and (max-width: 834px) {
  .deliver-banner {
    margin: 0 -16px 30px;
  }
}
.deliver-banner img {
  width: 100%;
}

.deliver-list {
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .deliver-list {
    margin-bottom: 57px;
  }
}
.deliver-list__item {
  border-bottom: 1px solid #DBDAD2;
  padding: 52px 0 56px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-left: 130px;
}
@media screen and (max-width: 1245px) {
  .deliver-list__item {
    padding: 35px 0 35px 60px;
  }
}
@media screen and (max-width: 834px) {
  .deliver-list__item {
    flex-wrap: wrap;
    padding: 28px 0 23px;
  }
}
.deliver-list__item:nth-child(1) {
  border-top: 1px solid #DBDAD2;
}
.deliver-list__item .c-ttl__02 {
  padding-right: 20px;
  width: 49%;
  margin-bottom: 0;
}
@media screen and (max-width: 1245px) {
  .deliver-list__item .c-ttl__02 {
    width: 55%;
    padding-right: 15px;
  }
}
@media screen and (max-width: 834px) {
  .deliver-list__item .c-ttl__02 {
    width: calc(100% - 69px);
    padding-right: 0;
    padding-left: 31px;
  }
}
.deliver-list__item .c-txt__01 {
  width: 51%;
}
@media screen and (max-width: 1245px) {
  .deliver-list__item .c-txt__01 {
    width: 45%;
  }
}
@media screen and (max-width: 834px) {
  .deliver-list__item .c-txt__01 {
    width: 100%;
    margin-top: 12px;
    padding: 0 16px;
  }
}
.deliver-list__icon {
  width: 90px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 1245px) {
  .deliver-list__icon {
    width: 50px;
  }
}
@media screen and (max-width: 834px) {
  .deliver-list__icon {
    width: 69px;
    position: static;
    transform: translate(11px, -2px);
  }
}
.deliver-list__icon img {
  width: 100%;
}

.deliver-box {
  background: #fff;
  border-radius: 16px;
  padding: 64px 9.445% 70px;
  margin-bottom: 83px;
}
@media screen and (max-width: 1245px) {
  .deliver-box {
    padding: 40px 6.09%;
  }
}
@media screen and (max-width: 834px) {
  .deliver-box {
    padding: 54px 15px 40px;
    margin-bottom: 20px;
  }
}
.deliver-box .c-ttl__04 {
  margin-bottom: 68px;
}
@media screen and (max-width: 1245px) {
  .deliver-box .c-ttl__04 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 834px) {
  .deliver-box .c-ttl__04 {
    margin-bottom: 45px;
  }
}
.deliver-box .guide-list--number {
  max-width: 1160px;
  margin: 0 auto;
  width: 100%;
}
.deliver-box .guide-list--number .guide-list__item {
  padding-bottom: 40px;
  margin-bottom: 39px;
}
@media screen and (max-width: 834px) {
  .deliver-box .guide-list--number .guide-list__item {
    padding-bottom: 38px;
    margin-bottom: 35px;
  }
}
.deliver-box .guide-list--number .guide-list__item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
@media screen and (max-width: 834px) {
  .deliver-box .guide-list--number .guide-list__ct::before {
    top: 10px;
  }
  .deliver-box .guide-list--number .guide-list__ct .c-ttl__05 {
    margin-bottom: 7px;
  }
}
.deliver-box__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1160px;
  margin: 0 auto;
  width: 100%;
  padding-top: 3px;
}
@media screen and (max-width: 834px) {
  .deliver-box__flex {
    padding-top: 0;
    margin-top: -7px;
  }
}
.deliver-box__flex-ct {
  margin-top: -9px;
  width: 54.75%;
  padding-right: 112px;
}
@media screen and (max-width: 1440px) {
  .deliver-box__flex-ct {
    padding-right: 25px;
  }
}
@media screen and (max-width: 834px) {
  .deliver-box__flex-ct {
    padding-right: 0;
    width: 100%;
    margin-bottom: 12px;
  }
}
.deliver-box__flex-ct .c-ttl__05 {
  margin-bottom: 10px;
}
.deliver-box__flex-ct .c-txt__01 {
  margin-bottom: 33px;
}
@media screen and (max-width: 834px) {
  .deliver-box__flex-ct .c-txt__01 {
    margin-bottom: 0;
  }
}
.deliver-box__flex-ct .c-btn__05 {
  margin-bottom: 10px;
}
.deliver-box__flex-img {
  width: 45.25%;
  position: relative;
}
.deliver-box__flex-img::before {
  content: "";
  float: left;
  padding-top: 68.7022900763%;
}
.deliver-box__flex-img::after {
  content: "";
  display: table;
  clear: both;
}
.deliver-box__flex-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.deliver-box__flex-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .deliver-box__flex-img {
    width: 100%;
  }
}

.sec-flow {
  padding: 129px 0 0;
}
@media screen and (max-width: 1245px) {
  .sec-flow {
    padding: 90px 0 0;
  }
}
@media screen and (max-width: 834px) {
  .sec-flow {
    padding: 56px 0 0;
  }
  .sec-flow .eoyster-box {
    top: 37.5%;
  }
  .sec-flow .eoyster-bg img {
    min-height: 707px;
    max-height: 707px;
  }
}

.sec-flow02 {
  padding-bottom: 118px;
}
@media screen and (max-width: 1245px) {
  .sec-flow02 {
    padding-bottom: 90px;
  }
}
@media screen and (max-width: 834px) {
  .sec-flow02 {
    padding-bottom: 60px;
  }
}

.flow-pdf {
  margin-bottom: 119px;
  padding-top: 42px;
}
@media screen and (max-width: 1245px) {
  .flow-pdf {
    padding-top: 0;
    margin-bottom: 56px;
  }
}
.flow-pdf__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .flow-pdf__item {
    flex-wrap: wrap;
  }
}
.flow-pdf__item:not(:last-child) {
  margin-bottom: 49px;
  border-bottom: 1px solid #dbdad2;
  padding-bottom: 46px;
}
@media screen and (max-width: 1245px) {
  .flow-pdf__item:not(:last-child) {
    padding-bottom: 39px;
    margin-bottom: 34px;
  }
}
.flow-pdf__ct {
  width: calc(100% - 360px);
  padding-right: 110px;
}
@media screen and (min-width: 1246px) {
  .flow-pdf__ct--02 {
    padding-top: 24px;
  }
}
@media screen and (max-width: 1440px) {
  .flow-pdf__ct {
    padding-right: 25px;
  }
}
@media screen and (max-width: 1245px) {
  .flow-pdf__ct {
    width: calc(100% - 220px);
  }
}
@media screen and (max-width: 834px) {
  .flow-pdf__ct {
    width: 100%;
    margin-bottom: 40px;
    padding-right: 0;
  }
}
.flow-pdf__ct .c-ttl__04 {
  margin-bottom: 19px;
}
@media screen and (max-width: 834px) {
  .flow-pdf__ct .c-ttl__04 {
    margin-bottom: 10px;
  }
}
.flow-pdf__btn {
  margin-top: 49px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px 40px;
}
@media screen and (max-width: 834px) {
  .flow-pdf__btn {
    margin-top: 24px;
  }
}
.flow-pdf__img {
  width: 360px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.flow-pdf__img::before {
  content: "";
  float: left;
  padding-top: 61.1111111111%;
}
.flow-pdf__img::after {
  content: "";
  display: table;
  clear: both;
}
.flow-pdf__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.flow-pdf__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1245px) {
  .flow-pdf__img {
    width: 220px;
  }
}
@media screen and (max-width: 834px) {
  .flow-pdf__img {
    width: 91%;
    margin: 0 auto;
  }
  .flow-pdf__img::before {
    padding-top: 54.111%;
  }
}

@media screen and (max-width: 834px) {
  .page-franchise_index .vol-tabs.tab-menu {
    margin-left: 0;
    margin-bottom: 60px;
  }
}

.franchise-intro {
  padding-bottom: 100px;
}
@media screen and (max-width: 834px) {
  .franchise-intro {
    padding-bottom: 50px;
  }
}
.franchise-intro__slider {
  margin-bottom: 75px;
}
@media screen and (max-width: 834px) {
  .franchise-intro__slider {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 834px) {
  .franchise-intro__ttl {
    margin-bottom: 34px;
  }
}
.franchise-intro__desc {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .franchise-intro__desc {
    margin-bottom: 30px;
  }
}
.franchise-intro__control {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 32px;
  margin-bottom: 136px;
}
@media screen and (max-width: 1024px) {
  .franchise-intro__control {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 834px) {
  .franchise-intro__control {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 640px) {
  .franchise-intro__control {
    gap: 24px 0;
  }
}
.franchise-intro__control li {
  width: 320px;
}
@media screen and (max-width: 834px) {
  .franchise-intro__control li {
    width: 280px;
  }
}
@media screen and (max-width: 640px) {
  .franchise-intro__control li {
    width: 100%;
  }
}
.franchise-intro__control li a {
  max-width: 100%;
}
.franchise-intro__flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.franchise-intro__flex-slider {
  width: 46.58%;
}
@media screen and (max-width: 1024px) {
  .franchise-intro__flex-slider {
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .franchise-intro__flex-slider div {
    position: relative;
  }
  .franchise-intro__flex-slider div::before {
    content: "";
    float: left;
    padding-top: 115.3846153846%;
  }
  .franchise-intro__flex-slider div::after {
    content: "";
    display: table;
    clear: both;
  }
  .franchise-intro__flex-slider div > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .franchise-intro__flex-slider div > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.franchise-intro__flex-ct {
  width: 53.42%;
  padding-left: 5.85vw;
  padding-right: 15px;
}
@media screen and (max-width: 1024px) {
  .franchise-intro__flex-ct {
    width: 100%;
    padding: 60px 15px 0;
  }
}
.franchise-intro__flex-dl {
  max-width: 524px;
  width: 100%;
  margin-bottom: 74px;
}
@media screen and (max-width: 834px) {
  .franchise-intro__flex-dl {
    max-width: 100%;
    margin-bottom: 53px;
  }
}
.franchise-intro__flex-dl:last-of-type {
  margin-bottom: 0;
}
.franchise-intro__flex-dt {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .franchise-intro__flex-dt {
    margin-bottom: 6px;
  }
}

.sec-profitability .c-ttl__01 {
  margin-bottom: 62px;
}
@media screen and (max-width: 834px) {
  .sec-profitability .c-ttl__01 {
    margin-bottom: 24px;
  }
}

.profitability-block {
  margin-bottom: 110px;
}
@media screen and (max-width: 834px) {
  .profitability-block {
    margin-bottom: 50px;
  }
}
.profitability-block__ttl {
  margin-bottom: 65px;
}
@media screen and (max-width: 834px) {
  .profitability-block__ttl {
    margin-bottom: 26px;
  }
}
.profitability-block__model {
  display: grid;
  gap: 40px 40px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media screen and (max-width: 1024px) {
  .profitability-block__model {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
@media screen and (max-width: 834px) {
  .profitability-block__model {
    gap: 18px;
  }
}
.profitability-block__model-item {
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 32px 18px;
}
@media screen and (max-width: 834px) {
  .profitability-block__model-item {
    padding: 8px 10px;
  }
  .profitability-block__model-item:last-child {
    position: relative;
    left: 50%;
  }
}
.profitability-block__model-ttl {
  text-align: center;
}
@media screen and (max-width: 834px) {
  .profitability-block__model-ttl {
    font-size: 1.4rem;
    margin-bottom: 4px;
  }
}
.profitability-block__model-number {
  text-align: center;
  font-size: 1.6rem;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 24px;
  gap: 0 13px;
  position: relative;
}
@media screen and (max-width: 1245px) {
  .profitability-block__model-number {
    gap: 0 3px;
  }
}
@media screen and (max-width: 834px) {
  .profitability-block__model-number {
    font-size: 1.2rem;
    margin-bottom: 13px;
  }
}
.profitability-block__model-number span {
  font-family: "Bodoni Moda", serif;
  font-size: clamp(3.7rem, 2.604rem + 2.81vw, 8rem);
  font-weight: 700;
  line-height: 1;
  color: #0A1F36;
}
.profitability-block__model-number .number-sm {
  font-size: clamp(2.2rem, 1.741rem + 1.176vw, 4rem);
  font-weight: 700;
}
.profitability-block__model-number .txt-to {
  font-size: clamp(1.5rem, 0.863rem + 1.634vw, 4rem);
}
.profitability-block__model-number .txt-lg {
  font-family: "Noto Sans JP", serif;
  font-size: clamp(1.9rem, 1.314rem + 1.503vw, 4.2rem);
  font-weight: 900;
  line-height: 1;
}
.profitability-block__model-number.txt-lg02 {
  font-size: clamp(1.4rem, 1.043rem + 0.915vw, 2.8rem);
  text-align: left;
  font-weight: 900;
}
.profitability-block__model-number--business > span {
  font-size: 1.6rem;
  margin-bottom: 34px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .profitability-block__model-number--business > span {
    font-size: 1.2rem;
    margin-bottom: 13px;
  }
}
.profitability-block__model-number--business .txt-to {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
}
@media screen and (max-width: 834px) {
  .profitability-block__model-number--business .txt-to {
    top: 20px;
  }
}
.profitability-block__model-ico {
  text-align: center;
}
.profitability-block__note {
  margin-top: 30px;
}
.profitability-block__note--right {
  max-width: 362px;
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  .profitability-block__note--right {
    display: none;
  }
}
.profitability-block__costs-dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #DBDAD2;
}
.profitability-block__costs-dl:first-child {
  border-top: 1px solid #DBDAD2;
}
.profitability-block__costs-dt, .profitability-block__costs-dd {
  padding: 38px 0;
}
@media screen and (max-width: 834px) {
  .profitability-block__costs-dt, .profitability-block__costs-dd {
    padding: 18px 0;
  }
}
.profitability-block__costs-dt {
  width: 265px;
}
@media screen and (max-width: 834px) {
  .profitability-block__costs-dt {
    width: 150px;
  }
}
.profitability-block__costs-dd {
  width: calc(100% - 265px);
}
@media screen and (max-width: 834px) {
  .profitability-block__costs-dd {
    width: calc(100% - 150px);
    text-align: right;
    padding-right: 28px;
  }
}
.profitability-block--costs .profitability-block__ttl {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .profitability-block--costs .profitability-block__ttl {
    margin-bottom: 28px;
  }
}
.profitability-block--income .profitability-block__note {
  margin-top: 17px;
}
@media screen and (max-width: 834px) {
  .profitability-block--income .profitability-block__note {
    margin-top: 48px;
  }
}
.profitability-block--income .profitability-block__ttl {
  margin-bottom: 29px;
}
@media screen and (max-width: 834px) {
  .profitability-block--income .profitability-block__ttl {
    margin-bottom: 0;
  }
}
.profitability-block__income {
  overflow-x: auto;
}
.profitability-block__income table {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .profitability-block__income table {
    width: 960px;
  }
}
.profitability-block__income table tr th, .profitability-block__income table tr td {
  padding: 33px 0;
  border-bottom: 1px solid #DBDAD2;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .profitability-block__income table tr th, .profitability-block__income table tr td {
    padding: 19px 0;
  }
}
.profitability-block__income table tr th:first-child, .profitability-block__income table tr td:first-child {
  width: 17%;
}
.profitability-block__income table tr th {
  font-weight: 600;
  padding: 20px 0;
}
.profitability-block__income table tr td:first-child {
  text-align: left;
}

.sec-support {
  padding: 130px 0 70px;
}
@media screen and (max-width: 834px) {
  .sec-support {
    padding: 56px 0;
  }
  .sec-support .content-block {
    margin-bottom: 30px;
  }
}

.support-block__item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 40px 0;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .support-block__item {
    padding: 30px 0;
  }
}
.support-block__ct {
  width: 69%;
  padding-right: 80px;
  position: relative;
  top: -8px;
}
@media screen and (max-width: 1024px) {
  .support-block__ct {
    padding-right: 30px;
    width: 65%;
  }
}
@media screen and (max-width: 640px) {
  .support-block__ct {
    width: 100%;
    padding-right: 0;
    margin-bottom: 16px;
  }
}
.support-block__ct-txt-lead {
  font-size: 2.1rem;
  font-weight: 600;
  margin-bottom: 7px;
}
@media screen and (max-width: 834px) {
  .support-block__ct-txt-lead {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}
.support-block__ct-ttl {
  margin-bottom: 38px;
}
@media screen and (max-width: 834px) {
  .support-block__ct-ttl {
    margin-bottom: 24px;
  }
}
.support-block__img {
  width: 31%;
  border-radius: 8px;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .support-block__img {
    width: 35%;
  }
}
@media screen and (max-width: 640px) {
  .support-block__img {
    width: 100%;
  }
}

.support-process {
  margin-bottom: 120px;
}
@media screen and (max-width: 834px) {
  .support-process {
    margin-bottom: 60px;
  }
}
.support-process__ttl {
  margin-bottom: 68px;
}
@media screen and (max-width: 834px) {
  .support-process__ttl {
    margin-bottom: 62px;
  }
}
.support-process .guide-list__item {
  align-items: center;
  padding-bottom: 42px;
  margin-bottom: 38px;
}
@media screen and (max-width: 834px) {
  .support-process .guide-list__item {
    margin-bottom: 30px;
  }
}

.franchise-bnr {
  padding-bottom: 120px;
}
@media screen and (max-width: 834px) {
  .franchise-bnr {
    padding-bottom: 60px;
  }
}
.franchise-bnr .eoyster-bg img {
  min-height: 521px;
}
.franchise-bnr .eoyster-box__heading {
  font-size: 3.2rem;
}
.franchise-bnr .eoyster-box__btn {
  gap: 18px;
}
@media screen and (max-width: 1024px) {
  .franchise-bnr .eoyster-box__btn-item {
    width: 250px;
  }
}
@media screen and (max-width: 834px) {
  .franchise-bnr .eoyster-box__btn-item {
    width: 100%;
    max-width: 326px;
  }
}
@media screen and (max-width: 834px) {
  .franchise-bnr .eoyster-bg img {
    min-height: 650px;
    max-height: 650px;
  }
  .franchise-bnr .eoyster-box__heading {
    font-size: 2.6rem;
    margin-bottom: 16px;
  }
  .franchise-bnr .eoyster-box__ttl {
    line-height: 1.8;
    margin-bottom: 3px;
  }
  .franchise-bnr .eoyster-box__btn {
    gap: 26px 18px;
  }
}
@media screen and (max-width: 640px) {
  .franchise-bnr .eoyster-box {
    padding-top: 0;
    top: 60px;
    transform: none;
  }
  .franchise-bnr .eoyster-bg img {
    min-height: 855px;
    max-height: 855px;
  }
}

.page-recruit_index .content-left {
  padding-bottom: 0;
  padding-top: 110px;
}
@media screen and (max-width: 834px) {
  .page-recruit_index .content-left {
    padding-top: 0;
  }
}

.sec-recruit {
  overflow-x: hidden;
}
.sec-recruit .recruit-slider__main {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .sec-recruit .recruit-slider__main {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .sec-recruit .recruit-slider__main-item {
    position: relative;
  }
  .sec-recruit .recruit-slider__main-item::before {
    content: "";
    float: left;
    padding-top: 83.8461538462%;
  }
  .sec-recruit .recruit-slider__main-item::after {
    content: "";
    display: table;
    clear: both;
  }
  .sec-recruit .recruit-slider__main-item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .sec-recruit .recruit-slider__main-item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.sec-recruit .recruit-slider__thumb-item {
  position: relative;
}
.sec-recruit .recruit-slider__thumb-item::before {
  content: "";
  float: left;
  padding-top: 66.6666666667%;
}
.sec-recruit .recruit-slider__thumb-item::after {
  content: "";
  display: table;
  clear: both;
}
.sec-recruit .recruit-slider__thumb-item > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.sec-recruit .recruit-slider__thumb-item > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.franchise-intro--recruit {
  padding-bottom: 120px;
}
@media screen and (max-width: 834px) {
  .franchise-intro--recruit {
    padding-bottom: 60px;
  }
}
.franchise-intro--recruit .franchise-intro__control {
  margin-bottom: 0;
  gap: 23px 20px;
}

.about-work {
  background: #F3F3F3;
  padding: 130px 0 120px;
}
@media screen and (max-width: 1245px) {
  .about-work {
    padding: 80px 0;
  }
}
@media screen and (max-width: 834px) {
  .about-work {
    padding: 57px 0;
  }
}
.about-work__ttl {
  margin-bottom: 73px;
}
@media screen and (max-width: 834px) {
  .about-work__ttl {
    margin-bottom: 42px;
  }
}
.about-work__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #DBDAD2;
  padding: 35px 0;
}
@media screen and (max-width: 834px) {
  .about-work__dl {
    padding: 25px 0;
  }
}
.about-work__dl:first-child {
  border-top: 1px solid #DBDAD2;
}
.about-work__dl:nth-child(2) .about-work__dd p {
  margin-bottom: 22px;
}
.about-work__dl:nth-child(2) .about-work__dd p:last-of-type {
  margin-bottom: 0;
}
.about-work__dt {
  width: 164px;
}
@media screen and (max-width: 640px) {
  .about-work__dt {
    width: 100%;
    margin-bottom: 7px;
  }
}
.about-work__dd {
  width: calc(100% - 164px);
}
@media screen and (max-width: 640px) {
  .about-work__dd {
    width: 100%;
  }
}
.about-work__dd p {
  margin-bottom: 24px;
}
@media screen and (max-width: 834px) {
  .about-work__dd p {
    margin-bottom: 10px;
  }
}
.about-work__dd p:last-of-type {
  margin-bottom: 0;
}
.about-work__dd p span {
  font-weight: 700;
}

.sec-philosophy {
  padding: 105px 0 125px;
}
@media screen and (max-width: 834px) {
  .sec-philosophy {
    padding: 60px 0;
  }
}
.sec-philosophy .content-block .c-ttl__01 {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .sec-philosophy .content-block .c-ttl__01 {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 834px) {
  .sec-philosophy .content-block__row .c-ttl__02 {
    margin-bottom: 27px;
  }
}
.sec-philosophy .system02-list {
  margin-bottom: 57px;
}
@media screen and (max-width: 834px) {
  .sec-philosophy .system02-list {
    margin-bottom: 35px;
  }
}
.sec-philosophy .c-btn__02 {
  max-width: 400px;
  margin: 0 auto;
}

.sec-career {
  padding: 130px 0 40px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-career {
    padding: 57px 0 67px;
  }
}
.sec-career .content-block {
  margin-bottom: 100px;
}
@media screen and (max-width: 834px) {
  .sec-career .content-block {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 834px) and (max-width: 834px) {
  .sec-career .content-block__row .c-ttl__02 {
    margin-bottom: 25px;
  }
}
.sec-career .guide-list__ct p {
  margin-bottom: 25px;
}
.sec-career .guide-list__ct p:last-of-type {
  margin-bottom: 0;
}
.sec-career .guide-list__ct p span {
  font-weight: 700;
}
@media screen and (min-width: 835px) {
  .sec-career .guide-list__img {
    position: relative;
    top: -44px;
  }
}
.sec-career .guide-list--number .guide-list__item {
  align-items: flex-start;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .sec-career .guide-list--number .guide-list__item {
    margin-bottom: 34px;
  }
}
.sec-career .guide-list--number .guide-list__item:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.sec-interview {
  padding: 130px 0;
}
@media screen and (max-width: 834px) {
  .sec-interview {
    padding: 60px 0;
  }
}

.interview-bnr {
  position: relative;
  margin-bottom: 72px;
}
@media screen and (min-width: 835px) {
  .interview-bnr:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 53.41%;
    height: 100%;
    background: rgb(10, 31, 54);
    background: linear-gradient(90deg, rgb(10, 31, 54) 75%, rgba(10, 31, 54, 0) 100%);
    top: 0;
    left: 0;
    z-index: 2;
  }
}
@media screen and (max-width: 834px) {
  .interview-bnr {
    margin-bottom: 27px;
  }
}
.interview-bnr__slider-item {
  position: relative;
}
@media screen and (max-width: 834px) {
  .interview-bnr__slider-item {
    position: relative;
  }
  .interview-bnr__slider-item::before {
    content: "";
    float: left;
    padding-top: 61.5384615385%;
  }
  .interview-bnr__slider-item::after {
    content: "";
    display: table;
    clear: both;
  }
  .interview-bnr__slider-item > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .interview-bnr__slider-item > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.interview-bnr__slider-item img {
  max-width: 1100px;
  display: block;
  margin-left: auto;
}
.interview-bnr__ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 3;
}
@media screen and (max-width: 834px) {
  .interview-bnr__ct {
    position: static;
    background: #202A34;
    transform: none;
    padding: 20px 0 25px;
  }
}
@media screen and (max-width: 834px) {
  .interview-bnr__ct-ttl {
    margin-bottom: 15px;
  }
}
.interview-bnr__ct-name {
  font-size: 2.9rem;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .interview-bnr__ct-name {
    font-size: 2.2rem;
    margin-bottom: 7px;
  }
}
.interview-bnr__ct-name span {
  font-size: 1.6rem;
}
@media screen and (max-width: 834px) {
  .interview-bnr__ct-name span {
    font-size: 1.4rem;
  }
}
.interview-content {
  height: 480px;
  overflow-y: auto;
}
@media screen and (max-width: 834px) {
  .interview-content {
    height: 400px;
  }
}
.interview-content dl {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .interview-content dl {
    margin-bottom: 60px;
  }
}
.interview-content dl:last-of-type {
  margin-bottom: 0;
}
.interview-content dl dt {
  margin-bottom: 14px;
}
.interview-content dl dd p {
  margin-bottom: 30px;
}
.interview-content dl dd p:last-of-type {
  margin-bottom: 0;
}

.sec-faq {
  padding: 126px 0 116px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-faq {
    padding: 58px 0 60px;
  }
}
.sec-faq .c-ttl__01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .sec-faq .c-ttl__01 {
    margin-bottom: 28px;
  }
}

.faq-block {
  margin-bottom: 110px;
}
@media screen and (max-width: 834px) {
  .faq-block {
    margin-bottom: 60px;
  }
}
.faq-block:last-of-type {
  margin-bottom: 0;
}
.faq-block__ttl {
  margin-bottom: 62px;
}
@media screen and (max-width: 834px) {
  .faq-block__ttl {
    margin-bottom: 67px;
  }
}
.faq-block__ct {
  max-width: 1160px;
  margin: 0 auto;
}
.faq-block__dl {
  padding-top: 35px;
  padding-left: 118px;
  position: relative;
  padding-bottom: 34px;
  margin-bottom: 36px;
  border-bottom: 1px solid #DBDAD2;
}
.faq-block__dl:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .faq-block__dl {
    padding-top: 0;
  }
}
@media screen and (max-width: 834px) {
  .faq-block__dl {
    padding-left: 0;
  }
}
.faq-block__dt {
  margin-bottom: 18px;
}
@media screen and (max-width: 834px) {
  .faq-block__dt {
    padding-left: 78px;
  }
}
.faq-block__dt span {
  font-family: "Lato", serif;
  font-size: 4.8rem;
  line-height: 1;
  font-weight: 500;
  color: #BA915B;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 834px) {
  .faq-block__dt span {
    font-size: 3.6rem;
  }
}
.sec-message {
  padding: 130px 0 120px;
}
@media screen and (max-width: 834px) {
  .sec-message {
    padding: 60px 0;
  }
}
.sec-message .c-ttl__01 {
  margin-bottom: 64px;
}
@media screen and (max-width: 834px) {
  .sec-message .c-ttl__01 {
    margin-bottom: 20px;
  }
}
.sec-message .c-btn__02 {
  margin: 0 auto;
  position: relative;
  left: -36px;
}
@media screen and (max-width: 834px) {
  .sec-message .c-btn__02 {
    left: 0;
  }
}

.message-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 60px;
  max-width: 1296px;
}
@media screen and (max-width: 834px) {
  .message-block {
    margin-bottom: 35px;
  }
}
.message-block__ct {
  width: 50.9%;
}
@media screen and (max-width: 640px) {
  .message-block__ct {
    width: 100%;
    margin-bottom: 46px;
  }
}
.message-block__ct p {
  margin-bottom: 30px;
}
.message-block__ct p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .message-block__ct-desc {
    height: 320px;
    overflow-y: auto;
  }
}
.message-block__img {
  width: 40.43%;
  position: relative;
}
.message-block__img::before {
  content: "";
  float: left;
  padding-top: 125.1908396947%;
}
.message-block__img::after {
  content: "";
  display: table;
  clear: both;
}
.message-block__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.message-block__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 640px) {
  .message-block__img {
    width: 100%;
    position: relative;
  }
  .message-block__img::before {
    content: "";
    float: left;
    padding-top: 116.2011173184%;
  }
  .message-block__img::after {
    content: "";
    display: table;
    clear: both;
  }
  .message-block__img > :first-child {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .message-block__img > :first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sec-entry {
  padding: 130px 0;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-entry {
    padding: 57px 0;
  }
}
.sec-entry .c-ttl__01 {
  margin-bottom: 66px;
}
@media screen and (max-width: 834px) {
  .sec-entry .c-ttl__01 {
    margin-bottom: 19px;
  }
}

.entry-intro {
  margin-bottom: 68px;
}
@media screen and (max-width: 834px) {
  .entry-intro {
    margin-bottom: 56px;
  }
}

.entry-form {
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .entry-form {
    margin-bottom: 60px;
  }
  .entry-form:last-of-type {
    margin-bottom: 0;
  }
}
.entry-form__ttl {
  color: #fff;
  background: #0D2641;
  padding: 21px 43px;
  position: relative;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .entry-form__ttl {
    font-size: 1.8rem;
    padding: 15px 20px;
    padding-right: 50px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 78px;
  }
}
.entry-form__ttl:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 24px;
  height: 24px;
  background: url(../img/recruit/ico-plus.png) no-repeat center/100%;
  top: calc(50% - 12px);
  right: 40px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .entry-form__ttl:after {
    width: 16px;
    height: 16px;
    right: 20px;
    top: calc(50% - 8px);
  }
}
.entry-form__ttl.on:after {
  background: url(../img/recruit/ico-minus.png) no-repeat center/100%;
}
.entry-form__ttl span {
  font-size: 1.6rem;
  display: block;
  margin-bottom: 14px;
}
@media screen and (max-width: 834px) {
  .entry-form__ttl span {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 2;
  }
}
.entry-form__main {
  display: none;
  padding-top: 64px;
}
@media screen and (max-width: 834px) {
  .entry-form__main {
    padding-top: 30px;
  }
}
.entry-form__dl {
  padding: 39px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .entry-form__dl {
    padding: 17px 0;
  }
}
.entry-form__dl:first-child {
  border-top: 1px solid #DBDAD2;
}
.entry-form__dl--any .entry-form__dt:after {
  display: none;
}
.entry-form__dl--radio {
  padding: 24px 0;
}
.entry-form__dl--radio dt {
  padding-top: 0;
}
.entry-form__dl-note {
  display: block;
  font-size: 1.4rem;
  line-height: 2;
  color: #9D9D9D;
  margin-top: 15px;
}
@media screen and (max-width: 834px) {
  .entry-form__dl-note {
    margin-top: 8px;
  }
}
.entry-form__dl--last {
  border-bottom: none;
  padding-bottom: 0;
}
.entry-form__dt {
  font-weight: 500;
  font-size: 1.6rem;
  padding-top: 18px;
  width: 320px;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .entry-form__dt {
    width: 100%;
    font-size: 1.4rem;
    padding-top: 0;
    margin-bottom: 13px;
  }
}
.entry-form__dt:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 45px;
  height: auto;
  content: "必須";
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  background: #0D2641;
  border-radius: 12px;
  top: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  padding: 3px 0 7px;
}
@media screen and (max-width: 834px) {
  .entry-form__dt:after {
    width: 37px;
    padding: 3px 0 5px;
    font-size: 1.2rem;
  }
}
.entry-form__dt--has-note:after {
  display: none;
}
.entry-form__dt--has-note span:first-child {
  position: relative;
}
.entry-form__dt--has-note span:first-child:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 45px;
  height: 24px;
  content: "必須";
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  background: #0D2641;
  border-radius: 12px;
  top: 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  padding-bottom: 3px;
}
@media screen and (max-width: 834px) {
  .entry-form__dt--has-note span:first-child:after {
    width: 37px;
    height: 20px;
    font-size: 1.2rem;
  }
}
.entry-form__dd {
  width: calc(100% - 320px);
}
@media screen and (max-width: 1024px) {
  .entry-form__dd {
    width: 100%;
  }
}
.entry-form__dd--age {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 16px;
}
.entry-form__dd--multiple-input .wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .entry-form__dd--multiple-input .wpcf7-form-control-wrap {
    margin-bottom: 16px;
  }
}
.entry-form__dd--multiple-input .form-control {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .entry-form__dd--multiple-input .form-control {
    margin-bottom: 16px;
  }
}
.entry-form__dd--multiple-input .form-control:last-of-type {
  margin-bottom: 0;
}
.entry-form__check {
  display: block;
  text-align: center;
  margin: 68px auto 56px;
  max-width: 290px;
}
@media screen and (max-width: 834px) {
  .entry-form__check {
    margin: 33px auto 28px;
  }
}
.entry-form__check label {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  padding: 0 0 0 46px;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-width: 834px) {
  .entry-form__check label {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 385px) {
  .entry-form__check label {
    font-size: 1.2rem;
  }
}
.entry-form__check label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin: 0;
}
.entry-form__check label .wpcf7-list-item-label:after {
  content: "";
  position: absolute;
  display: none;
}
.entry-form__check label input:checked ~ .wpcf7-list-item-label:after {
  display: block;
}
.entry-form__check label .wpcf7-list-item-label:after {
  width: 9px;
  height: 15px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  left: 10px;
  top: 5px;
  transform: rotate(45deg);
}
@media screen and (max-width: 834px) {
  .entry-form__check label .wpcf7-list-item-label:after {
    left: 8px;
    top: 2px;
  }
}
.entry-form__check label .wpcf7-list-item-label:before {
  content: "";
  position: absolute;
  height: 28px;
  width: 28px;
  border: 1px solid #000;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
@media screen and (max-width: 834px) {
  .entry-form__check label .wpcf7-list-item-label:before {
    width: 26px;
    height: 26px;
  }
}
.entry-form__control {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 720px;
  margin: 0 auto;
  gap: 15px 30px;
}
@media screen and (max-width: 640px) {
  .entry-form__control {
    max-width: 350px;
  }
}
.entry-form__btn-submit {
  margin: 0 auto;
  border: none;
  cursor: pointer;
}
.entry-form__radio {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px 40px;
}
@media screen and (max-width: 834px) {
  .entry-form__radio {
    gap: 0 20px;
    height: auto;
  }
}
.entry-form__select {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 17px;
}
@media screen and (max-width: 1024px) {
  .entry-form__select {
    gap: 0 5px;
  }
}
.entry-form__select .form-control {
  width: 200px;
}
@media screen and (max-width: 834px) {
  .entry-form__select .form-control {
    width: 170px;
  }
}
@media screen and (max-width: 640px) {
  .entry-form__select .form-control {
    width: 114px;
  }
}
@media screen and (max-width: 385px) {
  .entry-form__select .form-control {
    width: 107px;
  }
}
.entry-form__store {
  margin-bottom: 24px;
}
@media screen and (max-width: 834px) {
  .entry-form__store {
    margin-bottom: 18px;
  }
}
.entry-form__store:last-of-type {
  margin-bottom: 0;
}
.entry-form__store-label {
  margin-bottom: 10px;
}

@media screen and (max-width: 834px) {
  .page-recruit_list_index .c-ttl__04 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .page-recruit_list_index .list-menu {
    flex-wrap: nowrap;
    width: max-content;
    gap: 0 20px;
  }
  .page-recruit_list_index .list-menu__item.active a {
    white-space: nowrap;
  }
}

.page-recruit_list_detail .company-option {
  max-width: 1440px;
  margin: 0 auto;
}
.page-recruit_list_detail .company-option__dd .c-btn__03 {
  font-size: 1.6rem;
  margin-top: 20px;
  font-size: 1.6rem;
}
.page-recruit_list_detail .company-option__dd .c-btn__03:after {
  right: -8px;
  top: 35%;
}
.page-recruit_list_detail .company-option__dl--last {
  margin-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .page-recruit_list_detail .company-option__dl--last {
    margin-bottom: 32px;
  }
}
.page-recruit_list_detail .c-btn__02 {
  margin: 0 auto;
}

.sec-workplace {
  padding: 120px 0;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .sec-workplace {
    padding: 60px 0;
  }
}
.sec-workplace .content-block {
  margin-bottom: 75px;
}
@media screen and (max-width: 834px) {
  .sec-workplace .content-block {
    margin-bottom: 58px;
  }
}
.sec-workplace .c-ttl__04 {
  margin-bottom: 72px;
}
@media screen and (max-width: 834px) {
  .sec-workplace .c-ttl__04 {
    margin-bottom: 57px;
  }
}
@media screen and (max-width: 834px) {
  .sec-workplace .guide-list--number .guide-list__item {
    margin-bottom: 28px;
  }
  .sec-workplace .guide-list--number .guide-list__item:last-of-type {
    margin-bottom: 44px;
  }
}
@media screen and (max-width: 834px) {
  .sec-workplace .guide-list--number .guide-list__ct::before {
    top: 6px;
  }
}
@media screen and (max-width: 834px) {
  .sec-workplace .guide-list--number .guide-list__ct .c-ttl__05 {
    margin-bottom: 13px;
  }
}

.entry-confirm {
  padding: 130px 0 120px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .entry-confirm {
    padding: 58px 0 60px;
  }
}
.entry-confirm__ttl {
  margin-bottom: 65px;
}
@media screen and (max-width: 834px) {
  .entry-confirm__ttl {
    margin-bottom: 30px;
  }
}
.entry-confirm__intro {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .entry-confirm__intro {
    margin-bottom: 35px;
  }
}
.entry-confirm .entry-form {
  margin-bottom: 0;
}
.entry-confirm .entry-form__ttl:after {
  display: none;
}
.entry-confirm .entry-form__dl {
  padding: 35px 0;
}
@media screen and (max-width: 834px) {
  .entry-confirm .entry-form__dl {
    padding: 15px 0;
  }
}
.entry-confirm .entry-form__dl--last {
  margin-bottom: 43px;
}
@media screen and (max-width: 834px) {
  .entry-confirm .entry-form__dl--last {
    margin-bottom: 20px;
  }
}
.entry-confirm .entry-form__dt {
  padding-top: 0;
}
.entry-confirm .entry-form__main {
  display: block;
}
.entry-confirm .c-btn__02 {
  margin: 0 auto;
}

.recruit-list .content-left {
  padding-bottom: 23px;
}
.recruit-list .content-left .list-menu {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .recruit-list .content-left .list-menu {
    margin-bottom: 6px;
  }
}
.recruit-list .content-left .list-menu li.active a {
  padding: 12.5px 24px;
}
.recruit-list .content-left .list-menu li a {
  padding: 13px 0;
}
.recruit-list .content-left .list-menu li a:hover {
  padding: 12.5px 24px;
}
.recruit-list .shop-list {
  max-width: 1160px;
  margin: 0 auto;
}
.recruit-list .shop-list__main {
  width: 100%;
}
.recruit-list .shop-list__main-btn {
  position: relative;
  top: -10px;
  padding-top: 0;
}
.recruit-list .shop-list__main-btn .c-btn__02:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .recruit-list .shop-list__main-btn .c-btn__02:not(:last-child) {
    margin-bottom: 24px;
  }
}
.recruit-list .shop-list__main-item {
  padding-bottom: 34px;
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .recruit-list .shop-list__main-item {
    padding-bottom: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .recruit-list .shop-list__main-img {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 641px) {
  .recruit-list .shop-list__main-imgItem {
    opacity: 1 !important;
  }
}
@media screen and (max-width: 834px) {
  .recruit-list .shop-list__main-ct {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 834px) {
  .recruit-list .shop-list__main-ct .c-txt__01 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .page-contact_index .c-ttl__03 .c-ft--jp, .page-contact_confirm .c-ttl__03 .c-ft--jp, .page-contact_thanks .c-ttl__03 .c-ft--jp {
    line-height: 1.5;
    margin-bottom: 18px;
  }
}

.page-contact_thanks .entry-confirm {
  background: #fff;
}
@media screen and (min-width: 835px) {
  .page-contact_thanks .entry-confirm {
    padding: 136px 0 120px;
  }
}
.page-contact_thanks .entry-confirm__intro {
  text-align: center;
}

.contact-page, .contact-confirm {
  padding: 130px 0;
}
@media screen and (max-width: 834px) {
  .contact-page, .contact-confirm {
    padding: 53px 0;
  }
}
.contact-page__ttl, .contact-confirm__ttl {
  margin-bottom: 44px;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .contact-page__ttl, .contact-confirm__ttl {
    margin-bottom: 27px;
  }
}
@media screen and (max-width: 834px) {
  .contact-page .scroll-sp, .contact-confirm .scroll-sp {
    margin-bottom: 70px;
  }
}
.contact-page .list-menu, .contact-confirm .list-menu {
  margin-bottom: 110px;
  gap: 10px 20px;
}
@media screen and (max-width: 834px) {
  .contact-page .list-menu, .contact-confirm .list-menu {
    max-width: max-content;
    width: max-content;
    margin-bottom: 0;
  }
}
.contact-page .list-menu li.active a, .contact-confirm .list-menu li.active a {
  padding: 13px 24px;
}
.contact-page__intro, .contact-confirm__intro {
  margin-bottom: 136px;
}
.contact-page__intro a, .contact-confirm__intro a {
  color: #000;
  text-decoration: underline;
}
@media screen and (max-width: 834px) {
  .contact-page__intro, .contact-confirm__intro {
    margin-bottom: 36px;
  }
}
@media screen and (max-width: 834px) {
  .contact-page .entry-form__ttl, .contact-confirm .entry-form__ttl {
    padding: 25px 20px;
    padding-right: 50px;
  }
  .contact-page .entry-form__ttl span, .contact-confirm .entry-form__ttl span {
    font-size: 1.4rem;
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .contact-page .entry-form__dl:nth-child(2), .contact-confirm .entry-form__dl:nth-child(2) {
    border-top: 1px solid #DBDAD2;
  }
}
.contact-page .entry-form__dl--textarea .entry-form__dt, .contact-confirm .entry-form__dl--textarea .entry-form__dt {
  padding-top: 0;
}
@media screen and (min-width: 835px) {
  .contact-page .entry-form__dt, .contact-confirm .entry-form__dt {
    width: 288px;
  }
}
@media screen and (min-width: 835px) {
  .contact-page .entry-form__dd, .contact-confirm .entry-form__dd {
    width: calc(100% - 288px);
  }
}
@media screen and (max-width: 834px) {
  .contact-page .entry-form__main, .contact-confirm .entry-form__main {
    padding-top: 22px;
  }
}
.contact-page .entry-form__main p, .contact-confirm .entry-form__main p {
  margin-bottom: 36px;
}
@media screen and (max-width: 834px) {
  .contact-page .entry-form__main p, .contact-confirm .entry-form__main p {
    margin-bottom: 24px;
  }
}
.contact-page .entry-form__main .form-control, .contact-confirm .entry-form__main .form-control {
  height: 60px;
  background: #F8F8F8;
  border: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .contact-page .entry-form__main .form-control, .contact-confirm .entry-form__main .form-control {
    height: 65px;
  }
}
.contact-page .entry-form__main .form-control--textarea, .contact-confirm .entry-form__main .form-control--textarea {
  height: 260px;
}

.contact-confirm .entry-form {
  margin-bottom: 0;
}
.contact-confirm .entry-form__ttl:after {
  display: none;
}
.contact-confirm .entry-form__dt {
  padding-top: 0;
}
.contact-confirm .entry-form__dl {
  padding: 35px 0;
}
@media screen and (max-width: 834px) {
  .contact-confirm .entry-form__dl {
    padding: 15px 0;
  }
}
.contact-confirm .entry-form__dl:nth-child(2) {
  border-top: 1px solid #DBDAD2;
}
.contact-confirm .entry-form__dl--last {
  margin-bottom: 77px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .contact-confirm .entry-form__dl--last {
    margin-bottom: 20px;
  }
}
.contact-confirm .entry-form__dt {
  padding-top: 0;
}
.contact-confirm .entry-form__main {
  display: block;
}
.contact-confirm .c-btn__02 {
  margin: 0 auto;
}

.entry-form__customer-box {
  background: #F3F3F3;
  padding: 35px 74px;
  margin-bottom: 37px;
}
@media screen and (max-width: 834px) {
  .entry-form__customer-box {
    padding: 20px 17px 30px;
    margin-bottom: 14px;
  }
}
.entry-form__customer-box .c-ttl__05 {
  margin-bottom: 6px;
}
@media screen and (max-width: 834px) {
  .entry-form__customer-box .c-ttl__05 {
    margin-bottom: 18px;
  }
}
.entry-form__customer-box a {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 600;
  margin-right: 14px;
  position: relative;
  color: #000;
}
@media screen and (max-width: 834px) {
  .entry-form__customer-box a {
    font-size: 2rem;
  }
}
.entry-form__customer-box a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  background: url(../img/contact/ico-phone.png) no-repeat center/100%;
  position: relative;
  margin-right: 7px;
  bottom: 3px;
}
@media screen and (max-width: 834px) {
  .entry-form__customer-box a:before {
    margin-right: 4px;
  }
}
.entry-form__customer-btn {
  margin-top: 50px;
  max-width: 380px;
}
@media screen and (max-width: 834px) {
  .entry-form__customer-btn {
    margin-top: 26px;
  }
}
.entry-form__customer p {
  margin-bottom: 33px;
}
.entry-form__customer p:last-of-type {
  margin-bottom: 0;
}

.txt-underline {
  text-decoration: underline;
  color: #000;
}

.terms-page {
  padding: 120px 0;
}
@media screen and (max-width: 834px) {
  .terms-page {
    padding: 50px 0;
  }
}
.terms-page__dl {
  margin-bottom: 30px;
}
.terms-page__dl:last-of-type {
  margin-bottom: 0;
}
.terms-page__dt {
  margin-bottom: 4px;
}
.terms-page__dd ol:not(.dots-list) {
  list-style: decimal;
  margin-top: 16px;
  margin-left: 27px;
}
.terms-page__dd ol:not(.dots-list) li {
  list-style: decimal;
  margin-bottom: 2px;
}
.terms-page__dd dl {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .terms-page__dd dl {
    margin-bottom: 20px;
  }
}
.terms-page__dd dl dt {
  margin-bottom: 4px;
}
.terms-page__dd--flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 30px;
}
.terms-page__dd-item {
  width: calc(50% - 15px);
}
@media screen and (max-width: 640px) {
  .terms-page__dd-item {
    width: 100%;
  }
}

.dots-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  line-height: 1.9;
}
.dots-list li:last-of-type {
  margin-bottom: 0;
}
.dots-list li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
  top: 14px;
  left: 3px;
}
@media screen and (max-width: 834px) {
  .dots-list li:before {
    top: 9px;
  }
}

.privacy-block {
  padding: 130px 0 117px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .privacy-block {
    padding: 50px 0;
  }
}
.privacy-block__ttl {
  margin-bottom: 58px;
}
@media screen and (max-width: 834px) {
  .privacy-block__ttl {
    margin-bottom: 20px;
  }
}
.privacy-block .txt-company {
  margin-bottom: 22px;
}
@media screen and (max-width: 834px) {
  .privacy-block .txt-company {
    margin-bottom: 15px;
  }
}
.privacy-block ol {
  list-style: decimal;
  margin-top: 16px;
  margin-left: 27px;
}
@media screen and (max-width: 834px) {
  .privacy-block ol {
    margin-left: 15px;
  }
}
.privacy-block ol li {
  list-style: decimal;
  margin-bottom: 2px;
}
.privacy-block:nth-child(2) {
  padding: 105px 0 117px;
}
@media screen and (max-width: 834px) {
  .privacy-block:nth-child(2) {
    padding: 40px 0;
  }
}
.privacy-block:nth-child(2) .c-ttl__02 {
  margin-bottom: 52px;
}
@media screen and (max-width: 834px) {
  .privacy-block:nth-child(2) .c-ttl__02 {
    margin-bottom: 30px;
  }
}
.privacy-block__content-info {
  padding: 33px 40px;
  background: #F3F3F3;
  margin-top: 30px;
}
@media screen and (max-width: 834px) {
  .privacy-block__content-info {
    padding: 15px 20px;
    margin-top: 20px;
  }
}
.privacy-block__content dl {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .privacy-block__content dl {
    margin-bottom: 20px;
  }
}
.privacy-block__content dl dt {
  margin-bottom: 4px;
}
.privacy-block__content--flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 30px;
}
.privacy-block__content-item {
  width: calc(50% - 15px);
}
@media screen and (max-width: 640px) {
  .privacy-block__content-item {
    width: 100%;
  }
}

.page-oyster_index .content .inner {
  width: 100%;
  max-width: 1432px;
}
@media screen and (max-width: 834px) {
  .page-oyster_index .c-ttl__01 .c-ft--jp {
    padding-top: 4px;
  }
}
.page-oyster_index .single-block__ct {
  padding-left: 110px;
}
@media screen and (max-width: 1245px) {
  .page-oyster_index .single-block__ct {
    padding-right: 0;
    padding-left: 8.835vw;
  }
}
@media screen and (max-width: 834px) {
  .page-oyster_index .single-block__ct {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media screen and (max-width: 834px) {
  .page-oyster_index .content-right {
    overflow-x: hidden;
  }
}

.oyster-top {
  padding-right: 110px;
}
@media screen and (max-width: 1245px) {
  .oyster-top {
    padding-right: 8.835vw;
  }
}
@media screen and (max-width: 834px) {
  .oyster-top {
    padding-right: 0;
  }
}
.oyster-top .single-block__img img {
  min-height: 494px;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .oyster-top .single-block__img img {
    min-height: 0;
    width: 100%;
  }
}

.oyster-type {
  padding: 130px 5vw 116px;
}
@media screen and (max-width: 834px) {
  .oyster-type {
    padding: 54px 0;
  }
}
.oyster-type__ttl {
  margin-bottom: 65px;
}
@media screen and (max-width: 834px) {
  .oyster-type__ttl {
    margin-bottom: 30px;
  }
}
.oyster-type__intro {
  max-width: 1296px;
  margin-bottom: 115px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 1245px) {
  .oyster-type__intro {
    align-items: flex-end;
  }
}
@media screen and (max-width: 834px) {
  .oyster-type__intro {
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #DBDAD2;
  }
}
.oyster-type__intro-ct {
  width: 50.92%;
}
@media screen and (max-width: 834px) {
  .oyster-type__intro-ct {
    width: 100%;
    margin-bottom: 26px;
  }
}
.oyster-type__intro-img {
  width: 40.43%;
}
@media screen and (max-width: 1024px) {
  .oyster-type__intro-img {
    width: 45.43%;
  }
}
@media screen and (max-width: 834px) {
  .oyster-type__intro-img {
    width: 100%;
  }
}
.oyster-type__intro-caption {
  font-size: 1.6rem;
  margin-top: 18px;
  display: block;
}
@media screen and (max-width: 834px) {
  .oyster-type__intro-caption {
    font-size: 1.4rem;
    margin-top: 13px;
  }
}
.oyster-type__wrapper {
  max-width: 1160px;
  margin: 0 auto 56px;
}
.oyster-type__table {
  margin-bottom: 70px;
  padding-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .oyster-type__table {
    margin-bottom: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 640px) {
  .oyster-type__table {
    overflow-x: auto;
    margin-right: -15px;
  }
}
.oyster-type__table table {
  width: 100%;
  border: 1px solid #DBDAD2;
}
@media screen and (max-width: 640px) {
  .oyster-type__table table {
    min-width: 484px;
    font-size: 1.2rem;
  }
}
.oyster-type__table table tr th, .oyster-type__table table tr td {
  width: 28.92%;
  border-right: 1px solid #DBDAD2;
  border-bottom: 1px solid #DBDAD2;
  padding: 36px 40px;
  vertical-align: top;
}
@media screen and (max-width: 1024px) {
  .oyster-type__table table tr th, .oyster-type__table table tr td {
    padding: 13px 16px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-type__table table tr th, .oyster-type__table table tr td {
    width: 29.2033333333%;
  }
}
.oyster-type__table table tr th:first-child, .oyster-type__table table tr td:first-child {
  width: 11.05%;
}
@media screen and (max-width: 834px) {
  .oyster-type__table table tr th:first-child, .oyster-type__table table tr td:first-child {
    width: 12.39%;
  }
}
.oyster-type__table table tr th {
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .oyster-type__table table tr th {
    line-height: 2.2;
  }
}
.oyster-type__table table tr td {
  font-size: 1.4rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .oyster-type__table table tr td {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 14px 16px;
  }
}
.oyster-type__table table tr td:first-child {
  padding: 40px 20px;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 1024px) {
  .oyster-type__table table tr td:first-child {
    padding: 11px 0 11px 5px;
    text-align: left;
  }
}
@media screen and (max-width: 834px) {
  .oyster-type__table table tr td:first-child {
    font-size: 1.4rem;
  }
}
.oyster-type__table table tr td:nth-child(2) {
  background: #F1F2F5;
}
.oyster-type__table table tr td:nth-child(3) {
  background: #F1F2F5;
}
.oyster-type__table table tr td:nth-child(4) {
  background: #F8F3EE;
}

.has-border {
  border-bottom: 1px solid #DBDAD2;
}

.oyster-map {
  padding-bottom: 80px;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .oyster-map {
    padding-bottom: 24px;
    margin-bottom: 60px;
  }
}
.oyster-map__ttl {
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .oyster-map__ttl {
    font-size: 1.8rem;
    line-height: 1.95;
    margin-bottom: 11px;
  }
}
.oyster-map__content {
  margin: 0 -1px;
}
.oyster-map__desc {
  margin-bottom: 73px;
}
@media screen and (max-width: 834px) {
  .oyster-map__desc {
    margin-bottom: 52px;
  }
}
.oyster-map__box {
  padding: 20px 16px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .oyster-map__box {
    font-size: 1.2rem;
    margin-bottom: 21px;
  }
}
.oyster-map__symbol {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .oyster-map__symbol {
    margin-bottom: 16px;
  }
}
.oyster-map__symbol li {
  margin-right: 20px;
}
@media screen and (max-width: 834px) {
  .oyster-map__symbol li {
    font-size: 1.2rem;
    font-weight: 500;
  }
}
.oyster-map__symbol li:last-of-type {
  margin-right: 0;
}
.oyster-map__symbol li::before {
  content: "●";
  display: inline-block;
  color: #566081;
  margin-right: 5px;
}
.oyster-map__symbol li:nth-child(1) {
  background: #F1F2F5;
}
.oyster-map__symbol li:nth-child(2) {
  background: #F8F3EE;
}
.oyster-map__symbol li:nth-child(2)::before {
  color: #D0AF8E;
}
.oyster-map__note {
  display: inline-block;
  border: 1px dotted #BA915B;
  padding: 14px 20px;
  color: #BA915B;
  font-weight: 500;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .oyster-map__note {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 15px 15px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-map__content {
    margin-right: -15px;
    overflow-x: auto;
    margin-left: -15px;
  }
}
.oyster-map__content svg {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .oyster-map__content svg {
    width: 482px;
  }
}
.oyster-map__content svg a rect {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (any-hover: hover) {
  .oyster-map__content svg a {
    transition: all 0.3s;
  }
  .oyster-map__content svg a:hover rect {
    opacity: 0.8;
  }
}

.oyster-calendar__ttl {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__ttl {
    font-size: 1.8rem;
    margin-bottom: 56px;
  }
}
.oyster-calendar__symbol {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__symbol {
    margin-bottom: 20px;
  }
}
.oyster-calendar__symbol-label {
  margin-right: 25px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__symbol-label {
    margin-right: 0;
    width: 100%;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}
.oyster-calendar__symbol-list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.oyster-calendar__symbol-list li {
  width: 172px;
  height: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-right: 23px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__symbol-list li {
    margin-right: 15px;
  }
}
.oyster-calendar__symbol-list li:last-of-type {
  margin-right: 0;
}
.oyster-calendar__symbol-list li::before {
  content: "●";
  display: inline-block;
  color: #566081;
  margin-right: 5px;
}
.oyster-calendar__symbol-list li:nth-child(1) {
  background: #F1F2F5;
}
.oyster-calendar__symbol-list li:nth-child(2) {
  background: #F8F3EE;
}
.oyster-calendar__symbol-list li:nth-child(2)::before {
  color: #D0AF8E;
}
.oyster-calendar__note {
  display: inline-block;
  border: 1px dotted #BA915B;
  padding: 14px 20px;
  color: #BA915B;
  font-weight: 500;
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__note {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 15px 15px;
    margin-bottom: 30px;
  }
}
.oyster-calendar__table {
  position: relative;
  border: 1px solid #DBDAD2;
  border-bottom: none;
}
.oyster-calendar__table .guide-table {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
}
.oyster-calendar__table .guide-table span {
  display: block;
  width: 7.4137931034%;
  height: 100%;
  position: relative;
}
.oyster-calendar__table .guide-table span:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 100%;
  background: #DBDAD2;
  top: 0;
  right: 0;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table .guide-table span {
    width: 7.2625698324%;
  }
}
.oyster-calendar__table .guide-table span:first-child {
  width: 11.0344827586%;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table .guide-table span:first-child {
    width: 13.4078212291%;
  }
}
.oyster-calendar__table .guide-table span:nth-child(3):after, .oyster-calendar__table .guide-table span:nth-child(4):after, .oyster-calendar__table .guide-table span:nth-child(5):after, .oyster-calendar__table .guide-table span:nth-child(10):after, .oyster-calendar__table .guide-table span:nth-child(11):after, .oyster-calendar__table .guide-table span:nth-child(12):after {
  right: -1px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table .guide-table span:nth-child(3):after, .oyster-calendar__table .guide-table span:nth-child(4):after, .oyster-calendar__table .guide-table span:nth-child(5):after, .oyster-calendar__table .guide-table span:nth-child(10):after, .oyster-calendar__table .guide-table span:nth-child(11):after, .oyster-calendar__table .guide-table span:nth-child(12):after {
    right: 0;
  }
}
.oyster-calendar__table .guide-table span:last-child {
  border-right: none;
}
.oyster-calendar__table .guide-table span:last-child:after {
  display: none;
}
.oyster-calendar__table table {
  width: 100%;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table {
    font-size: 1.4rem;
    border-top: none;
    border-bottom: none;
  }
}
@media screen and (max-width: 385px) {
  .oyster-calendar__table table {
    font-size: 1.2rem;
  }
}
.oyster-calendar__table table thead tr {
  border-bottom: 1px solid #DBDAD2;
}
.oyster-calendar__table table tr {
  position: relative;
}
.oyster-calendar__table table tr th {
  width: 7.4137931034%;
  border-right: 1px solid #DBDAD2;
  border-bottom: 1px solid #DBDAD2;
  background: #F3F3F3;
  padding: 36px 0;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr th {
    width: 7.2625698324%;
    padding: 18px 2.5%;
    line-height: 1.15;
    font-weight: 500;
    border: none;
    background: none;
  }
}
@media screen and (max-width: 704px) {
  .oyster-calendar__table table tr th {
    padding: 18px 2%;
  }
}
.oyster-calendar__table table tr th:first-child {
  width: 11.0344827586%;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr th:first-child {
    width: 12.8491620112%;
  }
}
.oyster-calendar__table table tr th:last-of-type {
  border-right: none;
}
.oyster-calendar__table table tr td {
  padding: 8px 0;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr td {
    padding: 2px 0;
  }
}
.oyster-calendar__table table tr td:first-child {
  text-align: center;
  cursor: pointer;
}
@media (any-hover: hover) {
  .oyster-calendar__table table tr td:first-child {
    transition: all 0.2s;
  }
  .oyster-calendar__table table tr td:first-child:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr td:first-child {
    font-size: 1rem;
  }
}
.oyster-calendar__table table tr td:last-of-type {
  border-right: none;
}
.oyster-calendar__table table tr td.area-01 {
  position: absolute;
  top: 0;
  width: 44.5%;
  right: 0;
}
.oyster-calendar__table table tr td.area-02 {
  position: absolute;
  top: 26px;
  width: 22.3%;
  right: 37%;
}
.oyster-calendar__table table tr.first td {
  padding-top: 24px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr.first td {
    padding-top: 8px;
  }
}
.oyster-calendar__table table tr.line {
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr.line:nth-last-child(2) {
    border-bottom: none;
  }
}
.oyster-calendar__table table tr.line td {
  padding-bottom: 24px;
}
@media screen and (max-width: 834px) {
  .oyster-calendar__table table tr.line td {
    padding-bottom: 8px;
  }
}
.oyster-calendar__table table tr:last-child th {
  border-bottom: none;
}

.scroll-hint-shadow-wrap::before, .scroll-hint-shadow-wrap:after {
  display: none;
}

.oyster-nutrition {
  padding: 130px 5vw 120px;
  background: #F3F3F3;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition {
    padding: 60px 0;
  }
}
.oyster-nutrition__ttl {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__ttl {
    margin-bottom: 27px;
  }
}
.oyster-nutrition__intro {
  margin-bottom: 78px;
  max-width: 1296px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__intro {
    margin-bottom: 60px;
  }
}
.oyster-nutrition__intro-ttl {
  margin-bottom: 35px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__intro-ttl {
    margin-bottom: 27px;
  }
}
.oyster-nutrition__main {
  max-width: 1160px;
  margin: 0 auto;
}
.oyster-nutrition__main-ttl {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__main-ttl {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
}
.oyster-nutrition__main-desc {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__main-desc {
    margin-bottom: 25px;
  }
}
.oyster-nutrition__main-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px min(5.833vw, 112px);
  margin-bottom: 56px;
}
@media screen and (max-width: 834px) {
  .oyster-nutrition__main-list {
    grid-template-columns: 1fr;
    max-width: 450px;
    margin: 0 auto 35px;
  }
}
@media screen and (max-width: 640px) {
  .oyster-nutrition__main-list {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.oyster-pairing {
  padding: 130px 5vw 100px;
}
.oyster-pairing .inner {
  max-width: 100%;
}
@media screen and (max-width: 834px) {
  .oyster-pairing {
    padding: 58px 0 60px;
  }
}
.oyster-pairing__ttl {
  margin-bottom: 73px;
}
@media screen and (max-width: 834px) {
  .oyster-pairing__ttl {
    margin-bottom: 27px;
  }
}
.oyster-pairing .single-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .single-block {
    margin-bottom: 20px;
  }
}
.oyster-pairing .single-block__ct {
  width: 45.83%;
  padding: 0;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .single-block__ct {
    width: 100%;
    margin-bottom: 26px;
  }
  .oyster-pairing .single-block__ct .c-ttl__02 {
    margin-bottom: 26px;
  }
}
.oyster-pairing .single-block__img {
  width: 45.83%;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .single-block__img {
    width: 100%;
  }
}
.oyster-pairing .deliver-box {
  background: #F4F4F4;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .deliver-box {
    padding: 54px 15px 60px;
    margin-bottom: 72px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-pairing .deliver-box .c-ttl__04 {
    text-align: left !important;
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-pairing .deliver-box__flex-ct {
    margin-bottom: 26px;
  }
}
.oyster-pairing .deliver-box.no-bg {
  padding: 0;
  background: none;
  margin-bottom: 0;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-ct {
  margin-top: 0;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-img {
  position: relative;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-img::before {
  content: "";
  float: left;
  padding-top: 66.4122137405%;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-img::after {
  content: "";
  display: table;
  clear: both;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.oyster-pairing .deliver-box.no-bg .deliver-box__flex-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.oyster-pairing .deliver-box.no-bg .c-ttl__04 {
  margin-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .deliver-box.no-bg .c-ttl__04 {
    font-size: 1.8rem;
    line-height: 1.8;
    margin-bottom: 13px;
  }
}
.oyster-pairing .guide-list__item {
  align-items: flex-start;
}
.oyster-pairing .guide-list__ct {
  padding-top: 30px;
}
.oyster-pairing .guide-list__ct::before {
  top: 5px;
}
.oyster-pairing .guide-list--number .guide-list__item {
  margin-bottom: 38px;
}
@media screen and (max-width: 834px) {
  .oyster-pairing .guide-list--number .guide-list__item {
    margin-bottom: 6px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-pairing .guide-list--number .guide-list__ct:before {
    top: 27px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-pairing .guide-list--number .guide-list__ct .c-ttl__05 {
    margin-bottom: 23px;
  }
}

@media screen and (max-width: 834px) {
  .sticky {
    background: #f8f8f8;
    position: sticky;
    top: 60px;
    z-index: 100;
  }
  .sticky:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: calc(100% - 1px);
    height: 100%;
    top: 0;
    left: 1px;
    background: #F3F3F3;
    border-right: 1px solid #dbdad2 !important;
    border-bottom: 1px solid #dbdad2 !important;
    z-index: -1;
  }
  .sticky:first-child::before {
    background: #fff;
    width: 100%;
    left: 0px;
  }
  .sticky:last-child::before {
    border-right: none !important;
  }
}

.txt-area {
  width: 100%;
  height: 32px;
  background: #F1F2F5;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.4rem;
  line-height: 1.1;
}
@media (any-hover: hover) {
  .txt-area {
    transition: all 0.2s;
  }
  .txt-area:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 1245px) {
  .txt-area {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 834px) {
  .txt-area {
    font-size: 0.9rem;
    height: 23px;
    width: calc(100% + 3px);
    margin-left: -1.5px;
  }
}
@media screen and (max-width: 385px) {
  .txt-area {
    font-size: 0.7rem;
  }
}
.txt-area::before {
  content: "●";
  display: inline-block;
  margin-right: 3px;
  color: #566081;
}
@media screen and (max-width: 834px) {
  .txt-area::before {
    font-size: 0.9rem;
    margin-right: 1px;
  }
}
.txt-area--yl {
  background: #F8F3EE;
}
.txt-area--yl::before {
  color: #D0AF8E;
}
@media screen and (max-width: 834px) {
  .txt-area--other {
    width: calc(100% + 26px);
    margin: 0 -10px;
    background: none;
  }
  .txt-area--other:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 50%;
    height: 100%;
    background: #F1F2F5;
    top: 0;
    left: 45%;
    transform: translateX(-50%);
    z-index: -1;
  }
  .txt-area--other.txt-area--yl:after {
    background: #F8F3EE;
  }
}

.overlay {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.overlay.is-show {
  opacity: 0.9;
  visibility: visible;
}

.oyster-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: none;
}
.oyster-modal__close {
  width: 50px;
  height: 50px;
  background: url(../img/oyster/btn-close.png) no-repeat center/100%;
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
  border: none;
  outline: 0;
}
@media screen and (max-width: 834px) {
  .oyster-modal__close {
    top: 16px;
    right: 20px;
    width: 36px;
    height: 36px;
  }
}
.oyster-modal__wrapper {
  max-width: 1160px;
  width: 90%;
  background: #F3F3F3;
  border-radius: 8px;
  padding: 50px 100px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 1024px) {
  .oyster-modal__wrapper {
    padding: 40px 16px;
  }
}
@media screen and (max-width: 834px) {
  .oyster-modal__wrapper {
    padding: 50px 16px;
    max-width: 600px;
    width: 92.3%;
  }
}
.oyster-modal__ttl {
  margin-bottom: 52px;
}
@media screen and (max-width: 1024px) {
  .oyster-modal__ttl {
    margin-bottom: 30px;
  }
}
.oyster-modal__content-scroll {
  max-height: 60vh;
  overflow-y: scroll;
}
@media screen and (max-width: 1024px) {
  .oyster-modal__content-scroll {
    height: 55vh;
  }
}
@media screen and (max-width: 834px) {
  .oyster-modal__content-scroll {
    height: 63vh;
  }
}
.oyster-modal__dl {
  margin-bottom: 40px;
}
.oyster-modal__dl:last-of-type {
  margin-bottom: 0;
}
.oyster-modal__dt {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 12px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 40px;
}
.oyster-modal__dt p {
  position: relative;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .oyster-modal__dt p {
    width: 100%;
    margin-bottom: 5px;
  }
  .oyster-modal__dt p:last-of-type {
    margin-bottom: 0;
  }
}
.oyster-modal__dt p span {
  color: #566081;
}
.oyster-modal__dt p:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #566081;
  top: 5px;
  left: 0;
  border-radius: 50%;
}
.oyster-modal__dt p.txt-yellow span {
  color: #D0AF8E;
}
.oyster-modal__dt p.txt-yellow:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  background: #D0AF8E;
  top: 5px;
  left: 0;
  border-radius: 50%;
}
.oyster-modal__dd {
  font-size: 1.4rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .oyster-modal__dd {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.oyster-modal__dd p {
  margin-bottom: 17px;
}
.oyster-modal__gallery {
  max-width: 600px;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .oyster-modal__gallery li {
    margin-bottom: 8px;
  }
  .oyster-modal__gallery li:last-of-type {
    margin-bottom: 0;
  }
}
.oyster-modal__gallery li:nth-child(1) {
  width: 25.83%;
}
@media screen and (max-width: 834px) {
  .oyster-modal__gallery li:nth-child(1) {
    width: 37.4%;
  }
}
.oyster-modal__gallery li:nth-child(2) {
  width: 41.67%;
}
@media screen and (max-width: 834px) {
  .oyster-modal__gallery li:nth-child(2) {
    width: 60.42%;
  }
}
.oyster-modal__gallery li:nth-child(3) {
  width: 29.33%;
}
@media screen and (max-width: 834px) {
  .oyster-modal__gallery li:nth-child(3) {
    width: 44.47%;
  }
}

.page-topics_index .content-right {
  padding-top: 135px;
}
@media screen and (max-width: 1770px) {
  .page-topics_index .content-right {
    padding-top: 80px;
  }
}
@media screen and (max-width: 834px) {
  .page-topics_index .content-right {
    padding-top: 58px;
  }
}
.page-topics_index .content-right .list-menu__item {
  margin-bottom: 23px;
}
.page-topics_index .content-right .list-menu__item a {
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .page-topics_index .content-right .list-menu {
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}
.page-topics_index .content-left {
  padding-top: 135px;
}
@media screen and (max-width: 834px) {
  .page-topics_index .content-left {
    padding-top: 11px;
  }
}

.topics-page {
  padding-bottom: 60px;
}
@media screen and (max-width: 834px) {
  .topics-page {
    padding-bottom: 34px;
  }
}
.topics-page__list {
  max-width: 1296px;
  margin: 0 auto 124px;
  position: relative;
  left: 66px;
}
@media screen and (max-width: 1770px) {
  .topics-page__list {
    left: 0;
  }
}
@media screen and (max-width: 834px) {
  .topics-page__list {
    margin-bottom: 60px;
  }
}
.topics-page__list-item {
  border-bottom: 1px solid #DBDAD2;
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .topics-page__list-item {
    padding-bottom: 24px;
    margin-bottom: 30px;
  }
}
.topics-page__list-item .c-arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  right: 0;
  overflow: hidden;
  transition: all 0.3s;
  transform: translateY(-50%);
}
@media screen and (max-width: 834px) {
  .topics-page__list-item .c-arrow {
    right: -13px;
    top: 34px;
    transform: translateY(0);
  }
}
.topics-page__list-item .c-arrow::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url(../img/index/arrow02.svg) 50% no-repeat;
  background-size: contain;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: all 0.3s;
}
.topics-page__list-item .c-arrow:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 11px;
  background: url(../img/index/arrow.svg) 50% no-repeat;
  background-size: contain;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.topics-page__list-img {
  width: 192px;
  position: relative;
  background: #F3F3F3;
}
.topics-page__list-img::before {
  content: "";
  float: left;
  padding-top: 75%;
}
.topics-page__list-img::after {
  content: "";
  display: table;
  clear: both;
}
.topics-page__list-img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.topics-page__list-img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .topics-page__list-img {
    width: 128px;
  }
}
.topics-page__list-ct {
  width: calc(100% - 192px);
  padding-left: 32px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .topics-page__list-ct {
    width: calc(100% - 128px);
    padding-left: 16px;
  }
}
.topics-page__list-date {
  font-family: "Lato", serif;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
}
@media screen and (max-width: 834px) {
  .topics-page__list-date {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}
.topics-page__list-cate {
  min-width: 200px;
  display: inline-block;
  border: 1px solid #0A1F36;
  border-radius: 15px;
  font-size: 1.2rem;
  text-align: center;
  padding: 5px 15px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .topics-page__list-cate {
    min-width: 185px;
  }
}
@media screen and (max-width: 834px) {
  .topics-page__list-cate {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 385px) {
  .topics-page__list-cate {
    text-align: left;
    min-width: 154px;
  }
}
.topics-page__list-meta {
  width: 264px;
  padding-left: 32px;
}
@media screen and (max-width: 1024px) {
  .topics-page__list-meta {
    padding-left: 16px;
    width: 220px;
  }
}
@media screen and (max-width: 834px) {
  .topics-page__list-meta {
    width: calc(100% - 128px);
  }
}
@media screen and (max-width: 385px) {
  .topics-page__list-meta {
    padding-right: 30px;
  }
}
.topics-page__list-title {
  width: calc(100% - 264px - 192px);
  font-weight: 500;
  font-size: 1.6rem;
  padding-left: 32px;
  padding-right: 50px;
}
@media screen and (max-width: 1024px) {
  .topics-page__list-title {
    width: calc(100% - 128px - 264px);
    padding-left: 20px;
    padding-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .topics-page__list-title {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 7px;
    font-size: 1.4rem;
    line-height: 2;
  }
}
.topics-page__list-title span {
  background: -webkit-gradient(linear, left top, left bottom, from(#333), to(#333)) 100% 100%/0 1px no-repeat;
  background: linear-gradient(#333, #333) 100% 100%/0 1px no-repeat;
  -webkit-transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
  transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
  will-change: background-size;
}
.topics-page__list-link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #0A1F36;
  position: relative;
}
@media screen and (max-width: 834px) {
  .topics-page__list-link {
    align-items: flex-start;
  }
}
.topics-page__list-link:hover {
  opacity: 1;
}
.topics-page__list-link:hover .topics-page__list-title span {
  background-position: 0 100%;
  background-size: 100% 1px;
}
.topics-page__list-link:hover .c-arrow {
  background: rgba(13, 38, 65, 0.9);
  transition: all 0.3s;
}
.topics-page__list-link:hover .c-arrow::before {
  opacity: 0;
  transition: all 0.3s;
}
.topics-page__list-link:hover .c-arrow:after {
  left: 13px;
  transition: all 0.3s;
}

.topics-detail {
  padding: 137px 0 120px;
}
@media screen and (max-width: 834px) {
  .topics-detail {
    padding: 80px 0 60px;
  }
}
.topics-detail__wrapper {
  padding-bottom: 80px;
  margin-bottom: 125px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .topics-detail__wrapper {
    padding-bottom: 27px;
    margin-bottom: 33px;
  }
}
.topics-detail__meta {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .topics-detail__meta {
    margin-bottom: 30px;
  }
}
.topics-detail__meta-date {
  font-family: "Lato", serif;
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  margin-bottom: 13px;
}
@media screen and (max-width: 834px) {
  .topics-detail__meta-date {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}
.topics-detail__meta-cate {
  min-width: 200px;
  display: inline-block;
  border: 1px solid #0A1F36;
  border-radius: 15px;
  font-size: 1.2rem;
  text-align: center;
  padding: 5px 15px;
}
@media screen and (max-width: 1024px) {
  .topics-detail__meta-cate {
    min-width: 185px;
  }
}
@media screen and (max-width: 834px) {
  .topics-detail__meta-cate {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 385px) {
  .topics-detail__meta-cate {
    text-align: left;
    min-width: 154px;
  }
}
.topics-detail__ttl {
  font-size: 3.2rem;
  font-weight: 500;
  padding-bottom: 40px;
  margin-bottom: 70px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .topics-detail__ttl {
    font-size: 2.6rem;
    line-height: 1.45;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}
.topics-detail__content img {
  max-width: 560px;
  width: 100%;
  height: auto;
  margin: 60px auto;
  display: block;
}
@media screen and (max-width: 834px) {
  .topics-detail__content img {
    margin: 32px auto;
  }
}
.topics-detail__content p {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .topics-detail__content p {
    margin-bottom: 28px;
  }
}
.topics-detail__content a[href$=".pdf"] {
  display: inline-block;
  padding: 4px 56px 4px 0;
  background: url(../img/topics/detail/ico-link.png) no-repeat center right/40px 40px;
  padding-right: 56px;
  text-decoration: underline;
  color: #000;
}
@media screen and (max-width: 834px) {
  .topics-detail__content a[href$=".pdf"] {
    padding: 4px 50px 4px 0;
    background: url(../img/topics/detail/ico-link.png) no-repeat center right/34px 34px;
  }
}
.topics-detail__btn {
  margin: 0 auto;
}

.page-opc_index .content .inner {
  width: 100%;
  max-width: 1432px;
}

.opc-top {
  padding-right: 110px;
}
@media screen and (max-width: 1245px) {
  .opc-top {
    padding-right: 8.835vw;
  }
}
@media screen and (max-width: 834px) {
  .opc-top {
    padding-right: 0;
  }
}
.opc-top .single-block__img img {
  min-height: 614px;
  object-fit: cover;
}
@media screen and (max-width: 1024px) {
  .opc-top .single-block__img img {
    min-height: 550px;
  }
}
@media screen and (max-width: 834px) {
  .opc-top .single-block__img {
    width: 100%;
  }
  .opc-top .single-block__img img {
    min-height: 0;
  }
}
@media screen and (max-width: 834px) {
  .opc-top .single-block__ct .c-ttl__02--black {
    margin-bottom: 30px;
  }
}
.opc-top .single-block__ct .c-txt__01 {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .opc-top .single-block__ct .c-txt__01 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 834px) {
  .opc-top .single-block__ct .c-btn__02 {
    margin: 0;
  }
}

.opc-point {
  padding: min(6.771vw, 130px) 5vw min(6.25vw, 120px);
}
@media screen and (max-width: 834px) {
  .opc-point {
    padding: 57px 0;
  }
}
.opc-point .c-ttl__01 {
  margin-bottom: min(3.802vw, 73px);
}
@media screen and (max-width: 834px) {
  .opc-point .c-ttl__01 {
    margin-bottom: 30px;
  }
}
.opc-point__intro {
  margin-bottom: 63px;
}
.opc-point .guide-list {
  max-width: 1160px;
  margin: 0 auto min(5.938vw, 114px);
}
@media screen and (max-width: 834px) {
  .opc-point .guide-list {
    margin-bottom: 60px;
  }
}
.opc-point .guide-list__item {
  align-items: center;
  padding-bottom: 40px;
}
.opc-point .eoyster {
  max-width: 1160px;
  margin: 0 auto;
  border-radius: 16px;
}
@media screen and (max-width: 834px) {
  .opc-point .eoyster .eoyster-box {
    top: 0;
    transform: none;
  }
}
.opc-point .eoyster .eoyster-bg img {
  min-height: 360px;
}
@media screen and (max-width: 834px) {
  .opc-point .eoyster .eoyster-bg img {
    min-height: 520px;
  }
}

.opc-money {
  margin-bottom: 40px;
}
.opc-money__ttl {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .opc-money__ttl {
    margin-bottom: 60px;
  }
}
.opc-money__ttlsub {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .opc-money__ttlsub {
    font-size: 1.8rem;
    margin-bottom: 17px;
  }
}
.opc-money__desc {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .opc-money__desc {
    margin-bottom: 25px;
  }
}
.opc-money__content {
  background: #F3F3F3;
  border-radius: 8px;
  padding: 48px min(5vw, 96px) min(3.125vw, 60px);
}
@media screen and (max-width: 834px) {
  .opc-money__content {
    padding: 32px 15px 36px;
  }
}
.opc-money__content-dl {
  padding-bottom: 34px;
  margin-bottom: 28px;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .opc-money__content-dl {
    padding-bottom: 25px;
    margin-bottom: 20px;
  }
}
.opc-money__content-dl:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.opc-money__content-dt {
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .opc-money__content-dt {
    margin-bottom: 18px;
  }
}
.opc-money__content-dd ul li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 17px;
}
@media screen and (max-width: 834px) {
  .opc-money__content-dd ul li {
    margin-bottom: 12px;
  }
}
.opc-money__content-dd ul li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  background: #0A1F36;
  border-radius: 50%;
  top: 12px;
  left: 0;
}
.opc-money__content-dd ul li:last-of-type {
  margin-bottom: 0;
}
.opc-money__content-dd a {
  text-decoration: underline;
  color: #000;
}
.opc-money__wp {
  max-width: 1160px;
  margin: 0 auto;
}

.opc-guide {
  background: #F3F3F3;
}
@media screen and (min-width: 835px) {
  .opc-guide {
    padding: min(7.083vw, 136px) 5vw 70px;
  }
}
@media screen and (max-width: 834px) {
  .opc-guide {
    padding: 58px 0 60px;
  }
}
@media screen and (min-width: 835px) {
  .opc-guide--inquiry {
    padding: 0 5vw;
  }
}
@media screen and (max-width: 834px) {
  .opc-guide--inquiry {
    padding: 0;
  }
}
.opc-guide--inquiry .opc-guide__block {
  padding-bottom: min(6.146vw, 118px);
  border-bottom: none;
}
@media screen and (max-width: 1024px) {
  .opc-guide--inquiry .opc-guide__block {
    padding-bottom: 80px;
  }
}
@media screen and (max-width: 834px) {
  .opc-guide--inquiry .opc-guide__block {
    padding-bottom: 60px;
  }
}
.opc-guide__ttl {
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  .opc-guide__ttl {
    margin-bottom: 23px;
  }
}
.opc-guide__block {
  padding-bottom: 80px;
  border-bottom: 1px solid #DBDAD2;
  max-width: 1160px;
  margin: 0 auto 70px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block {
    padding-bottom: 40px;
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 834px) {
  .opc-guide__block .c-btn__02 {
    margin: 0;
  }
}
.opc-guide__block .c-txt__01 a {
  text-decoration: underline;
  color: #000;
}
.opc-guide__block .c-txt__01 a[href^=tel] {
  text-decoration: none;
}
.opc-guide__block:last-of-type {
  margin-bottom: 0;
}
.opc-guide__block-ttl {
  margin-bottom: 37px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-ttl {
    font-size: 1.8rem;
    margin-bottom: 16px;
  }
}
.opc-guide__block-control {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 25px 32px;
}
.opc-guide__block-control li {
  max-width: 320px;
  width: 100%;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-control li {
    max-width: 360px;
  }
}
.opc-guide__block-desc {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-desc {
    margin-bottom: 30px;
  }
}
.opc-guide__block-desc p {
  margin-bottom: 14px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-desc p {
    margin-bottom: 6px;
  }
}
.opc-guide__block-desc p:last-of-type {
  margin-bottom: 0;
}
.opc-guide__block-app {
  background: #fff;
  border-radius: 8px;
  padding: 33px 0 27px;
}
@media screen and (min-width: 835px) {
  .opc-guide__block-app {
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  .opc-guide__block-app {
    padding: 13px 0 5px;
    position: relative;
  }
  .opc-guide__block-app:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 120px;
    height: 100px;
    content: "拡大できます";
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 10px;
  }
  .opc-guide__block-app:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 50px;
    height: 50px;
    background: url(../img/opc/ico-zoom.svg) no-repeat top center/100%;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.opc-guide__block-only-card {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 40px;
  margin-bottom: 64px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-only-card {
    margin-top: -10px;
    margin-bottom: 40px;
  }
}
.opc-guide__block-only-card span {
  font-size: 1.8rem;
  display: block;
}
@media screen and (max-width: 640px) {
  .opc-guide__block-only-card span {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 16px;
  }
}
.opc-guide__block-contact a {
  font-weight: 600;
  font-size: 1.8rem;
  position: relative;
  padding-left: 22px;
  margin-right: 17px;
  text-decoration: none;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-contact a {
    padding-left: 16px;
    font-size: 1.6rem;
  }
}
.opc-guide__block-contact a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  background: url(../img/opc/ico-phone.png) no-repeat center/100%;
  top: calc(50% - 7px);
  left: 0;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-time {
    display: block;
    width: 100%;
    margin-top: 9px;
  }
}
.opc-guide__block-wp {
  border-radius: 8px;
  background: #fff;
  padding: 58px min(5vw, 96px) 64px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp {
    padding: 32px 16px 40px;
  }
}
.opc-guide__block-wp .c-ttl__05 {
  margin-bottom: 18px;
}
.opc-guide__block-wp .c-txt__01 {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp .c-txt__01 {
    margin-bottom: 40px;
  }
}
.opc-guide__block-wp table {
  width: 100%;
}
.opc-guide__block-wp table.c-txt__01 {
  margin-bottom: 0;
}
.opc-guide__block-wp table th, .opc-guide__block-wp table td {
  text-align: center;
  border-bottom: 1px solid #DBDAD2;
}
.opc-guide__block-wp table th:first-child, .opc-guide__block-wp table td:first-child {
  text-align: left;
  width: 22%;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table th:first-child, .opc-guide__block-wp table td:first-child {
    width: 31.59%;
  }
}
.opc-guide__block-wp table th:nth-child(2), .opc-guide__block-wp table td:nth-child(2) {
  width: 26.2%;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table th:nth-child(2), .opc-guide__block-wp table td:nth-child(2) {
    width: 46%;
  }
}
.opc-guide__block-wp table th:nth-child(3), .opc-guide__block-wp table td:nth-child(3) {
  width: 35.17%;
  text-align: left;
  padding-left: 26px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table th:nth-child(3), .opc-guide__block-wp table td:nth-child(3) {
    width: 22%;
    text-align: center;
    padding-left: 0;
  }
}
.opc-guide__block-wp table th:nth-child(3) span, .opc-guide__block-wp table td:nth-child(3) span {
  width: 150px;
  text-align: center;
  display: block;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table th:nth-child(3) span, .opc-guide__block-wp table td:nth-child(3) span {
    width: 100%;
  }
}
.opc-guide__block-wp table th {
  padding-bottom: 32px;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table th {
    padding-bottom: 10px;
  }
}
.opc-guide__block-wp table td {
  padding: 20px 0;
  border-bottom: 1px solid #DBDAD2;
}
@media screen and (max-width: 834px) {
  .opc-guide__block-wp table td {
    padding: 26px 0;
  }
}

.sec-offer--opc {
  background-color: #fff;
}
@media screen and (min-width: 835px) {
  .sec-offer--opc {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
@media screen and (max-width: 834px) {
  .sec-offer--opc {
    padding: 60px 0;
  }
  .sec-offer--opc .eoyster-box {
    top: 0;
    transform: none;
  }
}
@media screen and (max-width: 834px) {
  .sec-offer--opc .eoyster-bg img {
    min-height: 650px;
  }
}

.app-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow-x: auto;
}
.app-modal picture {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  width: 800px;
  object-fit: contain;
  pointer-events: none;
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}