@use 'vars' as *;


.page-contact_index, .page-contact_confirm, .page-contact_thanks{
	.c-ttl__03 .c-ft--jp{
		@include mq(sm){
			line-height: 1.5;
			margin-bottom: 18px;
		}
	}
}

.page-contact_thanks{
	.entry-confirm{
		background: #fff;
		@include mq(md-min){
			padding: 136px 0 120px;
		}
		&__intro{
			text-align: center;
		}
	}
}

.contact-page, .contact-confirm{
	padding: 130px 0;
	@include mq{
		padding: 53px 0;
	}
	&__ttl{
		margin-bottom: 44px;
		text-align: center;
		@include mq{
			margin-bottom: 27px;
		}
	}
	.scroll-sp{
		@include mq{
			margin-bottom: 70px;
		}
	}
	.list-menu{
		margin-bottom: 110px;
		gap: 10px 20px;
		@include mq{
			max-width: max-content;
			width: max-content;
			margin-bottom: 0;
		}
		li{
			&.active{
				a{
					padding: 13px 24px;
				}
			}
		}
	}
	&__intro{
		margin-bottom: 136px;
		a{
			color: #000;
			text-decoration: underline;
		}
		@include mq{
			margin-bottom: 36px;
		}
	}
	.entry-form{
		&__ttl{
			@include mq{
				padding: 25px 20px;
				padding-right: 50px;
				span{
					@include font-size(14);
					width: 100%;
				}
			}
		}
		&__dl{
			@include mq{
				&:nth-child(2){
					border-top: 1px solid #DBDAD2;
				}
			}
			&--textarea{
				.entry-form__dt{
					padding-top: 0;
				}
			}
		}
		&__dt{
			@include mq(md-min){
				width: 288px;
			}
		}
		&__dd{
			@include mq(md-min){
				width: calc(100% - 288px);
			}
		}
		&__main{
			@include mq{
				padding-top: 22px;
			}
			p{
				margin-bottom: 36px;
				@include mq{
					margin-bottom: 24px;
				}
			}
			.form-control{
				height: 60px;
				background: #F8F8F8;
				border: 1px solid #DBDAD2;
				@include mq{
					height: 65px;
				}
				&--textarea{
					height: 260px;
				}
			}
		}
		
	}
}
.contact-confirm{
	.entry-form{
		margin-bottom: 0;
		&__ttl{
			&:after{
				display: none;
			}
		}
		&__dt{
			padding-top: 0;
		}
		&__dl{
			padding: 35px 0;
			@include mq{
				padding: 15px 0;
			}
			&:nth-child(2){
				border-top: 1px solid #DBDAD2;
			}
			&--last{
				margin-bottom: 77px;
				border-bottom: 1px solid #DBDAD2;
				@include mq{
					margin-bottom: 20px;
				}
			}
		}
		&__dt{
			padding-top: 0;
		}
		&__main{
			display: block;
		}
	}
	.c-btn__02{
		margin: 0 auto;
	}
}

.entry-form{
	&__customer{
		&-box{
			background: #F3F3F3;
			padding: 35px 74px;
			margin-bottom: 37px;
			@include mq{
				padding: 20px 17px 30px;
				margin-bottom: 14px;
			}
			.c-ttl__05{
				margin-bottom: 6px;
				@include mq{
					margin-bottom: 18px;
				}
			}
			a{
				@include font-size(24);
				line-height: 1;
				font-weight: 600;
				margin-right: 14px;
				position: relative;
				color: #000;
				@include mq{
					@include font-size(20);
				}
				@include p-before(14px, 14px){
					background: url(../img/contact/ico-phone.png) no-repeat center/100%;
					position: relative;
					margin-right: 7px;
					bottom: 3px;
					@include mq{
						margin-right: 4px;
					}
				}
			}
		}
		&-btn{
			margin-top: 50px;
			max-width: 380px;
			@include mq{
				margin-top: 26px;
			}
		}
		p{
			margin-bottom: 33px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}

.txt-underline{
	text-decoration: underline;
	color: #000;
}


