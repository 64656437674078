@use "vars" as *;


.c-fc{ //font-color
	&--white{
		color: white !important;
	}
	&--black{
		color: black !important;
	}
}



.c-ft{//font-family
	&--en{
		font-family: $font-en;
	}
	&--jp{
		font-family: $font-jp;
	}
}



.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}



.c-tx--v {
	//Vertical writing
	//display: inline-block;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	&-pc{
		@include mq(md-min) {
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
		}
	}
}



.c-al{
	&--c{
		text-align: center !important;
	}
	
	&--r{
		text-align: right !important
	}
	
	&--l{
		text-align: left !important;
	}
}



// Image position control
.c-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}



/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01{
		line-height: 2;
		@include font-size(16);
		@include mq(xl){
			@include font-size(14);
		}
		@include mq(xs){
			@include font-size(12);
		}
		&--white{
			color: #fff;
		}
		@include mq(){
			&--sp16{
				@include font-size(16);
			}
		}
	}
}

//ttl
.c-ttl{
	&__01{
		@include flex(0,center);
		@include mq(){
			display: block;
		}
		&--white{
			color: #fff;
		}
		&--block{
			display: block;
			span{
				display: block;
			}
			@include mq(xl-min){
				.c-ft--jp{
					padding-top: 21px !important;
				}
			}
		}
		.c-ft{
			&--en{
				letter-spacing: -1px;
				font-weight: bold;
				margin-right: 41px;
				font-family: $font-en;
				@include font-size(64);
				@include mq(xl){
					letter-spacing: 0;
					margin-right: 20px;
					@include font-size(50);
				}
				@include mq(){
					margin-right: 0;
					width: 100%;
					display: block;
					@include font-size(36);
				}
			}
			&--jp{
				padding-top: 17px;
				font-weight: 600;
				@include font-size(18);
				@include mq(xl){
					padding-top: 10px;
					@include font-size(16);
				}
				@include mq(){
					display: block;
					width: 100%;
				}
			}
		}
	}
	&__02{
		line-height: 1.65625;
		font-weight: bold;
		color: #fff;
		margin-bottom: 33px;
		@include font-size(32);
		@include mq(lp){
			margin-bottom: 12px;
			@include font-size(26);
		}
		@include mq(xl){
			margin-bottom: 12px;
			@include font-size(22);
		}
		@include mq(){
			margin-bottom: 22px;
			line-height: 1.423;
			@include font-size(26);
		}
		@include mq(xs){
			@include font-size(21);
		}
		&--black{
			color: #000;
		}
	}
	&__03{
		>span{
			display: block;
			color: #000;
		}
		.c-ft{
			&--jp{
				font-weight: 500;
				line-height: 1;
				margin-bottom: 38px;
				@include flex(0,center,wrap);
				// @include font-size(72);
				font-size: clamp(3.8rem, 2.933rem + 2.222vw, 7.2rem);
				@include mq(xl){
					margin-bottom: 20px;
					// @include font-size(50);
				}
				@include mq(){
					margin-bottom: 25px;
					// @include font-size(38);
				}
				@include mq(xs){
					@include font-size(30);
				}
				span{
					display: inline-block;
					font-weight: 500;
					margin-left: 55px;
					@include font-size(32);
					@include mq(xl){
						margin-left: 30px;
						@include font-size(28);
					}
					@include mq(){
						margin-top: 18px;
						margin-bottom: -5px;
						width: 100%;
						margin-left: 0;
						display: block;
						@include font-size(26);
					}
					@include mq(xs){
						@include font-size(20);
					}
				}
			}
			&--en{
				letter-spacing: 1px;
				font-weight: 600;
				@include font-size(18);
				@include mq(xl){
					@include font-size(16);
				}
				@include mq(xs){
					@include font-size(12);
				}
			}
		}
	}
	&__04{
		color: #000;
		font-weight: 600;
		margin-bottom: 13px;
		@include font-size(29);
		@include mq(xl){
			@include font-size(22);
		}
		@include mq(){
			margin-bottom: 10px;
			@include font-size(22);
		}
		@include mq(xs){
			@include font-size(18);
		}
		span{
			font-weight: 500;
			@include font-size(16);
			@include mq(xl){
				@include font-size(14);
			}
		}
	}
	&__05{
		font-weight: bold;
		margin-bottom: 24px;
		@include font-size(21);
		@include mq(xl){
			margin-bottom: 15px;
			@include font-size(18);
		}
		@include mq(){
			margin-bottom: 17px;
			line-height: 1.778;
		}
		@include mq(xs){
			@include font-size(16);
		}
	}
	&__06{
		font-weight: bold;
		margin-bottom: 10px;
		line-height: 1.6111;
		@include font-size(18);
		@include mq(xl){
			margin-bottom: 15px;
			@include font-size(16);
		}
		@include mq(){
			margin-bottom: 15px;
			line-height: 1.778;
		}
	}
}

//btn
.c-btn{
	&__01{
		display: inline-block;
		padding-right: 65px;
		color: #fff;
		position: relative;
		@include mq(){
			padding-right: 47px;
		}
		span{
			padding-bottom: 9px;
			display: inline-block;
			font-weight: 500;
			position: relative;
			@include font-size(18);
			@include p-before(100%,1px){
				border-bottom: 1px solid #fff;
				bottom: 0;
				right: 0;
				transition: all 0.3s;
			}
			@include p-after(0,1px){
				border-bottom: 1px solid #fff;
				bottom: 0;
				left: 0;
				transition: all 0.3s;
			}
			@include mq(){
				padding-bottom: 8px;
				line-height: 1.2;
				font-style: normal;
				@include font-size(14);
			}
		}
		&--small{
			padding-right: 85px;
			@include mq(){
				padding-right: 45px;
				max-width: 189px;
				margin-left: auto;
				display: block;
			}
			span{
				// font-family: $font-en;
				font-style: normal;
				@include font-size(16);
				@include mq(){
					@include font-size(14);
				}
			}
		}
		.c-arrow{
			width: 40px;
			height: 40px;
			position: absolute;
			background: #fff;
			border-radius: 50%;
			top: 50%;
			right: 0;
			overflow: hidden;
			transition: all 0.3s;
			transform: translateY(-50%);
			@include mq(){
				width: 36px;
				height: 36px;
			}
			@include p-before(14px,11px){
				background: url('../img/index/arrow02.svg') center no-repeat;
				background-size: contain;
				right: 13px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				transition: all 0.3s;
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
			@include p-after(14px,11px){
				background: url('../img/index/arrow.svg') center no-repeat;
				background-size: contain;
				left: -20px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
		}
		@include mq(md-min){
			&:hover{
				opacity: 1;
				span{
					&::before{
						width: 0;
						transition: all 0.3s;
					}
					&::after{
						width: 100%;
						transition: all 0.3s;
						transition-delay: 0.3s;
					}
				}
				.c-arrow{
					background: rgba(13, 38, 65, .9);
					transition: all 0.3s;
					&::before{
						opacity: 0;
						transition: all 0.3s;
					}
					&::after{
						left: 13px;
						transition: all 0.3s;
					}
				}
			}
		}
		&--black{
			color: #000;
			padding-right: 45px;
			@include mq(){
				padding-right: 32px;
				max-width: max-content;
				display: inline-block;
				line-height: 1.3;
			}
			span{
				&::before,&::after{
					background: #000;
				}
			}
			.c-arrow{
				top: 37%;
				@include mq(){
					background: none;
				}
				&::after{
					background: url('../img/index/arrow02.svg') center no-repeat;
					background-size: contain;
				}
			}
			@include mq(md-min){
				&:hover{
					.c-arrow{
						background: none;
					}
				}
			}
		}
		&--border{
			padding-right: 64px;
			@include mq(){
				padding-right: 48px;
			}
			.c-arrow{
				top: 52%;
				border: 1px solid #0A1F36;
				@include mq(){
					top: 48%;
				}
			}
		}
	}
	&__02{
		color: #fff;
		background: #BA915B;
		border-radius: 4px;
		padding: 10px 24px;
		@include flex(0,center);
		font-weight: 600;
		max-width: 320px;
		width: 100%;
		height: 72px;
		position: relative;
		@include font-size(18);
		border: none;
		cursor: pointer;
		@include mq(xl){
			max-width: 280px;
			height: 60px;
			@include font-size(16);
		}
		@include mq(){
			max-width: 358px;
			margin: 0 auto;
			height: 68px;
			padding: 10px 15px;
			@include font-size(14);
		}
		.c-arrow{
			position: absolute;
			width: 14px;
			right: 24px;
			top: 50%;
			overflow: hidden;
			height: 12px;
			transform: translateY(-50%);
			@include mq(){
				width: 12px;
				height: 10px;
				right: 16px;
			}
			&::after,&::before{
				content: '';
				position: absolute;
				width: 14px;
				height: 11px;
				background: url('../img/index/arrow.svg') center no-repeat;
				background-size: contain;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
			&::before{
				left: -20px;
				opacity: 0;
				transition: all 0;
			}
		}
		&--center{
			margin: 0 auto;
		}
		&--left{
			margin-left: 0;
			margin-right: auto;
		}
		@include mq(md-min){
			&:hover{
				opacity: 1;
				background: #af854e;
				.c-arrow{
					&::after{
						opacity: 0;
						transition: all 0.3s;
					}
					&::before{
						opacity: 1;
						left: 0;
						transition: all 0.3s;
						transition-delay: 0.3s;
					}
				}
			}
		}
		&--link{
			position: relative;
			@include p-after(13px,10px){
				background: url('../img/common/icon-link-white.svg') center no-repeat;
				background-size: contain;
				right: 24px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				@include mq(){
					right: 17px;
				}
			}
		}
		&--back{
			text-align: center;
			padding: 10px 30px;
			justify-content: center;
			.c-arrow{
				transform: scaleX(-1) translateY(-50%);
				right: auto;
				left: 24px;
				@include mq(){
					left: 16px;
				}
			}
		}
		&--gray{
			background: #888888;
			@include mq(md-min){
				&:hover{
					opacity: 1;
					background: #888888;
					.c-arrow{
						&::after{
							opacity: 0;
							transition: all 0.3s;
						}
						&::before{
							opacity: 1;
							left: 0;
							transition: all 0.3s;
							transition-delay: 0.3s;
						}
					}
				}
			}
		}
		&--lg{
			max-width: 400px;
		}
	}
	&__03{
		display: inline-block;
		color: #000;
		font-weight: 500;
		font-family: $font-en;
		@include font-size(14);
		line-height: 1.4;
		padding-right: 30px;
		position: relative;
		@include p-after(14px,20px){
			background: url('../img/index/arrow04.svg') center no-repeat;
			background-size: contain;
			right: 3px;
			top: 37%;
			transition: all 0.3s;
			transform: translateY(-50%);
		}
		>span{
			text-decoration: underline;
			display: inline-block;
			@include mq(){
				text-decoration: none;
				padding-bottom: 2px;
				border-bottom: 1px solid #000;
			}
			span{
				font-family: $font-jp;
			}
		}
		@include mq(){
			padding-right: 21px;
		}
		&--lg{
			@include font-size(16);
			@include mq(){
				@include font-size(14);
			}
		}
		&--shop{
			border-bottom: none !important;
			>span{
				@include p-before(100%,1px){
					background: #000;
					bottom: 0;
					right: 0;
					transition: all 0.3s;
				}
				@include p-after(0,1px){
					background: #000;
					bottom: 0;
					left: 0;
					transition: all 0.3s;
				} 
			}
			&:hover{
				>span{
					&::before{
						width: 0;
						transition: all .3s;
					}
					&::after{
						width: 100%;
						transition: all .3s;
						transition-delay: .3s;
					}
				}
			}
		}
		@include mq(md-min){
			&:hover{
				&::after{
					transition: all 0.3s;
					right: -4px;
				}
			}
		}
	}
	&__04{
		position: relative;
		padding-bottom: 10px;
		padding-right: 30px;
		display: block;
		color: #000;
		@include font-size(16);
		@include mq(){
			padding-bottom: 6px;
			@include font-size(14);
		}
		@include p-before(100%,1px){
			border-bottom: 1px solid #000;
			bottom: 0;
			right: 0;
			transition: all 0.3s;
		}
		@include p-after(0,1px){
			border-bottom: 1px solid #000;
			bottom: 0;
			left: 0;
			transition: all 0.3s;
		}
		.c-arrow{
			position: absolute;
			width: 14px;
			right: 0;
			top: 50%;
			overflow: hidden;
			height: 12px;
			transform: translateY(-50%);
			@include mq(){
				width: 12px;
				height: 10px;
				right: 0;
			}
			&::after,&::before{
				content: '';
				position: absolute;
				width: 14px;
				height: 11px;
				background: url('../img/index/arrow02.svg') center no-repeat;
				background-size: contain;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
			&::before{
				left: -20px;
				opacity: 0;
				transition: all 0;
			}
		}
		@include mq(md-min){
			&:hover{
				&::before{
					width: 0;
					transition: all 0.3s;
				}
				&::after{
					width: 100%;
					transition: all 0.3s;
					transition-delay: 0.3s;
				}
				.c-arrow{
					&::after{
						opacity: 0;
						transition: all 0.3s;
					}
					&::before{
						opacity: 1;
						left: 0;
						transition: all 0.3s;
						transition-delay: 0.3s;
					}
				}
			}
		}
	}
	&__05{
		color: #000;
		text-decoration: underline;
		@include font-size(16);
		display: inline-block;
	}
	&__06{
		// font-family: $font-en;
		position: relative;
		display: inline-block;
		padding-right: 30px;
		color: #000;
		@include font-size(16);
		@include p-after(15px,12px){
			background: url('../img/8th_sea_oyster/for_restaurants/icon-down.svg') center no-repeat;
			background-size: contain;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
		}
		span{
			display: inline-block;
			padding-bottom: 7px;
			border-bottom: 1px solid #000;
		}
	}
	&__tel{
		display: inline-block;
		padding-left: 20px;
		color: #000;
		font-weight: 600;
		position: relative;
		@include font-size(18);
		@include mq(){
			@include font-size(16);
		}
		@include p-before(14px,14px){
			background: url('../img/company/icon-tel.svg') center no-repeat;
			background-size: contain;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}


