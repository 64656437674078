@use 'vars' as *;

.terms-page{
	padding: 120px 0;
	@include mq{
		padding: 50px 0;
	}
	&__dl{
		margin-bottom: 30px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__dt{
		margin-bottom: 4px;
	}
	&__dd{
		ol{
			&:not(.dots-list){
				list-style: decimal;
				margin-top: 16px;
				margin-left: 27px;
				li{
					list-style: decimal;
					margin-bottom: 2px;
				}
			}
		}
		dl{
			margin-bottom: 30px;
			@include mq{
				margin-bottom: 20px;
			}
			dt{
				margin-bottom: 4px;
			}
		}
		&--flex{
			@include flex(0,0,wrap);
			gap: 0 30px;
		}
		&-item{
			width: calc(50% - 15px);
			@include mq(sm){
				width: 100%;
			}
		}
	}
}

.dots-list{
	li{
		position: relative;
		padding-left: 20px;
		margin-bottom: 5px;
		line-height: 1.9;
		&:last-of-type{
			margin-bottom: 0;
		}
		@include p-before(5px, 5px){
			background: #000;
			border-radius: 50%;
			top: 14px;
			left: 3px;
			@include mq{
				top: 9px;
			}
		}
	}
}