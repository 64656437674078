@use "vars" as *;

.mv-page{
	padding: 155px 0 78px;
	border-bottom: 1px solid #DBDAD2;
	@include mq(xl){
		padding: 100px 0 60px;
	}
	@include mq(){
		padding: 68px 0 60px;
	}
}

.sec-company{
    padding: 124px 0 120px;
	border-bottom: 1px solid #DBDAD2;
	@include mq(){
		padding: 52px 0 58px;
	}
}

.company-option{
	border-top: 1px solid #DBDAD2;
	&__dl{
		@include flex();
		padding: 32px 0 36px;
		border-bottom: 1px solid #DBDAD2;
		@include mq(){
			flex-wrap: wrap;
			padding: 24px 0 26px;
		}
	}
	&__dt{
		width: 195px;
		padding-right: 10px;
		font-weight: 600;
		@include mq(){
			width: 100%;
			padding-right: 0;
			margin-bottom: 2px;
		}
	}
	&__dd{
		width: calc(100% - 195px);
		p{
			margin-bottom: 24px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		@include mq(){
			width: 100%;
		}
		&-time{
			&-item{
				@include flex();
				span{
					&:nth-child(1){
						width: 66px;
						@include mq(){
							width: 57px;
						}
					}
					&:nth-child(2){
						width: calc(100% - 66px);
						@include mq(){
							width: calc(100% - 57px);
						}
					}
				}
			}
		}
		&-label{
			@include flex(0,0,wrap);
			gap: 8px;
			margin-bottom: 32px;
			@include mq{
				margin-bottom: 30px;
				margin-top: 10px;
			}
			li{
				display: inline-block;
				border: 1px solid;
				min-width: 120px;
				height: 28px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				@include font-size(12);
				font-weight: 500;
				// padding: 6px 30px;
				padding: 6px 14px;
				border-radius: 15px;
				@include mq{
					min-width: 114px;
				}
			}
		}
		.c-btn__03{
			border-bottom: 1px solid #000;
			padding-bottom: 8px;
			text-decoration: none;
			@include mq{
				border-bottom: none;
				padding-bottom: 0;
			}
			>span{
				text-decoration: none;
			}
		}
	}
}

.sec-business{
	padding: 119px 0;
	@include mq(){
		padding: 52px 0 60px;
	}
}

.business-block{
	margin-bottom: 71px;
	@include mq(){
		margin-bottom: 49px;
	}
}

.business-list{
	margin-bottom: 50px;
	@include mq(){
		margin-bottom: 33px;
	}
	&__item{
		@include flex(0,start);
		margin-bottom: 61px;
		padding-bottom: 62px;
		border-bottom: 1px solid #DBDAD2;
		@include mq(){
			flex-wrap: wrap;
			margin-bottom: 32px;
			padding-bottom: 42px;
		}
	}
	&__ct{
		padding-right: 63px;
		width: calc(100% - 360px);
		margin-top: -4px;
		@include mq(xl){
			margin-top: 0;
			padding-right: 25px;
			width: calc(100% - 300px);
		}
		@include mq(){
			width: 100%;
			padding-right: 0;
			margin-bottom: 28px;
		}
	}
	&__ttl{
		margin-bottom: 19px;
		@include mq(){
			margin-bottom: 17px;
		}
	}
	&__btn{
		@include flex(0,0,wrap);
	    gap: 10px 33px;
		margin-top: 34px;
		@include mq(){
			margin-top: 22px;
			gap: 21px 30px;
		}
	}
	&__img{
		@include aspect-ratio(360px,240px);
		border-radius: 8px;
		overflow: hidden;
	}
	&__r{
		width: 360px;
		@include mq(xl){
			width: 300px;
		}
		@include mq(){
			width: 100%;
		}
	}
}

.sec-patents{
	padding: 123px 0;
	background: #F3F3F3;
	@include mq(){
		padding: 52px 0 61px;
	}
}

.patents-block{
    margin-bottom: 107px;
	@include mq(){
		margin-bottom: 48px;
	}
}

.patents-list{
	&__item{
		&:not(:last-child){
			margin-bottom: 111px;
			@include mq(){
				margin-bottom: 56px;
			}
		}
	}
	&__pro{
		@include flex();
		margin: 0 -56px;
		@include mq(xl){
			margin: 0 -20px;
		}
		@include mq(){
			margin: 0;
			gap: 20px 0;
			flex-wrap: wrap;
		}
		&-item{
			width: 50%;
			padding: 0 56px;
			@include mq(xl){
				padding: 0 20px;
			}
			@include mq(){
				width: 100%;
				padding: 0;
			}
		}
		&-box{
			background: #fff;
			border-radius: 16px;
			padding: 71px 79px 75px;
			@include mq(xl){
				padding: 50px 30px;
			}
			@include mq(){
				padding: 39px 16px;
			}
			.c-txt__01{
				margin-bottom: 15px;
				@include mq(){
					margin-bottom: 22px;
				}
			}
		}
		&-img{
			@include aspect-ratio(320px,436px);
			max-width: 320px;
			width: 100%;
			margin: 0 auto 28px;
			display: block;
			@include mq(){
				width: 54.1%;
				margin: 0 auto 27px;
			}
		}
		&-ttl{
			color: #000;
			font-weight: 600;
			margin-bottom: 14px;
			@include font-size(21);
			@include mq(){
				margin-bottom: 12px;
				line-height: 1.555;
				@include font-size(18);
			}
		}
	}
	&__table{
		@include flex();
		margin: 0 -56px;
		@include mq(xl){
			margin: 0 -20px;
		}
		@include mq(){
			margin: 0;
			flex-wrap: wrap;
		}
		&-item{
			width: 50%;
			padding: 0 56px;
			@include mq(xl){
				padding: 0 20px;
			}
			@include mq(){
				padding: 0;
				width: 100%;
			}
		}
		&-dl{
			padding: 34px 0;
			border-bottom: 1px solid #DBDAD2;
			@include flex();
			@include mq(){
				padding: 23.5px 0;
			}
			&:nth-child(1){
				dt,dd{
					font-weight: 600;
				}
			}
		}
		&-dt{
			width: 255px;
			@include mq(){
				width: 233px;
			}
		}
		&-dd{
			width: calc(100% - 255px);
			@include mq(){
				width: calc(100% - 233px);
			}
		}
	}
}