@use "vars" as *;

.mv-shop {
	position: relative;

	@include mq(md-min) {
		.sec-breadcrumb {
			position: absolute;
			top: 220px;
			max-width: 1920px;
			left: 50%;
			z-index: 2;
			width: 100%;
			transform: translateX(-50%);

			a,
			span {
				color: #fff;

				&::after {
					background: #fff;
				}
			}
		}
	}

	&__img {
		.slick-track {
			display: flex !important;
		}

		.slick-slide {
			height: inherit !important;
		}

		@include mq() {
			margin-top: 59px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include mq(md-min){
				max-height: 100vh;
			}
			@include mq() {
				object-fit: cover;
				height: 128.205vw;
			}
		}

		// &-thumb{
		// 	@include aspect-ratio(1280, 800);
		// }
	}
}

.sec-detail {
	padding: 113px 0 119px;

	@include mq(xl) {
		padding: 90px 0;
	}

	@include mq() {
		padding: 58px 0 61px;
	}

	a[href^=tel] {
		pointer-events: auto;
	}
}

.detail-block {
	margin-bottom: 103px;

	@include mq() {
		margin-bottom: 51px;
	}

	&__btn {
		@include flex(0, 0, wrap);
		gap: 10px 16px;
		margin-bottom: 41px;

		@include mq() {
			gap: 24px 16px;
			margin-bottom: 34px;
		}

		&-item {
			width: 320px;

			@include mq(lp) {
				width: 280px;
			}

			@include mq() {
				width: 100%;
			}
		}
	}

	&__flex {
		@include flex(0, start);

		@include mq() {
			display: block;
		}

		.c-btn__03 {
			margin-right: 43px;
			padding-right: 41px;

			@include mq() {
				padding-right: 26px;
			}

			&::after {
				top: 29%;
			}

			@include mq() {
				margin-right: 0;
				margin-bottom: 21px;
			}

			>span {
				font-weight: normal;

				@include mq(md-min) {
					border-bottom: 1px solid black;
					padding-bottom: 8px;
					text-decoration: none;
				}

				@include font-size(16);

				@include mq() {
					@include font-size(14);
				}
			}
		}

		.list-tags {}
	}
}

@include mq(md-min) {
	.company-option--02 {
		.company-option__dl {
			.company-option__dt {
				width: 260px;
			}

			.company-option__dd {
				width: calc(100% - 260px);
			}
		}
	}
}

.menu-food {
	margin: 118px -24px 0;
	@include flex();

	@include mq(xl) {
		margin: 80px -10px 0;
	}

	@include mq() {
		margin: 60px 0 0;
		flex-wrap: wrap;
		gap: 24px 0;
	}

	&__item {
		padding: 0 24px;
		width: 33.33%;

		@include mq(xl) {
			padding: 0 10px;
		}

		@include mq() {
			padding: 0;
			width: 100%;
		}

		a {
			border-radius: 16px;
			overflow: hidden;
			display: block;
			position: relative;

			@include p-before(100%, 100%) {
				background: rgba(#0A1F36, 0.7);
				top: 0;
				left: 0;
				z-index: 1;
				transition: all 0.3s;
			}

			@include mq(md-min) {
				&:hover {
					opacity: 1;

					.menu-food__img {
						img {
							transform: scale(1.1);
							transition: all 0.5s;
						}
					}
				}
			}
		}

		&--02 {
			a {
				&::before {
					background: rgba(#BA915B, 0.3);
					transition: all 0.3s;
				}
			}

			.menu-food__link-ttl {
				span {
					&::after {
						background: url('../img/shop/icon-01.svg') center no-repeat;
						background-size: contain;
						transition: all 0.3s;
					}
				}
			}
		}
	}

	&__img {
		@include aspect-ratio(448px, 320px);

		@include mq() {
			&::before {
				padding-top: 48.2%;
			}
		}

		img {
			transition: all 0.5s;
		}
	}

	&__link {
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		bottom: 0;
		padding: 34px 70px 34px 40px;
		color: #fff;
		z-index: 2;

		@include mq(lp) {
			padding: 30px 50px 20px 20px;
		}

		@include mq(xl) {
			padding: 20px 50px 16px 15px;
		}

		@include p-after(40px, 40px) {
			background: #fff;
			border-radius: 50%;
			right: 31px;
			top: 64%;
			transform: translateY(-50%);

			@include mq(xl) {
				right: 11px;
				width: 30px;
				height: 30px;
			}

			@include mq() {
				width: 34px;
				height: 34px;
				right: 16px;
			}
		}

		@include p-before(13px, 10px) {
			background: url('../img/common/icon-link.svg') center no-repeat;
			background-size: contain;
			right: 46px;
			top: 64%;
			transform: translateY(-50%);
			z-index: 2;

			@include mq(xl) {
				right: 21px;
				width: 10px;
			}

			@include mq() {
				right: 28px;
			}
		}

		&-ttl {
			font-weight: 600;
			margin-bottom: 15px;
			letter-spacing: 0.5px;
			@include font-size(29);

			@include mq(lp) {
				margin-bottom: 10px;
				@include font-size(24);
			}

			@include mq(xl) {
				@include font-size(20);
			}

			@include mq() {
				margin-bottom: 5px;
				@include font-size(22);
			}

			span {
				transition: all 0.3s;
				padding-right: 40px;
				position: relative;

				@include mq(xl) {
					padding-right: 30px;
				}

				@include p-after(24px, 24px) {
					background: url('../img/shop/icon-02.svg') center no-repeat;
					background-size: contain;
					top: 54%;
					transform: translateY(-50%);
					right: 0;

					@include mq(xl) {
						width: 18px;
						height: 18px;
					}

					@include mq() {
						width: 22px;
						height: 22px;
					}
				}
			}
		}

		p {
			font-weight: 500;
			@include font-size(16);

			@include mq(xl) {
				@include font-size(14);
			}
		}
	}
}

.sec-fair {
	padding: 132px 0 78px;
	background: #F3F3F3;

	@include mq(xl) {
		padding: 70px 0 50px;
	}

	@include mq() {
		padding: 57px 0 60px;
	}

	.c-ttl__01 {
		margin-bottom: 72px;

		@include mq(xl) {
			margin-bottom: 35px;
		}

		@include mq() {
			margin-bottom: 27px;
		}

		.c-ft--en {
			margin-right: 27px;

			@include mq() {
				margin-right: 0;
				margin-bottom: -4px;
			}
		}
	}
}

.fair-flex {
	@include flex();
	gap: 0 112px;

	@include mq(xl) {
		flex-wrap: wrap;
	}

	&__left {
		//width: 430px;
		width: 320px;

		//@include mq(mac){
		//	width: 300px;
		//}
		//@include mq(xl){
		//	width: 100%;
		//}
		@include mq(xl) {
			width: 100%;
			margin-bottom: 24px;
			padding-right: 20px;
		}

		.c-txt__01 {
			margin-bottom: 50px;
		}
	}

	&__right {
		//width: calc(100% - 430px);
		width: calc(100% - 432px);

		//@include mq(mac){
		//	width: calc(100% - 300px);
		//}
		@include mq(xl) {
			width: 100%;
		}
	}
}

.tab-menu {
	margin-bottom: 71px;

	@include mq(xl) {
		margin-bottom: 35px;
		gap: 10px 35px;
		@include flex(center, center);
	}

	@include mq() {
		gap: 10px 30px;
		flex-wrap: wrap;
		justify-content: flex-start;
		max-width: 500px;
		margin: 0 auto;
	}

	&__item {
		&:not(:last-child) {
			margin-bottom: 24px;

			@include mq(xl) {
				margin-bottom: 0;
			}
		}

		span {
			display: inline-block;
			padding: 13px 0;
			color: #000;
			font-weight: 500;
			cursor: pointer;
			@include font-size(16);
			border-radius: 30px;
			transition: .3s;

			@include mq() {
				padding: 11px 0;
				@include font-size(14);
			}
		}

		&:hover {
			span {
				padding-left: 24px;
				padding-right: 24px;
				color: #fff;
				background: #0D2641;

				@include mq() {
					margin-right: -15px;
				}
			}
		}

		&.active {
			margin-bottom: 27px;

			@include mq(xl) {
				margin-bottom: 0;
			}

			span {
				color: #fff;
				background: #0D2641;
				padding: 13px 24px;

				@include mq() {
					margin-right: -15px;
					padding: 11px 24px;
				}
			}
		}
	}
}

.tab-content {
	&__item {
		opacity: 0;
		visibility: hidden;
		display: none;
		transition: all .2s ease-in;

		&.active {
			opacity: 1;
			display: block;
			visibility: visible;
		}
	}

}

.fair-flex {
	&__posts {
		@include mq() {
			margin-bottom: 33px;
		}

		&-item {
			padding-bottom: 39px;
			margin-bottom: 41px;
			border-bottom: 1px solid #DBDAD2;

			@include mq() {
				padding-bottom: 16px;
				margin-bottom: 24px;
			}

			a {
				@include flex(0, center);
				padding-right: 120px;
				position: relative;

				@include mq(lp) {
					padding-right: 40px;
				}

				@include mq() {
					padding-right: 0;
					flex-wrap: wrap;
				}

				@include p-before(14px, 11px) {
					background: url('../img/index/arrow02.svg') center no-repeat;
					background-size: contain;
					right: 40px;
					top: 50%;
					transition: all 0.3s;
					transform: translateY(-50%);

					@include mq(lp) {
						right: 10px;
					}

					@include mq() {
						display: none;
					}
				}

				@include mq(md-min) {
					&:hover {
						&::before {
							transition: all 0.3s;
							right: 30px;

							@include mq(lp) {
								right: 0;
							}
						}
					}
				}

				&:hover {
					h3 {
						&.c-txt__01 {
							background-position: 0 100%;
							background-size: 100% 1px;
						}
					}
				}
			}
		}

		&-img {
			width: 192px;
			@include aspect-ratio(192px, 144px);

			@include mq() {
				width: 36%;
			}
		}

		&-meta {
			width: 215px;
			padding-right: 20px;
			padding-left: 35px;
			padding-top: 5px;

			@include mq(lp) {
				padding-right: 15px;
				padding-left: 15px;
				width: 148px;
			}

			@include mq() {
				width: 64%;
				padding-left: 15px;
				padding-right: 20px;
				position: relative;

				@include p-before(14px, 11px) {
					background: url('../img/index/arrow02.svg') center no-repeat;
					background-size: contain;
					right: 0;
					top: 57%;
					transition: all 0.3s;
					transform: translateY(-50%);
				}

				.list-tags {
					gap: 7px;
				}
			}
		}

		&-date {
			display: block;
			color: #000000;
			font-family: $font-en;
			margin-bottom: 10px;
			@include font-size(16);

			@include mq() {
				margin-bottom: 6px;
				@include font-size(14);
			}
		}

		&-ct {
			width: calc(100% - 407px);
			color: #000;

			@include mq(lp) {
				width: calc(100% - 340px);
			}

			@include mq() {
				width: 100%;
				margin-top: 8px;
			}

			.c-txt__01 {
				margin-bottom: 7px;
				color: #0A1F36;

				@include mq() {
					margin-bottom: 10px;
				}

				span {
					font-weight: 500;

					@include mq() {
						width: 100%;
						display: block;
						margin-bottom: 5px;
					}
				}
			}

			h3 {
				color: #000 !important;
				display: inline-block;

				&.c-txt__01 {
					position: relative;
					background: -webkit-gradient(linear, left top, left bottom, from(#333), to(#333)) 100% 100% / 0 1px no-repeat;
					background: linear-gradient(#333, #333) 100% 100% / 0 1px no-repeat;
					-webkit-transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
					transition: background-size 0.6s cubic-bezier(0.25, 1, 0.5, 1) 0s;
					will-change: background-size;
				}
			}
		}
	}
}

.banner-mw {
	overflow: hidden;
	@include mq() {
		margin-top: 80px;
		height: 192px;
		position: relative;
	}
}

.banner-loop {
	padding: 120px 0 0;
	@include flex(0, 0, wrap);

	@include mq() {
		padding: 0;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		white-space: nowrap;
		-webkit-animation: slider-move 50s linear infinite;
		animation: slider-move 50s linear infinite;

		&:hover {
			-webkit-animation-play-state: paused;
			animation-play-state: paused;
		}
	}

	&__item {
		width: 33.33%;
		@include aspect-ratio(640px, 400px);

		@include mq() {
			width: 288px;
			@include aspect-ratio(288px, 192px);
		}
	}
}

.sec-instagram {
	padding: 120px 0;
	overflow: hidden;
	@include mq() {
		padding: 80px 0 59px;
	}
}

.instagram-img {
	margin-bottom: 55px;

	@include mq() {
		margin-bottom: 35px;
	}

	img {
		width: 100%;
	}
}

.menu-link {
	margin: 0 -20px;
	gap: 51px 0;
	@include flex(0, 0, wrap);

	@include mq() {
		gap: 24.5px 0;
	}

	&__item {
		padding: 0 20px;
		width: calc(100%/6);

		@include mq(xl) {
			width: calc(100%/4);
		}

		@include mq() {
			width: 33.33%;
		}

		@include mq(sm) {
			width: 50%;
		}
	}
}