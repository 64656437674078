@use "vars" as *;


body {
	color: #000;
	font-family: $font-jp;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
	@include mq(){
		// overflow-x: hidden;
	}
}

a{
	text-decoration: none;
	@include smooth-transition;
	&:hover{
		opacity: 0.7;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
	
	@include mq(){
		-webkit-tap-highlight-color: transparent;
	}
}






