@use 'vars' as *;
.page-opc_index{
	.content .inner {
		width: 100%;
		max-width: 1432px;
	}
}

.opc-top{
	padding-right: 110px;
	@include mq(xl){
		padding-right: 8.835vw;
	}
	@include mq{
		padding-right: 0;
	}
	.single-block{
		&__img{
			img{
				min-height: 614px;
				object-fit: cover;
			}
			@include mq(tb){
				img{
					min-height: 550px;
				}
			}
			@include mq{
				width: 100%;
				img{
					min-height: 0;
				}
			}
		}
		&__ct{
			.c-ttl__02--black{
				@include mq{
					margin-bottom: 30px;
				}
			}
			.c-txt__01{
				margin-bottom: 50px;
				@include mq{
					margin-bottom: 30px;
				}
			}
			.c-btn__02{
				@include mq{
					margin: 0;
				}
			}
		}
	}
}

.opc-point{
	padding: min(6.771vw, 130px) 5vw min(6.25vw, 120px);
	@include mq{
		padding: 57px 0;
	}
	.c-ttl__01{
		margin-bottom: min(3.802vw, 73px);
		@include mq{
			margin-bottom: 30px;
		}
	}
	&__intro{
		margin-bottom: 63px;
	}
	.guide-list{
		max-width: 1160px;
		margin: 0 auto min(5.938vw, 114px);
		@include mq{
			margin-bottom: 60px;
		}
		&__item{
			align-items: center;
			padding-bottom: 40px;
		}
	}

	.eoyster{
		max-width: 1160px;
		margin: 0 auto;
		border-radius: 16px;
		.eoyster-box{
			@include mq{
				top: 0;
				transform: none;
			}
		}
		.eoyster-bg{
			img{
				min-height: 360px;
				@include mq{
					min-height: 520px;
				}
			}
		}
	}
}

.opc-money{
	margin-bottom: 40px;
	&__ttl{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 60px;
		}
	}
	&__ttlsub{
		margin-bottom: 30px;
		@include mq{
			@include font-size(18);
			margin-bottom: 17px;
		}
	}
	&__desc{
		margin-bottom: 40px;
		@include mq{
			margin-bottom: 25px;
		}
	}
	&__content{
		background: #F3F3F3;
		border-radius: 8px;
		padding: 48px min(5vw, 96px) min(3.125vw, 60px);
		@include mq{
			padding: 32px 15px 36px;
		}
		&-dl{
			padding-bottom: 34px;
			margin-bottom: 28px;
			border-bottom: 1px solid #DBDAD2;
			@include mq{
				padding-bottom: 25px;
				margin-bottom: 20px;
			}
			&:last-of-type{
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: none;
			}
		}
		&-dt{
			margin-bottom: 10px;
			@include mq{
				margin-bottom: 18px;
			}
		}
		&-dd{
			ul{
				li{
					position: relative;
					margin-bottom: 8px;
					padding-left: 17px;
					@include mq{
						margin-bottom: 12px;
					}
					@include p-before(8px, 8px){
						background: #0A1F36;
						border-radius: 50%;
						top: 12px;
						left: 0;
					}
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
			a{
				text-decoration: underline;
				color: #000;
			}
		}
	}
	&__wp{
		max-width: 1160px;
		margin: 0 auto;
	}
}


.opc-guide{
	background: #F3F3F3;
	@include mq(md-min){
		padding: min(7.083vw, 136px) 5vw 70px;
	}
	@include mq{
		padding: 58px 0 60px;
	}
	&--inquiry{
		@include mq(md-min){
			padding: 0 5vw;
		}
		@include mq(){
			padding: 0;
		}
		.opc-guide__block{
			padding-bottom: min(6.146vw, 118px);
			border-bottom: none;
			@include mq(tb){
				padding-bottom: 80px;
			}
			@include mq{
				padding-bottom: 60px;
			}
		}
	}
	&__ttl{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 23px;
		}
	}
	&__block{
		padding-bottom: 80px;
		border-bottom: 1px solid #DBDAD2;
		max-width: 1160px;
		margin: 0 auto 70px;
		@include mq{
			padding-bottom: 40px;
			margin-bottom: 60px;
		}
		.c-btn__02{
			@include mq{
				margin: 0;
			}
		}
		.c-txt__01{
			a{
				text-decoration: underline;
				color: #000;
				&[href^="tel"] {
					text-decoration: none;
				}
			}
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		&-ttl{
			margin-bottom: 37px;
			@include mq{
				@include font-size(18);
				margin-bottom: 16px;
			}
		}
		&-control{
			@include flex(0,0,wrap);
			gap: 25px 32px;
			li{
				max-width: 320px;
				width: 100%;
				@include mq{
					max-width: 360px;
				}
			}
		}
		&-desc{
			margin-bottom: 50px;
			@include mq{
				margin-bottom: 30px
			}
			p{
				margin-bottom: 14px;
				@include mq{
					margin-bottom: 6px;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
		&-app{
			background: #fff;
			border-radius: 8px;
			padding: 33px 0 27px;
			@include mq(md-min){
				pointer-events: none;
			}
			@include mq{
				padding: 13px 0 5px;
				position: relative;
				@include p-before(120px, 100px){
					content: "拡大できます";
					background: rgba($color: #000000, $alpha: 0.5);
					border-radius: 8px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					@include font-size(15);
					text-align: center;
					display: flex;
					align-items: flex-end;
					justify-content: center;
					padding-bottom: 10px;
				}
				@include p-after(50px, 50px){
					background: url(../img/opc/ico-zoom.svg) no-repeat top center/100%;
					top: 46%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
		&-only-card{
			@include flex(0, center,wrap);
			gap: 0 40px;
			margin-bottom: 64px;
			@include mq{
				margin-top: -10px;
				margin-bottom: 40px;
			}
			span{
				@include font-size(18);
				display: block;
				@include mq(sm){
					width: 100%;
					@include font-size(14);
					margin-bottom: 16px;
				}
			}
		}
		&-contact{
			a{
				font-weight: 600;
				@include font-size(18);
				position: relative;
				padding-left: 22px;
				margin-right: 17px;
				text-decoration: none;
				@include mq{
					padding-left: 16px;
					@include font-size(16);
				}
				@include p-before(14px, 14px){
					background: url(../img/opc/ico-phone.png) no-repeat center/100%;
					top: calc(50% - 7px);
					left: 0;
				}
			}
		}
		&-time{
			@include mq{
				display: block;
				width: 100%;
				margin-top: 9px;
			}
		}
		&-wp{
			border-radius: 8px;
			background: #fff;
			padding: 58px min(5vw, 96px) 64px;
			@include mq{
				padding: 32px 16px 40px;
			}
			.c-ttl__05{
				margin-bottom: 18px;
			}
			.c-txt__01{
				margin-bottom: 50px;
				@include mq{
					margin-bottom: 40px;
				}
			}
			table{
				width: 100%;
				&.c-txt__01{
					margin-bottom: 0;
				}
				th, td{
					text-align: center;
					border-bottom: 1px solid #DBDAD2;
					&:first-child{
						text-align: left;
						width: 22%;
						@include mq{
							width: 31.59%;
						}
					}
					&:nth-child(2){
						width: 26.2%;
						@include mq{
							width: 46%;
						}
					}
					&:nth-child(3){
						width: 35.17%;
						text-align: left;
						padding-left: 26px;
						@include mq{
							width: 22%;
							text-align: center;
							padding-left: 0;
						}
						span{
							width: 150px;
							text-align: center;
							display: block;
							@include mq{
								width: 100%;
							}
						}
					}
				}
				th{
					padding-bottom: 32px;
					@include mq{
						padding-bottom: 10px;
					}
				}
				td{
					padding: 20px 0;
					border-bottom: 1px solid #DBDAD2;
					@include mq{
						padding: 26px 0;
					}
				}
			}
		}
	}
}

.sec-offer{
	&--opc{
		background-color: #fff;
		@include mq(md-min){
			padding-left: 5vw;
			padding-right: 5vw;
		}
		@include mq{
			padding: 60px 0;
			.eoyster-box{
				top: 0;
				transform: none;
			}
		}
		.eoyster-bg img{
			@include mq{
				min-height: 650px;
			}
		}
	}
}
.app-modal{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	overflow-x: auto;
	picture{
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		margin: auto;
		width: 800px;
		object-fit: contain;
		pointer-events: none;
	}
}


.js-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	body {
		-webkit-overflow-scrolling: auto;
	}
}