@use 'vars' as *;

.page-recruit_index{
	.content-left{
		padding-bottom: 0;
		padding-top: 110px;
		@include mq{
			padding-top: 0;
		}
	}
}

.sec-recruit{
	overflow-x: hidden;
	.recruit-slider{
		&__main{
			margin-bottom: 40px;
			@include mq{
				margin-bottom: 20px;
			}
			&-item{
				@include mq{
					@include aspect-ratio(390,327);
				}
			}
		}
		&__thumb{
			&-item{
				@include aspect-ratio(48,32);
			}
		}
	}
}

.franchise-intro{
	&--recruit{
		padding-bottom: 120px;
		@include mq{
			padding-bottom: 60px;
		}
		.franchise-intro__control{
			margin-bottom: 0;
			gap: 23px 20px;
		}
	}
}

.about-work{
	background: #F3F3F3;
	padding: 130px 0 120px;
	@include mq(xl){
		padding: 80px 0;
	}
	@include mq{
		padding: 57px 0;
	}
	&__ttl{
		margin-bottom: 73px;
		@include mq{
			margin-bottom: 42px;
		}
	}
	&__dl{
		@include flex(0,0,wrap);
		border-bottom: 1px solid #DBDAD2;
		padding: 35px 0;
		@include mq{
			padding: 25px 0;
		}
		&:first-child{
			border-top: 1px solid #DBDAD2;
		}

		&:nth-child(2){
			.about-work__dd{
				p{
					margin-bottom: 22px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&__dt{
		width: 164px;
		@include mq(sm){
			width: 100%;
			margin-bottom: 7px;
		}
	}
	&__dd{
		width: calc(100% - 164px);
		@include mq(sm){
			width: 100%;
		}
		p{
			margin-bottom: 24px;
			@include mq{
				margin-bottom: 10px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			span{
				font-weight: 700;
			}
		}
	}
}

.sec-philosophy{
	padding: 105px 0 125px;
	@include mq{
		padding: 60px 0;
	}
	.content-block .c-ttl__01{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 29px;
		}
	}
	.content-block__row .c-ttl__02{
		@include mq{
			margin-bottom: 27px;
		}
	}
	.system02-list{
		margin-bottom: 57px;
		@include mq{
			margin-bottom: 35px;
		}
	}
	.c-btn__02{
		max-width: 400px;
		margin: 0 auto;
	}
}

.sec-career{
	padding: 130px 0 40px;
	background: #F3F3F3;
	@include mq{
		padding: 57px 0 67px;
	}
	.content-block{
		margin-bottom: 100px;
		@include mq{
			margin-bottom: 60px;
			&__row{
				.c-ttl__02{
					@include mq{
						margin-bottom: 25px;
					}
				}
			}
		}
	}
	.guide-list{
		&__ct{
			p{
				margin-bottom: 25px;
				&:last-of-type{
					margin-bottom: 0;
				}
				span{
					font-weight: 700;
				}
			}
		}
		&__img{
			@include mq(md-min){
				position: relative;
				top: -44px;
			}
		}
		&--number{
			.guide-list__item{
				align-items: flex-start;
				margin-bottom: 70px;
				@include mq{
					margin-bottom: 34px;
				}
				&:last-of-type{
					border-bottom: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}
	}
}

.sec-interview{
	padding: 130px 0;
	@include mq{
		padding: 60px 0;
	}
}

.interview-bnr{
	position: relative;
	margin-bottom: 72px;
	@include mq(md-min){
		@include p-before(53.41%, 100%){
			background: rgb(10,31,54);
			background: linear-gradient(90deg, rgba(10,31,54,1) 75%, rgba(10,31,54,0) 100%);
			top: 0;
			left: 0;
			z-index: 2;
		}
	}
	@include mq{
		margin-bottom: 27px;
	}
	&__slider{
		&-item{
			position: relative;
			
			@include mq{
				@include aspect-ratio(39, 24);
			}
			img{
				max-width: 1100px;
				display: block;
				margin-left: auto;
			}
		}
	}
	&__ct{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 100%;
		color: #fff;
		z-index: 3;
		@include mq{
			position: static;
			background: #202A34;
			transform: none;
			padding: 20px 0 25px;
		}
		&-ttl{
			@include mq{
				margin-bottom: 15px;
			}
		}
		&-name{
			@include font-size(29);
			margin-bottom: 10px;
			@include mq{
				@include font-size(22);
				margin-bottom: 7px;
			}
			span{
				@include font-size(16);
				@include mq{
					@include font-size(14);
				}
			}
		}
		&-bar{

		}
	}
}

.interview-content{
	height: 480px;
	overflow-y: auto;
	@include mq{
		height: 400px;
	}
	dl{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 60px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		dt{
			margin-bottom: 14px;
		}
		dd{
			p{
				margin-bottom: 30px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
}

.sec-faq{
	padding: 126px 0 116px;
	background: #F3F3F3;
	@include mq{
		padding: 58px 0 60px;
	}
	.c-ttl__01{
		margin-bottom: 60px;
		@include mq{
			margin-bottom: 28px;
		}
	}
}

.faq-block{
	margin-bottom: 110px;
	@include mq{
		margin-bottom: 60px;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
	&__ttl{
		margin-bottom: 62px;
		@include mq{
			margin-bottom: 67px;
		}
	}
	&__ct{
		max-width: 1160px;
		margin: 0 auto;
	}
	&__dl{
		padding-top: 35px;
		padding-left: 118px;
		position: relative;
		padding-bottom: 34px;
		margin-bottom: 36px;
		border-bottom: 1px solid #DBDAD2;
		&:last-child{
			margin-bottom: 0;
		}
		@include mq(tb){
			padding-top: 0;
		}
		@include mq{
			padding-left: 0;
		}
	}
	&__dt{
		margin-bottom: 18px;
		@include mq{
			padding-left: 78px;
		}
		span{
			font-family: $font-en;
			@include font-size(48);
			line-height: 1;
			font-weight: 500;
			color: #BA915B;
			position: absolute;
			top: 0;
			left: 0;
			@include mq{
				@include font-size(36);
			}
		}
	}
	&__dd{
		
	}
}


.sec-message{
	padding: 130px 0 120px;
	@include mq{
		padding: 60px 0;
	}
	.c-ttl__01{
		margin-bottom: 64px;
		@include mq{
			margin-bottom: 20px;
		}
	}
	.c-btn__02{
		margin: 0 auto;
		position: relative;
		left: -36px;
		@include mq{
			left: 0;
		}
	}
}

.message-block{
	@include flex(between,start,wrap);
	margin-bottom: 60px;
	max-width: 1296px;
	@include mq{
		margin-bottom: 35px;
	}
	&__ct{
		width: 50.9%;
		@include mq(sm){
			width: 100%;
			margin-bottom: 46px;
		}
		p{
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&-desc{
			@include mq{
				height: 320px;
				overflow-y: auto;
			}
		}
	}
	&__img{
		width: 40.43%;
		@include aspect-ratio(524, 656);
		@include mq(sm){
			width: 100%;
			@include aspect-ratio(358, 416);
		}
	}
}


.sec-entry{
	padding: 130px 0;
	background: #F3F3F3;
	@include mq{
		padding: 57px 0;
	}
	.c-ttl__01{
		margin-bottom: 66px;
		@include mq{
			margin-bottom: 19px;
		}
	}
}

.entry-intro{
	margin-bottom: 68px;
	@include mq{
		margin-bottom: 56px;
	}
}

.entry-form{
	margin-bottom: 80px;
	@include mq{
		margin-bottom: 60px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__ttl{
		color: #fff;
		background: #0D2641;
		padding: 21px 43px;
		position: relative;
		margin-bottom: 0;
		font-weight: 500;
		cursor: pointer;
		@include mq{
			@include font-size(18);
			padding: 15px 20px;
			padding-right: 50px;
			@include flex(0, center,wrap);
			min-height: 78px;
		}
		@include p-after(24px, 24px){
			background: url(../img/recruit/ico-plus.png) no-repeat center/100%;
			top: calc(50% - 12px);
			right: 40px;
			@include smooth-transition;
			@include mq{
				width: 16px;
				height: 16px;
				right: 20px;
				top: calc(50% - 8px);
			}
		}
		&.on{
			&:after{
				background: url(../img/recruit/ico-minus.png) no-repeat center/100%;
			}
		}
		span{
			@include font-size(16);
			display: block;
			margin-bottom: 14px;
			@include mq{
				@include font-size(14);
				margin-top: 10px;
				margin-bottom: 0;
				line-height: 2;
			}

		}
	}
	&__main{
		display: none;
		padding-top: 64px;
		@include mq{
			padding-top: 30px;
		}
	}
	&__dl{
		padding: 39px 0;
		@include flex(0,0,wrap);
		border-bottom: 1px solid #DBDAD2;
		@include mq{
			padding: 17px 0;
		}
		&:first-child{
			border-top: 1px solid #DBDAD2;
		}
		&--any{
			.entry-form__dt{
				&:after{
					display: none;
				}
			}
		}
		&--radio{
			padding: 24px 0;
			dt{
				padding-top: 0;
			}
		}
		&-note{
			display: block;
			@include fts(14, 28);
			color: #9D9D9D;
			margin-top: 15px;
			@include mq{
				margin-top: 8px;
			}
		}
		&--last{
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	&__dt{
		font-weight: 500;
		@include font-size(16);
		padding-top: 18px;
		width: 320px;
		position: relative;
		@include mq(tb){
			width: 100%;
			@include font-size(14);
			padding-top: 0;
			margin-bottom: 13px;
		}
		@include p-after(45px, auto){
			content: "必須";
			@include font-size(14);
			font-weight: 500;
			color: #fff;
			background: #0D2641;
			border-radius: 12px;
			top: 0;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			margin-left: 10px;
			padding: 3px 0 7px;
			@include mq{
				width: 37px;
				padding: 3px 0 5px;
				@include font-size(12);
			}
		}
		&--has-note{
			&:after{
				display: none;
			}
			span{
				&:first-child{
					position: relative;
					@include p-after(45px, 24px){
						content: "必須";
						@include font-size(14);
						font-weight: 500;
						color: #fff;
						background: #0D2641;
						border-radius: 12px;
						top: 0;
						position: relative;
						display: inline-flex;
						align-items: center;
						justify-content: center;
						line-height: 1;
						margin-left: 10px;
						padding-bottom: 3px;
						@include mq{
							width: 37px;
							height: 20px;
							@include font-size(12);
						}
					}
				}
			}
		}
	}
	&__dd{
		width: calc(100% - 320px);
		@include mq(tb){
			width: 100%;
		}
		&--age{
			@include flex(0,center);
			gap: 0 16px;
		}
		&--multiple-input{
            .wpcf7-form-control-wrap{
                display: block;
               margin-bottom: 40px;
				@include mq{
					margin-bottom: 16px;
				} 
            }
			.form-control{
				margin-bottom: 40px;
				@include mq{
					margin-bottom: 16px;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
	&__check{
		display: block;
		text-align: center;
		margin: 68px auto 56px;
		max-width: 290px;
		@include mq {
			margin: 33px auto 28px;
		}

		label {
			position: relative;
			display: inline-block;
			@include font-size(16);
			padding: 0 0 0 46px;
			cursor: pointer;
			user-select: none;
			@include mq {
				@include font-size(14);
			}
			@include mq(xs) {
				@include font-size(12);
			}

			input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				z-index: 1;
				margin: 0;
			}

			.wpcf7-list-item-label:after {
				content: "";
				position: absolute;
				display: none;
			}

			input:checked ~ .wpcf7-list-item-label:after {
				display: block;
			}

			.wpcf7-list-item-label:after {
				width: 9px;
				height: 15px;
				border: solid #000;
				border-width: 0 3px 3px 0;
				left: 10px;
				top: 5px;
				transform: rotate(45deg);
				@include mq{
					left: 8px;
					top: 2px;
				}
			}

			.wpcf7-list-item-label:before {
				content: "";
				position: absolute;
				height: 28px;
				width: 28px;
				border: 1px solid #000;
				background: #fff;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				@include mq{
					width: 26px;
					height: 26px;
				}
			}
		}
	}

	&__control{
		@include flex(0,0,wrap);
		max-width: 720px;
		margin: 0 auto;
		gap: 15px 30px;
		@include mq(sm){
			max-width: 350px;
		}
	}


	&__btn-submit{
		margin: 0 auto;
		border: none;
		cursor: pointer;
	}
	&__radio{
		@include flex(0,center);
		gap: 15px 40px;
		@include mq{
			gap: 0 20px;
			height: auto;
		}
	}
	&__select{
		@include flex(0,0,wrap);
		gap: 0 17px;
		@include mq(tb){
			gap: 0 5px;
		}
		.form-control{
			width: 200px;
			@include mq{
				width: 170px;
			}
			@include mq(sm){
				width: 114px;
			}
			@include mq(xs){
				width: 107px;
			}
		}
	}
	&__select-custom{

	}
	&__store{
		margin-bottom: 24px;
		@include mq{
			margin-bottom: 18px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		&-label{
			margin-bottom: 10px;
		}
	}
} 


.page-recruit_list_index{
	.c-ttl__04{
		@include mq{
			@include font-size(22);
		}
	} 
	.list-menu{
		@include mq{
			flex-wrap: nowrap;
			width: max-content;
			gap: 0 20px;
			&__item{
				&.active{
					a{
						white-space: nowrap;
					}
				}
			}
		}
	}
}

.page-recruit_list_detail{
	.company-option{
		max-width: 1440px;
		margin: 0 auto;
		&__dd{
			.c-btn__03{
				font-size: 1.6rem;
				margin-top: 20px;
				@include font-size(16);
				
				&:after{
					right: -8px;
					top: 35%;
				}
			}
		}
		&__dl{
			&--last{
				margin-bottom: 60px;
				@include mq{
					margin-bottom: 32px;
				}
			}
		}
	}
	.c-btn__02{
		margin: 0 auto;
	}
}

.sec-workplace{
	padding: 120px 0;
	background: #F3F3F3;
	@include mq{
		padding: 60px 0;
	}
	.content-block{
		margin-bottom: 75px;
		@include mq{
			margin-bottom: 58px;
		}
	}
	.c-ttl__04{
		margin-bottom: 72px;
		@include mq{
			margin-bottom: 57px;
		}
	}
	.guide-list--number{
		.guide-list{
			&__item{
				@include mq{
					margin-bottom: 28px;
					&:last-of-type{
						margin-bottom: 44px;
					}
				}
			}
			&__ct{
				&::before{
					@include mq{
						top: 6px;
					}
				}
				.c-ttl__05{
					@include mq{
						margin-bottom: 13px;
					}
				}
			}
		}
	}
}


.entry-confirm{
	padding: 130px 0 120px;
	background: #F3F3F3;
	@include mq{
		padding: 58px 0 60px;
	}
	&__ttl{
		margin-bottom: 65px;
		@include mq{
			margin-bottom: 30px;
		}
	}
	&__intro{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 35px;
		}
	}

	.entry-form{
		margin-bottom: 0;
		&__ttl{
			&:after{
				display: none;
			}
		}
		&__dl{
			padding: 35px 0;
			@include mq{
				padding: 15px 0;
			}
			&--last{
				margin-bottom: 43px;
				@include mq{
					margin-bottom: 20px;
				}
			}
		}
		&__dt{
			padding-top: 0;
		}
		&__main{
			display: block;
		}
	}
	.c-btn__02{
		margin: 0 auto;
	}
}


.recruit-list{
	.content-left{
		padding-bottom: 23px;
		.list-menu{
			margin-bottom: 50px;
			@include mq{
				margin-bottom: 6px;
			}
			li{
				&.active{
					a{
						padding: 12.5px 24px;
					}
				}
				a{
					padding: 13px 0;
					&:hover{
						padding: 12.5px 24px;
					}
				}
			}
		}
	}
	
	.shop-list{
		max-width: 1160px;
		margin: 0 auto;
		&__main{
			width: 100%;
			&-btn{
				position: relative;
				top: -10px;
				padding-top: 0;
				.c-btn__02:not(:last-child) {
					margin-bottom: 30px;
					@include mq{
						margin-bottom: 24px;
					}
				}
			}
			&-item{
				padding-bottom: 34px;
				margin-bottom: 50px;
				@include mq{
					padding-bottom: 30px;
					margin-bottom: 40px;
				}
			}
			&-img{
				@include mq{
					margin-bottom: 25px;
				}
				&Item{
					@include mq(sm-min){
						opacity: 1 !important;
					}
				}
			}
			&-ct{
				@include mq{
					margin-bottom: 40px;
				}
				.c-txt__01{
					@include mq{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}