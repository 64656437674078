@use 'vars' as *;

.privacy-block{
	padding: 130px 0 117px;
	border-bottom: 1px solid #DBDAD2;
	@include mq{
		padding: 50px 0;
	}
	&__ttl{
		margin-bottom: 58px;
		@include mq{
			margin-bottom: 20px;
		}
	}
	.txt-company{
		margin-bottom: 22px;
		@include mq{
			margin-bottom: 15px;
		}
	}
	ol{
		list-style: decimal;
		margin-top: 16px;
		margin-left: 27px;
		@include mq{
			margin-left: 15px;
		}
		li{
			list-style: decimal;
			margin-bottom: 2px;
		}
	}

	&:nth-child(2){
		padding: 105px 0 117px;
		@include mq{
			padding: 40px 0;
		}
		.c-ttl__02{
			margin-bottom: 52px;
			@include mq{
				margin-bottom: 30px;
			}
		}
	}
	&__content{
		&-info{
			padding: 33px 40px;
			background: #F3F3F3;
			margin-top: 30px;
			@include mq{
				padding: 15px 20px;
				margin-top: 20px;
			}
		}
		dl{
			margin-bottom: 30px;
			@include mq{
				margin-bottom: 20px;
			}
			dt{
				margin-bottom: 4px;
			}
		}

		&--flex{
			@include flex(0,0,wrap);
			gap: 0 30px;
		}
		&-item{
			width: calc(50% - 15px);
			@include mq(sm){
				width: 100%;
			}
		}
	}
}

