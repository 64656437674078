@use "vars" as *;

.page-8th_sea_oyster_e-oyster_index{
	.mv-page{
		@include mq(){
			.c-ttl__03{
				.c-ft--jp{
					line-height: 1.447;
				}
			}
		}
	}
}

.eoyster-flex{
	margin-left: calc(((100vw - 100%) / 2) * -1);
	@include flex(0,center);
	@include mq(xl){
		padding: 50px 0 0;
	}
	@include mq(){
		padding: 0;
		flex-wrap: wrap;
		margin-left: 0;
	}
	&__img{
		width: 49.75%;
		@include mq(xl){
			width: 40%;
		}
		@include mq(){
			width: calc(100% + 32px);
			margin: 0 -16px 38px;
		}
	}
	&__ct{
		width: 50.25%;
		padding-left: 112px;
		padding-top: 10px;
		@include mq(lp){
			padding-left: 25px;
			padding-top: 0;
		}
		@include mq(xl){
			width: 60%;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
		}
		.c-txt__01{
			margin-bottom: 48px;
			max-width: 665px;
			@include mq(xl){
				margin-bottom: 30px;
			}
		}
		.c-btn__02{
			max-width: 400px;
		}
	}
}

.sec-recommend{
	padding: 132px 0 70px;
	@include mq(xl){
		padding: 90px 0 50px;
	}
	@include mq(){
		padding: 57px 0 31px;
	}
}



.recommend-list{
	@include flex(0,0,wrap);
	margin: 0 -56px 48px;
	gap: 80px 0;
	@include mq(xl){
		margin: 0 -15px 40px;
	}
	@include mq(){
		margin: 0 0 28px;
		flex-wrap: wrap;
		gap: 60px 0;
	}
	&__item{
		padding: 0 56px;
		width: 50%;
		@include mq(xl){
			padding: 0 15px;
		}
		@include mq(){
			width: 100%;
			padding: 0;
		}
		.c-ttl__04{
			margin-bottom: 33px;
			@include mq(xl){
				margin-bottom: 20px;
			}
			@include mq(){
				margin-bottom: 15px;
			}
		}
		.c-ttl__05{
			margin-bottom: 18px;
			@include mq(){
				margin-bottom: 12px;
			}
		}
		.c-txt__01{
		    margin-bottom: 49px;
			@include mq(xl){
				margin-bottom: 25px;
			}
			@include mq(){
				margin-bottom: 29px;
			}
		}
		.c-btn__02{
			max-width: 400px;
		}
	}
	&__img{
		overflow: hidden;
		border-radius: 8px;
		margin-bottom: 39px;
		@include aspect-ratio(664px,456px);
		@include mq(xl){
			margin-bottom: 20px;
		}
	}
}

.sec-guide{
	padding: 132px 0 118px;
	background: #F3F3F3;
	@include mq(xl){
		padding: 90px 0;
	}
	@include mq(){
		padding: 57px 0 61px;
	}
}



.sec-how{
	padding: 131px 0 54px;
	@include mq(xl){
		padding: 90px 0 60px;
	}
	@include mq(){
		padding: 56px 0 33px;
	}
}

.guide-box{
	background: #fff;
	border-radius: 8px;
	padding: 68px 96px;
	@include mq(xl){
		padding: 50px 30px;
	}
	@include mq(){
		padding: 33px 16px;
	}
	.c-ttl__05{
		margin-bottom: 10px;
		@include mq(){
			margin-bottom: 16px;
		}
	}
	&__dots{
		&-item{
			position: relative;
			padding-left: 16px;
			@include mq(){
				padding-left: 14px;
			}
			&:not(:last-child){
				margin-bottom: 8px;
				@include mq(){
					margin-bottom: 6px;
				}
			}
			@include p-before(8px,8px){
				background: #000;
				top: 13px;
				left: 0;
				border-radius: 50%;
				@include mq(){
					width: 6px;
					height: 6px;
					top: 12px;
				}
			}
		}
	}
}