@use 'vars' as *;
.page-topics_index{
	.content-right{
		padding-top: 135px;
		@include mq(mac){
			padding-top: 80px;
		}
		@include mq{
			padding-top: 58px;
		}
		
		.list-menu{
			&__item{
				margin-bottom: 23px;
				a{
					@include font-size(16);
					line-height: 2;
				}
			}
		}
		// .list-menu__item.active a{
		// 	padding: 16px 28px;
		// 	@include mq(tb){
		// 		padding: 16px 11px;
		// 	}
		// 	@include mq{
		// 		padding: 10px 22px;
		// 	}
		// }
		// &__fixed{
		// 	@include mq{
		// 		margin-bottom: 0;
		// 	}
		// }
	}
	// .content-right .list-menu__item a{
	// 	@media (any-hover: hover) {
	// 		transition: all .3s;
	// 		&:hover{
	// 			padding: 16px 28px;
	// 			@include mq(xl){
	// 				padding: 16px 11px;
	// 			}
	// 			@include mq{
	// 				padding: 10px 22px;
	// 			}
	// 		}
	// 	}
	// }
	.content-right .list-menu{
		@include mq{
			flex-wrap: nowrap;
			white-space: nowrap;
		}
	}
	.content-left{
		padding-top: 135px;
		@include mq{
			padding-top: 11px;
		}
	}
}
.topics-page{
	padding-bottom: 60px;
	@include mq{
		padding-bottom: 34px;
	}
	&__list{
		max-width: 1296px;
		margin: 0 auto 124px;
		position: relative;
		left: 66px;
		@include mq(mac){
			left: 0;
		}
		@include mq{
			margin-bottom: 60px;
		}
		&-item{
			border-bottom: 1px solid #DBDAD2;
			padding-bottom: 40px;
			margin-bottom: 40px;
			position: relative;
			@include mq{
				padding-bottom: 24px;
				margin-bottom: 30px;
			}
			.c-arrow{
				width: 40px;
				height: 40px;
				position: absolute;
				background: #fff;
				border-radius: 50%;
				top: 50%;
				right: 0;
				overflow: hidden;
				transition: all .3s;
				transform: translateY(-50%);
				@include mq{
					right: -13px;
					top: 34px;
					transform: translateY(0);
				}
				&::before{
					content: "";
					display: inline-block;
					position: absolute;
					width: 14px;
					height: 11px;
					background: url(../img/index/arrow02.svg) 50% no-repeat;
					background-size: contain;
					right: 13px;
					top: 50%;
					transform: translateY(-50%);
					z-index: 2;
					transition: all .3s;
				}
				&:after{
					content: "";
					display: inline-block;
					position: absolute;
					width: 14px;
					height: 11px;
					background: url(../img/index/arrow.svg) 50% no-repeat;
					background-size: contain;
					left: -20px;
					top: 50%;
					transform: translateY(-50%);
					z-index: 2;
				}
			}
		}
		
		&-img{
			width: 192px;
			@include aspect-ratio(192,144);
			background: #F3F3F3;
			@include mq(tb){
				width: 128px;
			}
		}
		&-ct{
			width: calc(100% - 192px);
			padding-left: 32px;
			@include flex(0,center);
			@include mq{
				width: calc(100% - 128px);
				padding-left: 16px;
			}
			
		}
		&-date{
			font-family: $font-en;
			@include font-size(16);
			font-weight: 500;
			display: block;
			margin-bottom: 20px;
			@include mq{
				@include font-size(14);
				margin-bottom: 5px;
			}
		}
		&-cate{
			min-width: 200px;
			display: inline-block;
			border: 1px solid #0A1F36;
			border-radius: 15px;
			@include font-size(12);
			text-align: center;
			padding: 5px 15px;
			margin-bottom: 10px;
			@include mq(tb){
				min-width: 185px;
			}
			@include mq{
				@include font-size(11);
			}
			@include mq(xs){
				text-align: left;
				min-width: 154px;
			}
		}
		&-meta{
			width: 264px;
			padding-left: 32px;
			@include mq(tb){
				padding-left: 16px;
				width: 220px;
			}
			@include mq{
				width: calc(100% - 128px);
			}
			@include mq(xs){
				padding-right: 30px;
			}
		}
		&-title{
			width: calc(100% - 264px - 192px);
			font-weight: 500;
			@include font-size(16);
			padding-left: 32px;
			padding-right: 50px;
			@include mq(tb){
				width: calc(100% - 128px - 264px);
				padding-left: 20px;
				padding-right: 0;
			}
			@include mq{
				width: 100%;
				padding-left: 0;
				padding-right: 0;
				margin-top: 7px;
				@include font-size(14);
				line-height: 2;
			}
			span{
				background: -webkit-gradient(linear, left top, left bottom, from(#333), to(#333)) 100% 100% / 0 1px no-repeat;
				background: linear-gradient(#333, #333) 100% 100% / 0 1px no-repeat;
				-webkit-transition: background-size 0.6s cubic-bezier(0.25,1,0.5,1) 0s;
				transition: background-size 0.6s cubic-bezier(0.25,1,0.5,1) 0s;
				will-change: background-size;
			}
		}
		&-link{
			@include flex(0,center,wrap);
			color: #0A1F36;
			position: relative;
			@include mq{
				align-items: flex-start;
			}
			&:hover{
				opacity: 1;
				.topics-page__list-title{
					span{
						background-position: 0 100%;
						background-size: 100% 1px;
					}
				}
				.c-arrow{
					background: rgba(13, 38, 65, .9);
					transition: all .3s;
					&::before{
						opacity: 0;
						transition: all .3s;
					}
					&:after{
						left: 13px;
						transition: all .3s;
					}
				}
			}
		}
	}
}


.topics-detail{
	padding: 137px 0 120px;
	@include mq{
		padding: 80px 0 60px;
	}
	&__wrapper{
		padding-bottom: 80px;
		margin-bottom: 125px;
		border-bottom: 1px solid #DBDAD2;
		@include mq{
			padding-bottom: 27px;
			margin-bottom: 33px;
		}
	}
	&__meta{
		margin-bottom: 40px;
		@include mq{
			margin-bottom: 30px;
		}
		&-date{
			font-family: $font-en;
			@include font-size(16);
			font-weight: 500;
			display: block;
			margin-bottom: 13px;
			@include mq{
				@include font-size(14);
				margin-bottom: 5px;
			}
		}
		&-cate{
			min-width: 200px;
			display: inline-block;
			border: 1px solid #0A1F36;
			border-radius: 15px;
			@include font-size(12);
			text-align: center;
			padding: 5px 15px;
			@include mq(tb){
				min-width: 185px;
			}
			@include mq{
				@include font-size(11);
			}
			@include mq(xs){
				text-align: left;
				min-width: 154px;
			}
		}
	}
	&__ttl{
		@include font-size(32);
		font-weight: 500;
		padding-bottom: 40px;
		margin-bottom: 70px;
		border-bottom: 1px solid #DBDAD2;
		@include mq{
			@include font-size(26);
			line-height: 1.45;
			padding-bottom: 30px;
			margin-bottom: 30px;
		}
	}
	&__content{
		img{
			max-width: 560px;
			width: 100%;
			height: auto;
			margin: 60px auto;
			display: block;
			@include mq{
				margin: 32px auto;
			}
		}
		p{
			margin-bottom: 30px;
			@include mq{
				margin-bottom: 28px;
			}
		}
		a[href$=".pdf"] {
			display: inline-block;
			padding: 4px 56px 4px 0;
			background: url(../img/topics/detail/ico-link.png) no-repeat center right/40px 40px;
			padding-right: 56px;
			text-decoration: underline;
			color: #000;
			@include mq{
				padding: 4px 50px 4px 0;
				background: url(../img/topics/detail/ico-link.png) no-repeat center right/34px 34px;
			}
		}
	}
	&__btn{
		margin: 0 auto;
	}
}