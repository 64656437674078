@use "vars" as *;

/* ====================================================
Header
==================================================== */
.main-header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1001;
	transition: .3s;
	@include mq{
		height: 62px;
	}
	&__inner{
		@include flex(between, center ,wrap);
		margin: 0 auto;
	}
	&__logo{
		width: 285px;
		position: fixed;
		top: 33px;
		left: 51%;
		transform: translateX(-50%);
		@include mq(lp){
			top: 28px;
			width: 250px;
		}
		@include mq(xl){
			left: 53%;
			width: 220px;
		}
		@include mq(tb){
			transform: translateX(0);
			left: 16px;
			width: 196px;
			top: 20px;
		}
		img{
			width: 100%;
		}
		.off{
			display: none;
		}
	}
	&__navGlobal{
		width: 100%;
		padding: 23px 71px;
		@include mq(tb-min){
			@include flex(between);
			display: flex !important;
		}
		@include mq(lp){
			padding: 20px 15px;
		}
		@include mq(tb) {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			padding: 94px 16px 80px;
			width: 100%;
			text-align: center;
			z-index: 99;
			overflow-y: auto;
		}
		&.is-show{
			//display: block;
			background: #fff;
			height: 100vh;
		}
		&-logo{
			position: fixed;
			top: 20px;
			left: 16px;
			width: 100%;
			max-width: 196px;
			img{
				width: 100%;
			}
		}
		.app-flex{
			background: #e8e4df;
			padding: 18px 16px 30px;
			margin: 40px -16px 29px;
		}
	}
	&__menu{
		@include mq(tb-min) {
			@include flex(end, center);
		}
		gap: 0 32px;
		@include mq(xl){
			gap: 0 25px;
		}
		&-item{
			position: relative;
			&.on{
				@include mq(tb){
					>a{
						color: #BA915B;
						border-color: #BA915B;
						transition: all 0.3s;
					}
				}
			}
			.accordion{
				@include mq(tb-min){
					display: none !important;
				}
			}
			@include mq(tb){
				position: relative;
				.accordion{
					cursor: pointer;
					display: block;
					width: 56px;
					height: 72px;
					position: absolute;
					right: -16px;
					top: 0;
					@include p-before(21px,1px){
						background: #000;
						left: 50%;
						top: 50%;
						transition: all 0.3s;
						transform: translate(-50%,-50%);
					}
					@include p-after(1px,21px){
						background: #000;
						left: 50%;
						top: 50%;
						transition: all 0.3s;
						transform: translate(-50%,-50%);
					}
					&.on{
						&::after{
							opacity: 0;
							transition: all 0.3s;
						}
						&::before{
							background: #BA915B;
							transition: all 0.3s;
						}
					}
				}
			}
			>a{
				font-weight: 500;
				color: #fff;
				@include font-size(16);
				display: block;
				padding: 16px 0;
				@include mq(xl){
					@include font-size(14);
				}
				@include mq(tb){
					transition: all 0.3s;
					border-bottom: 1px solid #DBDAD2;
					font-weight: 500;
					color: #000;
					padding: 23px 0 22px;
					display: block;
					text-align: left;
					@include font-size(18);
				}
			}
			&:hover{
				@include mq(tb-min){
					.main-header__submenu{
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}
			}
			&--contact{
				width: 139px;
				@include mq(xl){
					width: 100px;
				}
				@include mq(tb){
					margin-top: 40px;
					width: 100%;
					margin-bottom: 23px;
				}
				a{
					background: #0D2641;
					color: #fff;
					width: 100%;
					max-width: 139px;
					font-weight: 500;
					height: 48px;
					transition: all 0.3s;
					@include flex(center,center);
					border-radius: 24px;
					@include font-size(16);
					@include mq(xl){
						max-width: 100px;
						@include font-size(14);
					}
					@include mq(tb){
						max-width: 100%;
						width: 100%;
						background: #BA915B;
						height: 68px;
						max-width: 358px;
						border-radius: 4px;
						padding: 16px;
						margin-left: auto;
						margin-right: auto;
						justify-content: flex-start;
						position: relative;
						@include p-after(12px,10px){
							background: url('../img/index/arrow.svg') center no-repeat;
							background-size: contain;
							right: 16px;
							top: 50%;
							transform: translateY(-50%);
						}
					}
					@include mq(md-min){
						&:hover{
							opacity: 1;
							background: #fff;
							transition: all 0.3s;
							color: #0D2641;
							box-shadow: 0 0 10px rgba(#000, .2);
						}
					}
				}
			}
			&--black{
				@include mq(tb){
					margin-top: 0;
					a{
						background: #202A34;
					}
				}
			}
		}
	}

	&__submenu{
		@include mq(tb-min){
			position: absolute;
			top: 50px;
			left: 0;
			background: #fff;
			padding: 15px;
			width: 200px;
			opacity: 0;
			visibility: hidden;
			z-index: 10000;
			box-shadow: 5px 5px 15px 0px rgb(51 51 51 / 30%);
			transform: translateY(10px);
			@include smooth-transition;
		}
		@include mq(tb){
			display: none;
			padding: 15px 0 0;
		}
		&-item{
			margin-bottom: 19px;
			text-align: left;
			&:last-of-type{
				margin-bottom: 0;
			}
			>a{
				display: block;
				padding-left: 16px;
				color: #000;
				font-weight: 500;
				position: relative;
				@include font-size(15);
				@include p-before(8px,8px){
					background: #BA915B;
					border-radius: 50%;
					left: 0;
					top: 7px;
				}
			}
		}
	}

	&__submenu02{
		padding-left: 32px;
		margin-top: 14px;
		&-item{
			border-bottom: 1px solid #DBDAD2;
			a{
				display: block;
				color: #000;
				font-weight: 500;
				padding-bottom: 13px;
				position: relative;
				@include font-size(14);
			}
		}
	}

	&__fixed{
		position: fixed;
		top: 122px;
		left: 50%;
		transition: all 0.3s;
		z-index: 98;
		max-width: 511px;
		width: 100%;
		padding: 24px 10px;
		gap: 0 10px;
		@include flex(center,center);
		transform: translateX(-50%);
		border-radius: 8px;
		background: rgba(#fff,0.8);
		box-shadow: 0 3px 6px rgba(#000, .16);
		@include mq(mac){
			top: 100px;
		}
		@include mq(lp){
			max-width: 450px;
			padding: 15px 10px;
		}
		@include mq(xl){
			top: 73px;
			max-width: 400px;
		}
		@include mq(){
			top: 72px;
			max-width: 358px;
			width: 92%;
			padding: 10px 10px;
			gap: 0;
		}
		&-item{
			@include mq(){
				padding: 0 5px;
				width: 33.33%;
			}
			a{
				display: block;
				background: #BA915B;
				padding: 13px 15px;
				font-weight: 500;
				border-radius: 25px;
				color: #fff;
				@include font-size(16);
				@include mq(lp){
					padding: 10px 12px;
					@include font-size(15);
				}
				@include mq(xl){
					padding: 6px 8px;
					@include font-size(14);
				}
				@include mq(){
					border-radius: 35px;
					height: 57px;
					text-align: center;
					@include flex(center,center);
					line-height: 1.4275;
				}
				@include mq(xs){
					@include font-size(12);
				}
			}
		}
		&.hidden{
			opacity: 0;
			pointer-events: none;
			transition: all 0.3s;
		}
	}

	&__mobile-icon {
		position: absolute;
		top: 0;
		right: 0;
		display: none;
		width: 62px;
		height: 62px;
		//background: #eee;
		transition: background .5s;
		z-index: 102;
		cursor: pointer;
		@include mq(tb){
			display: block;
		}
		&-border{
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%);
			display: block;
			width: 30px;
			height: 1px;
			user-select: none;
			transition: background-color 0.3s;
			background: #fff;
			&:nth-child(1){
				margin-top: 8px;
				margin-left: -15px;
			}
			&:nth-child(3){
				margin-top: -8px;
				margin-left: -15px;
			}
		}
		&.mobile-close{
			.main-header__mobile-icon-border{
				background: #202A34;
			}
		}
	}	
	

	//active	
	&.is-active{
		background: #fff;
		transition: all 0.3s;
		box-shadow: 0 0 10px rgba(#000, .1);
		.main-header__menu{
			&-item{
				a{
					color: #000;
					transition: all 0.3s;
				}
			}
		}
		.main-header__mobile-icon-border{
			background: #333;
		}
		.main-header__logo{
			.on{
				display: none;
			}
			.off{
				display: block;
			}
		}
	}
	&.header-page{
		background: #fff;
		transition: all 0.3s;
		.main-header__menu{
			&-item{
				a{
					color: #000;
					transition: all 0.3s;
				}
			}
		}
		.main-header__logo{
			.on{
				display: none;
			}
			.off{
				display: block;
			}
		}
		.main-header__mobile-icon{
			.main-header__mobile-icon-border{
				background: #000;
			}
		}
	}
	&.header-page02{
		@include mq(){
			transition: all 0.3s;
			.main-header__menu{
				&-item{
					a{
						color: #000;
						transition: all 0.3s;
					}
				}
			}
			.main-header__logo{
				.on{
					display: none;
				}
				.off{
					display: block;
				}
			}
			.main-header__mobile-icon{
				.main-header__mobile-icon-border{
					background: #000;
				}
			}
		}
	}
}


// mobile menu btn animation
@media screen and (max-width: 1024px) {
	.main-header__mobile-icon-border:nth-child(1) {
		-webkit-animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	
	@-webkit-keyframes click-header-sp-bar-1-close {
		0% {
			transform: translateY(5px) rotate(45deg)
		}
		50% {
			transform: translateY(5px) rotate(0)
		}
		100% {
			transform: translateY(0) rotate(0)
		}
	}
	@keyframes click-header-sp-bar-1-close {
		0% {
			transform: translateY(5px) rotate(45deg)
		}
		50% {
			transform: translateY(5px) rotate(0)
		}
		100% {
			transform: translateY(0) rotate(0)
		}
	}
	.main-header__mobile-icon-border:nth-child(2) {
		-webkit-animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	@-webkit-keyframes click-header-sp-bar-2-close {
		0% {
			opacity: 0
		}
		20% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	@keyframes click-header-sp-bar-2-close {
		0% {
			opacity: 0
		}
		20% {
			opacity: 0
		}
		100% {
			opacity: 1
		}
	}
	.main-header__mobile-icon-border:nth-child(3) {
		-webkit-animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	@-webkit-keyframes click-header-sp-bar-3-close {
		0% {
			transform: translateY(-5px) rotate(-45deg)
		}
		50% {
			transform: translateY(-5px) rotate(0)
		}
		100% {
			transform: translateY(0) rotate(0)
		}
	}
	@keyframes click-header-sp-bar-3-close {
		0% {
			transform: translateY(-5px) rotate(-45deg)
		}
		50% {
			transform: translateY(-5px) rotate(0)
		}
		100% {
			transform: translateY(0) rotate(0)
		}
	}
	
//menu opened		
	.mobile-close .main-header__mobile-icon-border:nth-child(1) {
		margin-top: -5px;
		-webkit-animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	@-webkit-keyframes click-header-sp-bar-1-open {
		0% {
			transform: translateY(0) rotate(0)
		}
		50% {
			transform: translateY(5px) rotate(0)
		}
		100% {
			transform: translateY(5px) rotate(45deg)
		}
	}
	@keyframes click-header-sp-bar-1-open {
		0% {
			transform: translateY(0) rotate(0)
		}
		50% {
			transform: translateY(5px) rotate(0)
		}
		100% {
			transform: translateY(5px) rotate(45deg)
		}
	}
	.mobile-close .main-header__mobile-icon-border:nth-child(2) {
		-webkit-animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	@-webkit-keyframes click-header-sp-bar-2-open {
		0% {
			opacity: 1
		}
		80% {
			opacity: 0
		}
		100% {
			opacity: 0
		}
	}
	@keyframes click-header-sp-bar-2-open {
		0% {
			opacity: 1
		}
		80% {
			opacity: 0
		}
		100% {
			opacity: 0
		}
	}
	.mobile-close .main-header__mobile-icon-border:nth-child(3) {
		margin-top: 5px;
		-webkit-animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
		animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
	}
	@-webkit-keyframes click-header-sp-bar-3-open {
		0% {
			transform: translateY(0) rotate(0)
		}
		50% {
			transform: translateY(-5px) rotate(0)
		}
		100% {
			transform: translateY(-5px) rotate(-45deg)
		}
	}
	@keyframes click-header-sp-bar-3-open {
		0% {
			transform: translateY(0) rotate(0)
		}
		50% {
			transform: translateY(-5px) rotate(0)
		}
		100% {
			transform: translateY(-5px) rotate(-45deg)
		}
	}
}


