@use "vars" as *;

/* ====================================================
Footer
==================================================== */
.main-footer{
	position: relative;
	padding: 64px 0 97px;
	color: #fff;
	background: #202A34;
	@include mq(xl){
		padding: 50px 0;
	}
	@include mq(){
		padding: 40px 0 60px;
	}
	&__logo{
		margin-bottom: 63px;
		@include mq(xl){
			margin-bottom: 35px;
		}
		@include mq(){
			margin-bottom: 40px;
		}
		img{
			width: 100%;
			max-width: 588px;
			@include mq(xl){
				max-width: 450px;
			}
			@include mq(){
				max-width: 259px;
			}
		}
	}
	&__content{
		border-top: 1px solid #DBDAD2;
		border-bottom: 1px solid #DBDAD2;
		padding: 62px 0 35px;
		margin-bottom: 28px;
		@include mq(){
			padding: 36px 0 40px;
			margin-bottom: 37px;
		}
	}
	&__flex{
		@include flex(between);
		@include mq(xl){
			flex-wrap: wrap;
		}
	}
	&__nav{
		width: 800px;
		gap: 0 40px;
		@include flex(0, start, wrap);
		@include mq(lp){
			gap: 0 35px;
		}
		@include mq(xl){
			width: 100%;
		}
	}
	&__menu{
		@include mq(sm){
			width: 100%;
		}
		&-item{
			margin-bottom: 25px;
			@include mq(){
				margin-bottom: 34px;
			}
			>a{
				color: #fff;
				font-weight: 600;
				@include font-size(16);
				@include mq(lp){
					@include font-size(15);
				}
				@include mq(){
					@include font-size(14);
				}
				@include mq(md-min){
					&:hover{
						text-decoration: underline;
						transition: all 0.3s;
					}
				}
			}
		}
	}
	&__menusub{
		margin-top: 20px;
		@include mq(sm){
			margin-top: 18px;
			&--02{
				@include flex();
				gap: 0 29px;
			}
		}
		&-item{
			&:not(:last-child){
				margin-bottom: 14.5px;
				@include mq(lp){
					margin-bottom: 12px;
				}
				@include mq(){
					margin-bottom: 17px;
				}
			}
			>a{
				color: #707070;
				font-weight: 500; 
				@include font-size(14);
				@include mq(lp){
					@include font-size(13);
				}
				@include mq(){
					@include font-size(12);
				}
				@include mq(md-min){
					&:hover{
						text-decoration: underline;
						transition: all 0.3s;
					}
				}
			}
		}
	}
	&__btn{
		width: 320px;
		margin-top: -19px;
		@include mq(xl){
			margin-top: 20px;
			width: 100%;
		}
		@include mq(){
			margin-top: 24px;
		}
		&-item{
			&:not(:last-child){
				margin-bottom: 32px;
				@include mq(xl){
					margin-bottom: 20px;
				}
				@include mq(){
					margin-bottom: 24px;
				}
			}
			@include mq(xl){
				.c-btn__02{
					margin: 0 auto;
				}
			}
		}
	}
	&__privacy{
		gap: 0 30px;
		margin-bottom: 59px;
		@include flex();
		@include mq(xl){
			margin-bottom: 25px;
		}
		@include mq(){
			margin-bottom: 36px;
			gap: 0 22px;
		}
		@include mq(xs){
			gap: 15px 0;
			flex-wrap: wrap;
		}
		&-item{
			@include mq(xs){
				width: 100%;
			}
			a{
				color: #707070;
				font-weight: 600;
				@include font-size(14);
				@include mq(){
					@include font-size(12);
				}
				@include mq(){
					@include font-size(11);
				}
			}
		}
	}
	&__copyright{
		font-family: $font-en;
		font-weight: bold;
		@include font-size(14);
		color: #707070;
		@include mq(){
			text-align: center;
			@include font-size(12);
		}
		@include mq(){
			@include font-size(11);
		}
	}
}


