@use "vars" as *;

.inner {
	display: block;
	max-width: 1712px;
	width: 90%;
	margin: 0 auto;
	&--1440 {
		max-width: 1440px;
	}
	&--1325 {
		max-width: 1325px;
	}
	&--1160 {
		max-width: 1160px;
	}
	&--1120 {
		max-width: 1120px;
	}
	@include mq() {
		width: 100%;
		padding: 0 16px;
	}
}

.wrapper {
	padding-top: 219px;
	@include mq() {
		padding-top: 164px;
	}
}

.wrapper-sp {
	@include mq() {
		padding-top: 169px;
	}
}

.sec-breadcrumb {
	.inner {
		@include flex(0, 0, wrap);
		gap: 10px 0;
	}
	a,
	span {
		display: inline-block;
		font-weight: 500;
		@include font-size(16);
		@include mq() {
			@include font-size(14);
		}
	}
	a {
		margin-right: 24px;
		padding-right: 24px;
		color: #000;
		position: relative;
		text-decoration: underline;
		@include mq() {
			margin-right: 21px;
			padding-right: 21px;
		}
		@include p-after(1px, 18px) {
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			background: #000;
			@include mq() {
				height: 14px;
				top: 54%;
			}
		}
	}
	span {
		color: #838383;
	}
}

.list-tags {
	@include flex(0, 0, wrap);
	gap: 10px;
	margin-bottom: 15px;
	@include mq() {
		margin-bottom: 11px;
	}
	&__item {
		span,
		a {
			border: 1px solid #000;
			display: inline-block;
			height: 28px;
			min-width: 120px;
			background: #fff;
			padding: 4px 24px;
			font-weight: 500;
			color: #000;
			@include font-size(12);
			border-radius: 30px;
			@include flex(center, center);
			@include mq() {
				padding: 4px 14px;
				min-width: 116px;
				@include font-size(11);
			}
		}
	}
}

@include mq(md-min) {
	.mbpc-120 {
		margin-bottom: 120px !important;
	}
	.mbpc-116 {
		margin-bottom: 116px !important;
	}
}

.font-noto {
	font-family: $font-jp !important;
}

.font-lato {
	font-family: $font-en !important;
}

.content {
	@include flex(0, 0, wrap);
	@include mq() {
		flex-wrap: wrap-reverse;
	}
	&-left {
		padding: 129px 0 58px;
		width: calc(100% - 280px);
		border-right: 1px solid #dbdad2;
		@include mq(mac) {
			width: calc(100% - 200px);
			padding: 110px 0 50px 0;
		}
		@include mq(xl) {
			padding-right: 0;
			width: calc(100% - 150px);
		}
		@include mq() {
			width: 100%;
			padding: 58px 0 26px;
			border-right: 0;
		}
		&__mw {
			max-width: 1296px;
			width: 100%;
		}
		&--02 {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	&-right {
		width: 280px;
		padding: 79px 15px 0 39px;
		position: relative;
		@include mq(mac) {
			width: 200px;
			padding: 79px 15px 0 15px;
		}
		@include mq(xl) {
			width: 150px;
			padding: 60px 15px 0 15px;
		}
		@include mq() {
			width: 100%;
			padding: 64px 0 0 15px;
			overflow-x: auto;
		}
		&__fixed {
			position: sticky;
			top: 150px;
			left: 0;
			padding-bottom: 30px;
			@include mq(tb) {
				top: 50px;
			}
			@include mq() {
				height: auto;
				padding-bottom: 0;
				position: static;
				width: 680px;
			}
		}

		.list-menu {
			display: block;
			margin-bottom: 0;
			@include mq() {
				@include flex();
				gap: 0 20px;
				margin-bottom: 20px;
			}
			&__item {
				margin-bottom: 16px;
				@include mq(xl) {
					margin-bottom: 15px;
				}
				@include mq() {
					margin-bottom: 0;
				}
				a {
					text-align: left;
					padding: 8px 0;
					@include font-size(12);
					@include mq(xl) {
						padding: 8px 0;
					}
					@include mq() {
						padding: 4px 0;
						@include font-size(16);
					}
					&:hover {
						// opacity: 0.7;
						// background: none !important;
						// color: #000;
						background: #0d2641;
						padding: 8px 24px;
						@include mq(xl) {
							padding: 8px 15px;
						}
						@include mq() {
							padding: 11px 24px;
						}
					}
				}
				&.active {
					a {
						padding: 8px 24px;
						@include mq(xl) {
							padding: 8px 15px;
						}
						@include mq() {
							padding: 6px 19px;
						}
					}
				}
			}
		}
		&--02 {
			@include mq() {
				padding: 30px 0 0 0;
			}
		}
	}
}

.menu-accordion {
	@include mq() {
		border-radius: 10px;
		background: #f3f3f3;
		margin: 0 16px 59px;
		padding: 0 15px;
		.list-menu {
			display: none;
			border-top: 1px solid #fff;
			max-width: 100%;
			padding: 22px 0 12px;
			&__item {
				&:not(:last-child) {
					margin-bottom: 11px;
				}
				a {
					@include font-size(14);
				}
			}
		}
	}
	&__click {
		cursor: pointer;
		position: relative;
		font-family: $font-en;
		@include font-size(16);
		padding: 25px 0 25px 49px;
		@include p-before(32px, 32px) {
			background: url("../img/8th_sea_oyster/icon-plus.svg") center no-repeat;
			background-size: contain;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
		&.on {
			&::before {
				background: url("../img/8th_sea_oyster/icon-close.svg") center no-repeat;
				background-size: contain;
			}
		}
	}
}

//Block content common
.content-block {
	margin-bottom: 94px;
	@include mq(xl) {
		margin-bottom: 50px;
	}
	@include mq() {
		margin-bottom: 54px;
	}
	.c-ttl__01 {
		margin-bottom: 60px;
		@include mq(xl) {
			margin-bottom: 35px;
		}
		@include mq() {
			margin-bottom: 15px;
		}
	}
	> .c-txt__01 {
		max-width: 650px;
	}
	&__row {
		@include flex();
		@include mq() {
			flex-wrap: wrap;
		}
		.c-ttl__02 {
			width: 53.25%;
			padding-right: 50px;
			@include mq(xl) {
				width: 45%;
				padding-right: 25px;
			}
			@include mq() {
				width: 100%;
				padding-right: 0;
				margin-bottom: 18px;
			}
		}
		.c-txt__01 {
			width: 46.75%;
			padding-right: 131px;
			@include mq(lp) {
				padding-right: 0;
			}
			@include mq(xl) {
				width: 55%;
			}
			@include mq() {
				width: 100%;
			}
		}
	}

	&--full {
		margin-bottom: 66px;
		@include mq {
			margin-bottom: 54px;
		}
		.c-ttl__01 {
			@include mq {
				margin-bottom: 28px;
			}
		}
		.content-block {
			&__row {
				display: block;
				& > * {
					width: 100%;
				}
			}
		}
	}
}

//Voice box common
.voice-box {
	.c-ttl__04 {
		margin-bottom: 67px;
		@include mq(xl) {
			margin-bottom: 35px;
		}
		@include mq() {
			margin-bottom: 27px;
		}
	}
	&__cate {
	}
	&__list {
		&-item {
			@include flex(0, start);
			@include mq() {
				flex-wrap: wrap-reverse;
			}
			&:not(:last-child) {
				margin-bottom: 47px;
				@include mq() {
					margin-bottom: 49px;
				}
			}
			padding-bottom: 41px;
			border-bottom: 1px solid #dbdad2;
			@include mq() {
				padding-bottom: 45px;
				&:last-child {
					border-bottom: 0;
					padding-bottom: 0;
				}
			}
		}
		&-ct {
			width: 54.75%;
			padding-right: 112px;
			@include mq(lp) {
				padding-right: 25px;
			}
			@include mq() {
				padding-right: 0;
				width: 100%;
			}
			.c-ttl__05 {
				margin-bottom: 15px;
				@include mq() {
					margin-bottom: 0;
				}
			}
		}
		&-desc {
			margin-bottom: 31px;
			@include mq(xl) {
				margin-bottom: 18px;
			}
			span {
				display: block;
				font-weight: 500;
				margin-top: 4px;
				@include font-size(12);
			}
		}
		&-img {
			width: 45.25%;
			@include aspect-ratio(524px, 348px);
			@include mq() {
				width: 100%;
				margin-bottom: 26px;
			}
		}

		&--franchise {
			.voice-box__list {
				&-img {
					&::before {
						@include mq {
							padding-top: 62.412214%;
						}
					}
				}
				&-ct {
					@include mq {
						padding: 0 16px;
					}
				}
			}
		}
	}
}

.box-scroll {
	@include mq {
		overflow-x: auto;
		margin-right: -15px;
		margin-bottom: 60px;
	}
}

.vol-tabs {
	@include flex(0, 0);
	margin-bottom: 76px;
	@include mq {
		margin-bottom: 0;
	}
	&.tab-menu {
		max-width: max-content;
		flex-wrap: nowrap;
		//gap: 0 36px;
		gap: 8px;
		margin-bottom: 80px;
		@include mq {
			margin-bottom: 0;
		}
	}
	li {
		display: inline-block;
		//padding: 13px 24px;
		margin: 0 6px;
		padding: 13px 10px;
		border-radius: 25px;
		@include font-size(18);
		font-weight: 500;
		color: #000;
		cursor: pointer;
		margin-bottom: 0 !important;
		@include mq {
			padding: 8px 5px;
		}		
		&.active {
			background: #0d2641;
			color: #fff;
			padding: 13px 24px;
			@include mq {
				padding: 9px 21px;
			}
			//&:not(:first-child) {
			//	margin-left: -16px;
			//}
		}
		//&.active + li:hover{
		//	margin-left: -8px;
		//}
		@media (any-hover: hover) {
			transition: all 0.2s;
			&:hover {
				//margin-right: 16px;
				background: #0d2641;
				color: #fff;
				//margin-right: -20px;
				padding: 13px 24px;
				@include mq {
					padding: 9px 21px;
				}
			}
		}
	}
}

//Block eoyster of page index common
.eoyster-box {
	width: 100%;
	padding: 75px 80px 81px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	@include mq(xl) {
		padding: 60px 30px;
	}
	@include mq() {
		padding: 57px 16px 65px;
		border-radius: 15px;
	}
	@include mq(sm){
		top: 0;
		transform: none;
	}
	&__heading {
		font-weight: 600;
		color: #fff;
		margin-bottom: 38px;
		@include font-size(29);
		@include mq(xl) {
			@include font-size(22);
			margin-bottom: 20px;
		}
		@include mq() {
			line-height: 1.6636;
		}
	}
	&__ttl {
		font-weight: 600;
		color: #fff;
		margin-bottom: 11px;
		@include font-size(21);
		@include mq(xl) {
			@include font-size(18);
		}
		@include mq() {
			margin-bottom: 13px;
		}
	}
	.c-txt__01 {
		max-width: 585px;
		margin-bottom: 39px;
		@include mq(xl) {
			margin-bottom: 25px;
		}
		@include mq() {
			margin-bottom: 30px;
		}
	}
	&__btn {
		@include flex(0, 0, wrap);
		gap: 0 18px;
		@include mq() {
			gap: 20px 18px;
		}
		&-item {
			width: 320px;
			img {
				margin-right: 10px;
			}
		}
	}
}

//block guide of page top common
.guide-list {
	&--number {
		counter-reset: item;
		margin-bottom: 80px;
		@include mq(xl) {
			margin-bottom: 50px;
		}
		@include mq() {
			margin-bottom: -9px;
		}
		.guide-list__item {
			counter-increment: item;
			margin-bottom: 40px;
			@include mq(xl-min) {
				align-items: flex-end;
			}
			@include mq() {
				padding-bottom: 40px;
				margin-bottom: 38px;
			}
		}
		.guide-list__ct {
			padding-left: 96px;
			position: relative;
			@include mq(xl) {
				padding-left: 60px;
			}
			@include mq() {
				padding-left: 0;
				.c-ttl__05 {
					margin-bottom: 23px;
					padding-left: 63px;
				}
			}
			&:before {
				content: "0" counter(item);
				color: #ba915b;
				font-weight: 500;
				position: absolute;
				left: 0;
				top: -28px;
				line-height: 1;
				font-family: $font-en;
				@include font-size(48);
				@include mq(xl) {
					@include font-size(36);
				}
				@include mq() {
					top: 0;
				}
			}
		}
	}
	&__item {
		@include flex(0, center, wrap);
		padding-bottom: 48px;
		margin-bottom: 66px;
		border-bottom: 1px solid #dbdad2;
		@include mq(xl) {
			padding-bottom: 25px;
			margin-bottom: 25px;
		}
		@include mq() {
			padding-bottom: 40px;
			margin-bottom: 23px;
		}
		.c-ttl__04 {
			width: 100%;
			margin-bottom: 66px;
			@include mq(xl) {
				margin-bottom: 25px;
			}
			@include mq() {
				margin-bottom: 30px;
			}
		}
	}
	&__ct {
		width: calc(100% - 360px);
		padding-right: 70px;
		margin-bottom: -4px;
		@include mq(lp) {
			padding-right: 30px;
			margin-bottom: 0;
		}
		@include mq(xl) {
			padding-right: 20px;
			width: calc(100% - 200px);
		}
		@include mq() {
			padding-right: 0;
			width: 100%;
			margin-bottom: 25px;
		}
		.c-ttl__05 {
			margin-bottom: 15px;
			@include mq() {
				margin-bottom: 7px;
			}
		}
	}
	&__img {
		overflow: hidden;
		width: 360px;
		border-radius: 8px;
		@include aspect-ratio(360px, 200px);
		@include mq(xl) {
			width: 200px;
		}
		@include mq() {
			width: 100%;
		}
	}
	&--02 {
		@include mq(xl-min) {
			.guide-list__ct {
				padding-bottom: 33px;
			}
		}
	}
}

.bg-gray {
	background: #f3f3f3;
}

.pd0-sp {
	@include mq {
		padding: 0;
	}
}

@include mq(xl-min) {
	.fz32-pc {
		font-size: 3.2rem !important;
	}
}

.wp-pagenavi {
	@include flex(center, center);
	gap: 0 16px;
	@include mq {
		gap: 0 18px;
	}
	.page,
	.current,
	.extend {
		@include font-size(18);
		font-weight: 500;
		border: 0 !important;
		font-family: $font-en;
		text-decoration: underline;
		color: #000;
		cursor: pointer;
		@include mq {
			@include font-size(16);
		}
	}
	.current {
		color: #888888;
		text-decoration: none;
	}
	.extend {
		text-decoration: none;
	}
}

.previouspostslink,
.nextpostslink {
	width: 40px;
	height: 40px;
	position: relative;
	background: #fff;
	border: 1px solid #000;
	border-radius: 50%;
	right: 0;
	overflow: hidden;
	transition: all 0.3s;
	@include mq {
		width: 36px;
		height: 36px;
	}
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		width: 14px;
		height: 11px;
		background: url(../img/index/arrow02.svg) 50% no-repeat;
		background-size: contain;
		right: 13px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		transition: all 0.3s;
		@include mq {
			right: 10px;
		}
	}
	&:after {
		content: "";
		display: inline-block;
		position: absolute;
		width: 14px;
		height: 11px;
		background: url(../img/index/arrow.svg) 50% no-repeat;
		background-size: contain;
		left: -20px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
	}
}

.previouspostslink {
	margin-right: 16px;
	@include mq {
		margin-right: 50px;
	}
	&::before,
	&:after {
		transform: translateY(-50%) rotate(-180deg);
	}
	&:after {
		left: auto;
		right: -20px;
	}

	@media (any-hover: hover) {
		transition: all 0.3s;
		&:hover {
			background: #000;
			transition: all 0.3s;
			&::before {
				opacity: 0;
				transition: all 0.3s;
			}
			&:after {
				right: 13px;
				transition: all 0.3s;
			}
		}
	}
}
.nextpostslink {
	margin-left: 16px;
	@include mq {
		margin-left: 50px;
	}
	@media (any-hover: hover) {
		transition: all 0.3s;
		&:hover {
			background: #000;
			&::before {
				opacity: 0;
				transition: all 0.3s;
			}
			&:after {
				left: 13px;
				transition: all 0.3s;
			}
		}
	}
}

.single-block {
	@include flex(0, center, wrap);
	&__img {
		width: 49.74%;
		@include mq {
			width: 100%;
			margin-bottom: 40px;
		}
	}
	&__ct {
		width: calc(100% - 49.74%);
		//padding-left: min(112px, 5.833vw);
		padding-left: 110px;
		@include mq(xl){
			padding-right: 0;
			padding-left: 8.835vw;
		}
		//@include mq(tb) {
		//	padding: 0 30px;
		//}
		@include mq {
			width: 100%;
			padding: 0 16px;
		}
		&-ttl {
			margin-bottom: 40px;
			@include mq {
				margin-bottom: 35px;
			}
		}
	}
}

.btn-group {
	max-width: 816px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px 16px;
	@include mq(sm) {
		grid-template-columns: 1fr;
	}
	.c-btn__02 {
		max-width: 100%;
	}
	&--center {
		margin: 0 auto;
	}
}

.custom-select {
	@include font-size(14);
	@include mq {
		@include font-size(12);
	}
	&-trigger {
		width: 100%;
		height: 70px;
		background: #f8f8f8;
		border: 1px solid #dbdad2;
		@include flex(0, center);
		padding: 24px 24px;
		position: relative;
		cursor: pointer;
		@include mq {
			height: 65px;
			padding: 16px;
		}
		@media (any-hover: hover) {
			transition: all 0.2s;
			&:hover {
				opacity: 0.7;
			}
		}
		@include p-after(32px, 32px) {
			background: url(../img/recruit/ico-dropdown.png) no-repeat center/100%;
			top: calc(50% - 16px);
			right: 32px;
			@include smooth-transition;
			@include mq {
				right: 16px;
			}
		}
	}
	&.opened {
		.custom-select-trigger {
			&:after {
				background: url(../img/recruit/ico-dropdown-02.png) no-repeat center/100%;
			}
		}
		.custom-options {
			display: block;
		}
	}
}
.custom-options {
	background: #fff;
	border: 1px solid #dbdad2;
	border-top: none;
	display: none;
	.custom-option {
		padding: 20px 24px;
		display: block;
		position: relative;
		@include mq {
			padding: 20px 16px;
		}
		@include p-after(calc(100% - 48px), 1px) {
			background: #dbdad2;
			bottom: 0;
			left: 24px;
			@include mq {
				width: calc(100% - 32px);
				left: 16px;
			}
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
		@media (any-hover: hover) {
			transition: all 0.2s;
			&:hover {
				cursor: pointer;
				background: #f8f8f8;
			}
		}
	}
}

.form-control {
	width: 100%;
	height: 70px;
	padding: 0 24px;
	border: 1px solid #dbdad2;
	background: #fff;
	@include font-size(14);
	font-weight: 500;
	@include mq {
		height: 65px;
		@include font-size(12);
		padding: 0 15px;
	}
	&--short {
		width: 200px;
		@include mq {
			width: 114px;
		}
	}
	&--textarea {
		height: 260px;
		padding: 24px;
		resize: none;
		@include mq {
			padding: 24px 16px;
			height: 230px;
		}
	}
	&--select {
		cursor: pointer;
		position: relative;
		background: #fff url(../img/recruit/arr-down.png) no-repeat center right 32px/12px 7px;
		@include mq {
			background: #fff url(../img/recruit/arr-down.png) no-repeat center right 16px/12px 7px;
		}
	}
}

input::placeholder {
	color: #d1d1d1;
}

textarea::placeholder {
	color: #d1d1d1;
}

.mwform-radio-field,
.mwform-checkbox-field,
.wpcf7-radio,
.wpcf7-checkbox,
.check-radio{
	input {
		display: none;
	}
	input:checked + .mwform-radio-field-text:after {
		transform: scale(1) translateY(-50%);
	}
	input:checked + .mwform-checkbox-field-text:after {
		transform: scale(1) translateY(-50%);
	}
}
.wpcf7-radio,
.check-radio{
    input:checked + .wpcf7-list-item-label:after {
		transform: scale(1) translateY(-50%);
	}
}

.mwform-radio-field-text,
.mwform-checkbox-field-text,
.wpcf7-radio .wpcf7-list-item-label,
.check-radio .wpcf7-list-item-label{
	@include font-size(14);
	display: block;
	position: relative;
	padding: 8px 0 8px 30px;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	@include mq {
		padding: 4px 0 5px 38px;
		@include font-size(12);
	}
	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		width: 18px;
		height: 18px;
		background: #fff;
		border: 1px solid #dbdad2;
		@include smooth-transition;
		border-radius: 50%;
		@include mq {
			width: 14px;
			height: 14px;
			left: 15px;
		}
	}
	&:after {
		content: "";
		display: block;
		background-color: #ba915b;
		position: absolute;
		top: 50%;
		left: 4px;
		width: 12px;
		height: 12px;
		transform: translateY(-50%) scale(0);
		transform-origin: 50%;
		border-radius: 50%;
		transition: transform 200ms ease-out;
		@include mq {
			width: 8px;
			height: 8px;
			left: 19px;
		}
	}
}
.check-radio{
    .wpcf7-list-item{
        display: block;
    }
}
.wpcf7-list-item{
    margin: 0 !important;
}