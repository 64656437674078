@use "vars" as *;




.sec-sea02{
	background: #0A1F36;
	padding: 125px 0 57px;
	@include mq(xl){
		padding: 70px 0;
	}
	@include mq(){
		padding: 40px 0 35px;
	}
	.sea-flex--02{
		position: relative;
		.c-ttl__02{
			@include mq(){
				margin-bottom: 17px;
			}
		}
		.sea-flex__logo{
			@include mq(){
				margin-bottom: 37px;
			}
			img{
				@include mq(){
					max-width: 230px;
				}
			}
		} 
		.c-txt__01{
			@include mq(){
				margin-bottom: 0;
			}
		}
	}
}


.oyster-ct{
	position: relative;
	@include mq(){
		border-bottom: 1px solid #DBDAD2;
		padding-bottom: 55px;
	}
	&__img{
		@include mq(){
			margin-bottom: 59px;
		}
		&-item{
			img{
				width: 100%;
				min-height: 1000px;
				object-fit: cover;
				@include mq(){
					min-height: 0;
				}
			}
		}
	}
	&__list{
		position: absolute;
		top: 50.5%;
		left: 50%;
		transform: translate(-50%,-50%);
		color: #fff;
		@include mq(){
			position: static;
			top: 0;
			left: 0;
			color: #000;
			transform: translate(0,0);
		}
		&-item{
			&:not(:last-child){
				margin-bottom: 63px;
				@include mq(){
					margin-bottom: 45px;
				}
			}
			.c-ttl__04{
				color: #fff;
				margin-bottom: 35px;
				@include mq(){
					color: #000;
					margin-bottom: 15px;
				}
			}
		}
	}
}

.sec-methods{
	padding: 131px 0 115px;
	@include mq(xl){
		padding: 80px 0;
	}
	@include mq(){
		padding: 55px 0;
	}
	.c-ttl__01{
		margin-bottom: 68px;
		@include mq(xl){
			margin-bottom: 40px;
		}
		@include mq(){
			margin-bottom: 29px;
		}
	}
	.c-ttl__02{
		margin-bottom: 68px;
		@include mq(xl){
			margin-bottom: 40px;
		}
		@include mq(){
			margin-bottom: 26px;
		}
	}
	.c-ttl__04{
		margin-bottom: 30px;
		@include mq(xl){
			margin-bottom: 20px;
		}
		@include mq(){
			margin-bottom: 9px;
		}
	}
}

.methods-img{
	@include flex();
	margin: 0 -56px 48px;
	@include mq(xl){
		margin: 0 -20px 40px;
	}
	@include mq(){
		margin: 0 0 28px;
		flex-wrap: wrap;
		gap: 29px 0;
	}
	&__item{
		padding: 0 56px;
		width: 50%;
		@include mq(xl){
			padding: 0 20px;
		}
		@include mq(){
			width: 100%;
			padding: 0;
		}
	}
	&__thumb{
		@include aspect-ratio(524px,348px);
		@include mq(){
			&::before{
				padding-top: 68.412%;
			}
			&--02{
				&::before{
					padding-top: 55.412%;
				}
			}
		}
	}
}

.sec-system02{
	padding: 129px 0 120px;
	background: #F3F3F3;
	@include mq(xl){
		padding: 80px 0;
	}
	@include mq(){
		padding: 56px 0 61px;
	}
}

.system02-block{
	margin-bottom: 64px;
	@include mq(){
		margin-bottom: 54px;
	}
	.c-ttl__01{
		margin-bottom: 60px;
		@include mq(xl){
			margin-bottom: 40px;
		}
		@include mq(){
			margin-bottom: 29px;
		}
	}
	.c-ttl__02{
		margin-bottom: 31px;
		@include mq(xl){
			margin-bottom: 25px;
		}
		@include mq(){
			margin-bottom: 17px;
		}
	}
}

.system02-list{
	margin-bottom: 117px;
	@include mq(xl){
		margin-bottom: 70px;
	}
	@include mq(){
		margin-bottom: 58px;
	}
	&__item{
		@include flex(0,center,wrap);
		@include mq(md-min){
			&:nth-child(2n){
				.system02-list__ct{
					order: 2;
					padding-left: 112px;
					padding-right: 0;
					@include mq(xl){
						padding-left: 25px;
					}
				}
				.system02-list__img{
					order: 1;
				}
			}
		}
		&:not(:last-child){
			margin-bottom: 80px;
			@include mq(){
				margin-bottom: 57px;
			}
		}
	}
	&__ct{
		width: 54.85%;
		padding-right: 112px;
		@include mq(xl){
			width: 62%;
			padding-right: 25px;
		}
		@include mq(){
			padding-right: 0;
			width: 100%;
			margin-bottom: 24px;
		}
		.c-ttl__04{
			margin-bottom: 32px;
			@include mq(xl){
				margin-bottom: 20px;
			}
			@include mq(){
				margin-bottom: 11px;
			}
		}
	}
	&__img{
		width: 45.15%;
		@include aspect-ratio(524px,348px);
		@include mq(xl){
			width: 38%;
		}
		@include mq(){
			width: 100%;
			&::before{
				padding-top: 63.412%;
			}
		}
	}
}

.system02-box{
	background: #fff;
	padding: 62px 20px 70px;
	border-radius: 16px;
	@include mq(xl){
		padding: 50px 10px;
	}
	@include mq(sm){
		padding: 34px 16px;
	}
	.c-ttl__04{
		margin-bottom: 68px;
		@include mq(xl){
			margin-bottom: 40px;
		}
	}
	&__list{
		gap: 48px 0;
		@include flex(center,0,wrap);
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		@include mq(){
			gap: 36px 0;
		}
		&-item{
			padding: 0 20px;
			width: 33.33%;
			@include mq(xl){
				padding: 0 10px;
			}
			@include mq(){
				width: 50%;
			}
			@include mq(sm){
				width: 100%;
				padding: 0;
			}
			.c-ttl__05{
				text-align: center;
				margin-bottom: 12px;
				@include mq(){
					margin-bottom: 0;
				}
			}
		}
		&-img{
			@include aspect-ratio(360px,200px);
			border-radius: 10px;
			overflow: hidden;
			margin-bottom: 27px;
			@include mq(){
				margin-bottom: 6px;
				&::before{
					padding-top: 52.556%;
				}
			}
		}
	}
}

.sec-technology{
	padding: 129px 0 112px;
	@include mq(xl){
		padding: 80px 0;
	}
	@include mq(){
		padding: 56px 0 60px;
	}
	.c-ttl__01{
		margin-bottom: 60px;
		@include mq(xl){
			margin-bottom: 40px;
		}
		@include mq(){
			margin-bottom: 29px;
		}
	}
	.c-ttl__02{
		@include mq(){
			margin-bottom: 18px;
		}
	}
}

.technology-flex{
	@include flex(0,center,wrap);
	&__ct{
		width: 54.85%;
		padding-right: 112px;
		@include mq(xl){
			padding-right: 25px;
			width: 62%;
		}
		@include mq(){
			padding-right: 0;
			width: 100%;
			margin-bottom: 24px;
		}
	}
	&__img{
		width: 45.15%;
		@include aspect-ratio(524px,348px);
		@include mq(xl){
			width: 38%;
		}
		@include mq(){
			width: 100%;
		}
	}
}

.sec-offer{
	padding: 126px 0 120px;
	background: #F3F3F3;
	@include mq(xl){
		padding: 80px 0;
	}
	@include mq(){
		padding: 56px 0 61px;
	}
	.c-ttl__01{
		margin-bottom: 68px;
		@include mq(xl){
			margin-bottom: 40px;
		}
		@include mq(){
			margin-bottom: 30px;
		}
	}
	.c-ttl__02{
		margin-bottom: 36px;
		@include mq(xl){
			margin-bottom: 25px;
		}
		@include mq(){
			margin-bottom: 20px;
		}
	}
	.eoyster--02{
		&:not(:last-child){
			margin-bottom: 40px;
			@include mq(){
				margin-bottom: 20px;
			}
		}
	}
	&--02{
		padding: 83px 0 118px;
		@include mq(){
			padding: 39px 0 60px;
			.eoyster-box{
				// top: 34.5%;
				top: 0;
			}
			.eoyster-bg img{
				max-height: max-content;
			}
		}
	}
}

.offer-block{
	margin-bottom: 69px;
	@include mq(xl){
		margin-bottom: 40px;
	}
	@include mq(){
		margin-bottom: 54px;
	}
}