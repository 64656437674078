@use 'vars' as *;
.page-franchise_index {
	.vol-tabs.tab-menu {
		@include mq{
			margin-left: 0;
			margin-bottom: 60px;
		}
	}

}
.franchise-intro{
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 50px;
	}
	&__slider{
		margin-bottom: 75px;
		@include mq{
			margin-bottom: 32px;
		}
	}
	&__ttl{
		@include mq{
			margin-bottom: 34px;
		}
	}
	&__desc{
		margin-bottom: 50px;
		@include mq{
			margin-bottom: 30px;
		}
	}
	&__control{
		@include flex(0,0,wrap);
		gap: 0 32px;
		margin-bottom: 136px;
		@include mq(tb){
			margin-bottom: 100px;
		}
		@include mq{
			margin-bottom: 60px;
		}
		@include mq(sm){
			gap: 24px 0;
		}
		li{
			width: 320px;
			@include mq{
				width: 280px;
			}
			@include mq(sm){
				width: 100%;
			}
			a{
				max-width: 100%;
			}
		}
	}
	&__flex{
		@include flex(0,0,wrap);
		&-slider{
			width: 46.58%;
			@include mq(tb){
				width: 100%;
			}
			div{
				@include mq{
					@include aspect-ratio(39,45);
				}
			}
		}
		&-ct{
			width: calc(100% - 46.58%);
			padding-left: 5.85vw;
			padding-right: 15px;
			@include mq(tb){
				width: 100%;
				padding: 60px 15px 0;
			}
		}
		&-dl{
			max-width: 524px;
			width: 100%;
			margin-bottom: 74px;
			@include mq{
				max-width: 100%;
				margin-bottom: 53px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		&-dt{
			margin-bottom: 30px;
			@include mq{
				margin-bottom: 6px;
			}
		}
	}
}

.sec-profitability{
	.c-ttl__01{
		margin-bottom: 62px;
		@include mq{
			margin-bottom: 24px;
		}
	}
}

.profitability-block{
	margin-bottom: 110px;
	@include mq{
		margin-bottom: 50px;
	}
	&__ttl{
		margin-bottom: 65px;
		@include mq{
			margin-bottom: 26px;
		}
	}
	&__model{
		display: grid;
		gap: 40px 40px;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		@include mq(tb){
			grid-template-columns: 1fr 1fr;
			gap: 30px;
		}
		@include mq{
			gap: 18px;
		}
		&-item{
			border-radius: 8px;
			box-shadow: 0 3px 6px rgba(#000, 0.16);
			padding: 32px 18px;
			@include mq{
				padding: 8px 10px;
				&:last-child{
					position: relative;
					left: 50%;
				}
			}
		}
		&-ttl{
			text-align: center;
			@include mq{
				@include font-size(14);
				margin-bottom: 4px;
			}
		}
		&-number{
			text-align: center;
			@include font-size(16);
			@include flex(center, center,wrap);
			margin-bottom: 24px;
			gap: 0 13px;
			position: relative;
			@include mq(xl){
				gap: 0 3px;
			}
			@include mq{
				@include font-size(12);
				margin-bottom: 13px;
			}
			span{
				font-family: $font-BodoniModa;
				font-size: clamp(3.7rem, 2.604rem + 2.81vw, 8rem);
				font-weight: 700;
				line-height: 1;
				color: #0A1F36;
			}
			.number-sm{
				font-size: clamp(2.2rem, 1.741rem + 1.176vw, 4rem);
				font-weight: 700;
			}
			.txt-to{
				font-size: clamp(1.5rem, 0.863rem + 1.634vw, 4rem);
			}
			.txt-lg{
				font-family: $font-jp;
				font-size: clamp(1.9rem, 1.314rem + 1.503vw, 4.2rem);
				font-weight: 900;
				line-height: 1;
			}
			&.txt-lg02{
				font-size: clamp(1.4rem, 1.043rem + 0.915vw, 2.8rem);
				text-align: left;
				font-weight: 900;
			}
			&--business{
				>span{
					@include font-size(16);
					margin-bottom: 34px;
					position: relative;
					@include mq{
						@include font-size(12);
						margin-bottom: 13px;
					}
				}
				.txt-to{
					position: absolute;
					top: 36px;
					left: 50%;
					transform: translateX(-50%) rotate(90deg);
					@include mq{
						top: 20px;
					}
				}
			}
		} 
		&-ico{
			text-align: center;
		}
	}
	&__note{
		margin-top: 30px;
		&--right{
			max-width: 362px;
			margin-left: auto;
			margin-right: 0;
			@include mq(tb){
				display: none;
			}
		}
	}


	&__costs{
		&-dl{
			@include flex(0,center,wrap);
			border-bottom: 1px solid #DBDAD2;
			&:first-child{
				border-top: 1px solid #DBDAD2;
			}
		}
		&-dt, &-dd{
			padding: 38px 0;
			@include mq{
				padding: 18px 0;
			}
		}
		&-dt{
			width: 265px;
			@include mq{
				width: 150px;
			}
		}
		&-dd{
			width: calc(100% - 265px);
			@include mq{
				width: calc(100% - 150px);
				text-align: right;
				padding-right: 28px;
			}
		}
	}

	&--costs{
		.profitability-block__ttl{
			margin-bottom: 50px;
			@include mq{
				margin-bottom: 28px;
			}
		}
	}
	&--income{
		.profitability-block{
			&__note{
				margin-top: 17px;
				@include mq{
					margin-top: 48px;
				}
			}
			&__ttl{
				margin-bottom: 29px;
				@include mq{
					margin-bottom: 0;
				}
			}
		}
	}
	&__income{
		overflow-x: auto;
		table{
			width: 100%;
			@include mq(tb){
				width: 960px;
			}
			tr{
				th, td{
					padding: 33px 0;
					border-bottom: 1px solid #DBDAD2;
					text-align: center;
					@include mq{
						padding: 19px 0;
					}
					&:first-child{
						width: 17%;
					}
				}
				th{
					font-weight: 600;
					padding: 20px 0;
				}
				td{
					&:first-child{
						text-align: left;
					}
				}
			}

		}
	}
}


.sec-support{
	padding: 130px 0 70px;
	@include mq{
		padding: 56px 0;
		.content-block{
			margin-bottom: 30px;
		}
	}
}

.support-block{
	&__item{
		@include flex(0,start,wrap);
		padding: 40px 0;
		border-bottom: 1px solid #DBDAD2;
		@include mq{
			padding: 30px 0;
		}
	}
	&__ct{
		width: calc(100% - 31%);
		padding-right: 80px;
		position: relative;
		top: -8px;
		@include mq(tb){
			padding-right: 30px;
			width: 65%;
		}
		@include mq(sm){
			width: 100%;
			padding-right: 0;
			margin-bottom: 16px;
		}
		&-txt-lead{
			@include font-size(21);
			font-weight: 600;
			margin-bottom: 7px;
			@include mq{
				@include font-size(18);
				line-height: 1.6;
			}
		}
		&-ttl{
			margin-bottom: 38px;
			@include mq{
				margin-bottom: 24px;
			}
		}
	}
	&__img{
		width: 31%;
		border-radius: 8px;
		overflow: hidden;
		@include mq(tb){
			width: 35%;
		}
		@include mq(sm){
			width: 100%;
		}
	}
}


.support-process{
	margin-bottom: 120px;
	@include mq{
		margin-bottom: 60px;
	}
	&__ttl{
		margin-bottom: 68px;
		@include mq{
			margin-bottom: 62px;
		}
	}

	.guide-list{
		&__item{
			align-items: center;
			padding-bottom: 42px;
			margin-bottom: 38px;
			@include mq{
				margin-bottom: 30px;
			}
		}
	}
}

.franchise-bnr{
	padding-bottom: 120px;
	@include mq{
		padding-bottom: 60px;
	}
	.eoyster-bg img{
		min-height: 521px;
	}
	.eoyster-box{
		&__heading{
			@include font-size(32);
		}
		&__btn{
			gap: 18px;
			&-item{
				@include mq(tb){
					width: 250px;
				}
				@include mq{
					width: 100%;
					max-width: 326px;
				}
			}
		}
	}
	@include mq{
		.eoyster-bg img{
			min-height: 650px;
			max-height: 650px;
		}
		.eoyster-box{
			&__heading{
				@include font-size(26);
				margin-bottom: 16px;
			}
			&__ttl{
				line-height: 1.8;
				margin-bottom: 3px;
			}
			&__btn{
				gap: 26px 18px;
			}
		}
	}
	@include mq(sm){
		.eoyster-box{
			padding-top: 0;
			top: 60px;
			transform: none;
		}
		.eoyster-bg img{
			min-height: 855px;
			max-height: 855px;
		}
	}
}