@use "vars" as *;

.sec-mv{
	position: relative;
}

.page-index{
	overflow-x: hidden;
}

.mv-slider{
	&__item{
		img{
			width: 100%;
		}
		@include mq(){
			@include aspect-ratio(390px,790px);
		}
	}
	&__ct{
		// max-width: 1752px;
		padding: 0 104px;
		position: absolute;
		width: 100%;
		left: 50%;
		top: 38.5%;
		transform: translate(-50%,-50%);
		@include mq(xl){
			padding: 0 20px;
			top: 33.5%;
		}
		@include mq(){
			top: 37.8%;
			padding: 0 16px;
		}
		&-ttl{
			color: #fff;
			font-weight: bold;
			margin-bottom: 32px;
			font-family: $font-en;
			@include font-size(50);
			@include mq(xl){
				margin-bottom: 20px;
				@include font-size(40);
			}
			@include mq(xl){
				margin-bottom: 12px;
				@include font-size(36);
			}
			@include mq(){
				margin-bottom: 16px;
				@include font-size(30);
			}
		}
		&-desc{
			color: #fff;
			font-weight: 500;
			@include font-size(14);
			@include mq(){
				line-height: 1.667;
				@include font-size(12);
			}
		}
	}
	&__news{
		bottom: 361px;
		position: absolute;
		max-width: 454px;
		width: 100%;
		right: 104px;
		border-radius: 8px;
		background: rgba(13, 38, 65, .9);
		padding: 23px 50px 22px 30px;
		@include flex(0,center);
		@include mq(lp){
			bottom: 280px;
		}
		@include mq(xl){
			padding: 15px 45px 15px 15px;
			bottom: 150px;
			max-width: 400px;
		}
		@include mq(){
			padding: 15px 35px 21px 12px;
			background: rgba(10,31,54,.9);
			left: 50%;
			bottom: 241px;
			right: auto;
			width: 92%;
			max-width: 400px;
			transform: translateX(-50%);
		}
		&-img{
			width: 53px;
			@include mq(){
				width: 52px;
			}
			img{
				width: 100%;
			}
		}
		&-ct{
			width: calc(100% - 53px);
			padding-left: 18px;
			@include flex(0,center);
			color: #fff;
			@include mq(){
				top: 39.1%;
				width: calc(100% - 52px);
				padding-left: 22px;
			}
		}
		&-ttl{
			line-height: 1.2;
			width: 91px;
			padding-right: 5px;
			letter-spacing: -1px;
			font-weight: bold;
			font-family: $font-en;
			@include font-size(24);
			@include mq(xl){
				letter-spacing: 0;
				@include font-size(20);
				width: 70px;
			}
			@include mq(){
				width: 63px;
			}
		}
		&-desc{
			width: calc(100% - 91px);
			font-weight: 500;
			line-height: 1.7;
			@include font-size(11);
			@include mq(xl){
				width: calc(100% - 70px);
			}
			@include mq(){
				width: calc(100% - 63px);
				padding-top: 6px;
				padding-right: 5px;
			}
		}
		.c-arrow{
			width: 36px;
			height: 36px;
			right: 14px;
			top: 50%;
			overflow: hidden;
			border-radius: 50%;
			position: absolute;
			@include flex(center,center);
			transform: translateY(-50%);
			@include p-after(14px,11px){
				background: url('../img/index/arrow02.svg') center no-repeat;
				background-size: contain;
				left: -20px;
				top: 50%;
				transform: translateY(-50%);
			}
			@include mq(xl){
				right: 5px;
			}
			@include p-before(0%,0%){
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: #fff;
				border-radius: 50%;
				transition: all 0.5s;
			}
			img{
				width: 14px;
				transition: all 0.3s;
			}
		}
		@include mq(md-min){
			&:hover{
				opacity: 1;
				.c-arrow{
					img{
						opacity: 0;
						transition: all 0.3s;
					}
					&::after{
						left: 10px;
						transition: all 0.5s;
						transition-delay: 0.2s;
					}
					&::before{
						width: 100%;
						height: 100%;
						transition: all 0.5s;
					}
				}
			}
		}
	}
	&__oysterbar{
		background: rgba(#0A1F36,0.5);
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 115px 0;
		@include mq(mac){
			padding: 80px 0;
		}
		@include mq(xl){
			padding: 30px 0;
		}
		@include mq(){
			padding: 37px 0 44px;
			background: #0A1F36;
			position: static
		}
		.inner{
			@include flex(between,center);
			@include mq(){
				flex-wrap: wrap;
			}
		}
		.c-ttl__01{
			@include mq(){
				width: 100%;
				margin-bottom: 15px;
			}
		}
		&-link{
			@include mq(){
				width: 100%;
				text-align: right;
				margin-top: 20px;
			}
		}
	}
}

.loop{
	position: relative;
	height: 320px;
	@include mq(lp){
		height: 266px;
	}
	@include mq(xl){
		height: 233px;
	}
	@include mq(){
		height: 192px;
	}
}

.list-loop{
	display: flex;
	flex-direction: row;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	white-space: nowrap;
	-webkit-animation: slider-move 50s linear infinite;
	animation: slider-move 50s linear infinite;
	&:hover{
		-webkit-animation-play-state: paused;
		animation-play-state: paused;
	}
	&__item{
		width: 480px;
		@include mq(lp){
			width: 400px;
		}
		@include mq(xl){
			width: 350px;
		}
		@include mq(){
			width: 288px;
		}
		img{
			width: 100%;
		}
	}
	&__img{
		@include aspect-ratio(480px,320px);
	}
}

@-webkit-keyframes slider-move {
	0% {
		-webkit-transform: translate(-50%);
		transform: translate(-50%);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
@keyframes slider-move {
	0% {
		-webkit-transform: translate(-50%);
		transform: translate(-50%);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

.sec-club{
	background: #fff;
	&__link{
		padding: 42px 0;
		color: #000;
		position: relative;
		padding-right: 136px;
		@include flex(between,center);
		position: relative;
		@include mq(mac){
			padding-right: 90px;
		}
		@include mq(xl){
			padding-right: 0;
			flex-wrap: wrap;
			justify-content: center;
		}
		@include mq(){
			padding-right: 0;
			padding: 34px 0 36px;
		}
		.c-arrow{
			border: 1px solid #000;
			width: 40px;
			height: 40px;
			position: absolute;
			background: #fff;
			border-radius: 50%;
			top: 50%;
			right: 0;
			overflow: hidden;
			transition: all 0.3s;
			transform: translateY(-50%);
			@include mq(){
				display: none;
				width: 36px;
				height: 36px;
			}
			@include p-before(14px,11px){
				background: url('../img/index/arrow02.svg') center no-repeat;
				background-size: contain;
				right: 13px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				transition: all 0.3s;
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
			@include p-after(14px,11px){
				background: url('../img/index/arrow.svg') center no-repeat;
				background-size: contain;
				left: -20px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 2;
				@include mq(){
					width: 12px;
					height: 10px;
				}
			}
		}
		@include mq(md-min){
			&:hover{
				opacity: 1;
				.c-arrow{
					background: rgba(13, 38, 65, .9);
					transition: all 0.3s;
					&::before{
						opacity: 0;
						transition: all 0.3s;
					}
					&::after{
						left: 13px;
						transition: all 0.3s;
					}
				}
			}
		}
	}
}

.club-flex{
	@include flex(0,center);
	width: 100%;
	@include mq(){
		flex-wrap: wrap;
		padding: 0 32px;
	}
	@include mq(xs){
		padding: 0 12px;
	}
	&__logo{
		width: 164.5px;
		@include mq(){
			width: 100%;
			text-align: left;
			margin-bottom: 51px;
		}
		img{
			max-width: 164px;
			width: 100%;
			@include mq(xl){
				margin: 0 auto;
			}
			@include mq(){
				margin: 0;
				max-width: 302px;
			}
		}
	}
	&__opc{
		width: 590px;
		width: calc(100% - 164.5px);
		padding-left: 64px;
		@include mq(xl){
			padding-left: 25px;
			padding-right: 50px;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			p{
				padding-right: 50px;
				position: relative;
				@include p-after(34px,34px){
					background: #fff;
					border-radius: 50%;
					right: 0;
					border: 1px solid black;
					transform: translateY(-50%);
					top: 61%;
				}
				@include p-before(12px,10px){
					background: url('../img/index/arrow02.svg') center no-repeat;
					background-size: contain;
					right: 11px;
					top: 50%;
					top: 61%;
					transform: translateY(-50%);
					z-index: 2;
				}
			}
		}
		p{
			font-weight: 500;
		}
		&-ttl{
			line-height: 1.2;
			margin-bottom: 25px;
			padding-top: 24px;
			font-weight: 500;
			@include flex(0,center,wrap);
			@include mq(mac){
				padding-top: 0;
				margin-bottom: 15px;
			}
			@include mq(){
				margin-bottom: 25px;
			}
			span{
				margin-right: 23px;
				font-weight: 600;
				@include font-size(29);
				@include mq(){
					width: 100%;
					display: block;
					margin-bottom: 14px;
					margin-right: 0;
					@include font-size(22);
				}
			}
		}
	}
}
.club-img{
	width: 320px;
	@include mq(){
		width: calc(100% + 32px);
		margin: 0 -16px;
	}
	img{
		width: 100%;
	}
}

.sec-sea{
	margin-bottom: -2px;
	position: relative;
	.inner{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		@include mq(){
			top: 52%;
		}
	}
	&--02{
		.inner{
			@include mq(){
				top: 50%;
			}
		}
	}
}

.sea-img{
	&__item{
		img{
			width: 100%;
			@include mq(xl){
				min-height: 400px;
				object-fit: cover;
			}
			@include mq(tb){
				min-height: 0;
			}
		}
	}
	&--02{
		.sea-img__item{
			@include mq(){
				@include aspect-ratio(390px,600px);
			}
			img {
				min-height: 701px;
				object-fit: cover;
				@include mq(xl){
					min-height: 500px;
				}
				@include mq(){
					min-height: 600px;
				}
			}
		}
	}
}

.sea-flex{
	@include flex();
	@include mq(){
		flex-wrap: wrap;
	}
	&__logo{
		width: 50%;
		padding-top: 10px;
		@include mq(xl){
			padding-top: 0;
			width: 35%;
			padding-right: 20px;
		}
		@include mq(){
			width: 100%;
			padding-right: 0;
			margin-bottom: 24px;
		}
		img{
			max-width: 410px;
			width: 100%;
			@include mq(xl){
				max-width: 280px;
			}
			@include mq(){
				max-width: 206px;
			}
		}
	}
	&__ct{
		width: 50%;
		padding-left: 56px;
		@include mq(mac){
			padding-left: 0;
		}
		@include mq(xl){
			width: 65%;
		}
		@include mq(){
			width: 100%;
		}
		.c-txt__01{
			margin-bottom: 51px;
			@include mq(lp){
				margin-bottom: 25px;
			}
			@include mq(){
				margin-bottom: 30px;
			}
		}
	}
	&__ttl{
		@include mq(){
			letter-spacing: -0.5px;
		}
	}
	&--02{
		display: block;
		.sea-flex__ct{
			padding-left: 0;
			width: 100%;
			.c-txt__01{
				max-width: 800px;
			}
		}
	}
	&--03{
		@include mq(){
			.sea-flex__logo{
				margin-bottom: 35px;
				img {
					max-width: 228px;
				}
			}
			.sea-flex__ct .c-txt__01 {
				margin-bottom: 0;
			}
		}
	}
}

.sec-th{
	padding: 72px 0;
	background: #0A1F36;
	@include mq(){
		padding: 51px 0;
	}
}

.th-flex{
	@include flex();
	@include mq(){
		flex-wrap: wrap;
	}
	&__ct{
		width: 50%;
		padding-right: 50px;
		@include mq(xl){
			padding-right: 0;
		}
		@include mq(){
			width: 100%;
			margin-bottom: 31px;
		}
	}
	&__ttl{
		font-weight: 600;
		color: #fff;
		margin-bottom: 30px;
		@include font-size(29);
		@include mq(xl){
			margin-bottom: 15px;
			@include font-size(22);
		}
		@include mq(){
			margin-bottom: 22px;
			line-height: 1.6;
		}
	}
	&__list{
		margin: 0 -20px;
		width: calc(50% + 40px);
		padding-left: 56px;
		@include flex();
		@include mq(xl){
			margin: 0 -10px;
			width: calc(50% + 20px);
			padding-left: 20px;
		}
		@include mq(){
			flex-wrap: wrap;
			margin: 0;
			width: 100%;
			padding-left: 0;
			gap: 40px 0;
		}
		&-item{
			width: 50%;
			padding: 0 20px;
			@include mq(xl){
				padding: 0 10px;
			}
			@include mq(sm){
				padding: 0;
				width: 100%;
			}
			a{
				display: block;
				@include mq(md-min){
					&:hover{
						opacity: 1;
						.th-flex__list-img{
							img{
								transition: all 0.7s;
								transform: scale(1.08);
							}
						}
						.th-flex__list-link{
							span{
								&::after{
									width: 0;
									transition: all 0.3s;
								}
								&::before{
									width: 100%;
									transition: all 0.3s;
									transition-delay: 0.3s;
								}
							}
						}
						.c-arrow{
							&::after{
								opacity: 0;
								transition: all 0.3s;
							}
							&::before{
								opacity: 1;
								left: 0;
								transition: all 0.3s;
								transition-delay: 0.3s;
							}
						}
					}
				}
			}
		}
		&-img{
			overflow: hidden;
			border-radius: 10px;
			@include aspect-ratio(312px,178px);
			margin-bottom: 20px;
			overflow: hidden;
			img{
				transition: all 0.7s;
				@include mq(){
					height: 99% !important;
				}
			}
			@include mq(xl){
				margin-bottom: 12px;
			}
			@include mq(){
				border-radius: 3vw;
				margin-bottom: 17px;
			}
		}
		&-link{
			color: #fff;
			padding-right: 20px;
			// font-family: $font-en;
			position: relative;
			@include mq(){
				padding-right: 35px;
			}
			// @include p-before(14px,11px){
			// 	background: url('../img/index/arrow.svg') center no-repeat;
			// 	background-size: contain;
			// 	right: 0;
			// 	top: 39%;
			// 	transform: translateY(-50%);
			// 	z-index: 2;
			// 	@include mq(){
			// 		width: 12px;
			// 		height: 10px;
			// 		right: 16px;
			// 	}
			// }
			span{
				display: inline-block;
				padding-bottom: 9px;
				@include font-size(16);
				position: relative;
				@include mq(xl){
					@include font-size(12.5);
				}
				@include mq(){
					padding-bottom: 7px;
					@include font-size(14);
				}
				@include p-after(100%,1px){
					border-bottom: 1px solid #fff;
					bottom: 0;
					right: 0;
				}
				@include p-before(0%,1px){
					border-bottom: 1px solid #fff;
					bottom: 0;
					left: 0;
				}
			}
			.c-arrow{
				position: absolute;
				width: 14px;
				right: 0;
				top: 50%;
				overflow: hidden;
				height: 12px;
				transform: translateY(-50%);
				@include mq(){
					width: 12px;
					height: 10px;
					right: 15px;
					top: 41%;
				}
				&::after,&::before{
					content: '';
					position: absolute;
					width: 14px;
					height: 11px;
					background: url('../img/index/arrow.svg') center no-repeat;
					background-size: contain;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					@include mq(){
						width: 12px;
						height: 10px;
					}
				}
				&::before{
					left: -20px;
					opacity: 0;
					transition: all 0;
				}
			}
		}
	}
}

.sec-eoyster{
	padding: 130px 0 39px;
	@include mq(xl){
		padding: 60px 0 50px;
	}
	@include mq(){
		padding: 56px 0 0;
	}
	.c-ttl__01{
		margin-bottom: 72px;
		@include mq(xl){
			margin-bottom: 31px;
		}
	}
}

.eoyster{
	position: relative;
	max-width: 1440px;
	width: 100%;
	display: block;
	margin: 0 auto;
	overflow: hidden;
	border-radius: 10px;
	@include mq(){
		border-radius: 16px;
	}
	@include mq(md-min){
		&:hover{
			opacity: 1;
			.c-btn__01{
				span{
					&::before{
						width: 0;
						transition: all 0.3s;
					}
					&::after{
						width: 100%;
						transition: all 0.3s;
						transition-delay: 0.3s;
					}
				}
				.c-arrow{
					background: rgba(13, 38, 65, .9);
					transition: all 0.3s;
					&::before{
						opacity: 0;
						transition: all 0.3s;
					}
					&::after{
						left: 13px;
						transition: all 0.3s;
					}
				}
			}
			.eoyster-bg{
				img{
					transition: all 0.5s;
					transform: scale(1.1);
				}
			}
		}
	}
	&-bg{
		img{
			width: 100%;
			min-height: 441px;
			transition: all 0.5s;
			object-fit: cover;
			@include mq(xl){
				min-height: 350px;
			}
			@include mq(){
				// min-height: 480px;
				// max-height: 480px;
				height: 650px;
				width: 100%;
			}
		}
	}
}


.sec-tiktok{
	padding: 80px 0 119px;
	@include mq(xl){
		padding: 50px 0;
	}
	@include mq(){
		padding: 19px 0 60px;
	}
}

.tiktok{
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	display: block;
	@include mq(){
		border-radius: 16px;
	}
	&-bg{
		border-radius: 10px;
		img{
			width: 100%;
			min-height: 650px;
			object-fit: cover;
			transition: all 0.5s;
			@include mq(xl){
				min-height: 440px;
			}
			@include mq(){
				// max-height: 485px;
				// min-height: 485px;
				height: 650px;
			}
		}
	}
	@include mq(md-min){
		&:hover{
			opacity: 1;
			.tiktok-bg{
				img{
					transition: all 0.5s;
					transform: scale(1.1);
				}
			}
			.tiktok-box__btn{
				span{
					&::after{
						width: 0;
						transition: all 0.3s;
					}
					&::before{
						width: 100%;
						transition: all 0.3s;
						transition-delay: 0.3s;
					}
				}
			}
		}
	}
	&--02{
		@include mq(){
			.tiktok-box{
				top: 31%;
			}
		}
		.tiktok-bg{
			img{
				min-height: 387px;
				@include mq(){
					min-height: 515px;
					max-height: 515px;
				}
			}
		}
		.tiktok-box__btn{
			&::before{
				background: url('../img/index/arrow05.svg') center no-repeat;
				background-size: contain;
			}
		}
	}
}

.tiktok-box{
	padding: 130px 121px 88px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	@include mq(lp){
		padding: 100px 50px;
	}
	@include mq(xl){
		padding: 60px 30px;
	}
	@include mq(){
		padding: 57px 16px 64px;
		border-radius: 15px;
	}
	@include mq(sm){
		top: 0;
		transform: none;
	}
	.c-ttl__01{
		margin-bottom: 71px;
		@include mq(lp){
			margin-bottom: 40px;
		}
		@include mq(xl){
			margin-bottom: 25px;
		}
		@include mq(){
			margin-bottom: 33px;
		}
	}
	.c-ttl__02{
		margin-bottom: 30px;
		@include mq(lp){
			margin-bottom: 20px;
		}
		@include mq(){
			margin-bottom: 24px;
		}
	}
	.c-txt__01{
		margin-bottom: 25px;
		@include mq(){
			margin-bottom: 41px;
		}
	}
	&__btn{
		font-family: $font-en;
		color: #fff;
		display: inline-block;
		padding-left: 185px;
		position: absolute;
		top: 50%;
		right: 121px;
		transform: translateY(-50%);
		@include font-size(16);
		@include mq(lp){
			right: 50px;
		}
		@include mq(xl){
			right: 30px;
			padding-left: 100px;
		}
		@include mq(){
			padding-left: 67px;
			transform: translateY(0);
			position: static;
			margin-left: auto;
			max-width: 132px;
			display: block;
			@include font-size(14);
		}
		span{
			display: inline-block;
			padding-bottom: 6px;
			position: relative;
			@include p-after(100%,1px){
				border-bottom: 1px solid #fff;
				bottom: 0;
				right: 0;
			}
			@include p-before(0%,1px){
				border-bottom: 1px solid #fff;
				bottom: 0;
				left: 0;
			}
		}
		@include p-after(160px,160px){
			background: #fff;
			border-radius: 50%;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			@include mq(xl){
				width: 80px;
				height: 80px;
			}
			@include mq(){
				width: 56px;
				height: 56px;
			}
		}
		@include p-before(27px,24px){
			background: url('../img/index/arrow03.svg') center no-repeat;
			background-size: contain;
			left: 70px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			@include mq(xl){
				left: 32px;
				width: 20px;
				height: 18px;
			}
			@include mq(){
				left: 21px;
				width: 12px;
				height: 11px;
			}
		}
	}
}

.sec-contact{
	background: #202A34;
	padding: 63px 0 67px;
	@include mq(){
		padding: 42px 0 40px;
	}
}

.contact-flex{
	@include flex(between,center);
	padding-left: 136px;
	@include mq(lp){
		padding-left: 0;
	}
	@include mq(){
		flex-wrap: wrap;
	}
	&__ct{
		width: 650px;
		@include mq(xl){
			padding-right: 20px;
		}
		@include mq(){
			padding-right: 0;
			width: 100%;
			margin-bottom: 30px;
		}
		.c-ttl__02{
			margin-bottom: 29px;
			@include mq(lp){
				margin-bottom: 20px;
			}
			@include mq(xl){
				margin-bottom: 12px;
			}
			@include mq(){
				margin-bottom: 25px;
			}
		}
	}
	&__btn{
		width: 320px;
		padding-top: 32px;
		@include mq(){
			padding-top: 0;
			width: 100%;
		}
	}
}

.sec-app{
	padding: 65px 0 66px;
	background: #F3F3F3;
	@include mq(){
		padding: 47px 0 60px;
	}
}

.app-flex{
	@include flex(0,center);
	@include mq(xl){
		flex-wrap: wrap;
	}
	&__left{
		padding-right: 72px;
		margin-right: 70px;
		padding-top: 10px;
		padding-bottom: 10px;
		border-right: 1px solid #DBDAD2;
		@include flex(0,center);
		@include mq(lp){
			padding-right: 30px;
			margin-right: 30px;
		}
		@include mq(xl){
			padding-right: 0;
			margin-right: 0;
			border-right: 0;
			border-bottom: 1px solid #DBDAD2;
			padding-bottom: 15px;
			margin-bottom: 15px;
			width: 100%;
		}
		@include mq(){
			flex-wrap: wrap;
			padding-bottom: 30px;
			margin-bottom: 27px;
		}
	}
	&__list{
		margin-left: 72px;
		@include flex(center,center);
		gap: 0 24px;
		@include mq(lp){
			gap: 0 15px;
			margin-left: 30px;
		}
		@include mq(){
			gap: 0 10px;
			width: 100%;
			margin-left: 0;
			margin-top: 15px;
			justify-content: flex-start;
		}
		&-item{
			a{
				display: block;
				img{
					width: auto;
					height: 40px;
					@include mq(){
						height: 57px;
					}
				}
			}
		}
	}
	&__right{
		@include flex(0,center);
		@include mq(){
			flex-wrap: wrap;
		}
	}
	&__share{
		margin-left: 73px;
		@include flex(center,center);
		gap: 0 24px;
		@include mq(lp){
			gap: 0 15px;
			margin-left: 30px;
		}
		@include mq(){
			gap: 0 10px;
			margin-top: 15px;
			width: 100%;
			margin-left: 0;
			justify-content: flex-start;
		}
		&-item{
			a{
				display: block;
				img{
					width: 40px;
					@include mq(){
						width: 60px;
					}
				}
			}
		}
	}
}