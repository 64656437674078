@use 'vars' as *;
.page-oyster_index{
	.content .inner {
		width: 100%;
		max-width: 1432px;
	}
	.c-ttl__01{
		.c-ft--jp{
			@include mq{
				padding-top: 4px;
			}
		}
	}
	.single-block__ct{
		padding-left: 110px;
		@include mq(xl){
			padding-right: 0;
			padding-left: 8.835vw;
		}
		@include mq{
			padding-right: 16px;
			padding-left: 16px;
		}
	}
	.content-right{
		@include mq{
			overflow-x: hidden;
		}
	}
}
.oyster-top{
	//max-width: 1536px;
	padding-right: 110px;
	@include mq(xl){
		padding-right: 8.835vw;
	}
	@include mq{
		padding-right: 0;
	}

	.single-block__img{
		img{
			min-height: 494px;
			object-fit: cover;
			@include mq{
				min-height: 0;
				width: 100%;
			}
		}
	}
}

.oyster-type{
	padding: 130px 5vw 116px;
	@include mq{
		padding: 54px 0;
	}
	&__ttl{
		margin-bottom: 65px;
		@include mq{
			margin-bottom: 30px;
		}
	}
	&__intro{
		max-width: 1296px;
		margin-bottom: 115px;
		@include flex(between,0,wrap);
		@include mq(xl){
			align-items: flex-end;
		}
		@include mq{
			margin-bottom: 60px;
			padding-bottom: 60px;
			border-bottom: 1px solid #DBDAD2;
		}
		&-ct{
			width: 50.92%;
			@include mq{
				width: 100%;
				margin-bottom: 26px;
			}
		} 
		&-img{
			width: 40.43%;
			@include mq(tb){
				width: 45.43%;
			}
			@include mq{
				width: 100%;
			}
		}
		&-caption{
			@include font-size(16);
			margin-top: 18px;
			display: block;
			@include mq{
				@include font-size(14);
				margin-top: 13px;
			}
		}
	}
	&__wrapper{
		max-width: 1160px;
		margin: 0 auto 56px;
	}
	&__table{
		margin-bottom: 70px;
		padding-bottom: 80px;
		@include mq{
			margin-bottom: 60px;
			padding-bottom: 60px;
		}
		@include mq(sm){
			overflow-x: auto;
			margin-right: -15px;
		}
		table{
			width: 100%;
			border: 1px solid #DBDAD2;
			@include mq(sm){
				min-width: 484px;
				@include font-size(12);
			}
			tr{
				th, td{
					width: 28.92%;
					border-right: 1px solid #DBDAD2;
					border-bottom: 1px solid #DBDAD2;
					padding: 36px 40px;
					vertical-align: top;
					@include mq(tb){
						padding: 13px 16px;
					}
					@include mq{
						width: calc((100% - 12.39%)/3);
					}
					&:first-child{
						width: calc(100% - 29.65% * 3);
						@include mq{
							width: 12.39%;
						}
					}
				}
				th{
					font-weight: 500;
					text-align: center;
					@include mq{
						line-height: 2.2;
					}
				}
				td{
					@include fts(14,28);
					@include mq{
						@include font-size(12);
						line-height: 1.5;
						padding: 14px 16px;
					}
					&:first-child{
						padding: 40px 20px;
						text-align: center;
						@include fts(16,32);
						@include mq(tb){
							padding: 11px 0 11px 5px;
							text-align: left;
						}
						@include mq{
							@include font-size(14);
						}
					}
					&:nth-child(2){
						background: #F1F2F5;
					}
					&:nth-child(3){
						background: #F1F2F5;
					}
					&:nth-child(4){
						background: #F8F3EE;
					}
				}
			}
		}
	}
}

.has-border{
	border-bottom: 1px solid #DBDAD2;
}

.oyster-map{
	padding-bottom: 80px;
	margin-bottom: 70px;
	@include mq{
		padding-bottom: 24px;
		margin-bottom: 60px;
	}
	&__ttl{
		margin-bottom: 25px;
		@include mq{
			@include font-size(18);
			line-height: 1.95;
			margin-bottom: 11px;
		}
	}
	&__content{
		margin: 0 -1px;
	}
	&__desc{
		margin-bottom: 73px;
		@include mq{
			margin-bottom: 52px;
		}
	}
	&__box{
		padding: 20px 16px;
		background: #F3F3F3;
		@include mq{
			@include font-size(12);
			margin-bottom: 21px;
		}
	}
	&__symbol{
		@include flex(0,center,wrap);
		@include mq{
			margin-bottom: 16px;
		}
		li{
			margin-right: 20px;
			@include mq{
				@include font-size(12);
				font-weight: 500;
			}
			&:last-of-type{
				margin-right: 0;
			}
			&::before{
				content: "●";
				display: inline-block;
				color: #566081;
				margin-right: 5px;
			}
			&:nth-child(1){
				background: #F1F2F5;
			}
			&:nth-child(2){
				background: #F8F3EE;
				&::before{
					color: #D0AF8E;
				}
			}
		}
		
	}
	&__note{
		display: inline-block;
		border: 1px dotted #BA915B;
		padding: 14px 20px;
		color: #BA915B;
		font-weight: 500;
		background: #fff;
		@include mq{
			@include font-size(12);
			line-height: 1.5;
			padding: 15px 15px;
			margin-bottom: 15px;
		}
	}

	&__content{
		@include mq{
			margin-right: -15px;
			overflow-x: auto;
			margin-left: -15px;
		}
		svg{
			width: 100%;
			@include mq(sm){
				width: 482px;
			}
			a{
				rect{
					@include smooth-transition;
				}
				@media (any-hover: hover) {
					transition: all 0.3s;
					&:hover {
						rect{
							opacity: 0.8;
						}
					}
				}
			}
		}
	}
}

.oyster-calendar{
	&__ttl{
		margin-bottom: 35px;
		@include mq{
			@include font-size(18);
			margin-bottom: 56px;
		}
	}
	&__symbol{
		@include flex(0,center,wrap);
		margin-bottom: 30px;
		@include mq{
			margin-bottom: 20px;
		}
		&-label{
			margin-right: 25px;
			@include mq{
				margin-right: 0;
				width: 100%;
				@include font-size(16);
				margin-bottom: 15px;
			}
		}
		&-list{
			@include flex(0,center);
			li{
				width: 172px;
				height: 32px;
				@include flex(center, center);
				position: relative;
				margin-right: 23px;
				@include mq{
					margin-right: 15px;
				}
				&:last-of-type{
					margin-right: 0;
				}
				&::before{
					content: "●";
					display: inline-block;
					color: #566081;
					margin-right: 5px;
				}
				&:nth-child(1){
					background: #F1F2F5;
				}
				&:nth-child(2){
					background: #F8F3EE;
					&::before{
						color: #D0AF8E;
					}
				}
			}
		}
	}
	&__note{
		display: inline-block;
		border: 1px dotted #BA915B;
		padding: 14px 20px;
		color: #BA915B;
		font-weight: 500;
		margin-bottom: 50px;
		@include mq{
			@include font-size(12);
			line-height: 1.5;
			padding: 15px 15px;
			margin-bottom: 30px;
		}
	}
	&__table{
		position: relative;
		border: 1px solid #DBDAD2;
		border-bottom: none;
		// @include mq{
		// 	@include p-after(1px, 100%){
		// 		background: #DBDAD2;
		// 		top: 0;
		// 		right: 1px;
		// 	}
		// }
		.guide-table{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			pointer-events: none;
			span{
				display: block;
				width: calc((86/1160)*100%);
				height: 100%;
				// border-right: 1px solid #DBDAD2;
				position: relative;
				@include p-after(1px, 100%){
					background: #DBDAD2;
					top: 0;
					right: 0;
				}
				@include mq{
					width: calc((26/358)*100%);
				}
				&:first-child{
					width: calc((128/1160)*100%);
					@include mq{
						width: calc((48/358)*100%);
					}
				}
				&:nth-child(3),&:nth-child(4), &:nth-child(5),&:nth-child(10),&:nth-child(11), &:nth-child(12){
					&:after{
						right: -1px;
						@include mq{
							right: 0;
						}
					}
				}
				&:last-child{
					border-right: none;
					&:after{
						display: none;
					}
				}
			}
		}
		table{
			width: 100%;
			// border: 1px solid #DBDAD2;
			@include mq{
				// width: calc(100% - 1px);
				@include font-size(14);
				border-top: none;
				border-bottom: none;
			}
			@include mq(xs){
				@include font-size(12);
			}
			thead{
				tr{
					border-bottom: 1px solid #DBDAD2;
				}
			}
			tr{
				position: relative;
				th{
					width: calc((86/1160)*100%);
					border-right: 1px solid #DBDAD2;
					border-bottom: 1px solid #DBDAD2;
					background: #F3F3F3;
					padding: 36px 0;
					text-align: center;
					@include mq{
						width: calc((26/358)*100%);
						padding: 18px 2.5%;
						line-height: 1.15;
						font-weight: 500;
						border: none;
						background: none;
					}
					
					@media screen and (max-width: 704px){
						padding: 18px 2%;
					}
					&:first-child{
						width: calc((128/1160)*100%);
						background: #fff;
						@include mq{
							width: calc((46/358)*100%);
						}
					}
					&:last-of-type{
						border-right: none;
					}
				}
				td{
					padding: 8px 0;
					@include mq{
						padding: 2px 0;
					}
					&:first-child{
						text-align: center;
						cursor: pointer;
						@media (any-hover: hover) {
							transition: all .2s;
							&:hover{
								opacity: 0.7;
							}
						}
						@include mq{
							@include font-size(10);
						}
					}
					&:last-of-type{
						border-right: none;
					}
					&.area-01{
						position: absolute;
						top: 0;
						width: 44.5%;
						right: 0;
					}
					&.area-02{
						position: absolute;
						top: 26px;
						width: 22.3%;
						right: 37%;
					}
				}
				&.first{
					td{
						padding-top: 24px;
						@include mq{
							padding-top: 8px;
						}
					}
				}
				&.line{
					border-bottom: 1px solid #DBDAD2;
					&:nth-last-child(2){
						@include mq{
							border-bottom: none;
						}
					}
					td{
						padding-bottom: 24px;
						@include mq{
							padding-bottom: 8px;
						}
					}
				}
				&:last-child{
					th{
						border-bottom: none;
					}
				}
			}
		}
	}
}

.scroll-hint-shadow-wrap{
	&::before, &:after{
		display: none;
	}
}

.oyster-nutrition{
	padding: 130px 5vw 120px;
	background: #F3F3F3;
	@include mq{
		padding: 60px 0;
	}
	&__ttl{
		margin-bottom: 70px;
		@include mq{
			margin-bottom: 27px;
		}
	}
	&__intro{
		margin-bottom: 78px;
		max-width: 1296px;
		@include mq{
			margin-bottom: 60px;
		}
		&-ttl{
			margin-bottom: 35px;
			@include mq{
				margin-bottom: 27px;
			}
		}
	}
	&__main{
		max-width: 1160px;
		margin: 0 auto;
		&-ttl{
			margin-bottom: 30px;
			@include mq{
				@include font-size(18);
				margin-bottom: 15px;
			}
		}
		&-desc{
			margin-bottom: 50px;
			@include mq{
				margin-bottom: 25px;
			}
		}
		&-list{
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 30px min(5.833vw, 112px);
			margin-bottom: 56px;
			@include mq{
				grid-template-columns: 1fr;
				max-width: 450px;
				margin: 0 auto 35px;
			}
			@include mq(sm){
				margin-left: -16px;
				margin-right: -16px;
			}
		}
	}
}

.oyster-pairing{
	padding: 130px 5vw 100px;
	.inner{
		max-width: 100%;
	}
	@include mq{
		padding: 58px 0 60px;
	}
	&__ttl{
		margin-bottom: 73px;
		@include mq{
			margin-bottom: 27px;
		}
	}
	.single-block{
		@include flex(between,start,wrap);
		margin-bottom: 80px;
		@include mq{
			margin-bottom: 20px;
		}
		&__ct{
			width: 45.83%;
			padding: 0;
			@include mq{
				width: 100%;
				margin-bottom: 26px;
				.c-ttl__02{
					margin-bottom: 26px;
				}
			}
		}
		&__img{
			width: 45.83%;
			@include mq{
				width: 100%;
			}
		}
	}
	.deliver-box{
		background: #F4F4F4;
		@include mq{
			padding: 54px 15px 60px;
			margin-bottom: 72px;
		}
		.c-ttl__04{
			@include mq{
				text-align: left !important;
				margin-bottom: 32px;
			}
		}

		&__flex-ct{
			@include mq{
				margin-bottom: 26px;
			}
		}
		&.no-bg{
			padding: 0;
			background: none;
			margin-bottom: 0;
			.deliver-box__flex{
				&-ct{
					margin-top: 0;
				}
				&-img{
					@include aspect-ratio(524px,348px);
				}
			}
			.c-ttl__04{
				margin-bottom: 32px;
				@include mq{
					@include font-size(18);
					line-height: 1.8;
					margin-bottom: 13px;
				}
			}
		}
	}
	.guide-list{
		&__item{
			align-items: flex-start;
		}
		&__ct{
			padding-top: 30px;
			&::before{
				top: 5px;
			}
		}
		&--number{
			.guide-list__item{
				margin-bottom: 38px;
				@include mq{
					margin-bottom: 6px;
				}
			}
			.guide-list__ct{
				&:before {
					@include mq{
						top: 27px;
					}
				}
				.c-ttl__05{
					@include mq{
						margin-bottom: 23px;
					}
				}
			}
		}
	}
}

.sticky{
	@include mq{
		background: #f8f8f8;
		position: sticky;
		top: 60px;
		z-index: 100;
		@include p-before(calc(100% - 1px), 100%){
			top: 0;
			left: 1px;
			background: #F3F3F3;
			border-right: 1px solid #dbdad2 !important;
			// border-top: 1px solid #dbdad2 !important;
			border-bottom: 1px solid #dbdad2 !important;
			z-index: -1;
		}
		&:first-child{
			&::before{
				background: #fff;
				width: 100%;
				left: 0px;
			}
		}
		&:last-child{
			&::before{
				border-right: none !important;
			}
		}
	
	}
}

.txt-area{
	width: 100%;
	height: 32px;
	background: #F1F2F5;
	position: relative;
	@include flex(center,center);
	cursor: pointer;
	@include font-size(14);
	line-height: 1.1;
	@media (any-hover: hover) {
		transition: all .2s;
		&:hover{
			opacity: 0.7;
		}
	}
	@include mq(xl){
		@include font-size(12);
	}
	@include mq{
		@include font-size(9);
		height: 23px;
		width: calc(100% + 3px);
		margin-left: -1.5px;
	}
	@include mq(xs){
		@include font-size(7);
	}
	&::before{
		content: "●";
		display: inline-block;
		margin-right: 3px;
		color: #566081;
		@include mq{
			@include font-size(9);
			margin-right: 1px;
		}
	}
	&--yl{
		background: #F8F3EE;
		&::before{
			color: #D0AF8E;
		}
	}
	&--other{
		@include mq(){
			width: calc(100% + 26px);
			margin: 0 -10px;
			background: none;
			@include p-after(50%,100%){
				background: #F1F2F5;
				top: 0;
				left: 45%;
				transform: translateX(-50%);
				z-index: -1;
			}
			&.txt-area--yl{
				&:after{
					background: #F8F3EE;
				}
			}
		}
	}
}


.overlay{
	position: fixed;
	background: #fff;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	@include smooth-transition;
	&.is-show{
		opacity: 0.9;
		visibility: visible;
	}
}
.oyster-modal{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: none;
	&__close{
		width: 50px;
		height: 50px;
		background: url(../img/oyster/btn-close.png) no-repeat center/100%;
		position: absolute;
		top: 30px;
		right: 40px;
		cursor: pointer;
		border: none;
		outline: 0;
		@include mq{
			top: 16px;
			right: 20px;
			width: 36px;
			height: 36px;
		}
	}
	&__wrapper{
		max-width: 1160px;
		width: 90%;
		background: #F3F3F3;
		border-radius: 8px;
		padding: 50px 100px;
		margin: 0 auto;
		box-shadow: 0 0 10px rgba(#000, 0.16);
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		// height: 80vh;
		// max-height: 850px;
		// overflow: hidden;
		@include mq(tb){
			padding: 40px 16px;
		}
		@include mq{
			padding: 50px 16px;
			max-width: 600px;
			width: 92.3%;
		}
	}
	&__ttl{
		margin-bottom: 52px;
		@include mq(tb){
			margin-bottom: 30px;
		}
	}
	&__content-scroll{
		// height: 608px;
		max-height: 60vh;
		overflow-y: scroll;
		@include mq(tb){
			height: 55vh;
		}
		@include mq{
			height: 63vh;
		}
	}
	&__dl{
		margin-bottom: 40px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__dt{
		@include font-size(16);
		font-weight: 500;
		margin-bottom: 12px;
		@include flex(0,0,wrap);
		gap: 0 40px;
		@include mq{

		}
		p{
			position: relative;
			padding-left: 20px;
			@include mq(sm){
				width: 100%;
				margin-bottom: 5px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			span{
				color: #566081;
			}
			@include p-before(16px, 16px){
				background: #566081;
				top: 5px;
				left: 0;
				border-radius: 50%;
			}
			&.txt-yellow{
				span{
					color: #D0AF8E;
				}
				@include p-before(16px, 16px){
					background: #D0AF8E;
					top: 5px;
					left: 0;
					border-radius: 50%;
				}
			}
		}
	}
	&__dd{
		@include font-size(14);
		line-height: 2;
		@include mq{
			@include fts(12,18);
		}
		p{
			margin-bottom: 17px;
		}
	}
	&__gallery{
		max-width: 600px;
		width: 100%;
		@include flex(between,0,wrap);
		li{
			@include mq{
				margin-bottom: 8px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&:nth-child(1){
				width: 25.83%;
				@include mq{
					width: 37.4%;
				}
			}
			&:nth-child(2){
				width: 41.67%;
				@include mq{
					width: 60.42%;
				}
			}
			&:nth-child(3){
				width: 29.33%;
				@include mq{
					width: 44.47%;
				}
			}
		}
	}
}