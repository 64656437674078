@use "vars" as *;


.sec-restaurants{
	position: relative;
}

.restaurants-img{
	position: relative;
	height: 324px;
	overflow: hidden;
	@include mq(xl){
		height: 237px;
	}
	@include mq(){
		height: 192px;
	}
	@include p-before(100%,100%){
		background: rgba(#33373B,0.7);
		top: 0;
		left: 0;
		z-index: 2;
		pointer-events: none
	}
	&__loop{
		@include flex(0,0,wrap);
		flex-wrap: nowrap;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
		flex-direction: row;
		white-space: nowrap;
		-webkit-animation: slider-move 50s linear infinite;
		animation: slider-move 50s linear infinite;
		// &:hover{
		// 	-webkit-animation-play-state: paused;
		// 	animation-play-state: paused;
		// }
		&--02{
			-webkit-animation: slider-move 45s linear infinite;
			animation: slider-move 45s linear infinite;
		}
		&-item{
			width: 546px;
			@include aspect-ratio(546px,324px);
			position: relative;
			@include mq(xl){
				width: 400px;
				// &::before{
				// 	padding-top: 90%;
				// }	
			}
			@include mq(){
				width: 390px;
				@include aspect-ratio(390px,192px);
			}
		}
	}
}

.restaurants-box{
	position: absolute;
	max-width: 688px;
	width: 100%;
	background: #fff;
	border-radius: 16px;
    padding: 80px;
	top: 51%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 3;
	@include mq(xl){
		padding: 6.4252%;
		width: 60.26%;
	}
	@include mq(){
		padding: 35px 17px 41px;
		max-width: 100%;
		width: 100%;
		position: static;
		transform: translate(0,0);
	}
	.c-ttl__02{
		margin-bottom: 38px;
		@include mq(){
			margin-bottom: 21px;
			line-height: 1.6818;
			@include font-size(22);
		}
		@include mq(xs){
			@include font-size(18);
		}
	}
	.c-txt__01{
		margin-bottom: 50px;
		@include mq(){
			margin-bottom: 26px;
			line-height: 2.285;
		}
	}
}

.sec-feature{
    padding: 0 0 119px;
	@include mq(xl){
		padding-bottom: 80px;
	}
	@include mq(){
		padding: 57px 0 54px;
	}
}

.feature-flex{
	@include flex(0,center);
	margin-bottom: 73px;
	padding-left: 104px;
	@include mq(xl){
		padding-left: 30px;
		margin-bottom: 50px;
	}
	@include mq(){
		flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 61px;
	}
	&__ct{
		width: calc(43% + 120px);
		margin-right: -120px;
		position: relative;
		z-index: 2;
		padding-top: 50px;
		@include mq(lp){
			padding-top: 0;
		}
		@include mq(xl){
			width: 50%;
			margin-right: 0;
		}
		@include mq(){
			padding: 0 16px;
			width: 100%;
			margin-bottom: 26px;
		}
		.c-ttl__01{
			margin-bottom: 61px;
			@include mq(lp){
				margin-bottom: 25px;
			}
			@include mq(xl){
				margin-bottom: 10px;
			}
			@include mq(){
				margin-bottom: 28px;
			}
		}
		.c-ttl__02{
			@include mq(xl){
				margin-bottom: 0;
			}
		}
	}
	&__img{
		width: 57%;
		@include mq(xl){
			width: 50%;
		}
		@include mq(){
			width: 100%;
		}
		img{
			width: 100%;
		}
	}	
}

.feature-list{
	&__item{
		@include flex(0,center);
		@include mq(){
			flex-wrap: wrap;
		}
		&:not(:last-child){
			margin-bottom: 40px;
			@include mq(){
				margin-bottom: 56px;
			}
		}
	}
	&__img{
		width: 45.25%;
		@include aspect-ratio(524px,348px);
		@include mq(){
			width: 100%;
			margin-bottom: 28px;
		}
	}
	&__ct{
		width: 54.75%;
		padding-left: 112px;
		@include mq(lp){
			padding-left: 25px;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
		}
		.c-ttl__04{
			margin-bottom: 26px;
			@include mq(xl){
				margin-bottom: 9px;
			}
		}
	}
}

.sec-safe{
	padding: 129px 0 120px;
	background: #F3F3F3;
	@include mq(lp){
		padding: 100px 0;
	}
	@include mq(){
		padding: 56px 0 60px;
	}
}

.safe-box{
	background: #fff;
	border-radius: 16px;
	padding: 71px 15px 66px;
    margin-bottom: 56px;
	@include mq(xl){
		padding: 50px 5px;
		margin-bottom: 40px;
	}
	@include mq(){
		padding: 50px 16px;
		margin-bottom: 35px;
	}
	&__list{
		max-width: 1200px;
		margin: 0 auto;
		width: 100%;
		@include flex(0,0,wrap);
		&-item{
			width: 33.33%;
			padding: 0 20px;
			@include mq(xl){
				padding: 0 10px;
			}
			@include mq(){
				padding: 0;
				width: 100%;
				&:not(:last-child){
					margin-bottom: 53px;
				}
			}
			.c-ttl__05{
				text-align: center;
				margin-bottom: 16px;
				line-height: 1.7;
				@include mq(){
					margin-bottom: 6px;
				}
			}
		}
		&-img{
			margin-bottom: 28px;
			border-radius: 8px;
			overflow: hidden;
			@include aspect-ratio(360px,240px);
			@include mq(xl){
				margin-bottom: 15px;
			}
		}
	}
}

.sec-voice{
	padding: 129px 0 70px;
	@include mq(xl){
		padding: 100px 0 60px;
	}
	@include mq(){
		padding: 57px 0 56px;
	}
}

.voice-step{
	margin: 0 -20px 112px;
	@include flex(0,0,wrap);
	@include mq(xl){
		margin: 0 -10px 70px;
	}
	@include mq(){
		margin: 0 0 54px;
		gap: 21px 0;
	}
	&__item{
		width: 33.33%;
		padding: 0 20px;
		@include mq(xl){
			padding: 0 10px;
		}
		@include mq(){
			padding: 0;
			width: 100%;
		}
	}
	&__box{
		padding: 36px 15px;
		box-shadow: 0 3px 6px rgba(#000,0.16);
		border-radius: 8px;
		@include mq(){
		    padding: 30px 15px;
		}
		.c-ttl__05{
			margin-bottom: 20px;
			text-align: center;
			@include mq(){
				@include font-size(21);
			}
		}
		&-number{
			text-align: center;
			line-height: 1;
			color: #000;
			margin-bottom: 32px;
			@include flex(center,center);
			@include font-size(16);
			@include mq(){
				margin-bottom: 26px;
			}
			span{
				margin: 0 10px;
				font-family: $font-BodoniModa;
				font-weight: bold;
				color: #0A1F36;
				@include font-size(80);
				@include mq(xl){
					@include font-size(50);
				}
				@include mq(){
					@include font-size(80);
				}
			}
		}
		&-icon{
			text-align: center;
			img{
				height: 48px;
				width: auto;
				@include mq(){
					height: 56px;
				}
			}
		}
	}
}

.sec-deliver{
    padding: 131px 0 30px;
	background: #F0F0EF;
	@include mq(xl){
		padding: 90px 0 20px;
	}
	@include mq(){
		padding: 56px 0 39px;
	}
	.c-ttl__01{
		margin-bottom: 64px;
		@include mq(xl){
			margin-bottom: 35px;
		}
		@include mq(){
			margin-bottom: 31px;
		}
	}
}

.deliver-banner{
	margin-bottom: 54px;
	@include mq(){
		margin: 0 -16px 30px;
	}
	img{
		width: 100%;
	}
}

.deliver-list{
    margin-bottom: 80px;
	@include mq(){
		margin-bottom: 57px;
	}
	&__item{
		border-bottom: 1px solid #DBDAD2;
	    padding: 52px 0 56px;
		@include flex(0,center);
		position: relative;
		padding-left: 130px;
		@include mq(xl){
			padding: 35px 0 35px 60px;
		}
		@include mq(){
			flex-wrap: wrap;
			padding: 28px 0 23px;
		}
		&:nth-child(1){
			border-top: 1px solid #DBDAD2;
		}
		.c-ttl__02{
			padding-right: 20px;
			width: 49%;
			margin-bottom: 0;
			@include mq(xl){
				width: 55%;
				padding-right: 15px;
			}
			@include mq(){
				width: calc(100% - 69px);
				padding-right: 0;
				padding-left: 31px;
			}
		}
		.c-txt__01{
			width: 51%;
			@include mq(xl){
				width: 45%;
			}
			@include mq(){
				width: 100%;
				margin-top: 12px;
				padding: 0 16px;
			}
		}
	}
	&__icon{
		width: 90px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		@include mq(xl){
			width: 50px;
		}
		@include mq(){
			width: 69px;
			position: static;
			transform: translate(11px,-2px);
		}
		img{
			width: 100%;
		}
	}
	
}

.deliver-box{
	background: #fff;
	border-radius: 16px;
	padding: 64px 9.445% 70px;
    margin-bottom: 83px;
	@include mq(xl){
		//margin-bottom: 50px;
		//padding: 40px 15px;
		padding: 40px 6.09%;
	}
	@include mq(){
		padding: 54px 15px 40px;
		margin-bottom: 20px;
	}
	.c-ttl__04{
		margin-bottom: 68px;
		@include mq(xl){
			margin-bottom: 30px;
		}
		@include mq(){
			margin-bottom: 45px;
		}
	}
	.guide-list--number{
		max-width: 1160px;
		margin: 0 auto;
		width: 100%;
		.guide-list__item{
			padding-bottom: 40px;
			margin-bottom: 39px;
			@include mq(){
				padding-bottom: 38px;
				margin-bottom: 35px;
			}
			&:last-child{
				padding-bottom: 0;
				margin-bottom: 0;
				border-bottom: 0;
			}
		}
		@include mq(){
			.guide-list__ct{
				&::before {
					top: 10px;
				}
				.c-ttl__05 {
					margin-bottom: 7px;
				}
			}
		}
	}
	&__flex{
		@include flex(0,start,wrap);
		max-width: 1160px;
		margin: 0 auto;
		width: 100%;
		padding-top: 3px;
		@include mq(){
			padding-top: 0;
			margin-top: -7px;
		}
		&-ct{
			margin-top: -9px;
			width: 54.75%;
			padding-right: 112px;
			@include mq(lp){
				padding-right: 25px;
			}
			@include mq(){
				padding-right: 0;
				width: 100%;
				margin-bottom: 12px;
			}
			.c-ttl__05{
				margin-bottom: 10px;
			}
			.c-txt__01{
				margin-bottom: 33px;
				@include mq(){
					margin-bottom: 0;
				}
			}
			.c-btn__05{
				margin-bottom: 10px;
			}
		}
		&-img{
			width: 45.25%;
			@include aspect-ratio(524px,360px);
			@include mq(){
				width: 100%;
			}
		}
	}
}

.sec-flow{
	padding: 129px 0 0;
	@include mq(xl){
		padding: 90px 0 0;
	}
	@include mq(){
		padding: 56px 0 0;
		.eoyster-box{
			top: 37.5%;
		}
		.eoyster-bg img {
			min-height: 707px;
			max-height: 707px;
		}
	}
}

.sec-flow02{
	padding-bottom: 118px;
	@include mq(xl){
		padding-bottom: 90px;
	}
	@include mq(){
		padding-bottom: 60px;
	}
}

.flow-pdf{
	margin-bottom: 119px;
	padding-top: 42px;
	@include mq(xl){
		padding-top: 0;
		margin-bottom: 56px;
	}
	&__item{
		@include flex(0,center);
		@include mq(){
			flex-wrap: wrap;
		}
		&:not(:last-child){
			margin-bottom: 49px;
			border-bottom: 1px solid #dbdad2;
			padding-bottom: 46px;
			@include mq(xl){
				padding-bottom: 39px;
				margin-bottom: 34px;
			}
		}
	}
	&__ct{
		width: calc(100% - 360px);
		padding-right: 110px;
		@include mq(xl-min){
			&--02{
				padding-top: 24px;
			}
		}
		@include mq(lp){
			padding-right: 25px;
		}
		@include mq(xl){
			width: calc(100% - 220px);
		}
		@include mq(){
			width: 100%;
			margin-bottom: 40px;
			padding-right: 0;
		}
		.c-ttl__04{
			margin-bottom: 19px;
			@include mq(){
				margin-bottom: 10px;
			}
		}
	}
	&__btn{
		margin-top: 49px;
		@include flex(0,0,wrap);
		gap: 30px 40px;
		@include mq(){
			margin-top: 24px;
		}
		&-item{

		}
	}
	&__img{
		width: 360px;
		border-radius: 8px;
		overflow: hidden;
		@include aspect-ratio(360px,220px);
		@include mq(xl){
			width: 220px;
		}
		@include mq(){
			width: 91%;
			margin: 0 auto;
			&::before{
				padding-top: 54.111%;
			}
		}
	}
}