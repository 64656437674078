@use "vars" as *;
.scroll-sp{
	@include mq(){
		overflow-x: auto;
		margin-bottom: 74px;
		width: 100%;
	}
}

.list-menu{
	@include flex(center,center,wrap);
	gap: 10px 40px;
	margin-bottom: 112px;
	@include mq(){
		max-width: 875px;
		width: 100%;
		margin-bottom: 0;
		justify-content: flex-start;
		gap: 0 20px;
	}
	&__item{
		a{
			display: inline-block;
			color: #000;
			border-radius: 30px;
			font-weight: 500;
			padding: 12.5px 0;
			@include font-size(18);
			@include mq(){
				padding: 11px 24px;
				@include font-size(16);
			}
			&:hover{
				padding-left: 24px;
				padding-right: 24px;
				opacity: 1;
				color: #fff;
				background: #0D2641;
			}
		}
		
		&.active{
			a{
				color: #fff;
				background: #0D2641;
				padding-left: 24px;
				padding-right: 24px;
			}
		}
	}
}

.sec-shop{
	.list-menu{
		@include mq{
			flex-wrap: nowrap;
			width: max-content;
		}
		&__item{
			a{
				@include mq{
					padding: 13px 0;
				}
				&:hover{
					padding: 12.5px 24px;
				}
			}
			&.active{
				a{
					@include mq{
						padding: 12.5px 24px;
					}
				}
			}
		}
	}
}

.shop-list{
	&__item{
		@include flex(0,0,wrap);
		&:not(:last-child){
			margin-bottom: 63px;
			@include mq(){
				margin-bottom: 10px;
			}
		}
		.c-ttl__04{
			width: 136px;
			padding-right: 10px;	
			@include mq(xl){
				width: 100px;
			}
			@include mq(){
				width: 100%;
				margin-bottom: 32px;
				padding-right: 0;
			}
		}
	}
	&__main{
		padding-top: 71px;
		width: calc(100% - 136px);
		@include mq(xl){
			padding-top: 5px;
			width: 100%;
			width: calc(100% - 100px);
		}
		@include mq(){
			width: 100%;
			padding-top: 0;
		}
		&-img{
			margin-bottom: 40px;
			width: 100%;
			@include mq(sm-min){
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 15px;
			}
			@include mq(sm){
				margin-bottom: 30px;
				margin-left: -15px;
				margin-right: -15px;
				width: calc(100% + 30px);
				&Item{
					@include aspect-ratio(39,24);
				}
			}
		}
		&-item{
			@include flex(0,center,wrap);
			padding-bottom: 57px;
			margin-bottom: 63px;
			border-bottom: 1px solid #DBDAD2;
			@include mq(xl){
				padding-bottom: 40px;
				margin-bottom: 33px;
				flex-wrap: wrap;
			}
		}
		&-ct{
			width: calc(100% - 320px);
			padding-right: 20px;
			@include mq(lp){
				width: calc(100% - 250px);
			}
			@include mq(xl){
				width: 100%;
				margin-bottom: 20px;
				padding-right: 0;
			}
			@include mq(){
				width: calc(100% - 250px);
				margin-bottom: 24px;
			}
			@include mq(sm){
				width: 100%;
			}
			.c-ttl__05{
				a {
					color: inherit;
					background: linear-gradient(#333, #333) 100% 100% / 0 1px no-repeat;
					transition: background-size .6s cubic-bezier(.25,1,.5,1) 0s;
					will-change: background-size;
					&:hover {
						background-position: 0 100%;
						background-size: 100% 1px;
					}
				}
			}
			.list-tags{
				margin-bottom: 10px;
			}
			.c-btn__03{
				//margin-left: 19px;
				@include mq(){
					//transform: translateY(-7px);
					//margin-left: 0;
					span{
						border-bottom: 0;
						padding-bottom: 0;
						text-decoration: underline;
						@include font-size(12);
					}
				}
			}
			.c-txt__01{
				margin-bottom: 4px;
			}
		}
		&-desc{
			dl{
				@include flex();
				dt{
					//width: 82px;
					width: 5.2em;
					//@include mq(xl){
					//	width: 72px;
					//}
					//@include mq(xs){
					//	width: 63px;
					//}
				}
				dd{
					width: calc(100% - 5.2em);
					//width: calc(100% - 82px);
					//@include mq(xl){
					//	width: calc(100% - 72px);
					//}
					//@include mq(xs){
					//	width: calc(100% - 63px);
					//}
				}
				&.salary {
					dt {
						width: 7.1em;
					}
					dd {
						width: calc(100% - 7.1em);
					}
				}
			}
		}
		&-btn{
			width: 320px;
			padding-top: 45px;
			@include mq(lp){
				width: 250px;
			}
			@include mq(xl){
				width: 100%;
				padding-top: 0;
			}
			@include mq(){
				width: 250px;
			}
			@include mq(sm){
				width: 100%;
			}
			.c-btn__02{
				&:not(:last-child){
					margin-bottom: 25px;
					@include mq(xl){
						margin-bottom: 10px;
					}
					@include mq(){
						margin-bottom: 24px;
					}
				}
			}
		}
	}
}


